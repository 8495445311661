import { createContext, useReducer, useContext, useState } from "react";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
import utils from "../utils/utils";
//This to become serverside lookup
//{"rid": "#13:926","citype": "#12:189","name": "Cheeky","uuid": "SFRTKLTFR-fd70f230-bcd4-4088-85ff-787955b6bc9b",},

var def_value = [];
var ajaxstack = false;
const cultivarsReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_CULTIVARS":
			return {
				cultivars: action.cultivars
			};
		case "ADD_CULTIVAR":
			return {
				cultivars: [...state.cultivars, action.cultivar]
			};
		case "EDIT_CULTIVAR":
			let tcultivars = [];
			utils.each(state.cultivars, function (cultivar, cultivarind) {
				if (cultivar.uuid !== action.cultivar.uuid) {
					tcultivars.push(cultivar);
				} else {
					tcultivars.push(action.cultivar);
				}
			});
			return {
				cultivars: tcultivars
			};
		default:
			return state;
	}
};

export const CultivarsContext = createContext();

const CultivarsProvider = ({ children }) => {
	const cultivars = def_value;
	const [state, dispatch] = useReducer(cultivarsReducer, {
		cultivars: cultivars,
		filters: {}
	});
	// let ts = localStorage.getItem("cultivars");
	// const [cultivarsLookupState,setCultivarsLookup] = useState((utils.isset(ts)&&!utils.isempty(ts)?JSON.parse(ts):[]));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const value = {
		cultivars: state.cultivars,
		addCultivar: (cultivar, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }

			var params = {
				data: cultivar
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/cultivar", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "ADD_CULTIVAR", cultivar });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
			dispatch({ type: "ADD_CULTIVAR", cultivar });
		},
		getCultivars: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let cultivarFetchRules = utils.getLocalStorageItem("cultivarFetchRules");
			
			// if (!utils.isempty(cultivarFetchRules)) {
			// 	if (cultivarFetchRules.filtered === false&&cultivarFetchRules.trycache) {
			// 		let cultivars = utils.getLocalStorageItem("cultivars");
			// 		if(!utils.isempty(cultivars)) {
			// 			dispatch({ type: "REFRESH_CULTIVARS", cultivars });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	cultivarFetchRules = {trycache:true,filtered:true};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/cultivars", params).then(
				function (response) {
					if (response.data.success) {
						var cultivars = [];
						utils.each(response.data.data, function (cultivar, cultivarid) {
							//console.warn('org',org);
							cultivars.push(cultivar);
						});
						if (lookup !== false) {
							// setCultivarsLookup(cultivars);
							// dispatch({type:"REFRESH_CULTIVARS",cultivars});
							localStorage.setItem("cultivarsLookup", JSON.stringify(cultivars));
						} else {
							dispatch({ type: "REFRESH_CULTIVARS", cultivars });
						}
						//responsereceived= true;
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setCultivarsLookup([]);
						}
					}
					// cultivarFetchRules.filtered = false;
					// localStorage.setItem("cultivarFetchRules", JSON.stringify(cultivarFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editCultivar: (cultivar, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }

			var params = {
				data: cultivar
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/cultivar", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "EDIT_CULTIVAR", cultivar });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		// cultivarsLookupState:cultivarsLookupState,
		// setCultivarsLookup:setCultivarsLookup,
		getCultivarCIType: () => {
			return "ci_cultivar";
		},
		clearCultivars: () => {
			// setCultivarsLookup([]);
			let empty = [];
			// dispatch({type:"REFRESH_CULTIVARS",empty});
			localStorage.setItem("cultivarsLookup", JSON.stringify(empty));
		}
	}
	return (
		<CultivarsContext.Provider value={value}>
			{children}
		</CultivarsContext.Provider>
	);
};
export default CultivarsProvider;

