// @material-ui
import { makeStyles } from '@material-ui/core/styles';

// Material UI hook used for styling
// makeStyles - function that accepts styles object
// makeStyles - returns hook which we assign to our cutsom hook called "useStyles"

// Using @material ui theme spacing helper - for consistent spacing
// we access spacer helper by passing back callback function that retuerns the style object in makestyles
// then we have access to theme arg which hass access to spacing helper
export const useStyles = makeStyles((theme) => ({
    appRoot: {
        display: 'flex',
        backgroundColor: '#fafafa;',
    },
    appBar: {
        backgroundColor: '#ce6177',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    appBarSpacer: {
        [theme.breakpoints.down('xs')]: {
            ...theme.mixins.toolbar,
        }
    },
    appRootMainGridContainer: {
        display: 'flex',
        marginTop: '15px',
    },
    appGridContainer: {
        width: '100%',
        maxHeight: '97vh',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        padding: '8px',
        overflow: 'hidden',
    },
    navigationContainer: {
        paddingRight: '20px',
    },
    navigationLogoCollapsed: {
        width: '70%',
        cursor: 'pointer',
    },
    navigationLogoOpen: {
        width: '85%',
        cursor: 'pointer',
    },
    navigationLogoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: theme.spacing(6),
    },
    navigationDrawer: {
        width: 260,
        border: 'none',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        position: 'relative !important',
        height: '100vh !important',
        backgroundColor: '#fafafa;',
    },
    navigationDrawerCollapse: {
        width: theme.spacing(9),
    },
    navigationToolbarOpen: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: theme.spacing(1),
        ...theme.mixins.toolbar,
    },
    navigationToolbarCollapsed: {
        justifyContent: 'center',
        paddingRight: 0,
    },
    navigationListContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    navigationList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    navigationBottomContainer: {
        paddingTop: '15px',
        paddingBottom: '15px',
    },
    toolbarOptionsContainer: {
        marginTop: '5px',
    },
    toolbarOptionsBtn: {
        margin: '4px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    toolbarOptionsBtnActive: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#ebebec;',
    },
    toolbarOptionsCollapse: {
        width: theme.spacing(9),
    },
    menuItemIcon: {
        width: '100%',
    },
    menuItemIconBtn: {
        padding: '5px',
    },
    menuItem: {
        width: '85%',
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    menuItemActive: {
        backgroundColor: '#ebebec',
    },
    menuItemSVGIcon: {
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        minWidth: '50px !important',
    },
    collapsedMenuItem: {
        width: '100%',
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    censusResigterContaier: {
        display: 'flex',
        flex: '1',
    },
    mapContainer: {
        display: 'flex',
        flex: '1',
        height: '100%',
        flexDirection: 'column',
    },
    mapFunctionContainer: {
        flexBasis: '40%',
        borderRadius: '10px',
        margin: '15px',
        marginRight: '0px',
        padding: '8px',
    },
    chartContainer: {
        flexBasis: '50%',
        borderRadius: '10px',
        margin: '15px 15px 15px 0px',
        marginRight: '0px',
        padding: '8px',
    },
    chartChildContainer: {
        display: 'flex',
        flex: '1',
        height: '100%',
        flexDirection: 'column',
    },
    cardContainer: {
        border: '1px solid green',
        borderRadius: '4px',
    },
    mapBtn: {
        width: 80,
        border: '1px solid orange',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        position: 'relative',
        height: '100vh',
        backgroundColor: '#fafafa;',
        // background-color: #ebebec;
    }

}));