import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;
const plantSprayProductsReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_PLANTSPRAYPRODUCTS":
			return {
				plantsprayproducts: action.plantsprayproducts
			};
		case "ADD_PLANTSPRAYPRODUCTS":
			return {
				plantsprayproducts: [...state.plantsprayproducts, action.plantsprayproduct]
			};
		case "EDIT_PLANTSPRAYPRODUCTS":
			let tplantsprayproducts = [];
			utils.each(state.plantsprayproducts, function (plantsprayproduct, plantsprayproductind) {
				if (plantsprayproduct.uuid !== action.plantsprayproduct.uuid) {
					tplantsprayproducts.push(plantsprayproduct);
				} else {
					tplantsprayproducts.push(action.plantsprayproduct);
				}
			});
			return {
				plantsprayproducts: tplantsprayproducts
			};
		default:
			return state;
	}
};

export const PlantSprayProductsContext = createContext();

const PlantSprayProductsProvider = ({ children }) => {
	const plantsprayproducts = def_value;
	const [state, dispatch] = useReducer(plantSprayProductsReducer, {
		plantsprayproducts: plantsprayproducts,
		filters: {}
	});
	// let ts = localStorage.getItem("plantsprayproducts");
	// const [plantsprayproductsLookupState, setPlantsprayproductsLookup] = useState((utils.isset(ts) && !utils.isempty(ts) ? JSON.parse(ts) : []));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);

	const value = {
		plantsprayproducts: state.plantsprayproducts,
		addPlantSprayProduct: (plantsprayproduct, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: plantsprayproduct
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/plantsprayproduct", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "ADD_PLANTSPRAYPRODUCTS", plantsprayproduct });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		getPlantSprayProduct: (id) => {
			let tplantsprayproduct = {};
			utils.each(state.plantsprayproducts, function (plantsprayproduct, plantsprayproductind) {
				if (plantsprayproduct.uuid === id) {
					tplantsprayproduct = plantsprayproduct;
					return false;
				}
			});
			return tplantsprayproduct;
		},
		getPlantSprayProducts: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let plantSprayProductsFetchRules = utils.getLocalStorageItem("plantSprayProductsFetchRules");
			
			// if (!utils.isempty(plantSprayProductsFetchRules)) {
			// 	if (plantSprayProductsFetchRules.filtered === false&&plantSprayProductsFetchRules.trycache) {
			// 		let plantsprayproducts = utils.getLocalStorageItem("pmzones");
			// 		if(!utils.isempty(plantsprayproducts)) {
			// 			dispatch({ type: "REFRESH_PLANTSPRAYPRODUCTS", plantsprayproducts });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	plantSprayProductsFetchRules = {trycache:true,filtered:true};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/plantsprayproducts", params).then(
				function (response) {
					if (response.data.success) {
						var plantsprayproducts = [];
						utils.each(response.data.data, function (plantsprayproduct, plantsprayproductid) {
							if(utils.isset(plantsprayproduct.isused)&&plantsprayproduct.isused!=1) { return true; }
							plantsprayproducts.push(plantsprayproduct);
						});
						if (lookup !== false) {
							//setPlantsprayproductsLookup(plantsprayproducts);
							localStorage.setItem("plantSprayProductsLookup", JSON.stringify(plantsprayproducts))
							//localStorage.setItem("plantsprayproducts", JSON.stringify(plantsprayproducts))
						} else {
							//dispatch({ type: "REFRESH_PLANTSPRAYPRODUCTS", plantsprayproducts });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setPlantsprayproductsLookup([]);
						}
					}
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editPlantSprayProduct: (plantsprayproduct, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: plantsprayproduct
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/plantsprayproduct", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "EDIT_PLANTSPRAYPRODUCTS", plantsprayproduct });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		// plantsprayproductsLookupState: plantsprayproductsLookupState,
		// setPlantsprayproductsLookup: setPlantsprayproductsLookup,
		getPlantSprayProductCIType: () => {
			return "ci_plantsprayproduct_global";
		},
		clearPlantSprayProducts: () => {
			//setPlantsprayproductsLookup([]);
			let empty = [];
			// dispatch({ type: "REFRESH_PLANTSPRAYPRODUCTS", empty });
			localStorage.setItem("plantSprayProductsLookup", JSON.stringify(empty))
		}
	}
	return (
		<PlantSprayProductsContext.Provider value={value}>
			{children}
		</PlantSprayProductsContext.Provider>
	);
};
export default PlantSprayProductsProvider;

