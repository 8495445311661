import React, { useState, useContext, useEffect, useRef } from 'react'
import utils from "../../utils/utils";
import { v4 as uuidv4 } from 'uuid';
import { metaphone } from 'metaphone';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from 'moment';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import styled from '@emotion/styled';
import parse from 'html-react-parser';
import { SessionsContext } from '../../contexts/sessions';
import { PestInspectionsContext } from '../../contexts/pestinspections';
import { StateManagerContext } from '../../contexts/statemanager';

//Dialog
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { FormHelperText } from '@mui/material';

//Theme
const ThemeFormTextField = styled(TextField)(utils.theme.FormTextField);
const ThemeSelect = styled(Select)(utils.theme.Select);
//const ThemeTextField = styled(TextField)(utils.theme.TextField);
const color = "#7A9A01";
const theme = createTheme({
	components: {
		MuiIconButton: {
			styleOverrides: {
				sizeMedium: {
					color
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color
				}
			}
		}
	}
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export function Step001({ formData, setFormData, formErrors, farms, pmzones, persons, inspectionTemplates, formAction, formTemplate }) {

	let it = { data: {} };
	utils.each(inspectionTemplates, function (inspectiontemplate, inspectiontemplateind) {
		if (formAction === "edit" && formData.template === inspectiontemplate.ref) { it = inspectiontemplate; return false; }
		if (formAction === "add" && formTemplate === inspectiontemplate.ref) { it = inspectiontemplate; return false; }
	});
	const [open, setOpen] = useState(false);
	const [editRowInd, setEditRowInd] = useState(null);
	const handleClickOpen = () => { setOpen(true); };
	const handleClose = () => { setOpen(false); };
	const inspectioRef = useRef(null);
	const { getCookieData } = useContext(SessionsContext);
	var cookieData = getCookieData();

	var groupscolspan = {};
	var htr1 = "<tr><th colspan='1'></th>";
	var htr2 = "<tr><th>Tree</th>";
	it.data = typeof it.data === 'string' ? JSON.parse(it.data) : it.data;
	utils.each(it.data.columns, function (col, colid) {
		if (!utils.isset(groupscolspan[col.group])) { groupscolspan[col.group] = 0; }
		groupscolspan[col.group]++;
		htr2 += "<th style='text-align:center; background-color: beige;'>" + col.label + "</th>";
	});

	utils.each(groupscolspan, function (val, gid) {
		if (!utils.isempty(it.data.groups[gid])) { htr1 += "<th colspan='" + val + "' style='text-align:center; background-color: beige;'>" + it.data.groups[gid].label + "</th>"; }
	});
	htr1 += "</tr>";
	htr2 += "</tr>";

	var inspectionrowcount = 0;
	var maxspin = 100;
	var minspin = 0;
	var spinstep = 1;

	var labels = {
		"tmb": ["T", "M", "B"],
	};
	const updateData = (event) => {
		var inspectiontemplate = null;
		utils.each(inspectionTemplates, function (it, itind) {
			if (formData.template === it.ref) {
				inspectiontemplate = it;
			}
		});
		if (inspectiontemplate === null) { return ""; }
		var table = document.getElementById("inspectiontable001");
		var inspection = [];
		var rowind = -1;
		//console.warn("table",table);
		utils.each(table.rows, function (row, colind) {
			if (utils.isset(row.attributes["data-ignoreme"])) { return true; }
			if (rowind === -1) { rowind = parseInt(row.attributes["data-inspind"].value); }
			var metric = { count: 0, sample: [] }
			utils.each(row.cells, function (col, colind) {
				if (utils.isset(col.attributes["data-ignoreme"])) { return true; }
				utils.each(col.children, function (obj, objind) {
					if (obj.type === "checkbox") {
						if (obj.checked) {
							metric.sample.push(1);
							metric.count++;
						} else {
							metric.sample.push(0);
						}
					} else {
						//console.warn("obj",obj);
						let val = parseFloat(obj.valueAsNumber);
						metric.sample.push(val);
					}
				});
				inspection.push(metric);
			});
		});
		let inspection2 = [];
		for (var x in inspection) {
			var colmeta = inspectiontemplate.data.columns[x];
			if (colmeta.type === 'sum_metrics' || (colmeta.sampletype === 'computable' && colmeta.computeop === 'sum_metrics')) {
				let indexes = [];
				if (utils.notEmpty(colmeta.sum_metrics)) {
					indexes = colmeta.sum_metrics.split(',');
				} else {
					indexes = colmeta.metrics.split(',');
				}
				var sumtotal = 0;
				for (let mi = 0; mi < indexes.length; mi++) {
					var colind = parseFloat(indexes[mi]);
					if (utils.isset(inspection[colind])) {
						for (let si in inspection[colind].sample) {
							sumtotal += parseInt(inspection[colind].sample[si]);
						}
					}
				}
				inspection2[x] = {};
				inspection2[x].sample = [(sumtotal > 0 ? 1 : 0)];
				inspection2[x].count = sumtotal;
			} else {
				inspection2[x] = {};
				inspection2[x].sample = [];
				inspection2[x].count = 0;
				var tally = 0;
				var count = 0;

				for (var i in inspection[x].sample) {
					var val = 0;
					if (inspection[x].sample[i]) {
						val = parseInt(inspection[x].sample[i]);
					}
					inspection2[x].sample.push(val);
					inspection2[x].count += val;
					count++;
					tally += val;
				}
				if (utils.isset(colmeta.sampletally) && colmeta.sampletally === 'avg') {
					inspection2[x].count = (count > 0 ? tally / count : 0);
				}
			}
		}
		inspection = inspection2;
		let tformData = formData;

		if (!utils.isempty(inspection) && rowind !== -1 && utils.isset(tformData.rows[rowind])) {
			tformData.rows[rowind].inspection = inspection;
		}

		let totals = [];
		utils.each(inspectiontemplate.data.columns, function (colmeta, tmplcolind) {
			utils.each(tformData.rows, function (insprow, treeind) {
				let inspmetric = insprow.inspection[tmplcolind];
				if (!totals[tmplcolind]) { totals[tmplcolind] = { total: 0, samples: 0 }; }
				if (inspmetric.count !== 0 || (inspmetric.count === 0 && utils.isempty(colmeta.ignorezero))) {
					totals[tmplcolind].total += inspmetric.count;
					totals[tmplcolind].samples += parseInt(colmeta.samplesize);
				}
			});
		});
		tformData.totals = totals;
		setFormData(tformData);
		setOpen(false);
	}
	const editRow = () => {
		var inspectiontemplate = null;
		inspectiontemplate = it;
		if (inspectiontemplate === null) { return ""; }

		var html = "";
		let rowind = editRowInd;
		let insprow = [];
		if (utils.isset(formData.rows[rowind])) {
			insprow = formData.rows[rowind];
		}
		//console.warn("insprow",insprow,"formData",formData);
		if (utils.isempty(insprow)) { return ""; }

		if (utils.isempty(inspectiontemplate.rowcount)) { inspectionrowcount = 25 } else { inspectionrowcount = inspectiontemplate.rowcount };
		inspectiontemplate.data = typeof inspectiontemplate.data === 'string' ? JSON.parse(inspectiontemplate.data) : inspectiontemplate.data;
		//console.warn("inspectiontemplate.data",inspectiontemplate.data);

		utils.each(inspectiontemplate.data.groups, function (group, gid) {
			html += "<tr data-ignoreme='1'><th colspan='99' style='text-align:center; text-decoration: underline; font-size:2.0em; padding: 20px;'>" + group.label + "</th></tr>";
			//utils.each(formData.rows, function (inspectionrow, inspectionrowind) {
			utils.each(inspectiontemplate.data.columns, function (col, colind) {
				if (col.group !== gid) { return true; }
				if (col.sampletype !== "computable") {
					html += "<tr data-inspind=" + editRowInd + ">";
					html += "<td data-ignoreme='1' style='text-align:right; padding-right: 5px; font-size: 1.2em'>" + col.label + "</td>";
					html += "<td class='inspection-form-checkbox'  style='padding-right: 24px;'>";
					var selected = "";
					for (var i = 0; i < col.samplesize; ++i) {
						var val = 0;
						if (!utils.isempty(col.defaultval)) {
							val = +col.defaultval;
						}
						if (insprow.inspection[colind] && insprow.inspection[colind].sample && insprow.inspection[colind].sample[i]) {
							val = insprow.inspection[colind].sample[i];
						}
						//displayonlytally += val;
						if (utils.isset(col.minstep)) {
							minspin = col.minstep;
						}
						if (utils.isset(col.maxspin)) {
							maxspin = col.maxspin;
						}
						if (utils.isset(col.spinstep)) {
							spinstep = col.spinstep;
						}

						switch (col.sampletype) {
							case "spinner":
								html += '<input type="number" class="" max="' + maxspin + '" min="' + minspin + '" step="' + spinstep + '" value="' + val + '" data-sampleind="' + i + '">';
								break;
							case 'tmb':
								var label = (labels['tmb'][i] ? labels['tmb'][i] : "");
								if (utils.isset(col.labels)) {
									if (utils.isset(col.labels[i])) {
										label = col.labels[i];
									}
								}
								selected = "";
								if (val > 0) {
									selected = " checked";
								}
								html += '<input type="checkbox" class="myinput" value="1" id="" data-sampleind="' + i + '"' + selected + '><span>' + label + '</span>';
								break;
							case "tap":
							default:
								selected = "";
								if (val > 0) {
									selected = " checked";
								}
								html += '<input type="checkbox" class="myinput" value="1" id="" data-sampleind="' + i + '"' + selected + '>';
						}
					}
					html += "</td>";
				} else {

				}
				html += "</tr>";
			});
			//})
		});
		if (html === "") { html += "<tr></tr>"; }
		return html;
	};

	var tr = "";

	if (utils.isset(it.data) && !utils.isempty(it.data)) {
		if (utils.isempty(it.rowcount)) { inspectionrowcount = 25 } else { inspectionrowcount = it.rowcount; };
		it.data = typeof it.data === 'string' ? JSON.parse(it.data) : it.data;
		var trows = !utils.isset(formData.rows) ? [] : formData.rows;
		for (var i = 0; i < inspectionrowcount; i++) {
			if (!utils.isset(trows[i])) { trows[i] = { inspection: [], latitude: 0.00, longitude: 0.00 }; }
			tr += "<tr treind='" + (i) + "'><td style='color: #7A9A01; text-align: center;'>" + (i + 1) + "</td>";
			let treeind = i;
			var td = "";
			for (let colind = 0; colind < it.data.columns.length; colind++) {
				let col = it.data.columns[colind];
				if (!utils.isset(groupscolspan[col.group])) { groupscolspan[col.group] = 0; }
				groupscolspan[col.group]++;
				if (!utils.isset(trows[treeind].inspection[colind])) { trows[treeind].inspection[colind] = { count: 0, sample: false }; }
				td += "<td colind='" + colind + "' style='text-align:center;'>" + trows[treeind].inspection[colind].count + "</td>";
			};
			tr += td + "</tr>";
		}

		formData.rows = trows;

		let totals = [];
		utils.each(it.data.columns, function (colmeta, tmplcolind) {
			utils.each(formData.rows, function (insprow, treeind) {
				let inspmetric = insprow.inspection[tmplcolind];
				if (!totals[tmplcolind]) { totals[tmplcolind] = { total: 0, samples: 0 }; }
				if (inspmetric.count !== 0 || (inspmetric.count === 0 && utils.isempty(colmeta.ignorezero))) {
					totals[tmplcolind].total += inspmetric.count;
					totals[tmplcolind].samples += parseInt(colmeta.samplesize);
				}
			});
		});
		formData.totals = totals;
	}

	var footerrow = "";
	footerrow += "<tr>";
	footerrow += "<th></th>";

	for (var x = 0; x < it.data.columns.length; ++x) {
		//var postfix = "%";
		var format = "{VALUE}%";

		var tally = formData.totals[x].total;
		var postfix = "";
		var colmeta = it.data.columns[x];	//console.warn("FOOT:",x,colmeta);
		if (!utils.isempty(colmeta.format)) {
			format = colmeta.format;
		}
		//console.warn('colmeta.type',colmeta.type);
		// Process aggregation:
		switch (colmeta.type) {
			case 'none':
				tally = 0;
				postfix = "";
				format = "--";
				break;
			case 'weightedavg':
				tally = 0;
				format = "{VALUE}";
				if (colmeta.metrics !== "") {
					let tmetrics = colmeta.metrics.split(",");
					utils.each(tmetrics, function (colindex, arrindex) {
						if (arrindex == 0) {
							tally = formData.totals[colindex].total;
						} else {
							if (!utils.isempty(formData.totals[colindex].total)) {
								tally /= formData.totals[colindex].total;
							}
						}
					});
					if (tally !== 0) {
						tally = parseFloat(tally.toFixed(2));//.toFixed(2);
					}
				}
				break;
			case 'avg2':
				if (tally > 0) { tally = parseFloat((tally / (formData.totals[x].samples)).toFixed(2)); }
				postfix = "";
				format = "{VALUE}";
				break;
			case 'sum_fruitcount':// ASSUMES FIRST METRIC IS FRUITCOUNT!
				if (tally > 0) { tally = ((tally / formData.totals[0].total)).toFixed(2); }
				postfix = "";
				format = "{VALUE}";
				break;
			case 'sum':
				postfix = "";
				format = "{VALUE}";
				break;
			case 'min':
				tally = formData.totals[x].min;
				postfix = "";
				format = "{VALUE}";
				break;
			case 'max':
				tally = formData.totals[x].max;
				postfix = "";
				format = "{VALUE}";
				break;
			case 'count':
				if (tally > 0) { tally = formData.totals[x].samples; }
				postfix = "";
				format = "{VALUE}";
				break;
			default:
				if (colmeta.sampletype === 'spinner' || colmeta.sampletype === 'calc') { //console.warn("Spinner tally: ("+tally+")/("+I.tallies[x].samples+") = ",(tally / (I.tallies[x].samples)),I.tallies[x]);
					if (tally > 0) { tally = parseFloat((tally / (formData.totals[x].samples)).toFixed(2)); }
				} else if (formData.totals[x].samples > 0) {
					//console.log('Tally '+x,I.tallies[x],tally);
					tally = Math.round((tally / formData.totals[x].samples) * 100);
				} else {
					tally = 0;
				}
		}

		formData.totals[x].value = tally;

		footerrow += "<th style='text-align: center'>" + format.replace("{VALUE}", tally) + "</th>";
	}

	footerrow += "<th></th>";
	footerrow += "</tr>";

	return (
		<div className="step001-container">
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: 'relative', backgroundColor: "#c7d494", color: "rgb(122, 154, 1)" }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} style={{ backgroundColor: "#c7d494", color: "rgb(122, 154, 1)" }} variant="h6" component="div">
							Inspection
						</Typography>
						<Button autoFocus color="inherit" onClick={updateData}>
							Update
						</Button>
					</Toolbar>
				</AppBar>
				<Box>
					<table className="inspection-table-main" id={"inspectiontable001"} ref={inspectioRef} style={{ color: '#7A9A01', backgroundColor: '#c7d494', width: '100%' }}><tbody>
						{parse(editRow())}
					</tbody></table>
				</Box>
			</Dialog>


			<Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px' }}>
					<ThemeProvider theme={theme}>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DateTimePicker
									label="Inspection Date"
									ampm={false}
									showDaysOutsideCurrentMonth={true}
									inputFormat="DD/MM/yyyy HH:mm"
									value={(!utils.isempty(formData.date_performed) ? formData.date_performed : moment.format("YYYY-MM-DD HH:mm:ss"))}
									renderInput={(params) => <TextField {...params} />}
									onChange={(tdate) => {
										console.warn("inspection date", tdate);
										setFormData({ ...formData, date_performed: tdate.format("YYYY-MM-DD HH:mm:ss") });
									}}
								/>
							</LocalizationProvider>
						</FormControl>
					</ThemeProvider>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px' }}>
					{formAction === "edit" ?
						<FormControl fullWidth>
							<ThemeFormTextField
								type="text"
								id="performed_by"
								label="Started By"
								disabled={true}
								style={{ textAlign: 'center' }}
								sx={{
									"&.MuiInputBase-input.Mui-disabled": {
										WebkitTextFillColor: "black",
									},
									"&.MuiInputBase-input-MuiOutlinedInput-input": {
										textAlign: 'center'
									}
								}}
								value={(!utils.isempty(formData.performed_by) ? utils.getName(persons, formData.performed_by) : cookieData.name)}
								error={!utils.isempty(formErrors.performed_by) ? true : false}
								helperText={formErrors.performed_by}
							/>
						</FormControl>
						:
						<FormControl fullWidth error={!utils.isempty(formErrors.performed_by) ? true : false}>
							<InputLabel id="email" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size="small">Started By</InputLabel>
							<ThemeSelect
								label="Issue To"
								value={formData.performed_by}
								size="small"
								onChange={(event) => {
									setFormData({ ...formData, performed_by: event.target.value });
								}}
								MenuProps={{
									sx: {
										"&& .Mui-selected": {
											backgroundColor: "#5f7933",
											color: "#fff"
										},
									},
									PaperProps: {
										style: {
											maxHeight: 48 * 4.5 + 8,
											width: 250,
										},
										sx: {
											backgroundColor: "#c7d494",
										}
									}
								}}
							>
								{persons.map((value, ind) => {
									return (
										<MenuItem key={utils.uuid()} value={value.uuid}>{value.name}</MenuItem>
									);
								})}
							</ThemeSelect>
							<FormHelperText>{formErrors.performed_by}</FormHelperText>
						</FormControl>
					}
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px' }}>
					<FormControl fullWidth error={!utils.isempty(formErrors.farm) ? true : false}>
						<InputLabel id="farm" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Farm</InputLabel>
						<ThemeSelect
							label="Farm"
							value={formData.farm}
							onChange={(event) => {
								utils.each(farms, function (farm, farmid) {
									if (farm.uuid === event.target.value) {
										setFormData({ ...formData, farm: event.target.value, farmname: farm.name });
									}
								})
							}}
							MenuProps={{
								sx: {
									"&& .Mui-selected": {
										backgroundColor: "#5f7933",
										color: "#fff"
									},
								},
								PaperProps: {
									style: {
										maxHeight: 48 * 4.5 + 8,
										width: 250,
									},
									sx: {
										backgroundColor: "#c7d494",
									}
								}
							}}
						>
							{
								farms.map((value, ind) => {
									return (
										<MenuItem value={value.uuid}>{value.name}</MenuItem>
									)
								})
							}
						</ThemeSelect>
						<FormHelperText>{formErrors.farm}</FormHelperText>
					</FormControl>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px' }}>
					<FormControl fullWidth error={!utils.isempty(formErrors.zone) ? true : false}>
						<InputLabel id="zone" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Zone</InputLabel>
						<ThemeSelect
							label="Zone"
							value={formData.zone}
							onChange={(event) => {
								utils.each(pmzones, function (pmzone, pmzoneid) {
									if (pmzone.uuid === event.target.value) {
										setFormData({ ...formData, zone: event.target.value });
									}
								})
							}}
							MenuProps={{
								sx: {
									"&& .Mui-selected": {
										backgroundColor: "#5f7933",
										color: "#fff"
									},
								},
								PaperProps: {
									style: {
										maxHeight: 48 * 4.5 + 8,
										width: 250,
									},
									sx: {
										backgroundColor: "#c7d494",
									}
								}
							}}
						>
							{
								pmzones.map((value, ind) => {
									if (!utils.isempty(formData.farm) && formData.farm !== value.farm) { return null; }
									return (
										<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
									)
								})
							}
						</ThemeSelect>
						<FormHelperText>{formErrors.zone}</FormHelperText>
					</FormControl>
				</Box>

				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px' }}>
					<FormControl fullWidth>
						<InputLabel sx={{ color: "#7A9A01", '&.Mui-focused': { color: '#7A9A01' } }} id="pest-inspection-template">Template</InputLabel>
						<ThemeSelect
							labelId="pest-inspection-template-label"
							id="pest-inspection-template-simple-select-disabled"
							value={formTemplate}
							label="Template"
							disabled={(formAction == 'Add' ? false : true)}
							onChange={(event) =>
								setFormData({ ...formData, template: event.target.value })
							}
							MenuProps={{
								sx: {
									"&& .Mui-selected": {
										backgroundColor: "#5f7933",
										color: "#fff"
									},
								},
								PaperProps: {
									style: {
										maxHeight: 48 * 4.5 + 8,
										width: 250,
									},
									sx: {
										backgroundColor: "#c7d494",
									}
								}
							}}
						>
							{
								inspectionTemplates.map((template) => {
									return (<MenuItem key={utils.uuid()} value={template.ref}>{template.name}</MenuItem>)
								})
							}
						</ThemeSelect>
					</FormControl>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px', marginBottom: '35px' }}>
					{/* TextArea Styling Applied in css class (dependand on parent div) */}
					<div style={{ display: 'flex', flex: '1', width: '100%', paddingTop: "5px", textAlign: "left" }} className="comments-container">
						<TextareaAutosize
							aria-label="Notes"
							minRows={1}
							style={{ width: '100%' }}
							value={formData.notes}
							placeholder="Notes"
							onChange={(event) =>
								setFormData({ ...formData, notes: event.target.value })
							}
						/>
					</div>
				</Box>
			</Box>

			<Box sx={{ marginTop: '5px', display: 'grid' }}>
				{
					formTemplate ?
						<div className="scrollable-pest-inspections-table-container">
							<span onClick={(event) => {
								console.warn("event", event);
								if (!utils.isset(event.target.parentElement.attributes.treind)) { return false; }
								setEditRowInd(event.target.parentElement.attributes.treind.value);
								handleClickOpen();
							}}>
								<table role="table" className="scout-inspection-form-table">
									<thead className="sticky-table-header">
										{parse(htr1)}
										{parse(htr2)}
									</thead>
									<tbody>
										{parse(tr)}
									</tbody>
									<tfoot className="sticky-table-footer">
										{parse(footerrow)}
									</tfoot>
								</table>
							</span>
						</div>
						: null
				}
			</Box>
		</div>
	);
}


export default function PestInspectionForm({ hideForm, pestinspectionid, formTemplate }) {

	const [pageIndex, setPageIndex] = useState(0);
	const currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");

	var farmsLookupState = utils.getLocalStorageItem("farmsLookup");
	var pmZonesLookupState = utils.getLocalStorageItem("pmZonesLookup");
	var personsLookup = utils.getLocalStorageItem("personsLookup");
	var inspectionTemplatesLookupState = utils.getLocalStorageItem("inspectionTemplatesLookup");

	const { addPestInspection, getPestInspection, editPestInspection, getPestInspectionCIType, formErrors } = useContext(PestInspectionsContext);
	const { getCookieData } = useContext(SessionsContext);
	const { setOverlayState } = useContext(StateManagerContext);
	const FormTitle = {
		add: "Add Inspection",
		edit: "Edit Inspection"
	};

	const [formAction, setformAction] = useState("add");
	let it = { data: {} };
	utils.each(inspectionTemplatesLookupState, function (inspectiontemplate, inspectiontemplateind) {
		if (formTemplate === inspectiontemplate.ref) { it = inspectiontemplate; return false; }
	});

	const [formData, setFormData] = useState({
		rid: "#13:0",
		date_performed: currentDateTime,
		performed_by: getCookieData().useruuid,
		created_by: getCookieData().useruuid,
		farm: "",
		zone: "",
		rows: [],
		totals: [],
		template: formTemplate,
		template_id: it.uuid,
		notes: "",
		status: "in_review",
		uuid: metaphone(getPestInspectionCIType()) + "-" + uuidv4()
	});


	const DisplayStep = () => {
		switch (pageIndex) {
			case 0:
				return <Step001 formData={formData} setFormData={setFormData} formErrors={formErrors} farms={farmsLookupState} pmzones={pmZonesLookupState} persons={personsLookup} inspectionTemplates={inspectionTemplatesLookupState} formAction={formAction} formTemplate={formTemplate} /*types={types}*/ />;
			default:
				return false;
		}
	};

	useEffect(() => {
		if (utils.isempty(pestinspectionid)) {
			let tuuid = metaphone(getPestInspectionCIType()) + "-" + uuidv4();
			setformAction("add");
			setFormData({
				rid: "#13:0",
				date_performed: currentDateTime,
				performed_by: getCookieData().useruuid,
				created_by: getCookieData().useruuid,
				farm: "",
				zone: "",
				template: formTemplate,
				template_id: it.uuid,
				rows: [],
				totals: [],
				notes: "",
				status: "in_review",
				uuid: tuuid
			});
		} else {
			setformAction("edit");
			setFormData(getPestInspection(pestinspectionid));
		}
	}, [pestinspectionid])

	console.warn("Inspection formData", formData);
	return (
		<div className="form scout-inspections-form-bg">
			<div className="progressbar">
				<div style={{ width: pageIndex === 0 ? "100%" : "100%" }}></div>
			</div>
			<div className="form-container">
				<div className="header">
					<h1 style={{ color: "#7A9A01" }}>{FormTitle[formAction]}</h1>
				</div>
				<div className="body">{DisplayStep()}</div>
				<div className="footer">
					<button
						onClick={(e) => {
							setOverlayState("overlay_visible");
							switch (e.target.innerText) {
								case 'Submit':
									addPestInspection({
										rid: formData.rid,
										inspectionDate: formData.currentDateTime,
										performed_by: formData.performed_by,
										created_by: formData.created_by,
										farm: formData.farm,
										zone: formData.zone,
										rows: JSON.stringify(formData.rows),
										totals: JSON.stringify(formData.totals),
										notes: formData.notes,
										template: formData.template_ref,
										status: formData.status,
										uuid: formData.uuid
									}, hideForm, setOverlayState);
									break;
								case 'Save':
									editPestInspection({
										rid: formData.rid,
										date_performed: formData.date_performed,
										performed_by: formData.performed_by,
										created_by: formData.created_by,
										farm: formData.farm,
										zone: formData.zone,
										rows: JSON.stringify(formData.rows),
										totals: JSON.stringify(formData.totals),
										notes: formData.notes,
										template: formData.template_ref,
										template_id: formData.template_id,
										status: formData.status,
										uuid: formData.uuid
									}, hideForm, setOverlayState);
									break;
								default:
									hideForm();
									break;
							}
						}}
					>
						{formAction === "add" ? "Submit" : "Save"}
					</button>
					<button
						onClick={(e) => {
							hideForm();
						}}
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};