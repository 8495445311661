import React, { useContext, useState } from "react";
import {
  MapContainer,
  useMap,
  useMapEvents,
  TileLayer,
  LayersControl,
  LayerGroup,
  // Polygon,
  Marker,
  CircleMarker,
  Popup,
} from "react-leaflet";
import { OrchardsContext } from "../../contexts/orchards";
import { PestTrapInspectionsContext } from "../../contexts/pesttrapinspections";

import { PestInspectionsContext } from "../../contexts/pestinspections";

import utils from "../../utils/utils";
import styled from "@emotion/styled";
import { StateManagerContext } from "../../contexts/statemanager";
// import moment from 'moment';
import * as L from "leaflet";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import * as turf from "@turf/turf";
// import Localbase from 'localbase';
// import { render } from "react-dom";
// import { OneKPlusOutlined } from "@mui/icons-material";
import calcIntersection4 from "../../utils/line4intersection";
const ThemeSelect = styled(Select)(utils.theme.Select);
const ThemeSlider = styled(Slider)(utils.theme.Slider);
var I = {
  currentblockpopup: false,
  editOrchard: false,
  shapes: {},
  markers: {},
  shape: false,
  blocks: {}, //AC Added
  dragging: false,
  uimode: "select",
  blocklayers: {},
  selectedblock: "",
  shapeclicked: false,

  saveBlock: function (map) {
    var I = this;
    let orchard = I.blocks[I.selectedblock];
    let listCurrentFilter = utils.getLocalStorageItem('orchardsCurrentFilter');
    I.editOrchard(orchard, () => { }, listCurrentFilter);
    if (!utils.isempty(I.markers) && utils.isset(I.markers[I.selectedblock])) {
      I.markers[I.selectedblock].clearLayers();
    }
    if (!utils.isempty(I.shapes) && utils.isset(I.shapes[I.selectedblock])) {
      I.shapes[I.selectedblock].remove();
    }
    if (
      !utils.isempty(I.blocklayers) &&
      utils.isset(I.blocklayers[I.selectedblock])
    ) {
      I.blocklayers[I.selectedblock].clearLayers();
    }
    I.refreshBlock(I.selectedblock,false,map);
    I.selectedblock = false;
    if (I.currentblockpopup) {
      I.currentblockpopup.remove();
    }
  },
  deleteBlock: function(map) {
    var I = this;
    if (!I.selectedblock) {
      return true;
    }
    if (!utils.isempty(I.markers) && utils.isset(I.markers[I.selectedblock])) {
      I.markers[I.selectedblock].clearLayers();
    }
    if (!utils.isempty(I.shapes) && utils.isset(I.shapes[I.selectedblock])) {
      I.shapes[I.selectedblock].remove();
    }
    if (!utils.isempty(I.blocklayers) &&utils.isset(I.blocklayers[I.selectedblock])) {
      I.blocklayers[I.selectedblock].clearLayers();
    }
    if (I.currentblockpopup) {
      I.currentblockpopup.remove();
    }
    let orchard = I.blocks[I.selectedblock];
    orchard.boundary = [];
    let listCurrentFilter = utils.getLocalStorageItem('orchardsCurrentFilter');
    I.editOrchard(orchard, () => { }, listCurrentFilter);
    I.blocks[I.selectedblock].boundary = [];
    I.refreshBlock(I.selectedblock,false,map);
    I.selectedblock = false;
  },
  cancelBlock: function (map) {
    var I = this;
    if (!I.selectedblock) {
      return true;
    }
    if (!utils.isempty(I.markers) && utils.isset(I.markers[I.selectedblock])) {
      I.markers[I.selectedblock].clearLayers();
    }
    if (!utils.isempty(I.shapes) && utils.isset(I.shapes[I.selectedblock])) {
      I.shapes[I.selectedblock].remove();
    }
    if (!utils.isempty(I.blocklayers) &&utils.isset(I.blocklayers[I.selectedblock])) {
      I.blocklayers[I.selectedblock].clearLayers();
    }
    if (I.currentblockpopup) {
      I.currentblockpopup.remove();
    }
    let orchards = utils.getLocalStorageItem("orchardsLookup");
    utils.each(orchards, (orchard, orchardind) => {
      if (I.selectedblock === orchard.uuid) {
        if (utils.isset(orchard.boundary) && !utils.isempty(orchard.boundary)) {
          orchard.boundary =
            typeof orchard.boundary === "string"
              ? JSON.parse(orchard.boundary)
              : orchard.boundary;
        } else {
          orchard.boundary = [];
        }
        I.blocks[I.selectedblock].boundary = orchard.boundary;
        //I.selectedblock = false;
        I.refreshBlock(orchard.uuid, false, map);
      }
    });
  },

  updatePoly: function(blockuuid,options,map) {
    var I = this;
    console.warn("blockuuid,options,map",blockuuid,options,map);
    let tidx = 0;
    if(options!==false&&(!utils.isset(options.addmarker)||options.addmarker===false)) {
      I.blocks[blockuuid].boundary[options.idx].lat=options.latlon.lat;
      I.blocks[blockuuid].boundary[options.idx].lng=options.latlon.lng;
      tidx = options.idx;
      //I.shapes[blockuuid].setLatLngs(I.blocks[blockuuid].boundary);
    } else {
      if(options!==false) {
        if(utils.isempty(I.blocks[blockuuid].boundary)) {
          I.blocks[blockuuid].boundary.push(options.latlon);
          //tidx = options.idx;
          tidx = I.blocks[blockuuid].boundary.length-1;
          I.refreshBlock(blockuuid,{latlon:options.latlon},map);
        } else {
          let latlon = options.latlon;
          let closest = {distance:9999999};
          let lat1 = latlon.lat;
          let lon1 = latlon.lng;
          let blockpoly = [];
          utils.each(I.blocks[blockuuid].boundary,function(curcoord,curcoordidx){
            let lat2 = curcoord.lat;
            let lon2 = curcoord.lng;
            blockpoly.push([lat2,lon2]);
            let distance = Math.acos(Math.sin(lat1)*Math.sin(lat2)+Math.cos(lat1)*Math.cos(lat2)*Math.cos(lon2-lon1))*6371;
            let latdir = (Math.abs(lat2) - Math.abs(lat1)<0?"s":"n");
            let londir = (Math.abs(lon2) - Math.abs(lon1)<0?"e":"w");
            // console.warn("latdir",latdir,"lat2",lat2,"lat1",lat1);
            // console.warn("londir",londir,"lon2",lon2,"lon1",lon1);
            //console.warn("curcoordidx",curcoordidx,"curcoord",curcoord,"distance",distance,"latdir",latdir,"londir",londir);
            if(closest.distance>distance) {
              closest.idx = curcoordidx;
              closest.distance = distance;
              closest.direction = latdir+londir;
              closest.lat = lat2;
              closest.lng = lon2;
            }
          });
          let closestidx = closest.idx;
          const firstind = 0;
          let lastind = I.blocks[blockuuid].boundary.length-1;
          let res1 = false;
          let res2 = false;
          //console.warn(turf.lineString([[-21.964416, 64.148203],[-21.956176, 64.141316],[-21.93901, 64.135924],[-21.927337, 64.136673]]));
          var line1 = [];
          var line2 = [];
          var line3 = [];
          if(I.blocks[blockuuid].boundary.length>2) {
            let prevind = closestidx == firstind?lastind:closestidx-1;
            let nextind = closestidx == lastind?firstind:closestidx+1;
            line1 = [[lat1,lon1],[I.blocks[blockuuid].boundary[prevind].lat,I.blocks[blockuuid].boundary[prevind].lng]];
            line3 = [[lat1,lon1],[I.blocks[blockuuid].boundary[nextind].lat,I.blocks[blockuuid].boundary[nextind].lng]];
            
            line2 = [[closest.lat,closest.lng],[I.blocks[blockuuid].boundary[prevind].lat,I.blocks[blockuuid].boundary[prevind].lng]];
            res1 = calcIntersection4(line1,line2);
            res2 = calcIntersection4(line3,line2);
            if(!res1||(utils.isset(res1.intersection)&&(res1.intersection.lat==I.blocks[blockuuid].boundary[prevind].lat||res1.intersection.lng==I.blocks[blockuuid].boundary[prevind].lng))) {
              res1 = false;
            } else {
              res1 = true; //intersects
            }
            if(!res2||(utils.isset(res2.intersection)&&(res2.intersection.lat==I.blocks[blockuuid].boundary[prevind].lat||res2.intersection.lng==I.blocks[blockuuid].boundary[prevind].lng))) {
              res2 = false;
            } else {
              res2 = true;
            }
            if(res1||res2) {
              if(!res1) {
                if(prevind<closestidx) {
                  if(closestidx==lastind) {
                    I.blocks[blockuuid].boundary.push(latlon);
                  } else {
                    I.blocks[blockuuid].boundary.splice(closestidx,0,latlon);
                  }
                } else {
                  if(prevind==lastind) {
                    I.blocks[blockuuid].boundary.push(latlon);
                  } else {
                    I.blocks[blockuuid].boundary.splice(prevind,0,latlon);
                  }
                }
              } else {
                if(nextind<closestidx) {
                  I.blocks[blockuuid].boundary.splice(closestidx,0,latlon);
                } else {
                  I.blocks[blockuuid].boundary.splice(nextind,0,latlon);
                }
              }
            } else {

            }
          } else {
            I.blocks[blockuuid].boundary.push(latlon);
          }
        }
      }
      if(utils.isset(I.markers[I.selectedblock])){I.markers[I.selectedblock].clearLayers();}
      utils.each(I.blocks[blockuuid].boundary,function(curcoord,curcoordidx){
        var m = new L.marker(curcoord, {
          cindex: curcoordidx,
          draggable: true,
          blockuuid: blockuuid,
        }).addTo(I.markers[blockuuid]);
        m.on("drag", function (e) {
          var idx = this.options.cindex;
          I.updatePoly(this.options.blockuuid,{ idx: idx, latlon: e.latlng },map);
        });
        m.on("dragend", function (e) {
          I.dragging = false;
          //I.refreshBlock(this.options.blockuuid, false, map);
        });
        m.on("dragstart", function (e) {
          I.dragging = true;
        });
        m.on("click", function (e) {
          console.warn("m.on(click e",e);
          if (e.originalEvent.ctrlKey) {
            I.blocks[e.target.options.blockuuid].boundary.splice(e.target.options.cindex,1);
            I.updatePoly(e.target.options.blockuuid,false,map);
          }
        });
        // var popup = "<div style='width:10px; height: 10px; text-align:center;'><p style='font-size:1em; color:#293036;'>" + curcoordidx + "</p></div>";
        // var op = {};
        // op.sticky = false;
        // op.direction = "center";
        // op.className = "mouseover";
        // op.permanent = true;
        // op.direction = "right";
        //m.bindTooltip(popup, op);
      });
    }
    I.shapes[blockuuid].setLatLngs(I.blocks[blockuuid].boundary);
    // console.warn("tshape",tshape);
  },
  refreshBlock: function (blockuuid, options, map) {
    var I = this;
    //console.warn("refreshBlock",blockuuid,options,I.dragging);

    if (options !== false) {
      I.blocks[blockuuid].boundary[options.idx] = options.latlon;
    }
 
    if (I.dragging) { return true; }

    if (!utils.isempty(I.shapes) && utils.isset(I.shapes[blockuuid])) {
      I.shapes[blockuuid].remove();
    }

    if (!utils.isempty(I.blocklayers) && utils.isset(I.blocklayers[blockuuid])) {
      I.blocklayers[blockuuid].clearLayers();
    }

    let block = I.blocks[blockuuid];
    var outlinecol = !utils.isempty(block.outlinecol) ? block.outlinecol : "lime";
    var fillcol = !utils.isempty(block.fillcol) ? block.fillcol : "white";
    var tshapeoptions = { color: outlinecol, fillColor: fillcol, fillOpacity: 0.5, blockuuid: block.uuid, };
    var tshape = new L.polygon(block.boundary, tshapeoptions).addTo(map);

    var popup = "<div style='width:90px; height: 100%; text-align:center;'><p style='font-size:1em; color:#293036;'>" + I.blocks[blockuuid].name + "</p></div>";
    var op = {};
    op.sticky = false;
    op.direction = "center";
    op.className = "mouseover";
    tshape.bindTooltip(popup, op); //.openTooltip();
    I.shapes[block.uuid] = tshape;
    if (I.selectedblock) {
    }

    var href = utils.parseURL(window.location.href);
    var chartType = utils.getLocalStorageItem("chartType");
		if(href === "census" && chartType==="FIELDS") {
      tshape.on("click", (event) => {
        I.shapeclicked = true;
        let tblockuuid = event.target.options.blockuuid;
        if(utils.isset(I.markers[I.selectedblock])){I.markers[I.selectedblock].clearLayers();}
        I.selectedblock = tblockuuid;
        utils.each(I.blocks[I.selectedblock].boundary, function (coord, index) {
          var m = new L.marker(coord, {
            cindex: index,
            draggable: true,
            blockuuid: I.selectedblock,
          }).addTo(I.markers[I.selectedblock]);
          m.on("drag", function (e) {
            var idx = this.options.cindex;
            I.updatePoly(this.options.blockuuid,{ idx: idx, latlon: e.latlng },map);
          });
          m.on("dragend", function (e) {
            I.dragging = false;
            //I.refreshBlock(this.options.blockuuid, false, map);
          });
          m.on("dragstart", function (e) {
            I.dragging = true;
          });
          m.on("click", function (e) {
            console.warn("m.on(click e",e);
            if (e.originalEvent.ctrlKey) {
              I.blocks[e.target.options.blockuuid].boundary.splice(e.target.options.cindex,1);
              I.updatePoly(e.target.options.blockuuid,false,map);
            }
          });
          // var popup = "<div style='width:10px; height: 10px; text-align:center;'><p style='font-size:1em; color:#293036;'>" + index + "</p></div>";
          // var op = {};
          // op.sticky = false;
          // op.direction = "center";
          // op.className = "mouseover";
          // op.permanent = true;
          // op.direction = "right";
          // m.bindTooltip(popup, op);
        });
      })
      .on("contextmenu", (event) => {
        if (I.selectedblock === event.target.options.blockuuid) {
          let ts = event.target;
          if (ts.isTooltipOpen()) {
            ts.closeTooltip();
          }
          I.currentblockpopup = L.popup()
            .setLatLng(event.latlng)
            .openOn(map)
            .on("contentupdate", (event) => {
              let I = this;
              let savebutton = document.getElementById("btnsaveblock");
              savebutton.addEventListener("click", function () {
                I.saveBlock(map);
              });
              let deletebutton = document.getElementById("btndeleteblock");
              deletebutton.addEventListener("click", function () {
                I.deleteBlock(map);
              });
              let cancelbutton = document.getElementById("btncancelblock");
              cancelbutton.addEventListener("click", function () {
                I.cancelBlock(map);
              });
            });
          I.currentblockpopup.setContent(
            "<p>" +
            I.blocks[event.target.options.blockuuid].name +
            '</p> <button id="btnsaveblock" style="color: rgb(255, 255, 255); background-color: #7A9A01; font-weight: 300; margin: 4px; font-size: 0.875rem; line-height: 1.45; letter-spacing: 0.02857em; text-transform: uppercase; min-width: 64px; border-radius: 4px; padding: 5px 15px; box-sizing: border-box; border-color: rgb(122, 154, 1); display: inline-flex; justify-content: center; position: relative;">Save</button>' +
            '<button id="btndeleteblock" style="color: rgb(255, 255, 255); background-color: #7A9A01; font-weight: 300; margin: 4px; font-size: 0.875rem; line-height: 1.45; letter-spacing: 0.02857em; text-transform: uppercase; min-width: 64px; border-radius: 4px; padding: 5px 15px; box-sizing: border-box; border-color: rgb(122, 154, 1); display: inline-flex; justify-content: center; position: relative;">Delete</button>' +
            '<button id="btncancelblock" style="color: rgb(255, 255, 255); background-color: #7A9A01; font-weight: 300; margin: 4px; font-size: 0.875rem; line-height: 1.45; letter-spacing: 0.02857em; text-transform: uppercase; min-width: 64px; border-radius: 4px; padding: 5px 15px; box-sizing: border-box; border-color: rgb(122, 154, 1); display: inline-flex; justify-content: center; position: relative;">Cancel</button>'
          );
        }
      });
    }// && chartType !== "FIELDS"
  },
  onClick: function (e, map) {
    var I = this;
    var href = utils.parseURL(window.location.href);
    var chartType = utils.getLocalStorageItem("chartType");
		if(href !== "census") { return; }// && chartType !== "FIELDS"
		console.warn("map clicked...",I);
    if (!I.dragging && !I.shapeclicked&&(I.selectedblock!==""||I.selectedblock!==false)) {
      //Need to add a new marker
      let options = {latlon:e.latlng,addmarker:true};
      I.updatePoly(I.selectedblock,options,map);
    }
    I.shapeclicked = false;
  },
};

function MapController({ bounds, orchards, editOrchard }) {
  //console.warn("MapController...");
  I.editOrchard = editOrchard;
  utils.each(orchards, function (o, i) {
    let boundary = [];
    if (utils.isset(o.boundary) && !utils.isempty(o.boundary)) {
      boundary = typeof o.boundary === "string" ? JSON.parse(o.boundary) : o.boundary;
    }
    o.boundary = boundary;
    I.blocks[o.uuid] = o;
  });
  const map = useMap();

  //console.warn("MapController I.blocklayers",map,utils.extend({},I.blocklayers));
  if (!utils.isempty(bounds)) {
    map.fitBounds(bounds);
  }
  let ZLevel = map.getZoom();
  if (ZLevel === 18) {
    map.setZoom(17);
  }
  utils.each(I.blocks, function (block, blockind) {
    if (utils.isset(block.boundary) && !utils.isempty(block.boundary)) {
      let boundary = typeof block.bounday === "string" ? JSON.parse(block.boundary) : block.boundary;
      I.blocks[block.uuid].boundary = boundary;
    }
    let foundlayer = false;
    map.eachLayer(function(layer){
      //console.warn("layer",layer);
      if(utils.isset(layer.uuid) && layer.uuid==block.uuid) {
        //map.removeLayer(layer);
        I.blocklayers[block.uuid] = layer;
        //I.refreshBlock(block.uuid, false, map);
        foundlayer = true;
        return false;
      }
    });

    if(!foundlayer) {
      let tblocklayer = new L.featureGroup().addTo(map);
      tblocklayer.uuid = block.uuid;
      I.blocklayers[block.uuid] = tblocklayer;
      let tmarker = new L.featureGroup().addTo(map);
      tmarker.uuid = block.uuid;
      I.markers[block.uuid] = tmarker;
      I.refreshBlock(block.uuid, false, map);
    }
  });
  return null;
}
function MapEventController() {
  const map = useMap();
  I.map = map;
  const mape = useMapEvents({
    click: (event) => {
      I.onClick(event, map);
    },
    // contextmenu: (event) => {
    // },
  });

  return null;
}

const Map = () => {
  const {
    trapChartFilters,
    setTrapChartFilters,
    trapListChartFilters,
    setTrapListChartFilters,
		// trapReportForceRefresh,
		// setTrapReportForceRefresh,
  } = useContext(StateManagerContext);
  const { orchards, editOrchard } = useContext(OrchardsContext);
  const { ptiReportData, ptiListData } = useContext(PestTrapInspectionsContext);
  const pesttraps = utils.getLocalStorageItem("pestTrapsLookup");
  const { piReportData, piListData } = useContext(PestInspectionsContext);
  //const inspectionTemplates = utils.getLocalStorageItem("inspectionTemplatesLookup");
  var chartType = utils.getLocalStorageItem("chartType");
  var href = utils.parseURL(window.location.href);
  var mapheight = "100%";
  var bounds = [];

  const [emptyOrchard, setEmptyOrchard] = useState("");
  
  console.warn("orchards from context...",orchards);
  var noemptyboundaries = true;
  if (!utils.isempty(orchards)) {
    utils.each(orchards, (orchard, i) => {
      if (utils.isset(orchard.boundary) && !utils.isempty(orchard.boundary)) {
        orchard.boundary =
          typeof orchard.boundary === "string"
            ? JSON.parse(orchard.boundary)
            : orchard.boundary;
        if (!utils.isempty(orchard.boundary)) {
          bounds.push(orchard.boundary);
        } else {
          noemptyboundaries = false;
        }
      } else {
        noemptyboundaries = false;
      }
    });
  }
  var trapPositions = [];
  if (href === "pest-and-disease-settings") {
    if (chartType !== "TRAPS") {
      utils.setLocalStorageItem("chartType", "PM ZONES");
      chartType = "PM ZONES";
    }
    switch (chartType) {
      case "TRAPS":
        if (!utils.isempty(pesttraps)) {
          utils.each(pesttraps, function (pesttrap, pesttrapind) {
            if (
              !isNaN(parseFloat(pesttrap.latitude)) &&
              !isNaN(parseFloat(pesttrap.longitude))
            ) {
              trapPositions.push({
                name: pesttrap.name,
                id: pesttrap.uuid,
                latitude: pesttrap.latitude,
                longitude: pesttrap.longitude,
              });
							bounds.push([pesttrap.latitude,pesttrap.longitude]);
            }
          });
        }
        break;
      default:
        trapPositions = [];
        break;
    }
  }

  var trapInspectionsPositions = [];
	var marks = [];
  if (href === "pest-monitoring-trap-readings") {
    if (chartType !== "REPORT") {
      utils.setLocalStorageItem("chartType", "REVIEW");
      chartType = "REVIEW";
    }
    if (chartType === "REPORT") {
      mapheight = "100%";
      if (!utils.isempty(ptiReportData.mapdata)) {
        bounds = [];
        let tw = ptiReportData.dataweeks[trapChartFilters.selectedWeek];
        let tp = ptiReportData.datapests[trapChartFilters.selectedPest];
        utils.each(ptiReportData.mapdata[tw][tp.uuid], function (r, rind) {
          if (
            !isNaN(parseFloat(r.latitude)) &&
            !isNaN(parseFloat(r.longitude)) &&
            !parseFloat(r.latitude) !== 0 &&
            parseFloat(r.longitude) !== 0
          ) {
            trapInspectionsPositions.push({
              name: r.pestname,
              pest: r.pest,
              latitude: r.latitude,
              longitude: r.longitude,
              count: r.pestcount,
            });
            bounds.push([r.latitude, r.longitude]);
          }
        });
      }
      utils.each(ptiReportData.dataweeks, function (a, b) {
        marks.push({ value: b, label: a });
      });
    } else {
      mapheight = "100%";
      if (!utils.isempty(ptiListData.mapdata)) {
        bounds = [];
        let tw = ptiListData.dataweeks[trapListChartFilters.selectedWeek];
        let tp = ptiListData.datapests[trapListChartFilters.selectedPest];

        utils.each(ptiListData.mapdata[tw][tp.uuid], function (r, rind) {
          if (
            !isNaN(parseFloat(r.latitude)) &&
            !isNaN(parseFloat(r.longitude)) &&
            !parseFloat(r.latitude) !== 0 &&
            parseFloat(r.longitude) !== 0
          ) {
            trapInspectionsPositions.push({
              name: r.pestname,
              pest: r.pest,
              latitude: r.latitude,
              longitude: r.longitude,
              count: r.pestcount,
            });
            bounds.push([r.latitude, r.longitude]);
          }
        });
      }
      utils.each(ptiListData.dataweeks, function (a, b) {
        marks.push({ value: b, label: a });
      });
    }
  }
  const position = [-34.270836, 18.4233];

  var pestInspectionsPositions = [];

  if (href === "scout-insepctions") {
    if (chartType !== "REPORT") {
      utils.setLocalStorageItem("chartType", "REVIEW");
      chartType = "REVIEW";
    }
    if (chartType === "REPORT") {
      mapheight = "100%";

      if (!utils.isempty(piReportData.mapdata)) {
        bounds = [];
        let tw = piReportData.dataweeks[trapChartFilters.selectedWeek];
        let tp = piReportData.datapests[trapChartFilters.selectedPest];

        utils.each(piReportData.mapdata[tw], function (tree, treeind) {
          if (!isNaN(parseFloat(tree.latitude)) && !isNaN(parseFloat(tree.longitude)) && !parseFloat(tree.latitude) !== 0 && parseFloat(tree.longitude) !== 0) {
            pestInspectionsPositions.push({
              name: piReportData.datapests[tp.uuid].pest,
              group: piReportData.datapests[tp.uuid].group,
              latitude: tree.latitude,
              longitude: tree.longitude,
              count: tree.inspection[tp.uuid].count,
            });
            bounds.push([tree.latitude, tree.longitude]);
          }
        });
      }
      utils.each(piReportData.dataweeks, function (a, b) {
        marks.push({ value: b, label: a });
      });
    } else {
      mapheight = "100%";
      if (!utils.isempty(piListData.mapdata)) {
        bounds = [];
        let tw = piListData.dataweeks[trapListChartFilters.selectedWeek];
        let tp = piListData.datapests[trapListChartFilters.selectedPest];

        utils.each(piListData.mapdata[tw], function (tree, treeind) {
          if (!isNaN(parseFloat(tree.latitude)) && !isNaN(parseFloat(tree.longitude)) && !parseFloat(tree.latitude) !== 0 && parseFloat(tree.longitude) !== 0) {
            pestInspectionsPositions.push({
              name: piListData.datapests[tp.uuid].pest,
              group: piListData.datapests[tp.uuid].group,
              latitude: tree.latitude,
              longitude: tree.longitude,
              count: tree.inspection[tp.uuid].count,
            });
            bounds.push([tree.latitude, tree.longitude]);
          }
        });
      }
      utils.each(piListData.dataweeks, function (a, b) {
        marks.push({ value: b, label: a });
      });
    }
  }

  function showPIReportFilters() {
    return (
      <div style={{ width: "100%" }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel
            sx={{ color: "#7A9A01", "&.Mui-focused": { color: "#7A9A01" } }}
            id="pest-inspection-status"
          >
            Pest
          </InputLabel>
          <ThemeSelect
            labelId="pest-inspection-status-label"
            id="pest-inspection-simple-select-disabled"
            value={trapChartFilters.selectedPest}
            label="Pest"
            size="small"
            onChange={(event) =>
              setTrapChartFilters({
                ...trapChartFilters,
                selectedPest: event.target.value,
              })
            }
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: "rgb(199, 212, 148)",
                  color: "rgb(122, 154, 1)",
                },
              },
            }}
          >
            {piReportData.datapests.map((value, ind) => {
              return (
                <MenuItem key={utils.uuid()} value={ind}>
                  {value.name}
                </MenuItem>
              );
            })}
          </ThemeSelect>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "25px",
            marginRight: "25px",
          }}
        >
          <ThemeSlider
            aria-label="Week"
            defaultValue={0}
            getAriaValueText={(value) => {
              return piReportData.dataweeks[value];
            }}
            onChange={(event) => {
              setTrapChartFilters({
                ...trapChartFilters,
                selectedWeek: event.target.value,
              });
            }}
            min={0}
            max={marks.length - 1}
            step={1}
            valueLabelDisplay="off"
            marks={marks}
          />
        </Box>
      </div>
    );
  }
  function showPIListFilters() {
    return (
      <div style={{ width: "100%" }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel
            sx={{ color: "#7A9A01", "&.Mui-focused": { color: "#7A9A01" } }}
            id="pest-inspection-status"
          >
            Pest
          </InputLabel>
          <ThemeSelect
            labelId="pest-inspection-status-label"
            id="pest-inspection-simple-select-disabled"
            value={trapListChartFilters.selectedPest}
            label="Pest"
            size="small"
            onChange={(event) =>
              setTrapListChartFilters({
                ...trapListChartFilters,
                selectedPest: event.target.value,
              })
            }
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: "rgb(199, 212, 148)",
                  color: "rgb(122, 154, 1)",
                },
              },
            }}
          >
            {piListData.datapests.map((value, ind) => {
              return (
                <MenuItem key={utils.uuid()} value={ind}>
                  {value.name}
                </MenuItem>
              );
            })}
          </ThemeSelect>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "25px",
            marginRight: "25px",
          }}
        >
          <ThemeSlider
            aria-label="Week"
            defaultValue={0}
            getAriaValueText={(value) => {
              return piListData.dataweeks[value];
            }}
            onChange={(event) => {
              setTrapListChartFilters({
                ...trapListChartFilters,
                selectedWeek: event.target.value,
              });
            }}
            min={0}
            max={marks.length - 1}
            step={1}
            valueLabelDisplay="off"
            marks={marks}
          />
        </Box>
      </div>
    );
  }
  function showPTIReportFilters() {
    return (
      <div style={{ width: "100%" }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel
            sx={{ color: "#7A9A01", "&.Mui-focused": { color: "#7A9A01" } }}
            id="pest-inspection-status"
          >
            Pest
          </InputLabel>
          <ThemeSelect
            labelId="pest-inspection-status-label"
            id="pest-inspection-simple-select-disabled"
            value={trapChartFilters.selectedPest}
            label="Pest"
            size="small"
            onChange={(event) =>
              setTrapChartFilters({
                ...trapChartFilters,
                selectedPest: event.target.value,
              })
            }
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: "rgb(199, 212, 148)",
                  color: "rgb(122, 154, 1)",
                },
              },
            }}
          >
            {ptiReportData.datapests.map((value, ind) => {
              return (
                <MenuItem key={utils.uuid()} value={ind}>
                  {value.name}
                </MenuItem>
              );
            })}
          </ThemeSelect>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "25px",
            marginRight: "25px",
          }}
        >
          <ThemeSlider
            aria-label="Week"
            defaultValue={0}
            getAriaValueText={(value) => {
              return ptiReportData.dataweeks[value];
            }}
            onChange={(event) => {
              setTrapChartFilters({
                ...trapChartFilters,
                selectedWeek: event.target.value,
              });
            }}
            min={0}
            max={marks.length - 1}
            step={1}
            valueLabelDisplay="off"
            marks={marks}
          />
        </Box>
      </div>
    );
  }
  function showPTIListFilters() {
    return (
      <div style={{ width: "100%" }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel
            sx={{ color: "#7A9A01", "&.Mui-focused": { color: "#7A9A01" } }}
            id="pest-inspection-status"
          >
            Pest
          </InputLabel>
          <ThemeSelect
            labelId="pest-inspection-status-label"
            id="pest-inspection-simple-select-disabled"
            value={trapListChartFilters.selectedPest}
            label="Pest"
            size="small"
            onChange={(event) =>
              setTrapListChartFilters({
                ...trapListChartFilters,
                selectedPest: event.target.value,
              })
            }
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: "rgb(199, 212, 148)",
                  color: "rgb(122, 154, 1)",
                },
              },
            }}
          >
            {ptiListData.datapests.map((value, ind) => {
              return (
                <MenuItem key={utils.uuid()} value={ind}>
                  {value.name}
                </MenuItem>
              );
            })}
          </ThemeSelect>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "25px",
            marginRight: "25px",
          }}
        >
          <ThemeSlider
            aria-label="Week"
            defaultValue={0}
            getAriaValueText={(value) => {
              return ptiListData.dataweeks[value];
            }}
            onChange={(event) => {
              setTrapListChartFilters({
                ...trapListChartFilters,
                selectedWeek: event.target.value,
              });
            }}
            min={0}
            max={marks.length - 1}
            step={1}
            valueLabelDisplay="off"
            marks={marks}
          />
        </Box>
      </div>
    );
  }
  function showEmptyBoundaryOrchards() {
    if (noemptyboundaries) {
      return null;
    }
    return (
      <div style={{ width: "100%" }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel
            sx={{ color: "#7A9A01", "&.Mui-focused": { color: "#7A9A01" } }}
            id="orchard-no-boundary"
          >
            Orchards
          </InputLabel>
          <ThemeSelect
            labelId="orchard-no-boundary-label"
            id="orchard-no-boundary-select-disabled"
            value={emptyOrchard}
            label="Orchards without boundaries"
            size="small"
            onChange={(event) => {
              I.cancelBlock(I.map);
              I.selectedblock = event.target.value;
              I.shapeclicked = false;
              setEmptyOrchard(event.target.value);
            }}
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: "rgb(199, 212, 148)",
                  color: "rgb(122, 154, 1)",
                },
              },
            }}
          >
            {orchards.map((orchard, ind) => {
              if (
                !utils.isset(orchard.boundary) ||
                utils.isempty(orchard.boundary)
              ) {
                return (
                  <MenuItem key={utils.uuid()} value={orchard.uuid}>
                    {orchard.name}
                  </MenuItem>
                );
              } else {
                return null;
              }
            })}
          </ThemeSelect>
        </FormControl>
      </div>
    );
  }
console.warn("rendering map...",orchards);
  return (
    <Box id="map" sx={{ display: 'flex', flex: '1', height: '100%', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        {href === "scout-insepctions" && !utils.isempty(pestInspectionsPositions)
          ? chartType === "REPORT"
            ? showPIReportFilters()
            : showPIListFilters()
          : ""}

        {href === "pest-monitoring-trap-readings" && !utils.isempty(trapInspectionsPositions)
          ? chartType === "REPORT"
            ? showPTIReportFilters()
            : showPTIListFilters()
          : ""}
        {href === "census" && chartType === "FIELDS" ? showEmptyBoundaryOrchards() : ""}
      </Box>


      <Box sx={{ display: 'flex', flex: '1', height: '100%' }}>
        <MapContainer
          className="map"
          center={position}
          zoom={11}
          style={{ height: mapheight, width: "100%" }}
        >
          <MapController
            bounds={bounds}
            orchards={orchards}
            editOrchard={editOrchard}
          />
          <MapEventController />
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Satelite View">
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Default View">
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayerGroup>
              {trapPositions.map((trap, i) => {
                return (
                  <Marker
                    key={trap.uuid}
                    position={[trap.latitude, trap.longitude]}
                  >
                    <Popup>
                      <span>{trap.name}</span>
                    </Popup>
                  </Marker>
                );
              })}
            </LayerGroup>
            <LayerGroup>
              {trapInspectionsPositions.map((insp, i) => {
                let color =
                  parseFloat(insp.count) === 0
                    ? "blue"
                    : parseFloat(insp.count) > 0 && parseFloat(insp.count) <= 3
                      ? "yellow"
                      : "red";
                return (
                  <CircleMarker
                    key={insp.pest}
                    pathOptions={{
                      color: color,
                      fill: true,
                      fillOpacity: 1,
                      fillColor: color,
                    }}
                    radius={5}
                    center={[insp.latitude, insp.longitude]}
                  >
                    <Popup>
                      <span>{insp.name}</span>
                      <br />
                      <span>{insp.count}</span>
                    </Popup>
                  </CircleMarker>
                );
              })}
            </LayerGroup>
            <LayerGroup>
              {pestInspectionsPositions.map((insp, i) => {
                let color =
                  parseFloat(insp.count) === 0
                    ? "blue"
                    : parseFloat(insp.count) > 0 && parseFloat(insp.count) <= 3
                      ? "yellow"
                      : "red";
                return (
                  <CircleMarker
                    key={insp.pest}
                    pathOptions={{
                      color: color,
                      fill: true,
                      fillOpacity: 1,
                      fillColor: color,
                    }}
                    radius={5}
                    center={[insp.latitude, insp.longitude]}
                  >
                    <Popup>
                      <span>{insp.name}</span>
                      <br />
                      <span>{insp.group}</span>
                      <br />
                      <span>{insp.count}</span>
                    </Popup>
                  </CircleMarker>
                );
              })}
            </LayerGroup>
          </LayersControl>
        </MapContainer>
      </Box>
    </Box>
  );
};

export default Map;
