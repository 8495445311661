import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var ajaxstack = false;
var def_value = [];

const pmzonesReducer = (state, action) => {
	let tpmzones = [];
	switch (action.type) {
		case "REFRESH_PMZONES":
			localStorage.setItem("pmZonesLookup", JSON.stringify(action.pmzones));
			localStorage.setItem("pmzones", JSON.stringify(action.pmzones));
			return {
				pmzones: action.pmzones
			};
		case "ADD_PMZONE":
			tpmzones = utils.updateArray(state.pmzones, action.pmzone,);
			tpmzones = utils.sortArray(tpmzones);
			localStorage.setItem("pmZonesLookup", JSON.stringify(tpmzones));
			localStorage.setItem("pmzones", JSON.stringify(tpmzones));
			return {
				pmzones: tpmzones
			};
		case "EDIT_PMZONE":
			utils.each(state.pmzones, function (pmzone, pmzoneind) {
				if (pmzone.uuid !== action.pmzone.uuid) {
					tpmzones.push(pmzone);
				} else {
					tpmzones.push(action.pmzone);
				}
			});
			tpmzones = utils.sortArray(tpmzones);
			localStorage.setItem("pmZonesLookup", JSON.stringify(tpmzones));
			localStorage.setItem("pmzones", JSON.stringify(tpmzones));
			return {
				pmzones: tpmzones
			};
		default:
			return state;
	}
};

export const PMZonesContext = createContext();

const PMZonesProvider = ({ children }) => {
	const pmzones = def_value;
	const [state, dispatch] = useReducer(pmzonesReducer, {
		pmzones: pmzones,
		filters: {}
	});
	// let ts = localStorage.getItem("pmzones");
	// const [pmZonesLookupState, setPMZonesLookup] = useState((utils.isset(ts) && !utils.isempty(ts) ? JSON.parse(ts) : []));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);

	const [formErrors, setFormErrors] = useState({ name: '', farm: '', block: '', commodity: '', cultivar_name: '', zone_type: ''});

	const value = {
		pmzones: state.pmzones,
		addPMZone: (pmzone, hideform, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pmzone
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/pmzone", params, headers).then(
				function (response) {
					if (response.data.success) {
						pmzone = response.data.data;
						dispatch({ type: "ADD_PMZONE", pmzone });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		getPMZone: (id) => {
			let tpmzone = {};
			utils.each(state.pmzones, function (pmzone, pmzoneind) {
				if (pmzone.uuid === id) {
					tpmzone = pmzone;
					return false;
				}
			});
			return tpmzone;
		},
		getPMZones: (filters, lookup = false, ttoken = false, force = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let pmZonesFetchRules = utils.getLocalStorageItem("pmZonesFetchRules");

			// if (!utils.isempty(pmZonesFetchRules) && !force) {
			// 	if (pmZonesFetchRules.filtered === false && pmZonesFetchRules.trycache) {
			// 		let pmzones = utils.getLocalStorageItem("pmzones");
			// 		if (!utils.isempty(pmzones)) {
			// 			dispatch({ type: "REFRESH_PMZONES", pmzones });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	pmZonesFetchRules = { trycache: true, filtered: true };
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/pmzones", params).then(
				function (response) {
					if (response.data.success) {
						var pmzones = [];
						utils.each(response.data.data, function (pmzone, pmzoneid) {
							//console.warn('org',org);
							pmzones.push(pmzone);
						});
						if (lookup !== false) {
							//setPMZonesLookup(pmzones);
							dispatch({ type: "REFRESH_PMZONES", pmzones });
						} else {
							dispatch({ type: "REFRESH_PMZONES", pmzones });
						}
						//responsereceived= true;
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							// setPMZonesLookup([]);
						}
					}
					// pmZonesFetchRules.filtered = false;
					// localStorage.setItem("pmZonesFetchRules", JSON.stringify(pmZonesFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editPMZone: (pmzone, hideform, filters, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pmzone
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/pmzone", params, headers).then(
				function (response) {
					if (response.data.success) {
						//dispatch({ type: "EDIT_PMZONE", pmzone });
						setFormErrors({ name: '', farm: '', block: '', commodity: '', cultivar_name: '', zone_type: ''});
						value.getPMZones(filters, false, false, true);
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		// pmZonesLookupState: pmZonesLookupState,
		// setPMZonesLookup: setPMZonesLookup,
		formErrors: formErrors,
		setFormErrors: setFormErrors,
		getPMZoneCIType: () => {
			return "ci_farmzone";
		},
		clearPMZones: () => {
			// setPMZonesLookup([]);
			let empty = [];
			dispatch({ type: "REFRESH_PMZONES", empty });
			localStorage.setItem("pmZonesLookup", JSON.stringify(empty));
			localStorage.setItem("pmzones", JSON.stringify(empty));
		},
		resetFormErrors: () => {
			setFormErrors({ name: '', farm: '', block: '', commodity: '', cultivar_name: '', zone_type: '' });
		}
	}
	return (
		<PMZonesContext.Provider value={value}>
			{children}
		</PMZonesContext.Provider>
	);
};
export default PMZonesProvider;

