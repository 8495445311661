/**
 * StateManager is used to clear all states when loggin out or switching contexts and sets the required cached items
 */
import { createContext, useContext, useState } from "react";

export const UpdateCIStatusStateContext = createContext();

const UpdateCIStatusStateProvider = ({ children }) => {
	const [updateCIState, setUpdateCIState] = useState({visibility:"updateci_hidden",data:{message:""}});
	
	const value = {
		updateCIState : updateCIState,
		setUpdateCIState : setUpdateCIState,
	}
	return (
		<UpdateCIStatusStateContext.Provider value={value}>
			{children}
		</UpdateCIStatusStateContext.Provider>
	);
};
export default UpdateCIStatusStateProvider;