import React, { useState, useContext, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import { OrganisationsContext } from '../../contexts/organisations';
import { StateManagerContext } from '../../contexts/statemanager';

import utils from "../../utils/utils";
import { v4 as uuidv4 } from 'uuid';
import { metaphone } from 'metaphone';
import '../../index.css';
import styled from "@emotion/styled";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


// Theme
const ThemeFormTextField = styled(TextField)(utils.theme.FormTextField);
const ThemeSelect = styled(Select)(utils.theme.Select);

export function Step001({ formData, setFormData, formErrors }) {
	var orgtype = [
		{uuid:"issuer",name:"Issuer"},
		{uuid:"producer",name:"Producer"},
	];
	return (
		<div className="step001-container">
			<ThemeFormTextField
				type="text"
				id="name"
				label="Name"
				placeholder="Name..."
				value={formData.name}
				onChange={(event) =>
					setFormData({ ...formData, name: event.target.value })
				}
				error={!utils.isempty(formErrors.name) ? true : false}
				helperText={formErrors.name}
			/>
			<ThemeFormTextField
				type="text"
				id="shortcode"
				label="Short Code"
				placeholder="Short Code..."
				value={formData.shortcode}
				onChange={(event) =>
					setFormData({ ...formData, shortcode: event.target.value })
				}
				error={!utils.isempty(formErrors.shortcode) ? true : false}
				helperText={formErrors.shortcode}
			/>
			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth>
					<InputLabel sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} id="">Type</InputLabel>
					<ThemeSelect
						label="Type"
						value={formData.org_type}
						onChange={(event) => {
							utils.each(orgtype, function (org, orgid) {
								if (org.uuid === event.target.value) {
									setFormData({ ...formData, org_type: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							orgtype.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
				</FormControl>
			</Box>
			<ThemeFormTextField
				type="text"
				id="address_1"
				label="Address 1"
				placeholder="Address 1..."
				value={formData.address_1}
				onChange={(event) =>
					setFormData({ ...formData, address_1: event.target.value })
				}
				error={!utils.isempty(formErrors.address_1) ? true : false}
				helperText={formErrors.address_1}
			/>
			<ThemeFormTextField
				type="text"
				id="address_2"
				label="Address 2"
				placeholder="Address 2..."
				value={formData.address_2}
				onChange={(event) =>
					setFormData({ ...formData, address_2: event.target.value })
				}
				error={!utils.isempty(formErrors.address_2) ? true : false}
				helperText={formErrors.address_2}
			/>
			<ThemeFormTextField
				type="text"
				id="address_3"
				label="Address 3"
				placeholder="Address 3..."
				value={formData.address_3}
				onChange={(event) =>
					setFormData({ ...formData, address_3: event.target.value })
				}
				error={!utils.isempty(formErrors.address_3) ? true : false}
				helperText={formErrors.address_3}
			/>
			<ThemeFormTextField
				type="text"
				id="phone"
				label="Phone"
				placeholder="Phone..."
				value={formData.phone}
				onChange={(event) =>
					setFormData({ ...formData, phone: event.target.value })
				}
				error={!utils.isempty(formErrors.phone) ? true : false}
				helperText={formErrors.phone}
			/>
		</div>
	);
}

export default function OrganisationForm({ hideForm, organisationid, formFilters }) {
	const [pageIndex, setPageIndex] = useState(0);
	//const numPages = 1;
	const { addOrganisation, getOrganisation, editOrganisation, getOrganisationCIType, formErrors } = useContext(OrganisationsContext);
	const { setOverlayState } = useContext(StateManagerContext);

	const FormTitle = {
		add: "Add Organisation",
		edit: "Edit Organisation"
	};
	const [formAction, setformAction] = useState("add");
	const [formData, setFormData] = useState({
		rid: "#13:0",
		name: "",
		shortcode: "",
		org_type:"",
		address_1: "",
		address_2: "",
		address_3: "",
		phone: "",
		uuid: metaphone(getOrganisationCIType()) + "-" + uuidv4()
	});

	const DisplayStep = () => {
		switch (pageIndex) {
			case 0:
				return <Step001 formData={formData} setFormData={setFormData} formErrors={formErrors} />;
			default:
				return false;
		}
	};

	useEffect(() => {
		if (utils.isempty(organisationid)) {
			let tuuid = metaphone(getOrganisationCIType()) + "-" + uuidv4();
			setformAction("add");
			setFormData({
				rid: "#13:0",
				name: "",
				shortcode: "",
				org_type:"",
				address_1: "",
				address_2: "",
				address_3: "",
				phone: "",
				status: "active",
				uuid: tuuid
			});
		} else {
			setformAction("edit");
			setFormData(getOrganisation(organisationid));
		}
	}, [organisationid, getOrganisation, getOrganisationCIType])

	return (
		<div className="form form-bg">
			<div className="progressbar">
				<div
					style={{ width: pageIndex === 0 ? "100%" : "100%" }}
				></div>
			</div>
			<div className="form-container">
				<div className="header">
					<h1 style={{ color: "#7A9A01" }}>{FormTitle[formAction]}</h1>
				</div>
				<div className="body">{DisplayStep()}</div>
				<div className="footer">
					<button
						onClick={(e) => {
							setOverlayState("overlay_visible");
							switch (e.target.innerText) {
								case 'Submit':
									//if (validateForm()) {
									addOrganisation({
										rid: formData.rid,
										name: formData.name,
										shortcode: formData.shortcode,
										org_type: formData.org_type,
										address_1: formData.address_1,
										address_2: formData.address_2,
										address_3: formData.address_3,
										phone: formData.phone,
										status: formData.status,
										uuid: formData.uuid
									}, hideForm, setOverlayState);
									break;
								case 'Save':
									editOrganisation({
										rid: formData.rid,
										name: formData.name,
										shortcode: formData.shortcode,
										org_type: formData.org_type,
										address_1: formData.address_1,
										address_2: formData.address_2,
										address_3: formData.address_3,
										phone: formData.phone,
										status: formData.status,
										uuid: formData.uuid
									}, hideForm, formFilters, setOverlayState);
									break;
								default:
									hideForm();
									break;
							}
						}}
					>
						{formAction === "add" ? "Submit" : "Save"}
					</button>
					<button
						onClick={(e) => {
							hideForm();
						}}
					>
						Cancel
					</button>
					{
						formAction === "add" ?
							null :
							<button
								onClick={(e) => {
									setOverlayState("overlay_visible");
									switch (e.target.innerText) {
										case 'Delete':
											editOrganisation({
												rid: formData.rid,
												name: formData.name,
												shortcode: formData.shortcode,
												org_type: formData.org_type,
												address_1: formData.address_1,
												address_2: formData.address_2,
												address_3: formData.address_3,
												phone: formData.phone,
												status: "deleted",
												uuid: formData.uuid
											}, hideForm, formFilters, setOverlayState);
											break;
										case 'Reinstate':
											editOrganisation({
												rid: formData.rid,
												name: formData.name,
												shortcode: formData.shortcode,
												org_type: formData.org_type,
												address_1: formData.address_1,
												address_2: formData.address_2,
												address_3: formData.address_3,
												phone: formData.phone,
												status: "active",
												uuid: formData.uuid
											}, hideForm, formFilters, setOverlayState);
											break;
										default:
											hideForm();
											break;
									}
								}}
							>
								{formData.status === "active" ? "Delete" : "Reinstate"}
							</button>
					}
				</div>
			</div>
		</div>
	);
}
