import React, { useContext } from "react";
import overlayimage from '../../assets/loader-blue.png';
import utils from "../../utils/utils";
import { StateManagerContext } from "../../contexts/statemanager";

export function LoadingOverlay (props) {
	const {overlayState} = useContext(StateManagerContext);
	return (
		<div className={"TheOverlay"} style={utils.theme[overlayState]}>
			<img src={overlayimage} alt={"Loading..."} style={{"width":"64px","height":"64px","margin":"auto"}}></img>
		</div>
	);
}

export default LoadingOverlay;