import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//import { StateManagerContext } from './statemanager';

var def_value = [];
var ajaxstack = false;

const orchardsReducer = (state, action) => {
	let torchards = [];
	switch (action.type) {
		case "REFRESH_ORCHARDS":
			localStorage.setItem("orchardsLookup", JSON.stringify(action.orchards));
			localStorage.setItem("orchards", JSON.stringify(action.orchards));
			return {
				orchards: action.orchards
			};
		case "ADD_ORCHARD":
			torchards = utils.updateArray(state.orchards, action.orchard,);
			torchards = utils.sortArray(torchards);
			localStorage.setItem("orchardsLookup", JSON.stringify(torchards));
			localStorage.setItem("orchards", JSON.stringify(torchards));
			return {
				orchards: torchards
			};
		case "EDIT_ORCHARD":
			utils.each(state.orchards, function (orchard, orchardind) {
				if (orchard.uuid !== action.orchard.uuid) {
					torchards.push(orchard);
				} else {
					torchards.push(action.orchard);
				}
			});
			torchards = utils.sortArray(torchards);
			localStorage.setItem("orchardsLookup", JSON.stringify(torchards));
			localStorage.setItem("orchards", JSON.stringify(torchards));
			return {
				orchards: torchards
			};
		default:
			return state;
	}
};

export const OrchardsContext = createContext();

const OrchardsProvider = ({ children }) => {
	const orchards = def_value;
	const [state, dispatch] = useReducer(orchardsReducer, {
		orchards: orchards,
		filters: {}
	});
	// let ts = localStorage.getItem("orchards");
	// const [orchardsLookupState,setOrchardsLookup] = useState((utils.isset(ts)&&!utils.isempty(ts)?JSON.parse(ts):[]));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const [formErrors, setFormErrors] = useState({ name: '' });
	const value = {
		orchards: state.orchards,
		addOrchard: (orchard, hideform,setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: orchard
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/orchard", params, headers).then(
				function (response) {
					if (response.data.success) {
						orchard = response.data.data
						dispatch({ type: "ADD_ORCHARD", orchard });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		getOrchard: (id) => {
			let torchard = {};
			utils.each(state.orchards, function (org, orgind) {
				if (org.uuid === id) {
					torchard = org;
					return false;
				}
			});
			return torchard;
		},
		getOrchards: (filters, lookup = false, ttoken = false, force = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			console.warn("getOrchards",filters);
			utils.setLocalStorageItem('orchardsCurrentFilter',filters);
			// let orchardFetchRules = utils.getLocalStorageItem("orchardFetchRules");

			// if (!utils.isempty(orchardFetchRules) && !force) {
			// 	if (orchardFetchRules.filtered === false && orchardFetchRules.trycache) {
			// 		let orchards = utils.getLocalStorageItem("orchards");
			// 		if (!utils.isempty(orchards)) {
			// 			dispatch({ type: "REFRESH_ORCHARDS", orchards });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	orchardFetchRules = { trycache: true, filtered: true };
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/orchards", params).then(
				function (response) {
					if (response.data.success) {
						var orchards = [];
						utils.each(response.data.data, function (orchard, orchardid) {
							//console.warn('org',org);
							orchards.push(orchard);
						});
						if (lookup !== false) {
							//setOrchardsLookup(orchards);
							dispatch({ type: "REFRESH_ORCHARDS", orchards });
						} else {
							dispatch({ type: "REFRESH_ORCHARDS", orchards });
						}
						//responsereceived= true;
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setOrchardsLookup([]);
						}
					}
					// orchardFetchRules.filtered = false;
					// localStorage.setItem("orchardFetchRules", JSON.stringify(orchardFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editOrchard: (orchard, hideform, filters,setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: orchard
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/orchard", params, headers).then(
				function (response) {
					if (response.data.success) {
						setFormErrors({ name: '' });
						//dispatch({ type: "EDIT_ORCHARD", orchard });
						value.getOrchards(filters, false, false, true);
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		// orchardsLookupState:orchardsLookupState,
		// setOrchardsLookup:setOrchardsLookup,
		formErrors: formErrors,
		setFormErrors: setFormErrors,
		getOrchardCIType: () => {
			return "ci_orchard";
		},
		clearOrchards: () => {
			// setOrchardsLookup([]);
			let empty = [];
			dispatch({ type: "REFRESH_ORCHARDS", empty });
			localStorage.setItem("orchardsLookup", JSON.stringify(empty));
			localStorage.setItem("orchards", JSON.stringify(empty));
		},
		resetFormErrors: () => {
			setFormErrors({ name: '' });
		}
	}
	return (
		<OrchardsContext.Provider value={value}>
			{children}
		</OrchardsContext.Provider>
	);
};
export default OrchardsProvider;

