import React, { useEffect, useState, useContext } from "react";
import { SprayRecommendationsContext } from "../../contexts/sprayrecommendations";
import SprayRecommendationForm from "./SprayRecommendationForm";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import "../../index.css";
import utils from "../../utils/utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import Box from "@mui/material/Box";
import AdapterMoment from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StateManagerContext } from '../../contexts/statemanager';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { AddCircle, Edit } from "@material-ui/icons";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

// Theme
const ThemeTextField = styled(TextField)(utils.theme.TextField);
const ThemeSelect = styled(Select)(utils.theme.Select);
const color = "#7A9A01";
const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color
        }
      }
    }
  }
});

export function SprayRecommendationList(props) {
  const { sprayrecommendations, getSprayRecommendations, fetchSprayRecommendationPDF, resetFormErrors } = useContext(SprayRecommendationsContext);
  var organisationsLookupState = utils.getLocalStorageItem("organisationsLookup");
  var personsLookupState = utils.getLocalStorageItem("personsLookup");
  var farmsLookupState = utils.getLocalStorageItem("farmsLookup");
  var farmCommoditiesLookupState = utils.getLocalStorageItem("farmCommoditiesLookup");
	var harvestZonesLookupState = utils.getLocalStorageItem("harvestZonesLookup");
  var plantSprayProductsLookupState = utils.getLocalStorageItem("plantSprayProductsLookup");

  const [formFilters, setFormFilters] = useState({ name: "", startDate: utils.getDate(moment().format("YYYY-MM-DD HH:mm:ss"), { closestMonth: 8 }), endDate: moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"), status: "active",comm_ref:[],products:[] });

  useEffect(() => {
    if (utils.isempty(sprayrecommendations)) {
      getSprayRecommendations(formFilters);
    }
  }, []);

  const [formContainerVis, setformContainerVis] = useState(
    "form-container dialog-loader-2 dialog-loader-2-hidden"
  );
  const [sprayrecommendationid, setsprayrecommendationid] = useState("");
  //const [formAction, setFormAction] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
	const { setOverlayState } = useContext(StateManagerContext);

  function hasCommodity (uuid) {
    //let cultivar = utils.getRecordByAttr(cultivarsLookupState,uuid,"agri_produce_commodity");
    //if(utils.isempty(cultivar)) { return false; }
    let zone = utils.getRecordByAttr(harvestZonesLookupState,uuid,"commodity");
    if(utils.isempty(zone)) { return false; }
    return true;
  }

	const [open, setOpen] = useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

  const [prodOpen, setProdOpen] = useState(false);
	const handleClickProdOpen = () => {
		setProdOpen(true);
	};
	const handleProdClose = () => {
		setProdOpen(false);
	};

  //const isEven = (idx) => idx % 2 === 0;
  const showForm = () => {
    setformContainerVis(
      "form-container dialog-loader-2 dialog-loader-2-show"
    );
  };
  const hideForm = () => {
    resetFormErrors();
    setformContainerVis(
      "form-container dialog-loader-2 dialog-loader-2-hidden"
    );
    setPageIndex(0);
  };
  const preSprayRecommendationID = (id) => {
    if (id !== "") {
      setsprayrecommendationid(id);
      // setFormAction("edit");
      showForm();
    } else {
      setsprayrecommendationid(id);
      // setFormAction("add");
      showForm();
    }
  };

  // if (utils.isempty(organisationsLookupState)) {
  // //   getOrganisations({ status: "active" }, true);
  // // }
  // // if (utils.isempty(personsLookupState)) {
  // //   getPersons({ status: "active" }, true);
  // // }
  // // var SprayRecommendationsData = [];

  //Dialog
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const handleFilterDialogClickOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };
  //Temp variables
  var commFilterLabel = "";
  var prodFilterLabel = "";

    utils.each(formFilters.comm_ref,function(com,comind){
      if(comind<2) { commFilterLabel = (commFilterLabel===""?"":"\n") + utils.getName(farmCommoditiesLookupState,com); }
    });
    commFilterLabel = (formFilters.comm_ref.length > 2) ? commFilterLabel = commFilterLabel + "\n... and " + (formFilters.comm_ref.length-2) + " others" : commFilterLabel;

    utils.each(formFilters.products,function(prod,prodind){
      if(prodind<2) { prodFilterLabel += (prodFilterLabel===""?"":"\n") + utils.getName(plantSprayProductsLookupState,prod); }
    });
    prodFilterLabel = (formFilters.products.length > 2) ? prodFilterLabel = prodFilterLabel + "\n... and " + (formFilters.products.length-2) + " others" : prodFilterLabel;
  function getProducts(recommendation) {
    let names = "";
    if(utils.isset(recommendation.products)&&!utils.isempty(recommendation.products)) {
      let t = typeof recommendation.products === "string"?JSON.parse(recommendation.products):recommendation.products;
      utils.each(t,function(prod,prodind){
        names += (names===""?"":", ")+prod.name;
      });
    }
    return names;
  }
  function getPersons(recommendation,attr) {
    let names = "";
    if(utils.isset(recommendation[attr])&&!utils.isempty(recommendation[attr])) {
      let t = typeof recommendation[attr] === "string"?JSON.parse(recommendation[attr]):recommendation[attr];
      utils.each(t,function(person_uuid,personind){
        names += (names===""?"":", ")+utils.getName(personsLookupState,person_uuid);
      });
    }
    return names;
  }
  return (
    <>
      <div className={formContainerVis}>
        <SprayRecommendationForm
          hideForm={hideForm}
          sprayrecommendationid={sprayrecommendationid}
          formFilters={formFilters}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      </div>

      <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
        <Box>
          <Button
            variant="outlined"
            onClick={handleFilterDialogClickOpen}
            startIcon={<FilterListIcon />}
            style={utils.theme.Button}
          >
            Filter
          </Button>
          <div className="filter-dialog">
            <Dialog
              open={openFilterDialog}
              onClose={handleFilterDialogClose}
              fullWidth={true}
              maxWidth={"sm"}
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{ m: 0, p: 2, color: "#7A9A01" }}
              >
                List Filters
                <IconButton
                  aria-label="close"
                  onClick={handleFilterDialogClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: "#7A9A01",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", }} >
                  <Box sx={{ margin: '5px' }}>
                    <ThemeProvider theme={theme}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Start Date"
                          inputFormat="DD/MM/yyyy"
                          value={formFilters.startDate}
                          renderInput={(params) => <ThemeTextField {...params} sx={{ width: '400px' }} />}
                          onChange={(tdate) => {
                            setFormFilters({ ...formFilters, startDate: tdate.format("YYYY-MM-DD HH:mm:ss") });
                          }}
                        />
                      </LocalizationProvider>
                      <FormHelperText sx={{ paddingLeft: '14px' }}>Filter by start date</FormHelperText>
                    </ThemeProvider>
                  </Box>
                  <Box sx={{ margin: '5px' }}>
                    <ThemeProvider theme={theme}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="End Date"
                          value={formFilters.endDate}
                          inputFormat="DD/MM/yyyy"
                          renderInput={(params) => <ThemeTextField style={{}} {...params} sx={{ width: '400px' }} />}
                          onChange={(tdate) => {
                            setFormFilters({ ...formFilters, endDate: tdate.format("YYYY-MM-DD HH:mm:ss") });
                          }}
                        />
                      </LocalizationProvider>
                      <FormHelperText sx={{ paddingLeft: '14px' }}>Filter by end date</FormHelperText>
                    </ThemeProvider>
                  </Box>
                  <Box sx={{ margin: '5px' }}>
									<div style={{ paddingTop: "5px", boxSizing: "content-box" }}>
                      <Button
                        size="medium"
                        sx={{ width: '400px', marginBottom: '10px', marginTop: '5px' }}
                        onClick={handleClickOpen}
                        disableElevation
                        variant="outlined"
                        style={utils.theme.Button}
                      >
                        Filter by commodity
                      </Button>
                      <Dialog
                        fullScreen={false}
                        open={open}
                        onClose={handleClose}
                        disableScrollLock
                        aria-labelledby="responsive-dialog-title"
                        >
                        <DialogTitle sx={{ color: '#7A9A01', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px', fontWeight: 'bold', fontSize: '1.5em' }}>{"Select Commodities"}</DialogTitle>
                        <DialogContent sx={{ color: '#7A9A01' }}>
                          <div className="container-fluid" >
                            {
                              farmCommoditiesLookupState.map((value, commind) => {
                                let hascommodity = hasCommodity(value.uuid);
                                  if(hascommodity) { 
                                    //console.warn("Commodity ",value.name," existst??? in context ",hascommodity, formFilters.comm_ref);
                                  return (
                                  <div key={value.uuid} className="custom-control custom-checkbox">
                                    <div className="form-group each_form_group">
                                      <FormGroup sx={{ fontSize: '1.5em' }}>
                                        <FormControlLabel control={
                                          <div className="nexus-checkbox">
                                            <label style={{}}>
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={value.uuid}
                                                idtype={'commodity'}
                                                value={value.uuid}
                                                //farmind={farmind}
                                                checked={formFilters.comm_ref.indexOf(value.uuid) !== -1 ? true : false}
                                                onChange={(event) => {
                                                  let commsselected = utils.extend([],formFilters.comm_ref);
                                                  let commsind = commsselected.indexOf(event.target.attributes.value.value);
                                                  if(commsind===-1) { commsselected.push(event.target.attributes.value.value); }
                                                  else { commsselected.splice(commsind,1); }
                                                  setFormFilters({...formFilters,comm_ref:commsselected});
                                                }}
                                              />
                                              <span className="checkbox-material" style={{}}>
                                                <span className="check"></span>
                                              </span>
                                            </label>
                                          </div>
                                        }
                                          label={value.name} />
                                      </FormGroup>
                                    </div>
                                  </div>
                                  )
                                  } else {
                                    return null;
                                  }
                              })
                            }
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleClose}
                            autoFocus
                            variant="outlined"
                            style={utils.theme.Button}
                          >
                            Done
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </Box>

                  <Box sx={{ margin: '5px' }}>
									<div style={{ paddingTop: "5px", boxSizing: "content-box" }}>
                      <Button
                        size="medium"
                        sx={{ width: '400px', marginBottom: '10px', marginTop: '5px' }}
                        onClick={handleClickProdOpen}
                        disableElevation
                        variant="outlined"
                        style={utils.theme.Button}
                      >
                        Filter by product
                      </Button>
                      <Dialog
                        fullScreen={false}
                        open={prodOpen}
                        onClose={handleProdClose}
                        disableScrollLock
                        aria-labelledby="responsive-dialog-title"
                        >
                        <DialogTitle sx={{ color: '#7A9A01', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px', fontWeight: 'bold', fontSize: '1.5em' }}>{"Select Products"}</DialogTitle>
                        <DialogContent sx={{ color: '#7A9A01' }}>
                          <div className="container-fluid" >
                            {
                              plantSprayProductsLookupState.map((value, prodind) => {
                                  return (
                                  <div key={value.uuid} className="custom-control custom-checkbox">
                                    <div className="form-group each_form_group">
                                      <FormGroup key={value.uuid} sx={{ marginLeft: '5px', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap' }}>
                                          <div className="nexus-checkbox">
                                            <label style={{}}>
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={value.uuid}
                                                idtype={'product'}
                                                value={value.uuid}
                                                //farmind={farmind}
                                                checked={formFilters.products.indexOf(value.uuid) !== -1 ? true : false}
                                                onChange={(event) => {
                                                  let prodsselected = utils.extend([],formFilters.products);
                                                  let prodsind = prodsselected.indexOf(event.target.attributes.value.value);
                                                  if(prodsind===-1) { prodsselected.push(event.target.attributes.value.value); }
                                                  else { prodsselected.splice(prodsind,1); }
                                                  setFormFilters({...formFilters,products:prodsselected});
                                                }}
                                              />
                                              <span className="checkbox-material" style={{}}>
                                                <span className="check"></span>
                                              </span>
                                            </label>
                                          </div>
                                          <Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                            <div>{value.name}</div>
                                            <div style={{ fontSize: '13px', color: '#919191' }}>{value.active_ing}</div>
                                          </Box>
                                      </FormGroup>
                                    </div>
                                  </div>
                                  )
                              })
                            }
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleProdClose}
                            autoFocus
                            variant="outlined"
                            style={utils.theme.Button}
                          >
                            Done
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </Box>

                  <Box>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel
                        id="simple-select-disabled-label"
                        sx={{ "&.Mui-focused": { color: "#7A9A01" } }}
                      >
                        Status
                      </InputLabel>
                      <ThemeSelect
                        labelId="simple-select-disabled-label"
                        id="simple-select-disabled"
                        value={formFilters.status}
                        label="Status"
                        sx={{ width: "400px" }}
                        onChange={(event) =>
                          setFormFilters({
                            ...formFilters,
                            status: event.target.value,
                          })
                        }
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "rgb(199, 212, 148)",
                              color: "rgb(122, 154, 1)",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"issued"}>Issued</MenuItem>
                        <MenuItem value={"revoked"}>Revoked</MenuItem>
                        <MenuItem value={"closed"}>Closed</MenuItem>
                        <MenuItem value={"deleted"}>Deleted</MenuItem>
                      </ThemeSelect>
                      <FormHelperText>Filter list by status</FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    utils.setLocalStorageItem("sprayRecommendationsFetchRules",true,"filtered");
                    getSprayRecommendations(formFilters);
                    setOpenFilterDialog(false);
                  }}
                  autoFocus
                  variant="outlined"
                  style={utils.theme.Button}
                >
                  Apply Filters
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Box>
        {
          !utils.isempty(sprayrecommendations) ?
            <Box sx={{ alignSelf: 'center', display: 'flex' }}>
              <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#7a9a01', marginRight: '5px' }}>Start Date:</span>
                <span style={{ color: 'GrayText' }}>{typeof formFilters.startDate == 'string' ? moment(formFilters.startDate).format("DD-MM-YYYY") : formFilters.startDate}</span>
              </div>
              <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#7a9a01', marginRight: '5px' }}>End Date:</span>
                <span style={{ color: 'GrayText' }}>{typeof formFilters.endDate == 'string' ? moment(formFilters.endDate).format("DD-MM-YYYY") : formFilters.endDate}</span>
              </div>
              {
                formFilters.comm_ref.length>0 ?
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#7a9a01', marginRight: '5px' }}>Commodities:</span>
                <span style={{ color: 'GrayText', whiteSpace:"pre-line" }}>
                  {commFilterLabel}
                </span>
                </div>
                : null
              }
              {
                formFilters.products.length>0 ?
                <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column', width:"200px" }}>
                <span style={{ color: '#7a9a01', marginRight: '5px' }}>Products:</span>
                <span style={{ color: 'GrayText', whiteSpace:"pre-line" }}>
                  {prodFilterLabel}
                </span>
                </div>
                : null
              }
              <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#7a9a01', marginRight: '5px' }}>Status:</span>
                <span style={{ color: 'GrayText' }}>{formFilters.status.charAt(0).toUpperCase() + formFilters.status.slice(1)}</span>
              </div>
            </Box>
            : null
        }
        <Box>
          <Button
            onClick={() => {
              preSprayRecommendationID("");
            }}
            variant="outlined"
            startIcon={<AddCircle />}
            style={utils.theme.Button}
          >
            Add
          </Button>
        </Box>
      </Box>

      <Box sx={{ width: "100%", marginTop: "5px", borderTop: 1, borderColor: "divider" }} >
        <div className="nexus-scroll-table">
          <table>
            <thead>
              <tr>
                <th>Organisation</th>
                <th>Farm</th>
                <th>Commodity</th>
                <th>Products</th>
                <th>Created By</th>
                <th>Date Assigned</th>
                <th>Expiry Date</th>
                <th>Comments</th>
                <th>Ref Note</th>
                <th>Issued</th>
                <th>Issued To</th>
                <th>Revoked</th>
                <th>Revoked To</th>
                <th>PDF</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {!utils.isempty(sprayrecommendations)
                ? sprayrecommendations.map((sprayrecommendation) => (
                  <tr key={utils.uuid()}>
                  <td>
                      {!utils.isempty(sprayrecommendation.organisation)
                        ? utils.getName(
                          organisationsLookupState,
                          sprayrecommendation.organisation
                        )
                        : ""}
                    </td>
                    <td>
                      {!utils.isempty(sprayrecommendation.farm_ref)
                        ? utils.getName(
                          farmsLookupState,
                          sprayrecommendation.farm_ref
                        )
                        : ""}
                    </td>
                    <td>
                      {!utils.isempty(sprayrecommendation.comm_ref)
                        ? utils.getName(
                          farmCommoditiesLookupState,
                          sprayrecommendation.comm_ref
                        )
                        : ""}
                    </td>
                    <td>
                      {
                        getProducts(sprayrecommendation)
                      }
                    </td>
                    <td>
                      {!utils.isempty(sprayrecommendation.created_by)
                        ? utils.getName(
                          personsLookupState,
                          sprayrecommendation.created_by
                        )
                        : ""}
                    </td>
                    <td>{typeof sprayrecommendation.date_assigned == 'object' || typeof sprayrecommendation.date_assigned == 'string' ? moment(sprayrecommendation.date_assigned).format("DD-MM-YYYY HH:mm") : sprayrecommendation.date_assigned}</td>
                    <td>{typeof sprayrecommendation.date_expires == 'object' || typeof sprayrecommendation.date_expires == 'string' ? moment(sprayrecommendation.date_expires).format("DD-MM-YYYY HH:mm") : sprayrecommendation.date_expires}</td>
                    <td>{sprayrecommendation.comments}</td>
                    <td>{sprayrecommendation.note_ref}</td>
                    <td>{sprayrecommendation.date_issued !== '1970-01-01 00:00:00' ? moment(sprayrecommendation.date_issued).format("DD-MM-YYYY HH:mm") : ""}</td>
                    <td>{getPersons(sprayrecommendation,"issued_to")}</td>
                    <td>{sprayrecommendation.date_revoked !== '1970-01-01 00:00:00' ? moment(sprayrecommendation.date_revoked).format("DD-MM-YYYY HH:mm") : ""}</td>
                    <td>{getPersons(sprayrecommendation,"revoked_to")}</td>
                    <td>
                    {sprayrecommendation.pdf !== "" ?
                    <IconButton
                        onClick={() => {
                          setOverlayState("overlay_visible");
                          console.log("sprayrecommendation.pdf", sprayrecommendation.pdf);
                          fetchSprayRecommendationPDF(sprayrecommendation.uuid,setOverlayState);
                        }}
                        color="primary"
                        sx={{ "&:hover": { color: "#789a3d" } }}
                        style={{ color: "#7A9A01" }}
                      >
                        <SaveAltIcon />
                      </IconButton>
                      :
                      ""}
                    </td>
                    <td>
                      <IconButton
                        onClick={() => {
                          console.log("sprayrecommendation.uuid", sprayrecommendation.uuid);
                          preSprayRecommendationID(sprayrecommendation.uuid);
                        }}
                        color="primary"
                        sx={{ "&:hover": { color: "#789a3d" } }}
                        style={{ color: "#7A9A01" }}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>
                ))
                : <tr key={utils.uuid()}><td colSpan={99}>No results found...</td></tr>}
            </tbody>
          </table>
        </div>
      </Box>
    </>
  );
}

export default SprayRecommendationList;
