import React, { useContext } from 'react';
import ReactEcharts from "echarts-for-react";
import utils from '../../utils/utils';
import { StateManagerContext } from '../../contexts/statemanager';
import { PestTrapInspectionsContext } from '../../contexts/pesttrapinspections';
import { PestInspectionsContext } from '../../contexts/pestinspections';
const Chart = () => {
	const { ptiReportData,ptiListData } = useContext(PestTrapInspectionsContext);
	const { piReportData,piListData } = useContext(PestInspectionsContext);
	var legenddata = [];
	var seriesdata = [];
	var xaxisdata = []
	var titledata = {};
	var tseriesdata = {};
	var legendselected = {};
	var chartType = utils.getLocalStorageItem("chartType");

	if (utils.parseURL(window.location.href) === 'pest-monitoring-trap-readings') {
		titledata = { show: true, text: "Trap Inspection Summary By Week" };
		var chartdatatrapinspections = {};
		var datapests = [];
		if (chartType === "REPORT") {
			chartdatatrapinspections = ptiReportData.chartdata;
			datapests = ptiReportData.datapests;
		} else {
			chartdatatrapinspections = ptiListData.chartdata;
			datapests = ptiListData.datapests;
		}

		if (!utils.isempty(chartdatatrapinspections)) {
			//legenddata.push(tp.name);
			utils.each(chartdatatrapinspections, function (week, wind) {
				xaxisdata.push(wind);
				utils.each(datapests, function (pest, pestind) {
					if (legenddata.findIndex(tpest => tpest === pest.name) === -1) {
						legenddata.push(pest.name)
					}
					if (!utils.isset(tseriesdata[pest.name])) {
						tseriesdata[pest.name] = {
							name: pest.name,
							type: "line",
							smooth: false,
							data: [],
						};
					}
					if (!utils.isset(week[pest.uuid])) {
						tseriesdata[pest.name].data.push(null);
					} else {
						let tval = week[pest.uuid].pestcount / (week[pest.uuid].inspcount === 0 ? 1 : week[pest.uuid].inspcount);
						console.warn(pest.name+" -> "+week[pest.uuid].pestcount+" -> "+week[pest.uuid].inspcount+" -> "+tval);
						tval = tval.toFixed(2);
						tseriesdata[pest.name].data.push(tval);
					}
				});
			});
			utils.each(tseriesdata, function (series, seriesind) {
				seriesdata.push(series);
			});
		}
	}
	if (utils.parseURL(window.location.href) === 'scout-insepctions') {
		titledata = { show: true, text: "Pest Inspection Summary By Week" };
		var chartdatapminspections = {};
		var pmdatapests = [];
		if (chartType === "REPORT") {
			chartdatapminspections = piReportData.chartdata;
			pmdatapests = piReportData.datapests;
		} else {
			chartdatapminspections = piListData.chartdata;
			pmdatapests = piListData.datapests;
		}

		if (!utils.isempty(chartdatapminspections)) {
			//legenddata.push(tp.name);
			utils.each(chartdatapminspections, function (week, wind) {
				xaxisdata.push(wind);
				utils.each(pmdatapests, function (pest, pestind) {
					console.warn("pest",pest);
					if (legenddata.findIndex(tpest => tpest === pest.name) === -1) {
						legenddata.push(pest.name)
					}
					if (!utils.isset(tseriesdata[pest.name])) {
						tseriesdata[pest.name] = {
							name: pest.name,
							type: "line",
							smooth: false,
							data: [],
						};
					}
					if (!utils.isset(week[pest.uuid])) {
						tseriesdata[pest.name].data.push(null);
					} else {
						let tval = 0;
						switch(week.type) {
							case "avg":
								tval = week[pest.uuid].value / (week[pest.uuid].count === 0 ? 1 : week[pest.uuid].count);
								tval = tval.toFixed(2);
								break;
							case "avg2":
								tval = week[pest.uuid].value / (week[pest.uuid].count === 0 ? 1 : week[pest.uuid].count);
								tval = tval.toFixed(2);
								break;
							default:
								tval = week[pest.uuid].value / (week[pest.uuid].count === 0 ? 1 : week[pest.uuid].count);
								tval = tval.toFixed(2);
								break;
						}
						
						tseriesdata[pest.name].data.push(tval);
						if(tval>0) {
							legendselected[pest.name] = true;
						} else {
							legendselected[pest.name] = false;
						}
					}
				});
			});
			utils.each(tseriesdata, function (series) {
				seriesdata.push(series);
			});
		}
	}
	const data = {
		tooltip: {
			trigger: "axis",
			axisPointer: {
				label: {
					backgroundColor: "#6a7985"
				}
			}
		},
		legend: {
			orient:'vertical',
			right: 5,
			itemGap:3,
			top:0,
			height:'auto',
			selected : legendselected,
			data: legenddata
		},
		dataZoom: [
			{
				type: "slider",
				height: 5,
				bottom: 10,
				borderColor: "transparent",
				backgroundColor: "#e2e2e2",
				handleIcon:
					"path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7v-1.2h6.6z M13.3,22H6.7v-1.2h6.6z M13.3,19.6H6.7v-1.2h6.6z", // jshint ignore:line
				handleSize: 15,
				handleStyle: {
					shadowBlur: 6,
					shadowOffsetX: 1,
					shadowOffsetY: 2,
					shadowColor: "#aaa"
				}
			},
			{
				type: "inside"
			}
		],
		grid: {
			left: "3%",
			right: 300,
			bottom: "3%",
			containLabel: true
		},
		xAxis: {
			type: "category",
			data: xaxisdata,
			show: true,
			axisLabel: {
				color: "gray",
				fontWeight: "bold",
				rotate: 33,
				interval: 0
			}
		},
		yAxis: {
			type: "value",
			axisLabel: {
				color: "gray",
				inside: true
			}
		},
		series: seriesdata,
	};
	console.warn("chart data??",data);
	return (
		<div id="map" style={{ height: "100%", width: "100%" }}>
			<div style={{ width: "100%", color: "#7a9a01", textAlign: "center" }}><h1>{titledata.text}</h1></div>
			<ReactEcharts
				style={{
					height: "80%",
					width: "100%"
				}}
				option={data}
			/>
		</div>
	)
}

export default Chart;