import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var ajaxstack = false;
var def_value = [];

const harvestzonesReducer = (state, action) => {
	let tharvestzones = [];
	switch (action.type) {
		case "REFRESH_HARVESTZONES":
			localStorage.setItem("harvestZonesLookup", JSON.stringify(action.harvestzones));
			localStorage.setItem("harvestzones", JSON.stringify(action.harvestzones));
			return {
				harvestzones: action.harvestzones
			};
		case "ADD_HARVESTZONE":
			tharvestzones = utils.updateArray(state.harvestzones,action.harvestzone,);
			tharvestzones = utils.sortArray(tharvestzones);
			localStorage.setItem("harvestZonesLookup", JSON.stringify(tharvestzones));
			localStorage.setItem("harvestzones", JSON.stringify(tharvestzones));
			return {
				harvestzones: tharvestzones
			};
		case "EDIT_HARVESTZONE":
			utils.each(state.harvestzones, function (harvestzone, harvestzoneind) {
				if (harvestzone.uuid !== action.harvestzone.uuid) {
					tharvestzones.push(harvestzone);
				} else {
					tharvestzones.push(action.harvestzone);
				}
			});
			tharvestzones = utils.sortArray(tharvestzones);
			localStorage.setItem("harvestZonesLookup", JSON.stringify(tharvestzones));
			localStorage.setItem("harvestzones", JSON.stringify(tharvestzones));
			return {
				harvestzones: tharvestzones
			};
		default:
			return state;
	}
};

export const HarvestZonesContext = createContext();

const HarvestZonesProvider = ({ children }) => {
	const harvestzones = def_value;
	const [state, dispatch] = useReducer(harvestzonesReducer, {
		harvestzones: harvestzones,
		filters: {}
	});
	// let ts = localStorage.getItem("harvestZones");
	// const [harvestZonesLookupState,setHarvestZonesLookup] = useState((utils.isset(ts)&&!utils.isempty(ts)?JSON.parse(ts):[]));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const [formErrors, setFormErrors] = useState({ name: '', farm: '', block: '', commodity: '', cultivar_name: ''})
	const value = {
		harvestzones: state.harvestzones,
		addHarvestZone: (harvestzone, hideform, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: harvestzone
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/farmharvestzone", params, headers).then(
				function (response) {
					if (response.data.success) {
						harvestzone = response.data.data;
						dispatch({ type: "ADD_HARVESTZONE", harvestzone });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		getHarvestZone: (id) => {
			let tharvestzone = {};
			utils.each(state.harvestzones, function (harvestzone, harvestzoneind) {
				if (harvestzone.uuid === id) {
					tharvestzone = harvestzone;
					return false;
				}
			});
			return tharvestzone;
		},
		getHarvestZones: (filters, lookup = false, ttoken = false, force = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let harvestZoneFetchRules = utils.getLocalStorageItem("harvestZoneFetchRules");

			// if (!utils.isempty(harvestZoneFetchRules) && !force) {
			// 	if (harvestZoneFetchRules.filtered === false && harvestZoneFetchRules.trycache) {
			// 		let harvestzones = utils.getLocalStorageItem("harvestzones");
			// 		if (!utils.isempty(harvestzones)) {
			// 			dispatch({ type: "REFRESH_HARVESTZONES", harvestzones });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	harvestZoneFetchRules = { trycache: true, filtered: true };
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/farmharvestzones", params).then(
				function (response) {
					if (response.data.success) {
						var harvestzones = [];
						utils.each(response.data.data, function (harvestzone, harvestzoneid) {
							//console.warn('org',org);
							// if(!utils.isset(harvestzone.cultivar_name)||utils.empty(harvestzone.cultivar_name)) {
							// 	harvestzone.uuid = "SFRTKLTFR-db3e1e6a-1ec4-44b4-a140-8717662e0192"; //#13:68755 None RID
							// }
							harvestzones.push(harvestzone);
						});
						if (lookup !== false) {
							//setHarvestZonesLookup(harvestzones);
							dispatch({ type: "REFRESH_HARVESTZONES", harvestzones });
						} else {
							dispatch({ type: "REFRESH_HARVESTZONES", harvestzones });
						}
						//responsereceived= true;
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setHarvestZonesLookup([]);
						}
					}
					// harvestZoneFetchRules.filtered = false;
					// localStorage.setItem("harvestZoneFetchRules", JSON.stringify(harvestZoneFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editHarvestZone: (harvestzone, hideform, filters, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: harvestzone
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/farmharvestzone", params, headers).then(
				function (response) {
					if (response.data.success) {
						//dispatch({ type: "EDIT_HARVESTZONE", harvestzone });
						setFormErrors({ name: '', farm: '', block: '', commodity: '', cultivar_name: ''});
						value.getHarvestZones(filters, false, false, true);
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		// harvestZonesLookupState:harvestZonesLookupState,
		// setHarvestZonesLookup:setHarvestZonesLookup,
		formErrors: formErrors,
		setFormErrors: setFormErrors,
		getHarvestZoneCIType: () => {
			return "ci_farmharvestz";
		},
		clearHarvestZones: () => {
			//setHarvestZonesLookup([]);
			let empty = [];
			dispatch({ type: "REFRESH_FARMS", empty });
			localStorage.setItem("harvestZonesLookup", JSON.stringify(empty));
			localStorage.setItem("harvestzones", JSON.stringify(empty));
		},
		resetFormErrors: () => {
			setFormErrors({ name: '', farm: '', block: '', commodity: '', cultivar_name: '' });
		}
	}
	return (
		<HarvestZonesContext.Provider value={value}>
			{children}
		</HarvestZonesContext.Provider>
	);
};
export default HarvestZonesProvider;

