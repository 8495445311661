import React, { useState, useContext, useEffect } from 'react'
import utils from "../../utils/utils";
import { v4 as uuidv4 } from 'uuid';
import { metaphone } from 'metaphone';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import { PestTrapsContext } from "../../contexts/pesttraps";
import { StateManagerContext } from '../../contexts/statemanager';

import '../../index.css';
import styled from '@emotion/styled';

// Theme
const ThemeFormTextField = styled(TextField)(utils.theme.FormTextField);
const ThemeSelect = styled(Select)(utils.theme.Select);
const ThemeSwitch = styled(Switch)(utils.theme.FormSwitch);

export function Step001({ formData, setFormData, formErrors, farms, orchards, pmzones, pesttraptypes, pests, pheromones }) {
	return (
		<div className="step001-container">
			{/* <label className='form-label-200' htmlFor="name">Name</label> */}
			<ThemeFormTextField
				type="text"
				id="name"
				label="Name"
				placeholder="Name..."
				value={formData.name}
				onChange={(event) =>
					setFormData({ ...formData, name: event.target.value })
				}
				error={!utils.isempty(formErrors.name) ? true : false}
				helperText={formErrors.name}
			/>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.farm) ? true : false}>
					<InputLabel id="farm" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Farm</InputLabel>
					<ThemeSelect
						label="Farm"
						value={formData.farm}
						onChange={(event) => {
							utils.each(farms, function (farm, farmid) {
								if (farm.uuid === event.target.value) {
									setFormData({ ...formData, farm: event.target.value, block: '', zone: '' });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							farms.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.farm}</FormHelperText>
				</FormControl>
			</Box>
			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.block) ? true : false}>
					<InputLabel id="block" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Field</InputLabel>
					<ThemeSelect
						label="Field"
						value={formData.block}
						onChange={(event) => {
							utils.each(orchards, function (orchard, orchardid) {
								if (orchard.uuid === event.target.value) {
									setFormData({ ...formData, block: event.target.value, zone: '' });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							orchards.map((value, ind) => {
								if (!utils.isempty(formData.farm) && value.farm !== formData.farm) { return null; }
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.block}</FormHelperText>
				</FormControl>
			</Box>
			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.zone) ? true : false}>
					<InputLabel id="zone" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Zone</InputLabel>
					<ThemeSelect
						label="Zone"
						value={formData.zone}
						onChange={(event) => {
							utils.each(pmzones, function (pmzone, pmzoneid) {
								if (pmzone.uuid === event.target.value) {
									setFormData({ ...formData, zone: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							pmzones.map((value, ind) => {
								if (!utils.isempty(formData.block) && value.block !== formData.block) { return null; }
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.zone}</FormHelperText>
				</FormControl>
			</Box>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.pest) ? true : false}>
					<InputLabel id="pest" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Pest</InputLabel>
					<ThemeSelect
						label="Pest"
						value={formData.pest}
						onChange={(event) => {
							utils.each(pests, function (pest, pestid) {
								if (pest.uuid === event.target.value) {
									setFormData({ ...formData, pest: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							pests.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.pest}</FormHelperText>
				</FormControl>
			</Box>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.traptype) ? true : false}>
					<InputLabel id="pesttraptype" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Trap Type</InputLabel>
					<ThemeSelect
						label="Trap Type"
						value={formData.traptype}
						onChange={(event) => {
							utils.each(pesttraptypes, function (pesttraptype, pesttraptypeid) {
								if (pesttraptype.uuid === event.target.value) {
									setFormData({ ...formData, traptype: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							pesttraptypes.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.traptype}</FormHelperText>
				</FormControl>
			</Box>

			<ThemeFormTextField
				type="text"
				id="latitude"
				label="Latitude"
				placeholder="Latitude..."
				value={formData.latitude}
				onChange={(event) =>
					setFormData({ ...formData, latitude: event.target.value })
				}
			/>

			<ThemeFormTextField
				type="text"
				id="longitude"
				label="Longitude"
				placeholder="Longitude..."
				value={formData.longitude}
				onChange={(event) =>
					setFormData({ ...formData, longitude: event.target.value })
				}
			/>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth>
					<InputLabel id="pheromone" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Pheromone</InputLabel>
					<ThemeSelect
						label="Pheromone"
						value={formData.pheromone}
						onChange={(event) => {
							utils.each(pheromones, function (pheromone, pheromoneid) {
								if (pheromone.uuid === event.target.value) {
									setFormData({ ...formData, pheromone: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							pheromones.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
				</FormControl>
			</Box>
			<Box sx={{ minWidth: "100%", display: 'flex', flexDirection: 'column', marginTop: "5px", justifyContent: 'left' }}>
				<Box sx={{ minWidth: "50%", marginTop: "5px", justifyContent: 'left' }}>
					<FormControlLabel
						label={"Can be Null"}
						sx={{ width: "200px" }}
						labelPlacement="start"
						control={
							<ThemeSwitch
								checked={(formData.hasnull == 1 || formData.hasnull == true || formData.hasnull == '1' ? true : false)}
								onChange={(event) => { setFormData({ ...formData, hasnull: event.target.checked }); }}
							/>
						}
					/>
					<FormControlLabel
						label={"Can Rebait"}
						sx={{ width: "200px" }}
						labelPlacement="start"
						control={
							<ThemeSwitch
								checked={(formData.rebait == 1 || formData.rebait == true || formData.rebait == '1' ? true : false)}
								onChange={(event) => { setFormData({ ...formData, rebait: event.target.checked }); }}
							/>
						}
					/>
				</Box>
				<Box sx={{ minWidth: "50%", marginTop: "5px", justifyContent: 'left' }}>
					<FormControlLabel
						label={"Has Sticky Pad"}
						labelPlacement="start"
						control={
							<ThemeSwitch
								checked={(formData.hasstickingcheck == 1 || formData.hasstickingcheck == true || formData.hasstickingcheck == '1' ? true : false)}
								onChange={(event) => { setFormData({ ...formData, hasstickingcheck: event.target.checked }); }}
							/>
						}
					/>
					<FormControlLabel
						label={"Has Housing Check"}
						labelPlacement="start"
						control={
							<ThemeSwitch
								checked={(formData.hashousingcheck == 1 || formData.hashousingcheck == true || formData.hashousingcheck == '1' ? true : false)}
								onChange={(event) => { setFormData({ ...formData, hashousingcheck: event.target.checked }); }}
							/>
						}
					/>
				</Box>
			</Box>
			{/* 
			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth>
					<Stack direction="row" spacing={1} alignItems="center" marginLeft={"200px"}>
						<Typography>No</Typography>
						<Switch
							Label={"Can be Null"}
							checked={(formData.hasnull==1?true:false)}
							onChange={(event)=>{ setFormData({ ...formData, hasnull: (event.target.value==true?1:0) }); }}
						/>
						<Typography>Yes</Typography>
					</Stack>
					</FormControl>
			</Box>
			*/}
		</div>
	);
}

export default function TrapForm({ hideForm, pesttrapid, formFilters }) {
	const [pageIndex] = useState(0);
	//const numPages = 1;
	var farmsLookupState = utils.getLocalStorageItem("farmsLookup");
	var orchardsLookupState = utils.getLocalStorageItem("orchardsLookup");
	var pmZonesLookupState = utils.getLocalStorageItem("pmZonesLookup");
	var pestsLookupState = utils.getLocalStorageItem("pestsLookup");
	var pestTrapTypesLookupState = utils.getLocalStorageItem("pestTrapTypesLookup");
	var pheromonesLookupState = utils.getLocalStorageItem("pheromonesLookup");

	const { addPestTrap, getPestTrap, editPestTrap, getPestTrapCIType, formErrors } = useContext(PestTrapsContext);
	const { setOverlayState } = useContext(StateManagerContext);
	const FormTitle = {
		add: "Add Trap",
		edit: "Edit Trap"
	};
	const [formAction, setformAction] = useState("add");
	const [formData, setFormData] = useState({
		rid: "#13:0",
		citype: "#12:170",
		farm: "",
		block: "",
		zone: "",
		pest: "",
		name: "",
		traptype: "",
		pheromone: "",
		latitude: 0,
		longitude: 0,
		hasnull: false,
		rebait: false,
		hashousingcheck: false,
		hasstickingcheck: false,
		uuid: metaphone(getPestTrapCIType()) + "-" + uuidv4()
	});
	//setFormAction(trapid===""?"add":"edit");
	const DisplayStep = () => {
		switch (pageIndex) {
			case 0:
				return <Step001 formData={formData} setFormData={setFormData} formErrors={formErrors} farms={farmsLookupState} orchards={orchardsLookupState} pmzones={pmZonesLookupState} pesttraptypes={pestTrapTypesLookupState} pests={pestsLookupState} pheromones={pheromonesLookupState} />;
			default:
				return false;
		}
	};

	useEffect(() => {
		if (utils.isempty(pesttrapid)) {
			let tuuid = metaphone(getPestTrapCIType()) + "-" + uuidv4();
			setformAction("add");
			setFormData({
				rid: "#13:0",
				citype: "#12:170",
				farm: "",
				block: "",
				zone: "",
				pest: "",
				traptype: "",
				pheromone: "",
				latitude: 0,
				longitude: 0,
				hasnull: false,
				rebait: false,
				hashousingcheck: false,
				hasstickingcheck: false,
				name: "",
				status: "active",
				uuid: tuuid
			});
		} else {
			setformAction("edit");
			setFormData(getPestTrap(pesttrapid));
		}
	}, [pesttrapid])
	return (
		<div className="form form-bg">
			<div className="progressbar">
				<div
					style={{ width: pageIndex === 0 ? "100%" : "100%" }}
				></div>
			</div>
			<div className="form-container">
				<div className="header">
					<h1 style={{ color: "#7A9A01" }}>{FormTitle[formAction]}</h1>
				</div>
				<div className="body">{DisplayStep()}</div>
				<div className="footer">
					<button
						onClick={(e) => {
							setOverlayState("overlay_visible");
							switch (e.target.innerText) {
								case 'Submit':
									addPestTrap({
										rid: formData.rid,
										citype: "#12:170",
										farm: formData.farm,
										block: formData.block,
										zone: formData.zone,
										pest: formData.pest,
										traptype: formData.traptype,
										pheromone: formData.pheromone,
										latitude: formData.latitude,
										longitude: formData.longitude,
										hasnull: formData.hasnull,
										hashousingcheck: formData.hashousingcheck,
										rebait: formData.rebait,
										hasstickingcheck: formData.hasstickingcheck,
										name: formData.name,
										status: formData.status,
										uuid: formData.uuid
									}, hideForm, setOverlayState);
									break;
								case 'Save':
									editPestTrap({
										rid: formData.rid,
										citype: "#12:170",
										farm: formData.farm,
										block: formData.block,
										zone: formData.zone,
										pest: formData.pest,
										traptype: formData.traptype,
										pheromone: formData.pheromone,
										latitude: formData.latitude,
										longitude: formData.longitude,
										hasnull: formData.hasnull,
										hashousingcheck: formData.hashousingcheck,
										rebait: formData.rebait,
										hasstickingcheck: formData.hasstickingcheck,
										name: formData.name,
										status: formData.status,
										uuid: formData.uuid
									}, hideForm, formFilters, setOverlayState);
									break;
								default:
									hideForm();
									break;
							}
						}
						}
					>
						{formAction === "add" ? "Submit" : "Save"}
					</button>
					<button
						onClick={(e) => {
							hideForm();
						}
						}
					>
						Cancel
					</button>
					{
						formAction === "add" ?
							null :
							<button
								onClick={(e) => {
									setOverlayState("overlay_visible");
									switch (e.target.innerText) {
										case 'Delete':
											editPestTrap({
												rid: formData.rid,
												citype: "#12:170",
												farm: formData.farm,
												block: formData.block,
												zone: formData.zone,
												pest: formData.pest,
												traptype: formData.traptype,
												pheromone: formData.pheromone,
												latitude: formData.latitude,
												longitude: formData.longitude,
												hasnull: formData.hasnull,
												hashousingcheck: formData.hashousingcheck,
												rebait: formData.rebait,
												hasstickingcheck: formData.hasstickingcheck,
												name: formData.name,
												status: "deleted",
												uuid: formData.uuid
											}, hideForm, formFilters, setOverlayState);
											break;
										case 'Reinstate':
											editPestTrap({
												rid: formData.rid,
												citype: "#12:170",
												farm: formData.farm,
												block: formData.block,
												zone: formData.zone,
												pest: formData.pest,
												traptype: formData.traptype,
												pheromone: formData.pheromone,
												latitude: formData.latitude,
												longitude: formData.longitude,
												hasnull: formData.hasnull,
												hashousingcheck: formData.hashousingcheck,
												rebait: formData.rebait,
												hasstickingcheck: formData.hasstickingcheck,
												name: formData.name,
												status: "active",
												uuid: formData.uuid
											}, hideForm, formFilters, setOverlayState);
											break;
										default:
											hideForm();
											break;
									}
								}}
							>
								{formData.status === "active" ? "Delete" : "Reinstate"}
							</button>
					}
				</div>
			</div>
		</div>
	);

}
