// @material-ui
import { BugReport, BugReportOutlined, HowToReg, HowToRegOutlined } from "@material-ui/icons"

// pages

const routes = [
    // {
    //     label: 'Dashboard',
    //     path: '/dashboard',
    //     icon: <DashboardOutlined />,
    //     activeIcon: <Dashboard />,
    // },
    {
        label: 'Census',
        path: '/census',
        icon: <HowToRegOutlined />,
        activeIcon: <HowToReg />,
    },
    {
        label: 'Pest Monitoring',
        path: '/pest-and-diseas',
        icon: <BugReportOutlined />,
        activeIcon: <BugReport />,
        subMenu: 
        [
            {
                label: 'Settings',
                path: '/pest-and-disease-settings',
            },
            {
                label: 'Trap Readings',
                path: '/pest-monitoring-trap-readings',
            },
            {
                label: 'Scout Inspections',
                path: '/scout-insepctions',
            }
        ] 
     }
    ,
    {
        label: 'Crop Protection',
        path: '/crop-chemicals',
        icon:  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="m13 10l5.949 9.516a.317.317 0 0 1-.268.484H5.319a.317.317 0 0 1-.266-.487L11 10V3h2ZM7 1v2h2v6.46l-5.641 8.99A2.318 2.318 0 0 0 5.319 22h13.362a2.318 2.318 0 0 0 1.96-3.55l-5.64-8.99L15 3h2V1Z"/></svg>,
        activeIcon:  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M18.68 22H5.32a2.318 2.318 0 0 1-1.96-3.55L4.27 17L9 9.46V3H7V1h10v2h-2v6.46L19.73 17l.91 1.45A2.318 2.318 0 0 1 18.68 22Z"/></svg>,
        subMenu: 
        [
            {
                label: 'Settings',
                path: '/crop-chemicals-settings',
            },
            {
                label: 'Recommendations',
                path: '/crop-chemicals-recommendations',
            },
            {
                label: 'Recommendation Programs',
                path: '/crop-chemicals-recommendation-programs',
            }
        ] 
    }
]

export default routes
