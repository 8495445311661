import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;

const UserContextsReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_USERCONTEXTS":
			return {
				usercontexts: action.usercontexts
			};
		case "ADD_USERCONTEXT":
			return {
				usercontexts: [...state.usercontexts, action.usercontext]
			};
		case "EDIT_USERCONTEXT":
			let tusercontexts = [];
			utils.each(state.usercontexts, function (org, orgind) {
				if (org.uuid !== action.usercontext.uuid) {
					tusercontexts.push(org);
				} else {
					tusercontexts.push(action.usercontext);
				}
			});
			//console.warn("edit tusercontexts",tusercontexts);
			return {
				usercontexts: tusercontexts
			};
		default:
			return state;
	}
};

export const UserContextsContext = createContext();

const UserContextsProvider = ({ children }) => {
	const usercontexts = def_value;
	const [state, dispatch] = useReducer(UserContextsReducer, {
		usercontexts: usercontexts,
		filters: {}
	});
	// let ts = localStorage.getItem("userContexts");
	// const [userContextsLookupState, setUserContextsLookup] = useState((utils.isset(ts) && !utils.isempty(ts) ? JSON.parse(ts) : []));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const [formErrors, setFormErrors] = useState({ name: '', shortcode: '', address_1: '', address_2: '', address_3: '', phone: '' });

	const value = {
		usercontexts: state.usercontexts,
		addUserContexts: (usercontext, hideform) => {
			const a = utils.new("ajax");

			if (utils.isempty(cookie)) { return false; }

			var params = {
				data: usercontext
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/usercontext", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "ADD_USERCONTEXT", usercontext });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
				});
		},
		getUserContext: (id) => {
			let tusercontext = {};
			utils.each(state.usercontexts, function (org, orgind) {
				if (org.uuid === id) {
					tusercontext = org;
					return false;
				}
			});
			return tusercontext;
		},
		getUserContexts: (lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			let userContextsFetchRules = utils.getLocalStorageItem("userContextsFetchRules");
			
			if (!utils.isempty(userContextsFetchRules)) {
				if (userContextsFetchRules.filtered === false&&userContextsFetchRules.trycache) {
					let spraytargets = utils.getLocalStorageItem("pmzones");
					if(!utils.isempty(spraytargets)) {
						dispatch({ type: "REFRESH_USERCONTEXTS", spraytargets });
						return true;
					}
				}
			} else {
				userContextsFetchRules = {trycache:true,filtered:true};
			}

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: {}
			};
			a.get("/usercontexts", params).then(
				function (response) {
					if (response.data.success) {
						//console.warn("response",response);
						var usercontexts = [];
						utils.each(response.data.data, function (org, orgid) {
							//console.warn('org',org);
							usercontexts.push(org);
						});
						if (lookup !== false) {
							//setUserContextsLookup(usercontexts);
							//dispatch({ type: "REFRESH_USERCONTEXTS", usercontexts });
							localStorage.setItem("userContextsLookup", JSON.stringify(usercontexts))
						} else {
							//dispatch({ type: "REFRESH_USERCONTEXTS", usercontexts });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setUserContextsLookup([]);
						}
					}
					ajaxstack = false;
				});
		},
		editUserContext: (usercontext, hideform) => {
			const a = utils.new("ajax");

			if (utils.isempty(cookie)) { return false; }

			var params = {
				data: usercontext
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/usercontext", params, headers).then(
				function (response) {
					if (response.data.success) {
						setFormErrors({ name: '', shortcode: '', address_1: '', address_2: '', address_3: '', phone: '' });
						dispatch({ type: "EDIT_USERCONTEXT", usercontext });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
				});
		},
		// userContextsLookupState: userContextsLookupState,
		// setUserContextsLookup: setUserContextsLookup,
		formErrors: formErrors,
		setFormErrors: setFormErrors,
		getUserContextCIType: () => {
			return "ci_usercontext";
		},
		clearUserContexts: () => {
			//setUserContextsLookup([]);
			let empty = [];
			// dispatch({ type: "REFRESH_USERCONTEXTS", empty });
			localStorage.setItem("userContextsLookup", JSON.stringify(empty))
		}
	}
	return (
		<UserContextsContext.Provider value={value}>
			{children}
		</UserContextsContext.Provider>
	);
};
export default UserContextsProvider;