import { createContext, useReducer, useContext, useEffect } from "react";
import utils from "../utils/utils";
import { useCookies } from 'react-cookie';
import { Buffer } from "buffer";
//This to become serverside lookup
var def_value = { authorized: false };

const sessionsReducer = (state, action) => {
	switch (action.type) {
		case "AUTH":
		utils.setLocalStorageItem("chartType","FIELDS");
			return {
				sessions: { authorized: true }
			};
		case "INVALIDDATE_SESSION":
			return {
				sessions: { authorized: false }
			};
		default:
			return state;
	}
};

export const SessionsContext = createContext();

const SessionsProvider = ({ children }) => {
	const [cookie, setCookie, removeCookie] = useCookies(["token"]);
	const sessions = def_value;

	const [state, dispatch] = useReducer(sessionsReducer, {
		sessions: sessions,
		filters: {}
	});
	//console.warn("state",state);
	const value = {
		sessions: state.sessions,
		authorize: (username, password, cb, overlaystate = false) => {
			if (utils.isempty(username) || utils.isempty(password)) {
				alert('Username and password needs to be specified.');
			} else {
				const a = utils.new("ajax");
				var params = {
					params: { username: username, password: password },
					//withCredentials:true,
				};
				utils.setAjaxStack(0);// make sure ajax stack is 0
				a.get("/auth", params).then(
					function (response) {
						if (response.data.success && !utils.isempty(response.data.token)) {
							setCookie('token', response.data.token, { path: '/' });
							localStorage.setItem('refreshData', "1");
							let ts = { authorized: true };
							dispatch({ type: "AUTH", ts });
							utils.setFlag("SwitchContext",true);
							cb(response.data.token);
						} else {
							alert(response.data.message);
							let ts = { authorized: false };
							dispatch({ type: "AUTH", ts });
						}
						overlaystate("overlay_hidden");
						//dispatch({type:"REFRESH_ORGS",sessions});
						//responsereceived= true;
					});
			}
		},
		switchUserContext: (context, cb=false,clearStates=false, overlaystate = false) => {
			const a = utils.new("ajax");
			var params = {
				headers: { "Authorization": "Bearer " + cookie.token },
				params: { filters: { context: context } }
			};
			utils.setFlag("SwitchContext",true);
			a.get("/switchusercontext", params).then(
				function (response) {
					//console.warn("switchUserContext new token", response.data);
					if (response.data.success && !utils.isempty(response.data.token)) {
						//removeCookie('token');
						setCookie('token', response.data.token, { path: '/' });
						dispatch({ type: "AUTH", sessions });
						clearStates();
						//console.warn("cb??",cb);
						utils.setAjaxStack(0);// make sure ajax stack is 0
						cb(response.data.token);
					} else {
						alert(response.data.message);
						dispatch({ type: "INVALIDDATE_SESSION", sessions });
					}
					overlaystate("overlay_hidden");
					//dispatch({type:"REFRESH_ORGS",sessions});
					//responsereceived= true;
				});
		},
		validate: () => {
			const a = utils.new("ajax");
			if (!utils.isset(cookie.token)) { return false; }
			var params = {
				headers: { "Authorization": "Bearer " + cookie.token },
			};
			a.get("/isValid", params).then(
				function (response) {
					if (!response.data.success) {
						//alert("validate -> removing cookie");
						dispatch({ type: "INVALIDDATE_SESSION", sessions });
						removeCookie('token');
					} else {
						dispatch({ type: "AUTH", sessions });
					}
				});
		},
		setinvalid: () => {
			//alert("setinvalid -> removing cookie");
			dispatch({ type: "INVALIDDATE_SESSION", sessions });
			removeCookie('token');
		},
		getCookieData: () => {
			if (utils.isempty(cookie.token)) { return false; }
			var t = cookie.token.split(".")[1];
			let buff = new Buffer(t, 'base64');
			return JSON.parse(buff.toString('ascii'));

		},
		logout: function (cb, overlaystate) {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/logout", params, headers).then(
				function (response) {
					if (response.data.success) {
						value.setinvalid();
						cb();
					} else {
						alert(response.data.message);
					}
					overlaystate("overlay_hidden");
				});
		},
	}
	// useEffect(() => {
	// 	value.validate();
	// }, []);
	return (
		<SessionsContext.Provider value={value}>
			{children}
		</SessionsContext.Provider>
	);
};
export default SessionsProvider;