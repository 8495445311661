import { createContext, useReducer, useContext } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;
const contextGroupsReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_CONTEXTGROUPS":
			return {
				contextGroups: action.contextGroups
			};
		case "ADD_CONTEXTGROUP":
			return {
				contextGroups: [...state.contextGroups, action.contextGroup]
			};
		case "EDIT_CONTEXTGROUP":
			let tcontextGroups = [];
			utils.each(state.contextGroups, function (contextGroup, contextGroupind) {
				if (contextGroup.rid !== action.contextGroup.rid) {
					tcontextGroups.push(contextGroup);
				} else {
					tcontextGroups.push(action.contextGroup);
				}
			});
			return {
				contextGroups: tcontextGroups
			};
		default:
			return state;
	}
};

export const ContextGroupsContext = createContext();

const ContextGroupsProvider = ({ children }) => {
	const contextGroups = def_value;
	const [state, dispatch] = useReducer(contextGroupsReducer, {
		contextGroups: contextGroups,
		filters: {}
	});
	// let ts = localStorage.getItem("contextGroups");
	// const [contextGroupsLookupState, setContextgroupsLookup] = useState((utils.isset(ts) && !utils.isempty(ts) ? JSON.parse(ts) : []));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);

	const value = {
		contextGroups: state.contextGroups,
		addContextGroup: (contextGroup, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: contextGroup
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/contextgroup", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "ADD_CONTEXTGROUP", contextGroup });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		getContextGroup: (id) => {
			let tcontextGroup = {};
			utils.each(state.contextGroups, function (org, orgind) {
				if (org.rid === id) {
					tcontextGroup = org;
					return false;
				}
			});
			return tcontextGroup;
		},
		getContextGroups: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/contextgroups", params).then(
				function (response) {
					if (response.data.success) {
						var contextGroups = [];
						utils.each(response.data.data, function (contextGroup, contextGroupid) {
							//console.warn('org',org);
							contextGroups.push(contextGroup);
						});
						if (lookup !== false) {
							// setContextgroupsLookup(contextGroups);
							//dispatch({ type: "REFRESH_CONTEXTGROUPS", contextGroups });
							localStorage.setItem("contextGroupsLookup", JSON.stringify(contextGroups));
						} else {
							//dispatch({ type: "REFRESH_CONTEXTGROUPS", contextGroups });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setContextgroupsLookup([]);
						}
					}
					// contextGroupsFetchRules.filtered = false;
					// localStorage.setItem("contextGroupsFetchRules", JSON.stringify(contextGroupsFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editContextGroup: (contextGroup, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: contextGroup
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/contextgroup", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "EDIT_CONTEXTGROUP", contextGroup });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		// contextGroupsLookupState: contextGroupsLookupState,
		// setContextgroupsLookup: setContextgroupsLookup,
		getContextGroupCIType: () => {
			return "ci_contextgroup";
		},
		clearContextGroups: () => {
			//setContextgroupsLookup([]);
			let empty = [];
			//dispatch({ type: "REFRESH_CONTEXTGROUPS", empty });
			localStorage.setItem("contextGroupsLookup", JSON.stringify(empty));
		}
	}
	return (
		<ContextGroupsContext.Provider value={value}>
			{children}
		</ContextGroupsContext.Provider>
	);
};
export default ContextGroupsProvider;

