import React, { useState, useEffect } from 'react'; //, useContext
//import { StateManagerContext } from '../contexts/statemanager';

// @material-ui
import { List, ListItem, ListItemText, ListItemIcon, Collapse, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

//external
import clsx from 'clsx';
import { Link, useLocation } from "react-router-dom";

// internal
import { useStyles } from '../styles';
import utils from '../utils/utils';
import { isDisabled } from '@testing-library/user-event/dist/utils';

// destructuring the properties("label, icon, activeIcon, path") we passed to the "MenuItem"
const MenuItem = ({ label, icon, activeIcon, path, subMenu, navigationContainerState }) => { //, useContext
  // calling useStyles in our component - gives access to the classes
  // we can then apply the class to div
  const classes = useStyles();

  // state to manage when menu icon is active
  const [active] = useState(true);

  // state to manage subMenu items
  const [subMenuActive, setSubMenuActive] = useState(false);

  const [open, setOpen] = useState(false);
  // const { ApplicationState,setApplicationState } = useContext(StateManagerContext);//,setChartType,chartType
  // to make menuItem active only when on associated page we use
  // useLoaction hook from 'react-router-dom and useEffect from 'React'
  const location = useLocation()
  // const currentPathName = window.location.href;

  // using location - that has property 'pathname' we will use against 'path'prop passed inside MeunItme component
  // using comparison to set active state to true or false if location pathname matche menu item path
  // location is set as dependency of our useEffect
  // useEffect(() => {
  //   setActive(location.pathname === path);
  // }, [location]);

  useEffect(() => {
    if (subMenu) {
      subMenu.map((subItem) => {
        setSubMenuActive(true);
        return true;
      })
    }
  }, [location, subMenu]);

  const handleClick = () => {
    setOpen(true);
  };
  console.warn("MenuItem", utils.getLocalStorageItem("chartType"));
  return (
    // menuItem - class will be default class
    // menuItemActive - class to be only used when state is Active
    // to achieve above will use package calles clsx
    // CLSX - utility for constructing classNames strings conditionally
    <List className={classes.navigationList}>
      <ListItem
        button={true}
        component={Link}
        to={!subMenu > 0 ? path : isDisabled}
        className={clsx(classes.menuItem, active && classes.menuItemActive)}>
        {
          (active || subMenu) && subMenuActive ? (
            <ListItemIcon style={{ color: "#7A9A01" }} className={classes.menuItemSVGIcon}> {activeIcon} </ListItemIcon>
          ) : <ListItemIcon style={{ color: "#7A9A01" }} className={classes.menuItemSVGIcon}> {icon} </ListItemIcon>
        }
        <ListItemText primary={label} primaryTypographyProps={{ variant: "body2" }} />
        {
          subMenu && subMenu.length > 0 ? (
            open ? <IconButton className={classes.menuItemIconBtn} onClick={() => setOpen(!open)}><ExpandMore style={{ color: "#7A9A01" }} /></IconButton> : <IconButton className={classes.menuItemIconBtn} onClick={handleClick}><ExpandLess style={{ color: "#7A9A01" }} /></IconButton>
          ) : null
        }
      </ListItem>
      {
        subMenu && subMenu.length > 0 && navigationContainerState ? (
          <Collapse className={classes.collapsedMenuItem} in={open} timeout="auto" unmountOnExit>
            {subMenu.map((subMenuItem, index) => (
              <List className={classes.navigationList} key={index} component="div" disablePadding>
                <ListItem
                  button={true}
                  onClick={
                    (event) => {
                      let chartType = utils.getLocalStorageItem("chartType");
                      let turl = utils.parseURL(event.target.baseURI);
                      if (turl === 'pest-monitoring-trap-readings' && (chartType !== "REVIEW" || chartType !== "REPORT")) {
                        utils.setLocalStorageItem("chartType", "REVIEW");
                      }
                      //setApplicationState({...ApplicationState,Selectedevent.target.baseURI});
                      
                    }
                  }
                  component={Link}
                  to={subMenuItem.path}
                  className={clsx(classes.menuItem, subMenuActive && classes.menuItemActive)}>
                  <ListItemText primary={subMenuItem.label} primaryTypographyProps={{ variant: "body2" }} />
                </ListItem>
              </List>
            ))}
          </Collapse>
        ) : null
      }
    </List>
  );
};

export default MenuItem
