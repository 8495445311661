/**
 * StateManager is used to clear all states when loggin out or switching contexts and sets the required cached items
 */
import { createContext, useContext, useState } from "react";
import { ApplicationMethodsContext } from "./applicationmethods";
import { CultivarsContext } from "./cultivars";
import { FarmCommoditiesContext } from "./farmcommodities";
import { FarmsContext } from "./farms";
import { HarvestZonesContext } from "./harvestzones";
import { InspectionTemplatesContext } from "./inspectiontemplates";
import { OrchardsContext } from "./orchards";
import { OrganisationsContext } from "./organisations";
import { PersonsContext } from "./persons";
import { PestInspectionsContext } from "./pestinspections";
import { PestsContext } from "./pests";
import { ContextGroupsContext } from "./contextgroups";
import { PestTrapInspectionsContext } from "./pesttrapinspections";
import { PestTrapsContext } from "./pesttraps";
import { PestTrapTypesContext } from "./pesttraptypes";
import { PheromonesContext } from "./pheromones";
import { PlantSprayProductsContext } from "./plantsprayproducts";
import { PMZonesContext } from "./pmzones";
import { SprayRecommendationsContext } from "./sprayrecommendations";
import { SprayProgramsContext } from "./sprayprograms";
import { SprayTargetsContext } from "./spraytargets";
import { UserContextsContext } from "./usercontexts";
import { ZoneTypesContext } from "./zonetypes";

export const StateManagerContext = createContext();

const StateManagerProvider = ({ children }) => {
	const { clearApplicationMethods, getApplicationMethods } = useContext(ApplicationMethodsContext);
	const { clearCultivars, getCultivars } = useContext(CultivarsContext);
	const { clearFarmCommodities, getFarmCommodities } = useContext(FarmCommoditiesContext);
	const { clearFarms, getFarms } = useContext(FarmsContext);
	const { clearHarvestZones, getHarvestZones } = useContext(HarvestZonesContext);
	const { clearInspectionTemplates, getInspectionTemplates } = useContext(InspectionTemplatesContext);
	const { clearOrchards, getOrchards } = useContext(OrchardsContext);
	const { clearOrganisations, getOrganisations } = useContext(OrganisationsContext);
	const { clearPersons, getPersons } = useContext(PersonsContext);
	const { clearPestInspections } = useContext(PestInspectionsContext);
	const { clearPests, getPests } = useContext(PestsContext);
	const { clearContextGroups, getContextGroups } = useContext(ContextGroupsContext);
	const { clearPestTrapInspections } = useContext(PestTrapInspectionsContext);
	const { clearPestTraps, getPestTraps } = useContext(PestTrapsContext);
	const { clearPestTrapTypes, getPestTrapTypes } = useContext(PestTrapTypesContext);
	const { clearPheromones, getPheromones } = useContext(PheromonesContext);
	const { clearPMZones, getPMZones } = useContext(PMZonesContext);
	const { clearSprayRecommendations } = useContext(SprayRecommendationsContext);
	const { clearSprayPrograms, getSprayPrograms } = useContext(SprayProgramsContext);
	const { clearSprayTargets, getSprayTargets } = useContext(SprayTargetsContext);
	const { clearPlantSprayProducts, getPlantSprayProducts } = useContext(PlantSprayProductsContext);
	const { clearUserContexts, getUserContexts } = useContext(UserContextsContext);
	const { clearZoneTypes,  getZoneTypes } = useContext(ZoneTypesContext);
	const [overlayState, setOverlayState] = useState("overlay_hidden");
	const [switchContextOverlayState, setSwitchContextOverlayState] = useState("overlay_hidden");
	const [trapChartFilters, setTrapChartFilters] = useState({ selectedWeek: 0, selectedPest: 0 });
	const [trapListChartFilters, setTrapListChartFilters] = useState({ selectedWeek: 0, selectedPest: 0 });
	//const [selectedMenu, setSelectedMenu] = useState(window.location.href);
	const [ApplicationState,setApplicationState] = useState({selectedMenu:window.location.href,showMap:true});
	const [trapReportForceRefresh, setTrapReportForceRefresh] = useState("");
	//const [showMap, setShowMap] = useState(true);
	//const [updateCIState, setUpdateCIState] = useState({visibility:"updateci_hidden",data:{message:""}});
	
	const value = {
		ApplicationState: ApplicationState,
		setApplicationState:setApplicationState,
		overlayState: overlayState,
		setOverlayState: setOverlayState,
		switchContextOverlayState: switchContextOverlayState,
		setSwitchContextOverlayState: setSwitchContextOverlayState,
		//selectedMenu: selectedMenu,
		//setSelectedMenu: setSelectedMenu,
		trapChartFilters: trapChartFilters,
		setTrapChartFilters: setTrapChartFilters,
		trapListChartFilters: trapListChartFilters,
		setTrapListChartFilters: setTrapListChartFilters,
		trapReportForceRefresh: trapReportForceRefresh,
		setTrapReportForceRefresh : setTrapReportForceRefresh,
		//showMap : showMap,
		//setShowMap : setShowMap,
		// updateCIState : updateCIState,
		// setUpdateCIState : setUpdateCIState,
		clearStates: function () {
			console.warn("Clearing cache states...");
			clearUserContexts();
			clearApplicationMethods();
			clearCultivars();
			clearFarmCommodities();
			clearFarms();
			clearHarvestZones();
			clearInspectionTemplates();
			clearOrchards();
			clearOrganisations();
			clearPersons();
			clearPestInspections();
			clearPests();
			clearContextGroups();
			clearPestTrapInspections();
			clearPestTraps();
			clearPestTrapTypes();
			clearPheromones();
			clearPMZones();
			clearSprayRecommendations();
			clearSprayPrograms();
			clearSprayTargets();
			clearPlantSprayProducts();
			clearZoneTypes();
		},
		getStates: function (token) {
			console.warn("Getting cache states...");
			getUserContexts(true, token);
			getApplicationMethods({ status: 'active' }, true, token);
			getCultivars({ status: 'active' }, true, token);
			getFarmCommodities({ status: 'active' }, true, token);
			getFarms({ status: 'active' }, true, token);
			getHarvestZones({ status: 'active' }, true, token);
			getInspectionTemplates({ status: 'active' }, true, token);
			getOrchards({ status: 'active' }, true, token);
			getOrganisations({ status: 'active' }, true, token);
			getPersons({ status: 'active' }, true, token);
			getPests({ status: 'active' }, true, token);
			getContextGroups({ status: 'active' }, true, token);
			getPestTraps({ status: 'active' }, true, token);
			getPestTrapTypes({ status: 'active' }, true, token);
			getPheromones({ status: 'active' }, true, token);
			getPMZones({ status: 'active' }, true, token);
			getSprayTargets({ status: 'active' }, true, token);
			getPlantSprayProducts({ status: 'active' }, true, token);
			getZoneTypes({ status: 'active' }, true, token);
			getSprayPrograms({ status: 'active' },null, true, token);
		},
	}
	return (
		<StateManagerContext.Provider value={value}>
			{children}
		</StateManagerContext.Provider>
	);
};
export default StateManagerProvider;