import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;

const organisationsReducer = (state, action) => {
	let torganisations = [];
	switch (action.type) {
		case "REFRESH_ORGANISATIONS":
			localStorage.setItem("organisationsLookup", JSON.stringify(action.organisations));
			localStorage.setItem("organisations", JSON.stringify(action.organisations));
			return {
				organisations: action.organisations
			};
		case "ADD_ORGANISATION":
			torganisations = utils.updateArray(state.organisations,action.organisation,);
			torganisations = utils.sortArray(torganisations);
			localStorage.setItem("organisationsLookup", JSON.stringify(torganisations));
			localStorage.setItem("organisations", JSON.stringify(torganisations));
			return {
				organisations: torganisations
			};
		case "EDIT_ORGANISATION":
			utils.each(state.organisations, function (org, orgind) {
				if (org.uuid !== action.organisation.uuid) {
					torganisations.push(org);
				} else {
					torganisations.push(action.organisation);
				}
			});
			torganisations = utils.sortArray(torganisations);
			localStorage.setItem("organisationsLookup", JSON.stringify(torganisations));
			localStorage.setItem("organisations", JSON.stringify(torganisations));
			//console.warn("edit torganisations",torganisations);
			return {
				organisations: torganisations
			};
		default:
			return state;
	}
};

export const OrganisationsContext = createContext();

const OrganisationsProvider = ({ children }) => {
	const organisations = def_value;
	const [state, dispatch] = useReducer(organisationsReducer, {
		organisations: organisations,
		filters: {}
	});
	// let ts = localStorage.getItem("organisations");
	// const [organisationsLookupState,setOrganisationsLookup] = useState((utils.isset(ts)&&!utils.isempty(ts)?JSON.parse(ts):[]));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const [formErrors, setFormErrors] = useState({ name: '', shortcode: '', address_1: '', address_2: '', address_3: '', phone: '' });

	const value = {
		organisations: state.organisations,
		addOrganisation: (organisation, hideform, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: organisation
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/organisation", params, headers).then(
				function (response) {
					if (response.data.success) {
						organisation = response.data.data;
						dispatch({ type: "ADD_ORGANISATION", organisation});
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
			});
		},
		getOrganisation: (id) => {
			let torganisation = {};
			utils.each(state.organisations, function (org, orgind) {
				if (org.uuid === id) {
					torganisation = org;
					return false;
				}
			});
			return torganisation;
		},
		getOrganisations: (filters, lookup = false, ttoken = false, force = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let organisationFetchRules = utils.getLocalStorageItem("organisationFetchRules");
			
			// if (!utils.isempty(organisationFetchRules) && !force) {
			// 	if (organisationFetchRules.filtered === false&&organisationFetchRules.trycache) {
			// 		let organisations = utils.getLocalStorageItem("organisations");
			// 		if(!utils.isempty(organisations)) {
			// 			dispatch({ type: "REFRESH_ORGANISATIONS", organisations });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	organisationFetchRules = {trycache:true,filtered:false};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/organisations", params).then(
				function (response) {
					console.warn("response",response);
					if (response.data.success) {
						var organisations = [];
						utils.each(response.data.data, function (org, orgid) {
							//console.warn('org',org);
							organisations.push(org);
						});
						if (lookup !== false) {
							//setOrganisationsLookup(organisations);
							dispatch({ type: "REFRESH_ORGANISATIONS", organisations });
						} else {
							dispatch({ type: "REFRESH_ORGANISATIONS", organisations });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							// setOrganisationsLookup([]);
						}
					}
					// organisationFetchRules.filtered = false;
					// localStorage.setItem("organisationFetchRules", JSON.stringify(organisationFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editOrganisation: (organisation, hideform, filters, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }

			var params = {
				data: organisation
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/organisation", params, headers).then(
				function (response) {
					if (response.data.success) {
						setFormErrors({ name: '', shortcode: '', address_1: '', address_2: '', address_3: '', phone: '' });
						//dispatch({ type: "EDIT_ORGANISATION", organisation });
						value.getOrganisations(filters, false, false, true);
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		// organisationsLookupState:organisationsLookupState,
		// setOrganisationsLookup:setOrganisationsLookup,
		formErrors: formErrors,
		setFormErrors: setFormErrors,
		getOrganisationCIType: () => {
			return "ci_organisation";
		},
		clearOrganisations: () => {
			// setOrganisationsLookup([]);
			let empty = [];
			dispatch({ type: "REFRESH_ORGANISATIONS", empty });
			localStorage.setItem("organisationsLookup", JSON.stringify(empty));
			localStorage.setItem("organisations", JSON.stringify(empty));
		},
		resetFormErrors: () => {
			setFormErrors({ name: '', shortcode: '', address_1: '', address_2: '', address_3: '', phone: ''  });
		}
	}
	return (
		<OrganisationsContext.Provider value={value}>
			{children}
		</OrganisationsContext.Provider>
	);
};
export default OrganisationsProvider;