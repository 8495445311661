import React, { useContext, useState } from "react";
import utils from "../../utils/utils";
import { StateManagerContext } from "../../contexts/statemanager";
import { SessionsContext } from "../../contexts/sessions";

import '../../index.css';
import styled from "@emotion/styled";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";

// Theme
const ThemeSelect = styled(Select)(utils.theme.Select);

export function SwitchContextOverlay(props) {
	const { switchContextOverlayState } = useContext(StateManagerContext);
	const { getCookieData, switchUserContext } = useContext(SessionsContext);
	const { getStates,clearStates, setOverlayState, setSwitchContextOverlayState } = useContext(StateManagerContext);
	
	const usercontexts = utils.getLocalStorageItem("userContextsLookup");
	var CD = getCookieData();

	const [tocontext, setToContextState] = useState(CD.activecontext);
	const btnstyle = {
		color: "white",
		borderColor: "#7A9A01",
		size: "small",
		'&:hover': {
			color: "#52312d",
			borderColor: "#52312d"
		}
	};

	return (
		<div className={"form-container dialog-loader-2 dialog-loader-2-hidden"} style={utils.theme[switchContextOverlayState]}>
			<div className="form form-bg" style={{ marginTop: "auto", marginBottom: "auto", marginLeft: "auto", marginRight: "auto", minHeight: "150px", maxHeight: "250px" }}>
				<div className="form-container">
					<div className="header">
						<h1 style={{ color: "#7A9A01" }}>{"Switch Context"}</h1>
					</div>
					<div className="body">
						<Box sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
							<div className="switch-context-container" style={{ backgroundColor: '#c7d494' }}>
								{
										<Box
											sx={{
												alignItems: 'center',
												margin: '14px 14px',
											}}
										>
											<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
												<FormControl fullWidth size="small">
													<InputLabel id="" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': {color:'rgba(0, 0, 0, 0.45)'} }}>Select Context</InputLabel>
													<ThemeSelect
														label="Select Context"
														value={tocontext}
														onChange={(event) => {
															setToContextState(event.target.value);
														}}
														MenuProps={{
															sx: {
																"&& .Mui-selected": {
																	backgroundColor: "#5f7933",
																	color: "#fff"
																},
															},
															PaperProps: {
																style: {
																	maxHeight: 48 * 4.5 + 8,
																	width: 250,
																},
																sx: {
																  backgroundColor: "#c7d494",
																}
															  }
														}}
													>
														{
															!utils.isempty(usercontexts) ? usercontexts.map((value, ind) => { return (<MenuItem key={value.rid} value={value.rid}>{value.name}</MenuItem>) }) : () => { return (<MenuItem value={"#13:0"}>{"Loading contexts..."}</MenuItem>) }
														}
													</ThemeSelect>
												</FormControl>
											</Box>
										</Box>
								}
							</div>
						</Box>
					</div>
					<div className="footer">
							<Link to="/">
							<button
								onClick={() => {
									setSwitchContextOverlayState("overlay_hidden");
									setOverlayState("overlay_visible");
									setTimeout(()=>{switchUserContext(tocontext,getStates,clearStates,setOverlayState)},100);
								}}
								variant="outlined"
								style={btnstyle}
							>
								Switch
							</button>
							</Link>
							<button
								onClick={() => {
									setSwitchContextOverlayState("overlay_hidden");
								}}
								variant="outlined"
								style={btnstyle}
							>
								Cancel
							</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SwitchContextOverlay;