import React, { useContext } from 'react'

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import OrganisationList from '../Organisations/OrganisationList';
import FarmList from '../Farms/FarmList';
import OrchardsList from '../Orchards/OrchardList';
import utils from '../../utils/utils';
import PersonsList from '../Persons/PersonsList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
       <Box sx={{ padding: '10px 0px 0px 0px' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const CensusRegisterComponent = () => {
  // calling useStyles in our component - gives access to the classes
  // we can then apply the class to div
  const [value, setValue] = React.useState(2);
  //const {setChartType} = useContext(StateManagerContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.warn("CensusRegisterComponent setLocalStorageItem chartType ",event.target.innerText);
    utils.setLocalStorageItem("chartType",event.target.innerText);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          TabIndicatorProps={{ style: { background: "#7A9A01" } }}
        >
          <Tab label="Orgs" {...a11yProps(0)} />
          <Tab label="Farms" {...a11yProps(1)} />
          <Tab label="Fields" {...a11yProps(2)} />
          <Tab label="People" {...a11yProps(3)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <OrganisationList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FarmList />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OrchardsList />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PersonsList />
      </TabPanel>
    </Box>
  );
}

export default CensusRegisterComponent