import React, { Fragment, useContext, useState } from 'react'
import utils from '../utils/utils';
// assets
import Logo from '../assets/logo/nexus_logo.png'
import LogoMinimized from '../assets/logo/nexus_logo_minimized.png'

// @material-ui
import { AppBar, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import { ChevronLeft, MenuOutlined } from '@material-ui/icons';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import Tooltip from '@mui/material/Tooltip';
import { Link } from "react-router-dom";
//external
import clsx from 'clsx';

// internal
import MenuItem from './MenuItem';
import StyledAvatar from './StyledAvatar';
import routes from '../routes';
import { useStyles } from '../styles';
import { SessionsContext } from '../contexts/sessions';
import { StateManagerContext } from '../contexts/statemanager';
import SwitchContextOverlay from './Overlays/SwitchContext';


const Navigation = () => {
	const usercontexts = utils.getLocalStorageItem("userContextsLookup");
	const { getCookieData, logout } = useContext(SessionsContext);
	const { clearStates, setOverlayState, setSwitchContextOverlayState } = useContext(StateManagerContext);

	// useTheme - gisves access to material ui theme - from there giving access to breakpoints
	const theme = useTheme();

	// theme.breakpoint.down(xs) is our breakpoint range (0px - 600px)
	// if width of screen between 0 - 600px 'matches' value becomes true else value false
	const matches = useMediaQuery(theme.breakpoints.down('xs'))

	// calling useStyles in our component - gives access to the classes
	// we can then apply the class to div
	const classes = useStyles();

	const [open, setOpen] = useState(true);

	const toggleNavigation = () => {
		setOpen(!open);
	};

	const closeNavigation = () => {
		if (matches) {
			setOpen(false);
		}
	};

	var CD = getCookieData();

	return (
		<div className={classes.navigationContainer}>
			<SwitchContextOverlay></SwitchContextOverlay>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton onClick={toggleNavigation} edge="start" color="inherit" aria-label="Menu">
						<MenuOutlined />
					</IconButton>
					<Typography color="inherit" component="h1" vảriant="h4">Nexus</Typography>
				</Toolbar>
			</AppBar>
			<Drawer
				// classes property - takes an object with a key assoicated with part of the component you want to target
				// "paper" - the part of the component we want to target
				// CLSX - utility for constructing classNames strings conditionally
				classes={{ paper: clsx(classes.navigationDrawer, !open && classes.navigationDrawerCollapse) }}
				variant={matches ? 'temporary' : 'permanent'}
				open={open}
				sx={{
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: open
							? theme.transitions.duration.leavingScreen
							: theme.transitions.duration.enteringScreen,
					}),
					'& .MuiDrawer-paper': {
						// justifyContent: 'space-between',
						overflowX: 'hidden',
						borderRight: '0px',
						borderRadius: '0px 16px 16px 0px',
						boxShadow: theme.shadows[8],
						backgroundColor: open ? '#fafafa' : '#fafafa',
						transition: theme.transitions.create('width', {
							easing: theme.transitions.easing.sharp,
							duration: open
								? theme.transitions.duration.leavingScreen
								: theme.transitions.duration.enteringScreen,
						}),
					},
				}}
			>
				<div className={clsx(classes.navigationToolbarOpen, !open && classes.navigationToolbarCollapsed)}>
					<IconButton onClick={toggleNavigation}>
						{open ? <ChevronLeft /> : <MenuOutlined />}
					</IconButton>
				</div>

				<div className={classes.navigationLogoContainer}>
					{
						open ? <img className={classes.navigationLogoOpen} src={Logo} alt="Logo" width={150} height={48} />
							: <img className={classes.navigationLogoCollapsed} src={LogoMinimized} alt="Logo" width={150} height={48} />
					}
				</div>
				<div className={classes.navigationListContainer}>
					{
						routes.map((route, index) => {
							//console.warn("route",route);
							return (
								<Fragment key={index}>
									<MenuItem
										key={index}
										label={route.label}
										icon={route.icon}
										activeIcon={route.activeIcon}
										path={route.path}
										subMenu={route.subMenu}
										onClick={closeNavigation}
										navigationContainerState={open}
									/>
								</Fragment>
							);
						})
					}
				</div>

				<div>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							margin: ' 14px 14px 10px 14px',
							paddingTop: '12px',
							borderTop: '1px solid lightgray',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								marginRight: '18px',
								paddingLeft: '0px',
								alignItems: 'center',
								alignContent: 'center',
							}}
						>
							<StyledAvatar />
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, alignSelf: 'center' }}>
							<Typography
								component="span"
								variant="body2"
								sx={{
									fontFamily: 'inherit',
									display: 'block',
									whiteSpace: 'initial',
									lineHeight: 'inherit',
									fontWeight: 500,
									color: 'rgb(122, 154, 1)',
								}}
							>
								{CD.name}
							</Typography>
						</Box>
						<Tooltip title="Switch Context" placement="top-start">
							<IconButton contained={"true"} sx={{ color: 'rgb(122, 154, 1)' }} onClick={() => { setSwitchContextOverlayState("overlay_visible"); }}>
								<ModelTrainingIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Log Out" placement="top-start">
							<Link to="/">
								<IconButton contained={"true"} sx={{ color: 'rgb(122, 154, 1)' }} onClick={() => { setOverlayState("overlay_visible"); utils.setLocalStorageItem("chartType",""); setTimeout(() => { logout(clearStates, setOverlayState); }, 100); }}>
									<ExitToAppIcon />
								</IconButton>
							</Link>
						</Tooltip>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							margin: '0px 14px 14px 14px',
						}}
					>
						{
							open ?
								!utils.isempty(usercontexts) ? usercontexts.map((value, ind) => {
									if (value.rid === CD.activecontext) {
										return (
											<Typography
												key={value.rid}
												value={value.rid}
												component="span"
												variant="body2"
												sx={{
													fontFamily: 'inherit',
													display: 'block',
													whiteSpace: 'initial',
													lineHeight: 'inherit',
													fontWeight: 500,
													color: 'rgba(0, 0, 0, 0.54)',
												}}
											>
												{value.name}
											</Typography>
										)
									} else {
										return null;
									}
								}) : () => {
									return (
										<Typography
											value={"#13:0"}
											component="span"
											variant="body2"
											sx={{
												fontFamily: 'inherit',
												display: 'block',
												whiteSpace: 'initial',
												lineHeight: 'inherit',
												fontWeight: 500,
												color: 'rgba(0, 0, 0, 0.54)',
											}}
										>
											{"Loading contexts..."}
										</Typography>
									)
								}
								: null
						}
					</Box>
				</div>
			</Drawer>
		</div>
	);
};

export default Navigation