import React, { useState, useContext, useEffect } from 'react'
import utils from "../../utils/utils";
import { v4 as uuidv4 } from 'uuid';
import { metaphone } from 'metaphone';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { OrchardsContext } from '../../contexts/orchards';
import { StateManagerContext } from '../../contexts/statemanager';

import styled from '@emotion/styled';

const colors = [
	{ id: "white", label: "White" },
	{ id: "silver", label: "Silver" },
	{ id: "gray", label: "Gray" },
	{ id: "black", label: "Black" },
	{ id: "red", label: "Red" },
	{ id: "maroon", label: "Maroon" },
	{ id: "yellow", label: "Yellow" },
	{ id: "olive", label: "Olive" },
	{ id: "lime", label: "Lime" },
	{ id: "green", label: "Green" },
	{ id: "aqua", label: "Aqua" },
	{ id: "teal", label: "Teal" },
	{ id: "blue", label: "Blue" },
	{ id: "navy", label: "Navy" },
	{ id: "fuchsia", label: "Fuchsia" },
	{ id: "purple", label: "Purple" }
];

// Theme
const ThemeFormTextField = styled(TextField)(utils.theme.FormTextField)
const ThemeSelect = styled(Select)(utils.theme.Select);

export function Step001({ formData, setFormData, formErrors, farms }) {
	return (
		<div className="step001-container">
			{/* <label className='form-label-200' htmlFor="name">Name</label> */}
			<ThemeFormTextField
				type="text"
				id="name"
				label="Name"
				placeholder="Name..."
				value={formData.name}
				onChange={(event) =>
					setFormData({ ...formData, name: event.target.value })
				}
				error={!utils.isempty(formErrors.name) ? true : false}
				helperText={formErrors.name}
			/>
			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth>
					<InputLabel id="" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Farm</InputLabel>
					<ThemeSelect
						label="Farm"
						value={formData.farm}
						onChange={(event) => {
							utils.each(farms, function (farm, farmid) {
								if (farm.uuid === event.target.value) {
									setFormData({ ...formData, farm: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							farms.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
				</FormControl>
			</Box>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth>
					<InputLabel id="" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Outline Colour</InputLabel>
					<ThemeSelect
						label="Outline Colour"
						value={formData.outlinecol}
						onChange={(event) => {
							utils.each(colors, function (color, colorid) {
								if (color.id === event.target.value) {
									setFormData({ ...formData, outlinecol: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							colors.map((value, ind) => {
								return (
									<MenuItem key={value.id} value={value.id}>{value.label}</MenuItem>
								)
							})
						}
					</ThemeSelect>
				</FormControl>
			</Box>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth>
					<InputLabel id="" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Fill Colour</InputLabel>
					<ThemeSelect
						label="Fill Colour"
						value={formData.fillcol}
						onChange={(event) => {
							utils.each(colors, function (color, colorid) {
								if (color.id === event.target.value) {
									setFormData({ ...formData, fillcol: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							colors.map((value, ind) => {
								return (
									<MenuItem key={value.id} value={value.id}>{value.label}</MenuItem>
								)
							})
						}
					</ThemeSelect>
				</FormControl>
			</Box>
		</div>
	);
}

export default function OrchardForm({ hideForm, orchardid, formFilters }) {
	const [pageIndex, setPageIndex] = useState(0);
	//const numPages = 1;
	const farmsLookupState = utils.getLocalStorageItem("farmsLookup");
	const { addOrchard, getOrchard, editOrchard, getOrchardCIType, formErrors } = useContext(OrchardsContext);
	const { setOverlayState } = useContext(StateManagerContext);

	const FormTitle = {
		add: "Add Field",
		edit: "Edit Field"
	};
	const [formAction, setformAction] = useState("add");
	const [formData, setFormData] = useState({
		rid: "#13:0",
		citype: ["#12:163"],
		farm: "",
		name: "",
		boundary: [],
		outlinecol: "",
		fillcol: "",
		uuid: metaphone(getOrchardCIType()) + "-" + uuidv4()
	});

	const DisplayStep = () => {
		switch (pageIndex) {
			case 0:
				return <Step001 formData={formData} setFormData={setFormData} formErrors={formErrors} farms={farmsLookupState}/>;
			default:
				return false;
		}
	};

	useEffect(() => {
		if (utils.isempty(orchardid)) {
			let tuuid = metaphone(getOrchardCIType()) + "-" + uuidv4();
			setformAction("add");
			setFormData({
				rid: "#13:0",
				citype: ["#12:163"],
				farm: "",
				name: "",
				boundary: [],
				outlinecol: "",
				fillcol: "",
				status: "active",
				uuid: tuuid
			});
		} else {
			setformAction("edit");
			setFormData(getOrchard(orchardid));
		}
	}, [orchardid, getOrchard, getOrchardCIType])
	return (
		<div className="form form-bg">
			<div className="progressbar">
				<div
					style={{ width: pageIndex === 0 ? "100%" : "100%" }}
				></div>
			</div>
			<div className="form-container">
				<div className="header">
					<h1 style={{ color: "#7A9A01" }}>{FormTitle[formAction]}</h1>
				</div>
				<div className="body">{DisplayStep()}</div>
				<div className="footer">
					<button
						onClick={(e) => {
							setOverlayState("overlay_visible");
							switch (e.target.innerText) {
								case 'Submit':
									addOrchard({
										rid: formData.rid,
										citype: ["#12:163"],
										farm: formData.farm,
										name: formData.name,
										boundary: formData.boundary,
										outlinecol: formData.outlinecol,
										fillcol: formData.fillcol,
										status: formData.status,
										uuid: formData.uuid
									}, hideForm,setOverlayState);
									break;
								case 'Save':
									editOrchard({
										rid: formData.rid,
										citype: ["#12:163"],
										farm: formData.farm,
										name: formData.name,
										boundary: formData.boundary,
										outlinecol: formData.outlinecol,
										fillcol: formData.fillcol,
										status: formData.status,
										uuid: formData.uuid
									}, hideForm, formFilters, setOverlayState);
									break;
								default:
									hideForm();
									break;
							}
						}
						}
					>
						{formAction === "add" ? "Submit" : "Save"}
					</button>
					<button
						onClick={(e) => {
							hideForm();
						}
						}
					>
						Cancel
					</button>
					{
						formAction === "add" ?
							null :
							<button
								onClick={(e) => {
									setOverlayState("overlay_visible");
									switch (e.target.innerText) {
										case 'Delete':
											editOrchard({
												rid: formData.rid,
												citype: ["#12:163"],
												farm: formData.farm,
												name: formData.name,
												boundary: formData.boundary,
												outlinecol: formData.outlinecol,
												fillcol: formData.fillcol,
												status: "deleted",
												uuid: formData.uuid
											}, hideForm, formFilters, setOverlayState);
											break;
										case 'Reinstate':
											editOrchard({
												rid: formData.rid,
												citype: ["#12:163"],
												farm: formData.farm,
												name: formData.name,
												boundary: formData.boundary,
												outlinecol: formData.outlinecol,
												fillcol: formData.fillcol,
												status: "active",
												uuid: formData.uuid
											}, hideForm, formFilters, setOverlayState);
											break;
										default:
											hideForm();
											break;
									}
								}}
							>
								{formData.status === "active" ? "Delete" : "Reinstate"}
							</button>
					}
				</div>
			</div>
		</div>
	);

}
