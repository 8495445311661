import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { UpdateCIStatusStateContext } from '../contexts/updatecistatusstate';
//This to become serverside lookup
var ajaxstack = false;
var def_value = [];

const PestTrapInspectionsReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_INSPECTIONS":
			return {
				pesttrapinspections: action.pesttrapinspections
			};
		case "REFRESH_REPORT_INSPECTIONS":
			return {
				ptiReportData: action.ptiReportData
			};
		case "REFRESH_REPORT_DATA":
			return {
				ptiReportData: action.ptiReportData
			};
		case "ADD_INSPECTION":
			return {
				pesttrapinspections: [...state.pesttrapinspections, action.pesttrapinspection]
			};
		case "EDIT_INSPECTION":
			let tinspections = [];
			let tptiReportData = state.ptiReportData;
			utils.each(tptiReportData.tdata, function (pesttrapinspection, trapind) {
				if (pesttrapinspection.rid !== action.pesttrapinspection.rid) {
					tinspections.push(pesttrapinspection);
				} else {
					tinspections.push(action.pesttrapinspection);
				}
			});
			tptiReportData.tdata = tinspections;
			return {
				ptiListData: tptiReportData
			};
		default:
			return state;
	}
};
const ListPestTrapInspectionsReducer = (state, action) => {
	let tinspections = [];
	let tptiListData = state.ptiListData;
	switch (action.type) {
		case "REFRESH_LIST_DATA":
			return {
				ptiListData: action.ptiListData
			};
		case "EDIT_INSPECTION":
			utils.each(tptiListData.tdata, function (pesttrapinspection, trapind) {
				if (pesttrapinspection.rid !== action.pesttrapinspection.rid) {
					tinspections.push(pesttrapinspection);
				} else {
					tinspections.push(action.pesttrapinspection);
				}
			});
			tptiListData.tdata = tinspections;
			return {
				ptiListData: tptiListData
			};
		case "ADD_INSPECTION":
			tptiListData.tdata.push(action.pesttrapinspection);
			return {
				ptiListData: tptiListData
			};
			// return {
			// 	ptiListData: [...state.ptiListData.tdata, action.pesttrapinspection]
			// };
		default:
			return state;
	}
};

export const PestTrapInspectionsContext = createContext();

const PestTrapInspectionsProvider = ({ children }) => {
	//const pesttrapinspections = def_value;
	const reportpesttrapinspections = [];

	const filerStartDate = moment();
	const month = moment().month();
	if(month < moment().month(8)){
		filerStartDate.add(-1, "year").month(7).startOf("month");
	}

	const [state, dispatch] = useReducer(PestTrapInspectionsReducer, {
		//pesttrapinspections: pesttrapinspections,
		reportpesttrapinspections: reportpesttrapinspections,
		ptiReportData: {
			datafarmzones: [],
			dataweeks: [],
			datapests: [],
			tdata: {},
			chartdata: {},
			mapdata: {},
			formFilters: { status: "closed", startDate: filerStartDate.format("YYYY-MM-DD HH:mm:ss"), endDate: moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"), pests: [] },
			maprefresh:""
		},
		filters: {}
	});
	const [state2, dispatch2] = useReducer(ListPestTrapInspectionsReducer, {
		ptiListData: {
			datafarmzones: [],
			dataweeks: [],
			datapests: [],
			tdata: [],
			chartdata: {},
			mapdata: {},
			formFilters: { status: "in_review", startDate: filerStartDate.format("YYYY-MM-DD HH:mm:ss"), endDate: moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"), pests: [] },
		},
	});
	//const [reportPestTrapInspectionsState, setReportPestTrapInspections] = useState([]);
	//const [pestTrapInspectionsLookupState, setPestTrapInspectionsLookup] = useState([]);
	//const [pestTrapInspectionsMapState, setPestTrapInspectionsMap] = useState([]);
	const { setUpdateCIState } = useContext(UpdateCIStatusStateContext);
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const [formErrors, setFormErrors] = useState({ farm:'', blockrid:'', zone:'', pest:'', pestcount:'', inspected_by:'' })
	const value = {
		//pesttrapinspections: state.pesttrapinspections,
		reportpesttrapinspections: state.reportpesttrapinspections,
		ptiReportData: state.ptiReportData,
		ptiListData: state2.ptiListData,
		setCloseInspectionSettings: (settings) => {
			let closeInspectionSettings = {
				message:"",
				data:{},
				visibility:"updateci_hidden",
			};
			closeInspectionSettings = settings;
			if(utils.isset(settings.data.rids) && settings.data.rids===false) {
				if(!utils.isset(settings.data.error)||utils.isempty(settings.data.error)) { closeInspectionSettings.visibility = "updateci_hidden"; }
				console.warn("rids is false closeInspectionSettings",closeInspectionSettings);
				setUpdateCIState(closeInspectionSettings);
			} else {
				closeInspectionSettings.visibility = "updateci_visible";
				closeInspectionSettings.data.message = "Close selected inspection?";
				//closeInspectionSettings.data.rids = closeInspectionSettings.data.rids;
				closeInspectionSettings.data.callBack = value.closeInspections;
				closeInspectionSettings.data.error = "";
				console.warn("closeInspectionSettings",closeInspectionSettings);
				setUpdateCIState(closeInspectionSettings);
			}
		},
		addPestTrapInspection: (pesttrapinspection, hideform, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pesttrapinspection
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/pesttrapinspection", params, headers).then(
				function (response) {
					if (response.data.success) {
						let pesttrapinspection = response.data.data;
						dispatch2({ type: "ADD_INSPECTION", pesttrapinspection });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				}
			);
			//dispatch({ type: "ADD_INSPECTION", pesttrapinspection });
		},
		getPestTrapInspection: (id) => {
			let tpesttrapinspection = {};
			utils.each(state2.ptiListData.tdata, function (pesttrapinspection, pesttrapinspectionind) {
				if (pesttrapinspection.rid === id) {
					tpesttrapinspection = pesttrapinspection;
					return false;
				}
			});
			return tpesttrapinspection;
		},
		getPestTrapInspections: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			let tfilters = utils.extend({},filters);
			tfilters = utils.toArrayRID(tfilters,'pest');
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: tfilters }
			};
			utils.incAjaxStack();
			a.get("/pesttrapinspections", params).then(
				function (response) {
					if (response.data.success) {
						var reportpesttrapinspections = [];
						utils.each(response.data.data, function (pesttrapinspection, pesttrapinspectionid) {
							reportpesttrapinspections.push(pesttrapinspection);
						});
						if (lookup !== false) {
						} else {
							var ptiListData = {
								datafarmzones: [],
								dataweeks: [],
								datapests: [],
								tdata: [],
								chartdata: {},
								mapdata: {},
								formFilters: filters,
							};
							if (!utils.isempty(reportpesttrapinspections)) {
								utils.each(reportpesttrapinspections, function (inspection, inspectionid) {
									inspection.pestcount = parseFloat(inspection.pestcount);
									inspection.pestcount = isNaN(inspection.pestcount)?0:inspection.pestcount;
									
									var tdate = (moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW') == '01') ? moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).endOf('isoWeek').format('YYYY') + ' ' + moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW') : moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).startOf('isoWeek').format('YYYY') + ' ' + moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW');
									if (ptiListData.dataweeks.findIndex(week => week === tdate) === -1) {
										ptiListData.dataweeks.push(tdate);
									}
									if (ptiListData.datapests.findIndex(pest => pest.uuid === inspection.pest) === -1) {
										ptiListData.datapests.push({ uuid: inspection.pest, name: inspection.pestname });
									}

									if (ptiListData.datafarmzones.findIndex(farmzone => farmzone.farm === inspection.farm && farmzone.zone === inspection.zone) === -1) {
										ptiListData.datafarmzones.push({ farm: inspection.farm, farmname: inspection.farmname, zone: inspection.zone, zonename: inspection.zonename });
									}
									ptiListData.tdata.push(inspection);

									if (!utils.isset(ptiListData.chartdata[tdate])) { ptiListData.chartdata[tdate] = {}; }
									if (!utils.isset(ptiListData.chartdata[tdate][inspection.pest])) { ptiListData.chartdata[tdate][inspection.pest] = { pestname: inspection.pestname, pest: inspectionid, latitude: inspection.latitude, longitude: inspection.longitude, pestcount: 0, inspcount: 0 }; }
									//console.warn("inspection.pestcount",inspection.pestcount);
									ptiListData.chartdata[tdate][inspection.pest].pestcount += inspection.pestcount;
									ptiListData.chartdata[tdate][inspection.pest].inspcount++;
									//console.warn("ptiListData.chartdata[tdate][inspection.pest].inspcount",ptiListData.chartdata[tdate][inspection.pest].inspcount);

									if (!utils.isset(ptiListData.mapdata[tdate])) { ptiListData.mapdata[tdate] = {}; }
									if (!utils.isset(ptiListData.mapdata[tdate][inspection.pest])) { ptiListData.mapdata[tdate][inspection.pest] = []; }
									ptiListData.mapdata[tdate][inspection.pest].push({ pestname: inspection.pestname, pest: inspectionid, latitude: inspection.latitude, longitude: inspection.longitude, pestcount: inspection.pestcount });
								});
								//setReportPestTrapInspections(tdata);
								ptiListData.dataweeks.sort();
								ptiListData.datapests.sort((a, b) => {
									return a.name.localeCompare(b.name);
								});
							}
							dispatch2({ type: "REFRESH_LIST_DATA", ptiListData });
							console.warn("state2",state2);
						}
						//responsereceived= true;
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setPestTrapInspectionsLookup([]);
						}
					}
					ajaxstack = false;
				});
		},
		getReportPestTrapInspections: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			a.get("/reports/pesttrapinspections", params).then(
				function (response) {
					if (response.data.success) {
						var reportpesttrapinspections = [];
						utils.each(response.data.data, function (pesttrapinspection, pesttrapinspectionid) {
							reportpesttrapinspections.push(pesttrapinspection);
						});
						if (lookup !== false) {
						} else {
							//dispatch({ type: "REFRESH_REPORT_INSPECTIONS", reportpesttrapinspections });
							var ptiReportData = {
								datafarmzones: [],
								dataweeks: [],
								datapests: [],
								tdata: {},
								chartdata: {},
								mapdata: {},
								formFilters: filters,
								mapRefresh: ""
							};
							if (!utils.isempty(reportpesttrapinspections)) {
								utils.each(reportpesttrapinspections, function (inspection, inspectionid) {
									inspection.pestcount = parseFloat(inspection.pestcount);
									inspection.pestcount = isNaN(inspection.pestcount)?0:inspection.pestcount;
									var tdate = (moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW') == '01') ? moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).endOf('isoWeek').format('YYYY') + ' ' + moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW') : moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).startOf('isoWeek').format('YYYY') + ' ' + moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW');
									//console.warn("inspection.date_performed",inspection.date_performed,"tdate",tdate);
									if (ptiReportData.dataweeks.findIndex(week => week === tdate) === -1) {
										ptiReportData.dataweeks.push(tdate);
									}
									if (ptiReportData.datapests.findIndex(pest => pest.uuid === inspection.pest) === -1) {
										ptiReportData.datapests.push({ uuid: inspection.pest, name: inspection.pestname });
									}

									if (ptiReportData.datafarmzones.findIndex(farmzone => farmzone.farm === inspection.farm && farmzone.zone === inspection.zone) === -1) {
										ptiReportData.datafarmzones.push({ farm: inspection.farm, farmname: inspection.farmname, zone: inspection.zone, zonename: inspection.zonename });
									}
									let key = inspection.farm + " " + inspection.zone;
									if (!utils.isset(ptiReportData.tdata[key])) { ptiReportData.tdata[key] = {}; }
									if (!utils.isset(ptiReportData.tdata[key][tdate])) { ptiReportData.tdata[key][tdate] = {}; }
									if (!utils.isset(ptiReportData.tdata[key][tdate][inspection.pest])) { ptiReportData.tdata[key][tdate][inspection.pest] = 0; }
									ptiReportData.tdata[key][tdate][inspection.pest] += parseInt(inspection.pestcount);

									if (!utils.isset(ptiReportData.chartdata[tdate])) { ptiReportData.chartdata[tdate] = {}; }
									if (!utils.isset(ptiReportData.chartdata[tdate][inspection.pest])) { ptiReportData.chartdata[tdate][inspection.pest] = { pestname: inspection.pestname, pest: inspectionid, latitude: inspection.latitude, longitude: inspection.longitude, pestcount: 0, inspcount: 0 }; }
									ptiReportData.chartdata[tdate][inspection.pest].pestcount += inspection.pestcount;
									ptiReportData.chartdata[tdate][inspection.pest].inspcount++;

									if (!utils.isset(ptiReportData.mapdata[tdate])) { ptiReportData.mapdata[tdate] = {}; }
									if (!utils.isset(ptiReportData.mapdata[tdate][inspection.pest])) { ptiReportData.mapdata[tdate][inspection.pest] = []; }
									ptiReportData.mapdata[tdate][inspection.pest].push({ pestname: inspection.pestname, pest: inspectionid, latitude: inspection.latitude, longitude: inspection.longitude, pestcount: inspection.pestcount });
								});
								//setReportPestTrapInspections(tdata);
								ptiReportData.dataweeks.sort();
								ptiReportData.datapests.sort((a, b) => {
									return a.name.localeCompare(b.name);
								});
							}
							dispatch({ type: "REFRESH_REPORT_DATA", ptiReportData });
						}
						//responsereceived= true;
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setPestTrapInspectionsLookup([]);
						}
					}
					ajaxstack = false;
				});
		},
		editPestTrapInspection: (pesttrapinspection, hideform, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pesttrapinspection
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/pesttrapinspection", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch2({ type: "EDIT_INSPECTION", pesttrapinspection });
						setFormErrors({ farm:'', blockrid:'', zone:'', pest:'', pestcount:'', inspected_by:'' });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		closeInspections: (rids) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: rids
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/closepesttrapinspection", params, headers).then(
				function (response) {
					if (response.data.success) {
						value.getPestTrapInspections(state2.ptiListData.formFilters);
						console.warn("Calling setCloseInspectionSettings");
						value.setCloseInspectionSettings({visibility:"updateci_hidden",data:{rids:false}});
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							value.setCloseInspectionSettings({data:{rids:response.data.data}});
						}
					}
				});
		},
		//reportPestTrapInspectionsState: reportPestTrapInspectionsState,
		// setReportPestTrapInspections: setReportPestTrapInspections,
		// pestTrapInspectionsLookupState: pestTrapInspectionsLookupState,
		// setPestTrapInspectionsLookup: setPestTrapInspectionsLookup,
		// pestTrapInspectionsMapState: pestTrapInspectionsMapState,
		// setPestTrapInspectionsMap: setPestTrapInspectionsMap,
		formErrors: formErrors,
		setFormErrors: setFormErrors,

		getPestTrapInspectionCIType: () => {
			return "ci_trap_inspection";
		},
		clearPestTrapInspections: () => {
			// setPestTrapInspectionsLookup([]);
			let empty = [];
			let ptiReportData= {
				datafarmzones: [],
				dataweeks: [],
				datapests: [],
				tdata: {},
				chartdata: {},
				mapdata: {},
				formFilters: { status: "closed", startDate: filerStartDate, endDate: moment(), pests: [] },
			};
			let ptiListData= {
				datafarmzones: [],
				dataweeks: [],
				datapests: [],
				tdata: {},
				chartdata: {},
				mapdata: {},
				formFilters: { status: "in_review", startDate: filerStartDate, endDate: moment(), pests: [] },
			};
			//dispatch({ type: "REFRESH_INSPECTIONS", pesttrapinspections:empty });
			dispatch({ type: "REFRESH_REPORT_INSPECTIONS", ptiReportData });
			dispatch2({ type: "REFRESH_LIST_DATA", ptiListData });
			
			//dispatch({ type: "REFRESH_LIST_INSPECTIONS", empty });
			// localStorage.setItem("pestTrapInspectionsLookup", JSON.stringify(empty));
			// localStorage.setItem("pesttrapinspections", JSON.stringify(empty));
			// localStorage.setItem("reportPestTrapInspectionsLookup", JSON.stringify(empty));
		},
		resetFormErrors: () => {
			setFormErrors({ farm:'', blockrid:'', zone:'', pest:'', pestcount:'', inspected_by:'' });
		}
	}
	return (
		<PestTrapInspectionsContext.Provider value={value}>
			{children}
		</PestTrapInspectionsContext.Provider>
	);
};
export default PestTrapInspectionsProvider;

