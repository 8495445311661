import React, { useState, useContext } from 'react'
import utils from '../../utils/utils';
// import PestInspectionForm from './PestInspectionForm';
import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import { PestInspectionsContext } from '../../contexts/pestinspections';
import parse from 'html-react-parser';
import styled from "@emotion/styled";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormHelperText from '@mui/material/FormHelperText';

//Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

// Theme
const ThemeTextField = styled(TextField)(utils.theme.TextField);
const ThemeSelect = styled(Select)(utils.theme.Select);
const color = "#7A9A01";
const theme = createTheme({
	components: {
		MuiIconButton: {
			styleOverrides: {
				sizeMedium: {
					color
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color
				}
			}
		}
	}
});

export function PestInspectionReportList(props) {


	const { piReportData, getReportPestInspections } = useContext(PestInspectionsContext);
	var farmsLookupState = utils.getLocalStorageItem("farmsLookup");
	var personsLookupState = utils.getLocalStorageItem("personsLookup");
	var pmZonesLookupState = utils.getLocalStorageItem("pmZonesLookup");
	var inspectionTemplatesLookupState = utils.getLocalStorageItem("inspectionTemplatesLookup");
	var pestInspectionReportRefresh = utils.getLocalStorageItem("pestInspectionReportRefresh");
	if (pestInspectionReportRefresh === null) { console.warn("setting pestInspectionReportRefresh to false..."); utils.setLocalStorageItem("pestInspectionReportRefresh", "false"); }
	// const [formContainerVis, setformContainerVis] = useState("form-container dialog-loader-2 dialog-loader-2-hidden");
	// const [pestinspectionid, setpestinspectionid] = useState("");
	// const [formAction, setFormAction] = useState("");

	// const showForm = () => {
	// 	setformContainerVis("form-container dialog-loader-2 dialog-loader-2-show");
	// }
	// const hideForm = () => {
	// 	setformContainerVis("form-container dialog-loader-2 dialog-loader-2-hidden");
	// }
	// const prePestInspectionID = (id) => {
	// 	if (id !== "") {
	// 		setpestinspectionid(id);
	// 		setFormAction("edit");
	// 		showForm();
	// 	} else {
	// 		setpestinspectionid(id);
	// 		setFormAction("add");
	// 		showForm();
	// 	}
	// }
	if (utils.isempty(piReportData.formFilters.template)) {
		piReportData.formFilters.template = !utils.isempty(inspectionTemplatesLookupState) ? inspectionTemplatesLookupState[0].ref : "";
		piReportData.formFilters.templatedisplayname = !utils.isempty(inspectionTemplatesLookupState) ? inspectionTemplatesLookupState[0].name : "";
	}

	const filerStartDate = moment();
	const month = moment().month();
	if (month < moment().month(8)) {
		filerStartDate.add(-1, "year").month(7).startOf("month");
	}

	const [formFilters, setFormFilters] = useState(utils.isset(piReportData) ? piReportData.formFilters : { status: "closed", startDate: filerStartDate.format("YYYY-MM-DD HH:mm:ss"), endDate: moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"), template: "" });

	console.warn("rendering report....", 'piReportData', piReportData);
	var htr1 = "<tr key={'" + utils.uuid() + "'}><th className='sticky-table-header-col' colspan='3'></th>" + (!utils.isempty(piReportData.tdata) ? "<th style='text-align: center; vertical-align: middle;' colspan='" + (piReportData.dataweeks.length * piReportData.datapests.length) + "'>Weeks</th>" : "") + "</tr>";
	var htr2 = "<tr key={'" + utils.uuid() + "'}><th className='sticky-table-header-col' colspan='3'></th>";
	var htr3 = "<tr key={'" + utils.uuid() + "'}><th className='sticky-table-header-col' colspan='3'></th>";
	var htr4 = "<tr key={'" + utils.uuid() + "'}><th className='sticky-col'>Farm</th><th className='sticky-col'>Orchard</th><th className='sticky-col'>Cultivar</th>";

	utils.each(piReportData.dataweeks, function (week, weekind) {
		htr2 += "<th style='text-align: center; vertical-align: middle;' colspan='" + piReportData.datapests.length + "'>" + (week.replace("_", " ")) + "</th>";
		utils.each(piReportData.template.data.groups,function(group,groupind) {
			let groupcolspan = 0;
			utils.each(piReportData.template.data.columns, function (col, colind) {

				if(col.group==groupind) {
					htr4 += "<th style='text-align: center; vertical-align: middle;'>" + (col.label) + "</th>";
					groupcolspan++;
				}
			});
			htr3 += "<th style='text-align: center; vertical-align: middle;' colspan='"+groupcolspan+"'>" + (group.label) + "</th>";
		});
	});

	htr2 += "</tr>";
	htr3 += "</tr>";
	htr4 += "</tr>";
	let tbody = "";
	console.warn("tdata???",piReportData.tdata);
	utils.each(piReportData.tdataindex,function(key,keyind){
		var inspection = piReportData.tdata[key];
		var inspectionind = key;
		//console.warn("inspection-----------------",inspection);
		tbody += "<tr>";
		tbody += "<td className='sticky-col'>"+inspection.farmname+"</td>";
		tbody += "<td className='sticky-col'>"+inspection.zonename+"</td>";
		tbody += "<td className='sticky-col'>"+inspection.cultivarname+"</td>";
		utils.each(piReportData.dataweeks,function(week,weekind){
			if(utils.isset(inspection.data[week])) {
				utils.each(inspection.data[week],function(pestinspection,pestinspectionind){
					let val = 0;
					let color = "";
					switch(pestinspection.type) {
						case "avg2":
							val = pestinspection.total/((pestinspection.count==0?1:pestinspection.count)*pestinspection.samplesize);
							val = val.toFixed(1);
							if(val==0) { color = "color:#CCC;"; }
							break;
						case "sum":
							if(pestinspection.total==0) { color = "color:#CCC;"; }
							val = pestinspection.total.toFixed(1);
							break;
						case "min":
							val = pestinspection.min;
							if(val==0) { color = "color:#CCC;"; }
							break;
						case "max":
							val = pestinspection.max;
							if(val==0) { color = "color:#CCC;"; }
							break;
						default:
							//avg
							val = pestinspection.total/((pestinspection.count==0?1:pestinspection.count)*pestinspection.samplesize)*100;
							val = val.toFixed(1);
							if(val==0) { color = "color:#CCC;"; }
							val = val + "%";
							break;
					}
					tbody += "<td className='week-readings-col' style='"+color+"'>"+val+"</td>";
				});
			} else {
				utils.each(piReportData.datapests,function(p,pi){
					tbody += "<td className='week-readings-col' style='color:#CCC;'>-</td>";
				});
			}
		});
		tbody += "</tr>";
	});
//console.warn("tbody???",tbody);
	//Dialog
	const [openFilterDialog, setOpenFilterDialog] = useState(false);
	const handleFilterDialogClickOpen = () => {
		setOpenFilterDialog(true);
	};
	const handleFilterDialogClose = () => {
		setOpenFilterDialog(false);
	};

	return (
		<>
			<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Button
						onClick={handleFilterDialogClickOpen}
						variant="outlined"
						startIcon={<FilterListIcon />}
						style={utils.theme.Button}
					>
						Filter
					</Button>
				</Box>

				{
					!utils.isempty(piReportData.tdata) ?
						<Box sx={{ alignSelf: 'center', display: 'flex' }}>
							<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
								<span style={{ color: '#7a9a01', marginRight: '5px' }}>Start Date:</span>
								<span style={{ color: 'GrayText' }}>{typeof formFilters.startDate == 'object' || typeof formFilters.startDate == 'string' ? moment(formFilters.startDate).format("DD-MM-YYYY") : formFilters.startDate}</span>
							</div>
							<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
								<span style={{ color: '#7a9a01', marginRight: '5px' }}>End Date:</span>
								<span style={{ color: 'GrayText' }}>{typeof formFilters.endDate == 'object' || typeof formFilters.endDate == 'string' ? moment(formFilters.endDate).format("DD-MM-YYYY") : formFilters.endDate}</span>
							</div>
							<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
								<span style={{ color: '#7a9a01', marginRight: '5px' }}>Status:</span>
								<span style={{ color: 'GrayText' }}>{formFilters.status === "in_review" ? "In Review" : formFilters.status.charAt(0).toUpperCase() + formFilters.status.slice(1)}</span>
							</div>
							<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
								<span style={{ color: '#7a9a01', marginRight: '5px' }}>Template:</span>
								<span style={{ color: 'GrayText' }}>{formFilters.templatedisplayname}</span>
							</div>
						</Box>
						:
						null
				}

				<Dialog
					open={openFilterDialog}
					onClose={handleFilterDialogClose}
					fullWidth={true}
					maxWidth={'sm'}
				>
					<DialogTitle
						id="alert-dialog-title"
						sx={{ m: 0, p: 2, color: '#7A9A01' }}
					>
						List Filters
						<IconButton
							aria-label="close"
							onClick={handleFilterDialogClose}
							sx={{ position: 'absolute', right: 8, top: 8, color: '#7A9A01' }}
						>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent dividers >
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<Box sx={{ margin: '5px' }}>
								<ThemeProvider theme={theme}>
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											label="Start Date"
											value={formFilters.startDate}
											inputFormat="DD/MM/yyyy"
											renderInput={(params) => <ThemeTextField {...params} sx={{ width: '400px' }} />}
											onChange={(tdate) => {
												setFormFilters({ ...formFilters, startDate: tdate.format("YYYY-MM-DD HH:mm:ss") });
												utils.setLocalStorageItem("pestInspectionReportRefresh", "false");
											}}
										/>
										<FormHelperText sx={{ paddingLeft: '14px' }}>Filter by start date</FormHelperText>
									</LocalizationProvider>
								</ThemeProvider>
							</Box>
							<Box sx={{ margin: '5px' }}>
								<ThemeProvider theme={theme}>
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											label="End Date"
											value={formFilters.endDate}
											inputFormat="DD/MM/yyyy"
											renderInput={(params) => <ThemeTextField {...params} sx={{ width: '400px' }} />}
											onChange={(tdate) => {
												setFormFilters({ ...formFilters, endDate: tdate.format("YYYY-MM-DD HH:mm:ss") });
												utils.setLocalStorageItem("pestInspectionReportRefresh", "false");
											}}
										/>
										<FormHelperText sx={{ paddingLeft: '14px' }}>Filter by end date</FormHelperText>
									</LocalizationProvider>
								</ThemeProvider>
							</Box>
							<Box sx={{ margin: '5px' }}>
								<FormControl sx={{ m: 1, minWidth: 120 }} >
									<InputLabel sx={{ color: "#7A9A01", '&.Mui-focused': { color: '#7A9A01' } }} id="pest-inspection-status">Status</InputLabel>
									<ThemeSelect
										labelId="pest-inspection-status-label"
										id="pest-inspection-simple-select-disabled"
										value={formFilters.status}
										label="Status"
										sx={{ width: '400px' }}
										onChange={(event) => {
											setFormFilters({ ...formFilters, status: event.target.value })
											utils.setLocalStorageItem("pestInspectionReportRefresh", "false");
										}
										}
										MenuProps={{
											sx: {
												"&& .Mui-selected": {
													backgroundColor: "rgb(199, 212, 148)",
													color: "rgb(122, 154, 1)"
												},
											}
										}}
									>
										<MenuItem value={"closed"}>Closed</MenuItem>
										<MenuItem value={"in_review"}>In Review</MenuItem>
									</ThemeSelect>
									<FormHelperText>Filter list by status</FormHelperText>
								</FormControl>
							</Box>
							<Box sx={{ margin: '5px' }}>
								<FormControl sx={{ m: 1, minWidth: 120 }} >
									<InputLabel sx={{ color: "#7A9A01", '&.Mui-focused': { color: '#7A9A01' } }} id="pest-inspection-template">Template</InputLabel>
									<ThemeSelect
										labelId="pest-inspection-template-label"
										id="pest-inspection-template-simple-select-disabled"
										value={formFilters.template}
										label="Template"
										sx={{ width: '400px' }}
										onChange={(event) => {
											let templatedisplayname = "";
											utils.each(inspectionTemplatesLookupState,function(it,itind){
												if(event.target.value===it.ref) {
													templatedisplayname = it.name;
												}
											});

											setFormFilters({ ...formFilters, template: event.target.value, templatedisplayname:templatedisplayname });
											utils.setLocalStorageItem("pestInspectionReportRefresh", "false");
										}
										}
										MenuProps={{
											sx: {
												"&& .Mui-selected": {
													backgroundColor: "rgb(199, 212, 148)",
													color: "rgb(122, 154, 1)"
												},
											}
										}}
									>

										{
											inspectionTemplatesLookupState.map((template) => {
												return (<MenuItem key={template.uuid} value={template.ref}>{template.name}</MenuItem>)
											})

										}
									</ThemeSelect>
									<FormHelperText>Filter list by template</FormHelperText>
								</FormControl>
							</Box>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => { getReportPestInspections(formFilters); setOpenFilterDialog(false) }}
							autoFocus
							variant="outlined"
							style={utils.theme.Button}
						>
							Apply Filters
						</Button>
					</DialogActions>
				</Dialog>
			</Box>

			<Box sx={{ width: '100%', marginTop: '5px' }}>
				<div className="scrollable-trap-readings-table-container">
					<table className='trap-inspection-list-table' role='table'>
						<thead className='sticky-table-header'>
						{parse(htr1)}
						{parse(htr2)}
						{parse(htr3)}
						{parse(htr4)}
						</thead>
						<tbody>
							{parse(tbody)}
						</tbody>
					</table>
				</div>
			</Box>
		</>
	)
}

export default PestInspectionReportList;