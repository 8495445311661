import React, { useState, useContext, useEffect } from 'react'
import utils from "../../utils/utils";
import { v4 as uuidv4 } from 'uuid';
import { metaphone } from 'metaphone';
import Box from '@mui/material/Box';
import { Container } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { FormHelperText } from '@mui/material';
import { HarvestZonesContext } from '../../contexts/harvestzones';
import { StateManagerContext } from '../../contexts/statemanager';
import '../../index.css';
import styled from "@emotion/styled";

// Theme
const ThemeFormTextField = styled(TextField)(utils.theme.FormTextField);
const ThemeSelect = styled(Select)(utils.theme.Select);

export function Step001({ formData, setFormData, formErrors, farms, orchards, farmcommodities, cultivars, types }) {
	var isYearCrop = false;
	
	if(!utils.isempty(formData.commodity)) {
		let tcommodity = utils.getRecord(farmcommodities,formData.commodity);
		if(utils.isset(tcommodity.annual_crop)&&tcommodity.annual_crop==="1") { isYearCrop = true; }
	}
	return (
		<div className="step001-container">
			<ThemeFormTextField
				type="text"
				id="name"
				label="Name"
				placeholder="Name..."
				value={formData.name}
				onChange={(event) =>
					setFormData({ ...formData, name: event.target.value })
				}
				error={!utils.isempty(formErrors.name) ? true : false}
				helperText={formErrors.name}
			/>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.farm) ? true : false}>
					<InputLabel id="farm" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Farm</InputLabel>
					<ThemeSelect
						label="Farm"
						value={formData.farm}
						onChange={(event) => {
							utils.each(farms, function (farm, farmid) {
								if (farm.uuid === event.target.value) {
									setFormData({ ...formData, farm: event.target.value, farmname: farm.name });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							farms.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.farm}</FormHelperText>
				</FormControl>
			</Box>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.block) ? true : false}>
					<InputLabel id="orchard" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Field</InputLabel>
					<ThemeSelect
						label="Field"
						value={formData.block}
						onChange={(event) => {
							utils.each(orchards, function (orchard, orchardid) {
								if (orchard.uuid === event.target.value) {
									setFormData({ ...formData, block: event.target.value, blockname: orchard.name });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							orchards.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.block}</FormHelperText>
				</FormControl>
			</Box>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.commodity) ? true : false}>
					<InputLabel id="commodity" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Commodity</InputLabel>
					<ThemeSelect
						label="Commodity"
						value={formData.commodity}
						onChange={(event) => {
							utils.each(farmcommodities, function (commodity, commodityid) {
								if (commodity.uuid === event.target.value) {
									setFormData({ ...formData, commodity: event.target.value, commodityname: commodity.name });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							farmcommodities.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.commodity}</FormHelperText>
				</FormControl>
			</Box>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.cultivar_name) ? true : false}>
					<InputLabel id="cultivar" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Cultivar</InputLabel>
					<ThemeSelect
						label="Cultivar"
						value={formData.cultivar_name}
						onChange={(event) => {
							utils.each(cultivars, function (cultivar, cultivarid) {
								if (cultivar.uuid === event.target.value) {
									setFormData({ ...formData, cultivar_name: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							cultivars.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.cultivar_name}</FormHelperText>
				</FormControl>
			</Box>

			<ThemeFormTextField
				type="number"
				id="harzonesize"
				label="Size"
				placeholder="Size..."
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
				value={formData.harzonesize}
				onChange={(event) =>
					setFormData({ ...formData, harzonesize: event.target.value })
				}
			/>
			{(!isYearCrop) ?
			<ThemeFormTextField
				type="number"
				id="zone_tree_count"
				label="Tree Count"
				placeholder="Tree Count..."
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
				value={formData.zone_tree_count}
				onChange={(event) =>
					setFormData({ ...formData, zone_tree_count: event.target.value })
				}
			/>
			:
			null
			}
			{(!isYearCrop) ?
			<ThemeFormTextField
				type="number"
				id="rowwidth"
				label="Row Width"
				placeholder="Row Width..."
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
				value={formData.rowwidth}
				onChange={(event) =>
					setFormData({ ...formData, rowwidth: event.target.value })
				}
			/>
			:
			null
			}
			{(!isYearCrop) ?
			<ThemeFormTextField
				type="number"
				id="plantdistance"
				label="Planting Distance"
				placeholder="Planting Distance..."
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
				value={formData.plantdistance}
				onChange={(event) =>
					setFormData({ ...formData, plantdistance: event.target.value })
				}
			/>
			:
			null
			}
			{(!isYearCrop) ?
			<ThemeFormTextField
				type="number"
				id="zone_trees_per_ha"
				label="Trees / Ha"
				placeholder="Trees / Ha..."
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
				value={formData.zone_trees_per_ha}
				onChange={(event) =>
					setFormData({ ...formData, zone_trees_per_ha: event.target.value })
				}
			/>
			:
			null
			}
			{(!isYearCrop) ?
			<ThemeFormTextField
				type="number"
				id="trv"
				label="Tree Row Volume"
				placeholder="Tree Row Volume..."
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
				value={formData.trv}
				onChange={(event) =>
					setFormData({ ...formData, trv: event.target.value })
				}
			/>
			:
			null
			}
			{(isYearCrop) ?
			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.commodity_history_1) ? true : false}>
					<InputLabel id="commodity_y1" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Previous Commodity 1</InputLabel>
					<ThemeSelect
						label="Previous Commodity 1"
						value={formData.commodity_history_1}
						onChange={(event) => {
							utils.each(farmcommodities, function (commodity, commodityid) {
								if (commodity.uuid === event.target.value) {
									setFormData({ ...formData, commodity_history_1: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							farmcommodities.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.commodity_history_1}</FormHelperText>
				</FormControl>
			</Box>
			:
			null
			}
			{(isYearCrop) ?
			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.cultivar_history_1) ? true : false}>
					<InputLabel id="cultivar" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Previous Cultivar 1</InputLabel>
					<ThemeSelect
						label="Previous Cultivar 1"
						value={formData.cultivar_history_1}
						onChange={(event) => {
							utils.each(cultivars, function (cultivar, cultivarid) {
								if (cultivar.uuid === event.target.value) {
									setFormData({ ...formData, cultivar_history_1: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							cultivars.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.cultivar_history_1}</FormHelperText>
				</FormControl>
			</Box>
			:
			null
			}
			{(isYearCrop) ?
			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.commodity_history_2) ? true : false}>
					<InputLabel id="commodity_y2" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Previous Commodity 2</InputLabel>
					<ThemeSelect
						label="Previous Commodity 2"
						value={formData.commodity_history_2}
						onChange={(event) => {
							utils.each(farmcommodities, function (commodity, commodityid) {
								if (commodity.uuid === event.target.value) {
									setFormData({ ...formData, commodity_history_2: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							farmcommodities.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.commodity_history_2}</FormHelperText>
				</FormControl>
			</Box>
			:
			null
			}
			{(isYearCrop) ?
			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.cultivar_history_2) ? true : false}>
					<InputLabel id="cultivar_y2" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Previous Cultivar 2</InputLabel>
					<ThemeSelect
						label="Previous Cultivar 2"
						value={formData.cultivar_history_2}
						onChange={(event) => {
							utils.each(cultivars, function (cultivar, cultivarid) {
								if (cultivar.uuid === event.target.value) {
									setFormData({ ...formData, cultivar_history_2: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							cultivars.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.cultivar_history_2}</FormHelperText>
				</FormControl>
			</Box>
			:
			null
			}
		</div>
	);
}

export default function HarvestZoneForm({ hideForm, harvestzoneid, formFilters }) {
	const [pageIndex, setPageIndex] = useState(0);
	//const numPages = 1;

	const farmsLookupState = utils.getLocalStorageItem("farmsLookup");
	const orchardsLookupState = utils.getLocalStorageItem("orchardsLookup");
	const cultivarsLookupState = utils.getLocalStorageItem("cultivarsLookup");
	const farmCommoditiesLookupState = utils.getLocalStorageItem("farmCommoditiesLookup");

	const types = [
		{ type: "fruit", name: "Fruit" },
		{ type: "veg", name: "Vegetable" },
	];
	const { addHarvestZone, getHarvestZone, editHarvestZone, getHarvestZoneCIType, formErrors } = useContext(HarvestZonesContext);
	const { setOverlayState } = useContext(StateManagerContext);

	const FormTitle = {
		add: "Add Zone",
		edit: "Edit Zone"
	};
	const [formAction, setformAction] = useState("add");
	const [formData, setFormData] = useState({
		rid: "#13:0",
		citype: "#12:165",
		farm: "",
		farmname: "",
		block: "",
		blockname: "",
		commodity: "",
		commodityname: "",
		//cultivar: "",
		cultivar_name: "",
		zone_year_established: "1000-01-01 00:00:00",
		zone_tree_count: 0,
		rowwidth: 0,
		plantdistance: 0,
		zone_trees_per_ha: 0,
		harzonesize: 0,
		commodity_history_1:"",
		cultivar_history_1:"",
		commodity_history_2:"",
		cultivar_history_2:"",
		trv: 0,
		name: "",
		type: "",
		uuid: metaphone(getHarvestZoneCIType()) + "-" + uuidv4()
	});
	//setFormAction(harvestzoneid===""?"add":"edit");
	const DisplayStep = () => {
		switch (pageIndex) {
			case 0:
				return <Step001 formData={formData} setFormData={setFormData} formErrors={formErrors} farms={farmsLookupState} orchards={orchardsLookupState} farmcommodities={farmCommoditiesLookupState} cultivars={cultivarsLookupState} types={types} />;
			default:
				return false;
		}
	};
	// const validateForm = () => {
	// 	let message = "";
	// 	if (utils.isempty(formData.name)) { message += (utils.isempty(message) ? "" : "\n") + "Name cannot be empty."; }
	// 	if (!utils.isempty(message)) { alert(message); return false; }
	// 	return true;
	// };
	// if(utils.isset(props.action)) {
	// 	setFormAction(props.action);
	// }
	useEffect(() => {
		if (utils.isempty(harvestzoneid)) {
			let tuuid = metaphone(getHarvestZoneCIType()) + "-" + uuidv4();
			setformAction("add");
			setFormData({
				rid: "#13:0",
				citype: "#12:165",
				farm: "",
				farmname: "",
				block: "",
				blockname: "",
				commodity: "",
				commodityname: "",
				//cultivar: "",
				cultivar_name: "",
				zone_year_established: "1970-01-01 00:00:00",
				zone_tree_count: 0,
				rowwidth: 0,
				plantdistance: 0,
				zone_trees_per_ha: 0,
				harzonesize: 0,
				commodity_history_1:"",
				cultivar_history_1:"",
				commodity_history_2:"",
				cultivar_history_2:"",
				trv: 0,
				name: "",
				type: "",
				status: "active",
				uuid: tuuid
			});
		} else {
			setformAction("edit");
			setFormData(getHarvestZone(harvestzoneid));
		}
	}, [harvestzoneid, getHarvestZone, getHarvestZoneCIType])
	return (
		<div className="form form-bg">
			<div className="progressbar">
				<div
					style={{ width: pageIndex === 0 ? "100%" : "100%" }}
				></div>
			</div>
			<div className="form-container">
				<div className="header">
					<h1 style={{ color: "#7A9A01" }}>{FormTitle[formAction]}</h1>
				</div>
				<div className="body">{DisplayStep()}</div>
				<div className="footer">
					<button
						onClick={(e) => {
							setOverlayState("overlay_visible");
							switch (e.target.innerText) {
								case 'Submit':
									addHarvestZone({
										rid: formData.rid,
										citype: "#12:165",
										farm: formData.farm,
										farmname: formData.farmname,
										block: formData.block,
										blockname: formData.blockname,
										commodity: formData.commodity,
										commodityname: formData.commodityname,
										//cultivar: formData.cultivar,
										cultivar_name: formData.cultivar_name,
										zone_year_established: formData.zone_year_established,
										zone_tree_count: formData.zone_tree_count,
										rowwidth: formData.rowwidth,
										plantdistance: formData.plantdistance,
										zone_trees_per_ha: formData.zone_trees_per_ha,
										harzonesize: formData.harzonesize,
										commodity_history_1:formData.commodity_history_1,
										cultivar_history_1:formData.cultivar_history_1,
										commodity_history_2:formData.commodity_history_2,
										cultivar_history_2:formData.cultivar_history_2,
										trv: formData.trv,
										name: formData.name,
										type: formData.type,
										status: formData.status,
										uuid: formData.uuid
									}, hideForm, setOverlayState);
									break;
								case 'Save':
									editHarvestZone({
										rid: formData.rid,
										citype: "#12:165",
										farm: formData.farm,
										farmname: formData.farmname,
										block: formData.block,
										blockname: formData.blockname,
										commodity: formData.commodity,
										commodityname: formData.commodityname,
										//cultivar: formData.cultivar,
										cultivar_name: formData.cultivar_name,
										zone_year_established: formData.zone_year_established,
										zone_tree_count: formData.zone_tree_count,
										rowwidth: formData.rowwidth,
										plantdistance: formData.plantdistance,
										zone_trees_per_ha: formData.zone_trees_per_ha,
										harzonesize: formData.harzonesize,
										commodity_history_1:formData.commodity_history_1,
										cultivar_history_1:formData.cultivar_history_1,
										commodity_history_2:formData.commodity_history_2,
										cultivar_history_2:formData.cultivar_history_2,
										trv: formData.trv,
										name: formData.name,
										type: formData.type,
										status: formData.status,
										uuid: formData.uuid
									}, hideForm, formFilters, setOverlayState);
									break;
								default:
									hideForm();
									break;
							}
						}
						}
					>
						{formAction === "add" ? "Submit" : "Save"}
					</button>
					<button
						onClick={(e) => {
							hideForm();
						}
						}
					>
						Cancel
					</button>
					{
						formAction === "add" ?
							null :
							<button
								onClick={(e) => {
									setOverlayState("overlay_visible");
									switch (e.target.innerText) {
										case 'Delete':
											editHarvestZone({
												rid: formData.rid,
												citype: "#12:165",
												farm: formData.farm,
												farmname: formData.farmname,
												block: formData.block,
												blockname: formData.blockname,
												commodity: formData.commodity,
												commodityname: formData.commodityname,
												//cultivar: formData.cultivar,
												cultivar_name: formData.cultivar_name,
												zone_year_established: formData.zone_year_established,
												zone_tree_count: formData.zone_tree_count,
												rowwidth: formData.rowwidth,
												plantdistance: formData.plantdistance,
												zone_trees_per_ha: formData.zone_trees_per_ha,
												harzonesize: formData.harzonesize,
												commodity_history_1:formData.commodity_history_1,
												cultivar_history_1:formData.cultivar_history_1,
												commodity_history_2:formData.commodity_history_2,
												cultivar_history_2:formData.cultivar_history_2,
												trv: formData.trv,
												name: formData.name,
												type: formData.type,
												status: "deleted",
												uuid: formData.uuid
											}, hideForm, formFilters, setOverlayState);
											break;
										case 'Reinstate':
											editHarvestZone({
												rid: formData.rid,
												citype: "#12:165",
												farm: formData.farm,
												farmname: formData.farmname,
												block: formData.block,
												blockname: formData.blockname,
												commodity: formData.commodity,
												commodityname: formData.commodityname,
												//cultivar: formData.cultivar,
												cultivar_name: formData.cultivar_name,
												zone_year_established: formData.zone_year_established,
												zone_tree_count: formData.zone_tree_count,
												rowwidth: formData.rowwidth,
												plantdistance: formData.plantdistance,
												zone_trees_per_ha: formData.zone_trees_per_ha,
												harzonesize: formData.harzonesize,
												commodity_history_1:formData.commodity_history_1,
												cultivar_history_1:formData.cultivar_history_1,
												commodity_history_2:formData.commodity_history_2,
												cultivar_history_2:formData.cultivar_history_2,
												trv: formData.trv,
												name: formData.name,
												type: formData.type,
												status: "active",
												uuid: formData.uuid
											}, hideForm, formFilters, setOverlayState);
											break;
										default:
											hideForm();
											break;
									}
								}}
							>
								{formData.status === "active" ? "Delete" : "Reinstate"}
							</button>
					}
				</div>
			</div>
		</div>
	);
}
