import { createContext, useReducer, useContext } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
import { textFieldClasses } from "@mui/material";
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;
const zoneTypesReducer = (state, action) => {
	let tzonetypes = [];
	switch (action.type) {
		case "REFRESH_ZONETYPES":
			localStorage.setItem("zoneTypesLookup", JSON.stringify(action.zonetypes));
			localStorage.setItem("zonetypes", JSON.stringify(action.zonetypes));
			return {
				zonetypes: action.zonetypes
			};
		case "ADD_ZONETYPE":
			tzonetypes = utils.updateArray(state.zonetypes,action.zonetype,);
			tzonetypes = utils.sortArray(tzonetypes);
			localStorage.setItem("zoneTypesLookup", JSON.stringify(tzonetypes));
			localStorage.setItem("zonetypes", JSON.stringify(tzonetypes));
			return {
				zonetypes: tzonetypes
			};
		case "EDIT_ZONETYPE":
			utils.each(state.zonetypes, function (zonetype, zonetypeind) {
				if (zonetype.uuid !== action.zonetype.uuid) {
					tzonetypes.push(zonetype);
				} else {
					tzonetypes.push(action.zonetype);
				}
			});
			tzonetypes = utils.sortArray(tzonetypes);
			localStorage.setItem("zoneTypesLookup", JSON.stringify(tzonetypes));
			localStorage.setItem("zonetypes", JSON.stringify(tzonetypes));
			return {
				zonetypes: tzonetypes
			};
		default:
			return state;
	}
};

export const ZoneTypesContext = createContext();

const ZoneTypesProvider = ({ children }) => {
	const zonetypes = def_value;
	const [state, dispatch] = useReducer(zoneTypesReducer, {
		zonetypes: zonetypes,
		filters: {}
	});
	// let ts = localStorage.getItem("pestTrapTypes");
	// const [pestTrapTypesLookupState, setPestTrapTypesLookup] = useState((utils.isset(ts) && !utils.isempty(ts) ? JSON.parse(ts) : []));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);

	const value = {
		zonetypes: state.zonetypes,
		// addPestTrapType: (zonetype, hideform) => {
		// 	const a = utils.new("ajax");
		// 	if (utils.isempty(cookie)) { return false; }
		// 	var params = {
		// 		data: zonetype
		// 	};
		// 	var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
		// 	a.post("/zonetype", params, headers).then(
		// 		function (response) {
		// 			if (response.data.success) {
		// 				dispatch({ type: "ADD_ZONETYPE", zonetype });
		// 				hideform();
		// 			} else {
		// 				if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
		// 					setinvalid();
		// 				} else {
		// 					return response;
		// 				}
		// 			}
		// 		});
		// },

		// getPestTrapType: (id) => {
		// 	let tpesttraptype = {};
		// 	utils.each(state.zonetypes, function (zonetype, zonetypeind) {
		// 		if (zonetype.uuid === id) {
		// 			tpesttraptype = zonetype;
		// 			return false;
		// 		}
		// 	});
		// 	return tpesttraptype;
		// },

		getZoneTypes: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let zoneTypesFetchRules = utils.getLocalStorageItem("zoneTypesFetchRules");
			
			// if (!utils.isempty(zoneTypesFetchRules)) {
			// 	if (zoneTypesFetchRules.filtered === false&&zoneTypesFetchRules.trycache) {
			// 		let zonetypes = utils.getLocalStorageItem("zonetypes");
			// 		if(!utils.isempty(zonetypes)) {
			// 			dispatch({ type: "REFRESH_ZONETYPES", zonetypes });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	zoneTypesFetchRules = {trycache:true,filtered:true};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/zonetypes", params).then(
				function (response) {
					if (response.data.success) {
						var zonetypes = [];
						utils.each(response.data.data, function (zonetype, zonetypeid) {
							zonetypes.push(zonetype);
						});
						if (lookup !== false) {
							//setPestTrapTypesLookup(zonetypes);
							dispatch({ type: "REFRESH_ZONETYPES", zonetypes });
						} else {
							dispatch({ type: "REFRESH_ZONETYPES", zonetypes });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							// setPestTrapTypesLookup([]);
						}
					}
					// zoneTypesFetchRules.filtered = false;
					// localStorage.setItem("zoneTypesFetchRules", JSON.stringify(zoneTypesFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editZoneType: (zonetype, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: zonetype
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/zonetype", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "EDIT_ZONETYPE", zonetype });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		// pestTrapTypesLookupState: pestTrapTypesLookupState,
		// setPestTraptypesLookup: setPestTrapTypesLookup,
		getZoneTypeCIType: () => {
			return "ci_zonetype";
		},
		clearZoneTypes: () => {
			// setPestTrapTypesLookup([]);
			let empty = [];
			dispatch({ type: "REFRESH_ZONETYPES", empty });
			localStorage.setItem("zoneTypesLookup", JSON.stringify(empty));
			localStorage.setItem("zonetypes", JSON.stringify(empty));
		}
	}
	return (
		<ZoneTypesContext.Provider value={value}>
			{children}
		</ZoneTypesContext.Provider>
	);
};
export default ZoneTypesProvider;

