import React, { useEffect, useState, useContext, useRef } from "react";
import utils from "../../utils/utils";
import { metaphone } from "metaphone";
import { v4 as uuidv4 } from "uuid";
import { styled, ThemeProvider } from "@mui/material/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Box from "@mui/material/Box";
import { ListItem, List, Link, ListItemIcon } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from '@mui/material/OutlinedInput';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormHelperText from "@mui/material/FormHelperText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { SprayRecommendationsContext } from "../../contexts/sprayrecommendations";
import { SessionsContext } from "../../contexts/sessions";
import "../../index.css";
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import parse from 'html-react-parser';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import SearchIcon from '@mui/icons-material/Search';
import { StateManagerContext } from '../../contexts/statemanager';


// Theme
const ThemeFormTextField = styled(TextField)(utils.theme.FormTextField)
const ThemeSelect = styled(Select)(utils.theme.Select);
const ThemeTextField = styled(TextField)(utils.theme.TextField);

const uomdata = {
	"--": {
		"perlitre": 1, // 
		"qtyfactor": 1, // Conversion factor to get to "L"
		"qtyunit": "L", // 
		"rateunit": "L", // 
		"ignorewater": 1,
		"multiplier": 0,
	},
	"ml/100L": {// ( ( {RATE} / {qtyfactor} ) / {perlitre} ) = DOSAGE/LITRE; {WATERRATE} * {DOSAGE} 
		"perlitre": 100, // 
		"qtyfactor": 1000, // Conversion factor to get to "L"
		"qtyunit": "L", // Conversion factor to get to "L"
		"rateunit": "ml", // Conversion factor to get to "L"
		"ignorewater": 0,
		"multiplier": 1,
	}, // Total volume of wter: WATERRATE * SIZE, * DOSAGE
	"L/100L": {// ( ( {RATE} / {qtyfactor} ) / {perlitre} ) = DOSAGE/LITRE; {WATERRATE} * {DOSAGE} 
		"perlitre": 100, // 
		"qtyfactor": 1, // Conversion factor to get to "L"
		"qtyunit": "L", // Conversion factor to get to "L"
		"rateunit": "L",
		"ignorewater": 0,
		"multiplier": 1,
	},
	"mg/100L": {
		"perlitre": 100,
		"qtyfactor": 1000,
		"qtyunit": "g",
		"rateunit": "mg",
		"ignorewater": 0,
		"multiplier": 1,
	},
	"g/100L": {
		"perlitre": 100,
		"qtyfactor": 1000,
		"qtyunit": "kg",
		"rateunit": "g",
		"ignorewater": 0,
		"multiplier": 1,
	},
	"kg/1000L": {
		"perlitre": 1000,
		"qtyfactor": 1,
		"qtyunit": "kg",
		"rateunit": "kg",
		"ignorewater": 0,
		"multiplier": 1,
	},
	"kg/100L": {
		"perlitre": 100,
		"qtyfactor": 1,
		"qtyunit": "kg",
		"rateunit": "g",
		"ignorewater": 0,
		"multiplier": 1,
	},
	"g/ha": {
		"perlitre": 1,
		"qtyfactor": 1000,
		"qtyunit": "kg",
		"rateunit": "g",
		"ignorewater": 1,
		"multiplier": 1,
	},
	"kg/ha": {
		"perlitre": 1,
		"qtyfactor": 1,
		"qtyunit": "kg",
		"rateunit": "kg",
		"ignorewater": 1,
		"multiplier": 1,
	},
	"ml/ha": {
		"perlitre": 1,
		"qtyfactor": 1000,
		"qtyunit": "L",
		"rateunit": "ml",
		"ignorewater": 1,
		"multiplier": 1,
	},
	"L/ha": {
		"perlitre": 1,
		"qtyfactor": 1,
		"qtyunit": "L",
		"rateunit": "L",
		"ignorewater": 1,
		"multiplier": 1,
	},
	"units/ha": {
		"perlitre": 1,
		"qtyfactor": 1,
		"qtyunit": "units",
		"rateunit": "units",
		"ignorewater": 1,
		"ignoretrv": 1,
		"isperha": 1,
		"multiplier": 1,
	},
	"units/100L": {
		"perlitre": 1,
		"qtyfactor": 1,
		"qtyunit": "units",
		"rateunit": "units",
		"ignorewater": 1,
		"ignoretrv": 1,
		"isperha": 1,
		"multiplier": 1,
	},
	"units/1000L": {
		"perlitre": 1,
		"qtyfactor": 1,
		"qtyunit": "units",
		"rateunit": "units",
		"ignorewater": 1,
		"ignoretrv": 1,
		"isperha": 1,
		"multiplier": 1,
	}
};

export function Step001({ formData, setFormData, formMeta, setFormMeta, pageIndex, setPageIndex, sprayprograms, organisations, farms, cultivars, harvestzones, commodities, applicationmethods, pests, orchards, products, persons, formErrors, generateSprayRecommendationPDF, fetchSprayRecommendationPDF, editSprayRecommendation, addSprayRecommendation, FormTitle, formAction, hideForm, formFilters, setOverlayState }) {
	const divRef = useRef(null);

	function scrollToTop() {
		console.log("ALERT!! ")
		divRef.current.scroll({
			top: 0,
			behavior: "smooth"
		});
	};

	var zonedialogdata = [];
	console.warn("Step001 formData", formData);
	if (utils.isset(formData.zones) && utils.isset(formData.pests) && utils.isset(formData.products)) {
		utils.each(harvestzones, function (harvestzone, harvestzoneind) {
			if (!utils.isset(harvestzone.farm) || !utils.isset(harvestzone.cultivar_name)) { return true; }
			let farmname = utils.getName(farms, harvestzone.farm);
			let farmind = zonedialogdata.findIndex(farm => farm.uuid === harvestzone.farm);
			if (farmind === -1) {
				zonedialogdata.push({ uuid: harvestzone.farm, name: farmname, selected: 'none', cultivars: [] });
				farmind = zonedialogdata.length - 1;
			}
			console.warn("farmind??????????",farmind)
			let cultivarname = utils.getName(cultivars, harvestzone.cultivar_name);
			let cultivarind = zonedialogdata[farmind].cultivars.findIndex(cultivar => cultivar.uuid === harvestzone.cultivar_name);
			if (cultivarind === -1) {
				zonedialogdata[farmind].cultivars.push({ uuid: harvestzone.cultivar_name, name: cultivarname, selected: 'none', zones: [] });
				cultivarind = zonedialogdata[farmind].cultivars.length - 1;
			}

			if (zonedialogdata[farmind].cultivars[cultivarind].zones.findIndex(zone => zone.uuid === harvestzone.uuid) === -1) {
				zonedialogdata[farmind].cultivars[cultivarind].zones.push({ uuid: harvestzone.uuid, selected: 'none', name: harvestzone.name });

				zonedialogdata[farmind].cultivars.sort(function (a, b) { return a.name.localeCompare(b.name); });
				zonedialogdata[farmind].cultivars[cultivarind].zones.sort(function (a, b) { return a.name.localeCompare(b.name); });
			}
		});
		var selectedtallies = {};
		formData.zones = typeof formData.zones === 'string' ? JSON.parse(formData.zones) : formData.zones;
		formData.pests = typeof formData.pests === 'string' ? JSON.parse(formData.pests) : formData.pests;
		formData.products = typeof formData.products === 'string' ? JSON.parse(formData.products) : formData.products;

		utils.each(formData.zones, function (fdzone, fdzoneind) {
			let farmind = zonedialogdata.findIndex(farm => farm.uuid === utils.getUUID(farms, fdzone.farm_id));
			let cultivarind = zonedialogdata[farmind].cultivars.findIndex(cultivar => cultivar.uuid === utils.getUUID(cultivars, fdzone.cultivar_id));
			if (!utils.isset(selectedtallies["" + farmind])) {
				selectedtallies["" + farmind] = { farmcount: 0, cultivarcounts: {} };
			}
			selectedtallies["" + farmind].farmcount++;
			if (!utils.isset(selectedtallies["" + farmind].cultivarcounts["" + cultivarind])) {
				selectedtallies["" + farmind].cultivarcounts["" + cultivarind] = 0;
			}
			selectedtallies["" + farmind].cultivarcounts["" + cultivarind]++;
			let zoneind = zonedialogdata[farmind].cultivars[cultivarind].zones.findIndex(tzone => tzone.uuid === utils.getUUID(harvestzones, fdzone.rid));
			zonedialogdata[farmind].cultivars[cultivarind].zones[zoneind].selected = 'full';
		});

		utils.each(selectedtallies, function (farmval, farmind) {
			farmind = parseInt(farmind);
			let farmzonecount = 0;
			utils.each(farmval.cultivarcounts, function (cultivarval, cultivarind) {
				cultivarind = parseInt(cultivarind);
				farmzonecount += zonedialogdata[farmind].cultivars[cultivarind].zones.length;
				if (zonedialogdata[farmind].cultivars[cultivarind].zones.length === cultivarval) {
					zonedialogdata[farmind].cultivars[cultivarind].selected = 'full';
				} else if (cultivarval > 0) {
					zonedialogdata[farmind].cultivars[cultivarind].selected = 'partial';
				} else {
					zonedialogdata[farmind].cultivars[cultivarind].selected = 'none';
				}
			});
			if (farmval.farmcount === farmzonecount) {
				zonedialogdata[farmind].selected = 'full';
			} else if (farmval.farmcount > 0) {
				zonedialogdata[farmind].selected = 'partial';
			} else {
				zonedialogdata[farmind].selected = 'none';
			}
		});
		zonedialogdata.sort(function (a, b) {
			return a.name.localeCompare(b.name);
		})
	}

	// PART 2 OF FORM /////////////////////////////////////////////////////////////////////////////////////
	const [open, setOpen] = useState(false);
	const [openInterventionDialog, setOpenInterventionDialog] = useState(false);
	const [openRevocationDialog, setOpenRevocationDialog] = useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleClickOpenInterventionDialog = () => {
		setOpenInterventionDialog(true);
	};
	const handleCloseInterventionDialog = () => {
		setOpenInterventionDialog(false);
	};

	const handleClickOpenRevocationDialog = () => {
		setOpenRevocationDialog(true);
	};
	const handleCloseRevocationDialog = () => {
		setOpenRevocationDialog(false);
	};

	const [pestName, setPestName] = useState([]);
	const handleProblemSelectChange = (event) => {
		var tpests = typeof event.target.value === 'string' ? event.target.value.split(",") : event.target.value;
		var tmp = {};
		var tpestName = [];
		utils.each(tpests, function (tpest, tpestind) {
			let pestind = pests.findIndex(p => p.name === tpest.trim());
			if (pestind !== -1) {
				if (!utils.isset(tmp[pests[pestind].rid])) { tmp[pests[pestind].rid] = { rid: "", name: "" }; }
				tmp[pests[pestind].rid].rid = pests[pestind].rid;
				tmp[pests[pestind].rid].name = pests[pestind].name;
				tpestName.push(pests[pestind].name);
			}
		});
		setFormData({ ...formData, pests: tmp });
		setPestName(tpestName);
	};

	var handleZoneChange = (event) => {
		console.log(event)
		var idtype = event.target.attributes.idtype.value;
		var farmind = false;
		var cultivarind = false;
		var zoneind = false;
		var tfarm = false;
		var tfdZones = formData.zones;
		switch (idtype) {
			case "farm":
				farmind = parseInt(event.target.attributes.farmind.value);
				tfarm = zonedialogdata[farmind];
				if (event.target.checked) {
					utils.each(tfarm.cultivars, function (cultivar, cind) {
						utils.each(cultivar.zones, function (zone, zind) {
							let tzone = {};
							let rfarm = utils.isset(tfarm.uuid) ? utils.getRecord(farms, tfarm.uuid) : {};
							let rcultivar = utils.isset(cultivar.uuid) ? utils.getRecord(cultivars, cultivar.uuid) : {};
							let rzone = utils.isset(zone.uuid) ? utils.getRecord(harvestzones, zone.uuid) : {};
							let rblock = utils.isset(rzone.block) ? utils.getRecord(orchards, rzone.block) : {};
							let rcommodity = utils.isset(rcultivar.agri_produce_commodity) ? utils.getRecord(commodities, rcultivar.agri_produce_commodity) : {};

							tzone.sortname = rfarm.name + rcultivar.name + rzone.name;
							tzone.commodity_id = rcommodity.rid;
							tzone.commodityname = rcommodity.name;
							tzone.cultivarname2 = rcommodity.name + " - " + rcultivar.name;
							tzone.cultivarname = rcultivar.name;
							tzone.cultivar_id = rcultivar.rid;
							tzone.farmname = rfarm.name;
							tzone.farm_id = rfarm.rid;
							tzone.status = rzone.status;
							tzone.block = rblock.rid;
							tzone.size = rzone.harzonesize;
							tzone.rid = rzone.rid;
							tzone.name = rzone.name;
							tfdZones[rzone.rid] = tzone;
						});
					});
				} else {
					utils.each(tfarm.cultivars, function (cultivar, cind) {
						utils.each(cultivar.zones, function (zone, zind) {
							let rzone = utils.isset(zone.uuid) ? utils.getRecord(harvestzones, zone.uuid) : {};
							if (utils.isset(tfdZones[rzone.rid])) { delete tfdZones[rzone.rid]; };
						});
					});
				}
				break;
			case "cultivar":
				farmind = parseInt(event.target.attributes.farmind.value);
				cultivarind = parseInt(event.target.attributes.cultivarind.value);
				tfarm = zonedialogdata[farmind];
				if (event.target.checked) {
					utils.each(tfarm.cultivars[cultivarind].zones, function (zone, zind) {
						let tzone = {};
						let rfarm = utils.isset(tfarm.uuid) ? utils.getRecord(farms, tfarm.uuid) : {};
						let rcultivar = utils.isset(tfarm.cultivars[cultivarind].uuid) ? utils.getRecord(cultivars, tfarm.cultivars[cultivarind].uuid) : {};
						let rzone = utils.isset(zone.uuid) ? utils.getRecord(harvestzones, zone.uuid) : {};
						let rblock = utils.isset(rzone.block) ? utils.getRecord(orchards, rzone.block) : {};
						let rcommodity = utils.isset(rcultivar.agri_produce_commodity) ? utils.getRecord(commodities, rcultivar.agri_produce_commodity) : {};

						tzone.sortname = rfarm.name + rcultivar.name + rzone.name;
						tzone.commodity_id = rcommodity.rid;
						tzone.commodityname = rcommodity.name;
						tzone.cultivarname2 = rcommodity.name + " - " + rcultivar.name;
						tzone.cultivarname = rcultivar.name;
						tzone.cultivar_id = rcultivar.rid;
						tzone.farmname = rfarm.name;
						tzone.farm_id = rfarm.rid;
						tzone.status = rzone.status;
						tzone.block = rblock.rid;
						tzone.size = rzone.harzonesize;
						tzone.rid = rzone.rid;
						tzone.name = rzone.name;
						tfdZones[rzone.rid] = tzone;
					});
				} else {
					utils.each(tfarm.cultivars[cultivarind].zones, function (zone, zind) {
						let rzone = utils.isset(zone.uuid) ? utils.getRecord(harvestzones, zone.uuid) : {};
						if (utils.isset(tfdZones[rzone.rid])) { delete tfdZones[rzone.rid]; };
					});
				}
				break;
			case "zone":
				farmind = parseInt(event.target.attributes.farmind.value);
				cultivarind = parseInt(event.target.attributes.cultivarind.value);
				zoneind = parseInt(event.target.attributes.zoneind.value);
				tfarm = zonedialogdata[farmind];
				if (event.target.checked) {
					let tzone = {};
					let rfarm = utils.isset(tfarm.uuid) ? utils.getRecord(farms, tfarm.uuid) : {};
					let rcultivar = utils.isset(tfarm.cultivars[cultivarind].uuid) ? utils.getRecord(cultivars, tfarm.cultivars[cultivarind].uuid) : {};
					let rzone = utils.isset(tfarm.cultivars[cultivarind].zones[zoneind].uuid) ? utils.getRecord(harvestzones, tfarm.cultivars[cultivarind].zones[zoneind].uuid) : {};
					let rblock = utils.isset(rzone.block) ? utils.getRecord(orchards, rzone.block) : {};
					let rcommodity = utils.isset(rcultivar.agri_produce_commodity) ? utils.getRecord(commodities, rcultivar.agri_produce_commodity) : {};

					tzone.sortname = rfarm.name + rcultivar.name + rzone.name;
					tzone.commodity_id = rcommodity.rid;
					tzone.commodityname = rcommodity.name;
					tzone.cultivarname2 = rcommodity.name + " - " + rcultivar.name;
					tzone.cultivarname = rcultivar.name;
					tzone.cultivar_id = rcultivar.rid;
					tzone.farmname = rfarm.name;
					tzone.farm_id = rfarm.rid;
					tzone.status = rzone.status;
					tzone.block = rblock.rid;
					tzone.size = rzone.harzonesize;
					tzone.rid = rzone.rid;
					tzone.name = rzone.name;
					tfdZones[rzone.rid] = tzone;
				} else {
					let rzone = utils.isset(tfarm.cultivars[cultivarind].zones[zoneind].uuid) ? utils.getRecord(harvestzones, tfarm.cultivars[cultivarind].zones[zoneind].uuid) : {};
					if (utils.isset(tfdZones[rzone.rid])) { delete tfdZones[rzone.rid]; };
				}
				break;
			default:
				break;
		}
		updateAllCalcs({tfdZones:tfdZones});
	};

	function updateProductCalcs(item) {
		console.warn("updateProductCalcs...");
		var uom = uomdata[item.rateunit];
		item.rate = isNaN(parseFloat(item.rate)) ? 0 : parseFloat(item.rate);
		var crate = item.rate;
		var dosage = 1;
		crate = (item.rate / uom.qtyfactor);// / uom.perlitre;
		dosage = crate / uom.perlitre;// GET DOSAGE PER LITRE
		console.warn("uom.ignorewater", uom, "dosage", dosage, "item", item);
		if (!utils.isempty(uom.ignorewater || parseInt(uom.ignorewater) === 1)) {// Ignore the water rate:
			item.qty = dosage * formData.sprayarea * uom.multiplier;
		} else {
			item.qty = dosage * formData.totalvol * uom.multiplier;
		}
		item.qty = parseFloat(item.qty.toFixed(3));
		item.unitcost = parseFloat(item.unitcost);
		item.totalcost = item.unitcost * item.qty;
		item.totalcost = parseFloat(item.totalcost.toFixed(2));
		item.costha = 0;
		if (formData.sprayarea > 0) {
			item.costha = item.totalcost / formData.sprayarea;
		}
		item.costha = parseFloat(item.costha.toFixed(2));

		item.unitofmeasure = uom.qtyunit;
		return item;
	};

	function updateAllCalcs(params) {
		var tformData = formData;
		tformData.products = typeof tformData.products === 'string' ? JSON.parse(tformData.products) : tformData.products;
		if(utils.isset(params.tfdZones)) {
			var totalarea = 0;
			utils.each(params.tfdZones, function (zone, zoneind) {
				zone.size = parseFloat(zone.size);
				zone.size = isNaN(zone.size) ? 0 : zone.size;
				totalarea += zone.size;
			});
			totalarea = parseFloat(totalarea.toFixed(2));
			var sprayperc = parseFloat(formData.sprayperc);
			sprayperc = isNaN(sprayperc) ? 100 : sprayperc;
			var sprayarea = totalarea * sprayperc / 100;
			var waterrate = parseFloat(formData.waterrate);
			waterrate = isNaN(waterrate) ? 0 : waterrate;
			var totalvol = sprayarea * waterrate;
			tformData.zones = params.tfdZones;
			tformData.totalarea = totalarea;
			tformData.sprayarea = sprayarea;
			tformData.totalvol = totalvol;
		}
		if(utils.isset(params.prodind)) {
			switch (params.attr) {
				case "whp":
					params.value = parseFloat(params.value);
					params.value = isNaN(params.value) ? 0 : params.value;
					break;
				default:
					break;
			}
			tformData.products[params.prodind][params.attr] = params.value;
	
		} else if(utils.isset(params.attr)) {
			switch (params.attr) {
				case "sprayperc":
				case "waterrate":
					params.value = parseFloat(params.value);
					params.value = isNaN(params.value) ? 0 : params.value;
					tformData[params.attr] = params.value;
					break;
				default:
					break;
			}
			tformData.sprayarea = tformData.sprayperc / 100 * tformData.totalarea;
			tformData.totalvol = tformData.sprayarea * tformData.waterrate;
		}

		utils.each(tformData.products, function (product, productind) {
			tformData.products[productind] = updateProductCalcs(product);
		});

		setFormData({ ...formData, tformData });
	}

	function formDataTable() {
		var rows = "";
		if (!utils.isempty(formData.zones)) {
			utils.each(formData.zones, (item) => {
				rows += "<tr>";
				rows += "<td>" + item.farmname + "</td>";
				rows += "<td>" + item.name + "</td>";
				rows += "<td>" + item.cultivarname + "</td>";
				rows += "<td>" + item.commodityname + "</td>";
				rows += "<td>" + item.size + "Ha</td>";
				rows += "</tr>";
			})
		}
		return rows;
	}

	var pestNameList = [];
	if (!utils.isempty(formData.pests)) {
		utils.each(formData.pests, function (fpest) {
			pestNameList.push(fpest.name);
		});
	}

	const [issueToName, setIssueToName] = useState([]);
	const handleIssueToSelectChange = (event) => {
		var tpersons = typeof event.target.value === 'string' ? event.target.value.split(",") : event.target.value;
		var tmp = [];
		var tmpissueToName = [];
		utils.each(tpersons, function (tperson, tpersonind) {
			let personind = persons.findIndex(p => p.name === tperson.trim());
			// console.log("person", personind)
			if (personind !== -1) {
				if (!utils.isset(tmp[persons[personind].uuid])) { tmp[persons[personind].uuid] = { rid: "", name: "" }; }
				tmp[persons[personind].uuid].rid = persons[personind].rid;
				tmp[persons[personind].uuid].name = persons[personind].name;
				tmpissueToName.push(persons[personind].name);
			}
		});
		var tmpArrayUUIDs = Object.keys(tmp)
		setFormMeta({ ...formMeta, issueto: tmpArrayUUIDs });
		setIssueToName(tmpissueToName);
	};

	const [revokedToName, setRevokedToName] = useState([]);
	const handleRevokeToSelectChange = (event) => {
		var tpersons = typeof event.target.value === 'string' ? event.target.value.split(",") : event.target.value;
		var tmp = [];
		var tmprevokedToName = [];
		utils.each(tpersons, function (tperson, tpersonind) {
			let personind = persons.findIndex(p => p.name === tperson.trim());
			// console.log("person", personind)
			if (personind !== -1) {
				if (!utils.isset(tmp[persons[personind].uuid])) { tmp[persons[personind].uuid] = { rid: "", name: "" }; }
				tmp[persons[personind].uuid].rid = persons[personind].rid;
				tmp[persons[personind].uuid].name = persons[personind].name;
				tmprevokedToName.push(persons[personind].name);
			}
		});
		var tmpArrayUUIDs = Object.keys(tmp)
		setFormMeta({ ...formMeta, revoked_to: tmpArrayUUIDs });
		setRevokedToName(tmprevokedToName);
	};
	const handleRevokeToInit = (issued_to) => {
		var tmp = [];
		var tmprevokedToName = [];
		utils.each(issued_to, function (tperson, tpersonind) {
			let personind = persons.findIndex(p => p.uuid === tperson);
			// console.log("person", personind)
			if (personind !== -1) {
				if (!utils.isset(tmp[persons[personind].uuid])) { tmp[persons[personind].uuid] = { rid: "", name: "" }; }
				tmp[persons[personind].uuid].rid = persons[personind].rid;
				tmp[persons[personind].uuid].name = persons[personind].name;
				tmprevokedToName.push(persons[personind].name);
			}
		});
		var tmpArrayUUIDs = Object.keys(tmp)
		setFormMeta({ ...formMeta, revoked_to: tmpArrayUUIDs });
		setRevokedToName(tmprevokedToName);
	};

	// the value of the search field 
	const [productName, setProductName] = useState("");
	// the search result
	const [foundProducts, setFoundProducts] = useState(products);
	const filter = (e) => {
		const keyword = e.target.value;
		if (keyword !== '') {
			const results = products.filter((product) => {
				return product.name.toLowerCase().startsWith(keyword.toLowerCase());
			});
			setFoundProducts(results);
		} else {
			setFoundProducts(products);
		}
		setProductName(keyword);
	};

//	console.warn("Recommendation formErrors", formErrors);

	const [selectedProgram, setSelectedProgram] = useState([]);
	const handleChangeProgram = (event) => {
		setSelectedProgram(event.target.value.name);
		setFormData({ ...formData, waterrate: event.target.value.waterrate, appmethod: event.target.value.appmethod, products: event.target.value.products, pests: event.target.value.pests,comments: event.target.value.comments});
	};

	function resetSelectedProgram() {
		setSelectedProgram(null);
	}

	if (!utils.isempty(formData)) {
		return (
			<div className="form form-bg recommendations-form-bg">
				<div className="form-container">
					<div className="header">
						<h1 style={{ color: "#7A9A01" }}>{FormTitle[formAction]}</h1>
					</div>
					<div className="body" ref={divRef}>
						<div className="step001-container">
							<div className="recomendation-form-container">
								<Box sx={{ flexGrow: 1 }}>
									<Box sx={{ flex: 1 }}>
										<ThemeProvider theme={theme}>
											<LocalizationProvider dateAdapter={AdapterMoment}>
												<DatePicker
													label="Due Date"
													value={formData.date_due}
													inputFormat="DD/MM/yyyy"
													renderInput={(params) => <ThemeTextField {...params} sx={{ maxWidth: '180px', paddingRight: '10px' }} size="small" />}
													onChange={(tdate) => {
														setFormData({ ...formData, date_due: tdate.format("MM/DD/YYYY HH:mm:ss") });
													}}

												/>
											</LocalizationProvider>

											<LocalizationProvider dateAdapter={AdapterMoment}>
												<DatePicker
													label="Expiry"
													value={formData.date_expires}
													inputFormat="DD/MM/yyyy"
													renderInput={(params) => <ThemeTextField {...params} sx={{ maxWidth: '180px', paddingRight: '10px' }} size="small" />}
													onChange={(tdate) => {
														setFormData({ ...formData, date_expires: tdate.format("MM/DD/YYYY HH:mm:ss") });
													}}
												/>
											</LocalizationProvider>
											<Box style={{ display: "none" }}>
												<LocalizationProvider dateAdapter={AdapterMoment}>
													<DatePicker
														label="Assigned"
														value={formData.date_assigned}
														inputFormat="DD/MM/yyyy"
														renderInput={(params) => <ThemeTextField {...params} sx={{ maxWidth: '180px', paddingRight: '10px' }} size="small" />}
														onChange={(tdate) => {
															setFormData({ ...formData, date_assigned: tdate.format("MM/DD/YYYY HH:mm:ss") });
														}}
													/>
												</LocalizationProvider>
											</Box>
										</ThemeProvider>
									</Box>

									<Box sx={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
										<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column !important', marginRight: '5px' }}>
											<FormControl fullWidth error={!utils.isempty(formErrors.organisation) ? true : false}>
												<InputLabel id="created_by" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size="small">Issuer</InputLabel>
												<ThemeSelect
													label="Issuer"
													size="small"
													value={formData.organisation}
													defaultValue={formData.organisation}
													onChange={(event) => {
														utils.each(organisations, function (org, orgid) {
															if (org.uuid === event.target.value) {
																setFormData({
																	...formData,
																	organisation: event.target.value,
																	orgadd_1: org.address_1,
																	orgadd_2: org.address_2,
																	orgadd_3: org.address_3,
																	orgphone: org.phone,
																});
															}
														});
													}}
													MenuProps={{
														sx: {
															"&& .Mui-selected": {
																backgroundColor: "#5f7933",
																color: "#fff"
															},
														},
														PaperProps: {
															style: {
																maxHeight: 48 * 4.5 + 8,
																width: 250,
															},
															sx: {
																backgroundColor: "#c7d494",
															}
														}
													}}
												>
													{organisations.map((value, ind) => {
														if (value.org_type !== "issuer") {
															return null;
														}
														return (
															<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
														);
													})}
												</ThemeSelect>
												<FormHelperText>{formErrors.organisation}</FormHelperText>
											</FormControl>

											<Box sx={{ display: 'flex', flex: '1', alignSelf: 'flex-start', textAlign: 'start', width: '100%' }}>
												<Card variant="outlined" sx={{ display: 'flex', flex: '1', backgroundColor: '#c7d494' }}>
													<CardContent sx={{ padding: '5px !important' }}>
														<div>
															<p style={{ margin: '5px' }}><b>Address:</b></p>
															<p style={{ margin: '5px' }}>{formData.orgadd_1}</p>
															<p style={{ margin: '5px' }}>{formData.orgadd_2}</p>
															<p style={{ margin: '5px' }}>{formData.orgadd_3}</p>
														</div>
														<div>
															<p style={{ margin: '5px' }}><b>Phone:</b></p>
															<p style={{ margin: '5px' }}>{formData.orgphone}</p>
														</div>
													</CardContent>
												</Card>
											</Box>
										</Box>

										<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column !important', marginLeft: '5px', marginRight: '5px' }}>
											<FormControl fullWidth error={!utils.isempty(formErrors.client) ? true : false}>
												<InputLabel id="client" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size="small">Client</InputLabel>
												<ThemeSelect
													label="Client"
													size="small"
													value={formData.client}
													onChange={(event) => {
														utils.each(organisations, function (org, orgid) {
															if (org.uuid === event.target.value) {
																setFormData({
																	...formData,
																	client: event.target.value,
																	cliadd_1: org.address_1,
																	cliadd_2: org.address_2,
																	cliadd_3: org.address_3,
																	cliphone: org.phone,
																});
															}
														});
													}}
													MenuProps={{
														sx: {
															"&& .Mui-selected": {
																backgroundColor: "#5f7933",
																color: "#fff"
															},
														},
														PaperProps: {
															style: {
																maxHeight: 48 * 4.5 + 8,
																width: 250,
															},
															sx: {
																backgroundColor: "#c7d494",
															}
														}
													}}
												>
													{organisations.map((value, ind) => {
														if (value.org_type !== "producer") {
															return null;
														}
														return (
															<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
														);
													})}
												</ThemeSelect>
												<FormHelperText>{formErrors.client}</FormHelperText>
											</FormControl>

											<Box sx={{ display: 'flex', flex: '1', alignSelf: 'flex-start', textAlign: 'start', width: '100%' }}>
												<Card variant="outlined" sx={{ display: 'flex', flex: '1', backgroundColor: '#c7d494' }}>
													<CardContent sx={{ padding: '5px !important' }}>
														<div>
															<p style={{ margin: '5px' }}><b>Address: </b></p>
															<p style={{ margin: '5px' }}>{formData.cliadd_1}</p>
															<p style={{ margin: '5px' }}>{formData.cliadd_2}</p>
															<p style={{ margin: '5px' }}>{formData.cliadd_3}</p>
														</div>
														<div>
															<p style={{ margin: '5px' }}><b>Phone:</b></p>
															<p style={{ margin: '5px' }}>{formData.cliphone}</p>
														</div>
													</CardContent>
												</Card>
											</Box>
										</Box>
									</Box>

									<Box sx={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
										<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', marginLeft: '5px' }}>
											<Box sx={{ display: 'flex', flex: 1, margin: '0px !important' }}>
												<FormControl fullWidth error={!utils.isempty(formErrors.farm_ref) ? true : false}>
													<InputLabel id="farm_ref" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size='small'>Farm Ref</InputLabel>
													<ThemeSelect
														label="Farm Ref"
														size='small'
														value={formData.farm_ref}
														onChange={(event) => {
															setFormData({ ...formData, farm_ref: event.target.value });
														}}
														MenuProps={{
															sx: {
																"&& .Mui-selected": {
																	backgroundColor: "#5f7933",
																	color: "#fff"
																},
															},
															PaperProps: {
																style: {
																	maxHeight: 48 * 4.5 + 8,
																	width: 250,
																},
																sx: {
																	backgroundColor: "#c7d494",
																}
															}
														}}
													>
														{farms.map((value, ind) => {
															return (
																<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
															);
														})}
													</ThemeSelect>
													<FormHelperText>{formErrors.farm_ref}</FormHelperText>
												</FormControl>
											</Box>
										</Box>

										<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', marginLeft: '5px' }}>
											<Box sx={{ display: 'flex', flex: 1, margin: '0px !important' }}>
												<FormControl fullWidth error={!utils.isempty(formErrors.comm_ref) ? true : false}>
													<InputLabel id="comm_ref" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size='small'>Commodity Ref</InputLabel>
													<ThemeSelect
														label="Commodity Ref"
														size='small'
														value={formData.comm_ref}
														onChange={(event) => {
															setFormData({ ...formData, comm_ref: event.target.value });
														}}
														MenuProps={{
															sx: {
																"&& .Mui-selected": {
																	backgroundColor: "#5f7933",
																	color: "#fff"
																},
															},
															PaperProps: {
																style: {
																	maxHeight: 48 * 4.5 + 8,
																	width: 250,
																},
																sx: {
																	backgroundColor: "#c7d494",
																}
															}
														}}
													>
														{commodities.map((value, ind) => {
															return (
																<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
															);
														})}
													</ThemeSelect>
													<FormHelperText>{formErrors.comm_ref}</FormHelperText>
												</FormControl>
											</Box>
										</Box>

										<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', marginLeft: '5px' }}>
											<Box sx={{ display: 'flex', flex: 1, margin: '0px !important' }}>
												<ThemeFormTextField
													type="text"
													id="note_ref"
													label="Note Ref"
													placeholder="Note Ref"
													size='small'
													fullWidth
													sx={{ margin: '0px !important' }}
													value={formData.note_ref}
													onChange={(event) =>
														setFormData({ ...formData, note_ref: event.target.value })
													}
												/>
											</Box>
										</Box>
									</Box>

									{
										!utils.isempty(formData.pdfname) ?
											<Box>
												<List >
													<ListItem
														button={true}
														component={Link}
														to={isDisabled}
														onClick={() => {
															fetchSprayRecommendationPDF(formData.uuid)
														}
														}
													>
														<ListItemIcon style={{ color: "#7A9A01" }} > <PictureAsPdfIcon /> </ListItemIcon>
														<ListItemText primary={formData.pdfname + ".pdf"} primaryTypographyProps={{ variant: "body2" }} />
													</ListItem>
												</List>
											</Box>
											:
											null
									}
								</Box>

								{
									FormTitle[formAction] === "Add Recommendation" ?
										<Box sx={{ flexGrow: 1 }}>
											<div style={{ paddingTop: "5px", boxSizing: "content-box" }}>
												<div>SELECT PROGRAM</div>
												<FormControl sx={{ m: 0, width: "100%", marginTop: '5px' }}>
													<InputLabel id="spray_program" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size='small'>Spray Program</InputLabel>
													<ThemeSelect
														label="Spray Program"
														id="spray_program"
														size='small'
														value={selectedProgram}
														onChange={handleChangeProgram}
														renderValue={(selected) => selected}
														MenuProps={{
															sx: {
																"&& .Mui-selected": {
																	backgroundColor: "#5f7933",
																	color: "#fff"
																},
															},
															PaperProps: {
																style: {
																	maxHeight: 48 * 4.5 + 8,
																	width: 250,
																},
																sx: {
																	backgroundColor: "#c7d494",
																}
															}
														}}
													>
														{sprayprograms.map((value, ind) => {
															return (
																<MenuItem key={value.uuid} value={value}>{value.name}</MenuItem>
															);
														})}
													</ThemeSelect>
												</FormControl>
											</div>
										</Box>
										: null
								}

								<Box sx={{ flexGrow: 1 }}>
									<div style={{ paddingTop: "5px", boxSizing: "content-box" }}>
										<div>SELECT FIELDS</div>
										<Button
											variant="contained"
											size="small"
											sx={{ width: 1, marginBottom: '10px', marginTop: '5px' }}
											onClick={handleClickOpen}
											disableElevation
											style={utils.theme.LoginButton}
										>
											Click to Select
										</Button>

										<Dialog
											fullScreen={fullScreen}
											open={open}
											onClose={handleClose}
											disableScrollLock
											aria-labelledby="responsive-dialog-title"
										>
											<DialogTitle sx={{ backgroundColor: '#c7d494', color: '#7A9A01', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px', fontWeight: 'bold', fontSize: '1.5em' }}>{"Select Fields"}</DialogTitle>
											<DialogContent sx={{ backgroundColor: '#c7d494' }}>
												<div className="container-fluid" >
													{
														zonedialogdata.map((farm, farmind) => (
															<div key={farm.uuid} className="custom-control custom-checkbox">
																<div className="form-group each_form_group">
																	<FormGroup>
																		<FormControlLabel control={
																			<div className="nexus-checkbox">
																				<label>
																					<input
																						type="checkbox"
																						className="custom-control-input"
																						id={farm.uuid}
																						idtype={'farm'}
																						farmind={farmind}
																						checked={farm.selected !== 'none' ? true : false}
																						onChange={handleZoneChange}
																					/>
																					<span class="checkbox-material">
																						<span class="check"></span>
																					</span>
																				</label>
																			</div>
																		}
																			label={farm.name} />
																	</FormGroup>
																</div>

																{
																	farm.cultivars.map((cultivar, cultivarind) => (
																		<div key={cultivar.uuid} className="form-group each_form_group">
																			{
																				<FormGroup sx={{ marginLeft: '20px' }}>
																					<FormControlLabel control={
																						<div className="nexus-checkbox">
																							<label>
																								<input
																									type="checkbox"
																									className="custom-control-input"
																									id={cultivar.uuid}
																									idtype={'cultivar'}
																									farmind={farmind}
																									cultivarind={cultivarind}
																									checked={cultivar.selected !== 'none' ? true : false}
																									onChange={handleZoneChange}
																								/>
																								<span class="checkbox-material">
																									<span class="check"></span>
																								</span>
																							</label>
																						</div>
																					}
																						label={cultivar.name} />
																				</FormGroup>
																			}

																			{
																				cultivar.zones.map((zone, zoneind) => (
																					<FormGroup key={zone.uuid} sx={{ marginLeft: '40px' }}>
																						<FormControlLabel control={
																							<div className="nexus-checkbox">
																								<label>
																									<input
																										type="checkbox"
																										className="custom-control-input"
																										id={zone.uuid}
																										idtype={'zone'}
																										farmind={farmind}
																										cultivarind={cultivarind}
																										zoneind={zoneind}
																										checked={zone.selected !== 'none' ? true : false}
																										onChange={handleZoneChange}
																									/>
																									<span class="checkbox-material">
																										<span class="check"></span>
																									</span>
																								</label>
																							</div>
																						}
																							label={zone.name} />
																					</FormGroup>
																				))
																			}
																		</div>
																	))
																}
															</div>
														))}
												</div>
											</DialogContent>
											<DialogActions sx={{ backgroundColor: '#c7d494' }}>
												<Button
													variant="contained"
													onClick={handleClose}
													autoFocus
													sx={{ color: '#fff' }}
													style={utils.theme.LoginButton}
												>
													Done
												</Button>
											</DialogActions>
										</Dialog>
									</div>

									<div className="recommendation-form-fields-table">
										<table>
											<thead>
												<tr>
													<th>Farm</th>
													<th>Name</th>
													<th>Cultivar</th>
													<th>Commodity</th>
													<th style={{ width: "100px" }}>Size</th>
												</tr>
											</thead>
											<tbody>
												{
													parse(formDataTable())
												}
												{
													formData.totalarea > 0 ?
														<tr>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td> {parseFloat(formData.totalarea).toFixed(2)} Ha </td>
														</tr>
														: null
												}
											</tbody>
										</table>
									</div>
								</Box>

								<Box>
									<div>SELECT PROBLEMS</div>
									<FormControl sx={{ m: 0, width: "100%", marginTop: '5px' }}>
										<InputLabel id="pests" sx={{ boxSizing: 'border-box', color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size='small'>Problems</InputLabel>
										<ThemeSelect
											label="Problems"
											id="pests"
											size="small"
											multiple
											value={pestNameList}
											onChange={handleProblemSelectChange}
											input={<OutlinedInput sx={{ display: 'grid', paddingRight: '35px', margin: '0px' }} label="Tag" />}
											renderValue={(selected) => selected.join(', ')}
											MenuProps={{
												sx: {
													"&& .Mui-selected": {
														backgroundColor: "#5f7933",
														color: "#fff"
													},
												},
												PaperProps: {
													style: {
														maxHeight: 48 * 4.5 + 8,
														width: 250,
													},
													sx: {
														backgroundColor: "#c7d494",
													},
												},
											}}
										>
											{pests.map((value) => (
												<MenuItem key={value.uuid} value={value.name}>
													<Checkbox
														checked={pestName.indexOf(value.name) > -1 || pestNameList.indexOf(value.name) > -1}
														sx={{
															color: "#7A9A01",
															'&.Mui-checked': {
																color: "#fff",
															},
														}}
													/>
													<ListItemText primary={value.name}></ListItemText>
												</MenuItem>
											))}
										</ThemeSelect>
									</FormControl>
								</Box>

								<Box>
									<div>SELECT INTERVENTION</div>
									<Button
										sx={{ width: 1, marginBottom: '10px', marginTop: '5px' }}
										variant="contained"
										size="small"
										onClick={handleClickOpenInterventionDialog}
										disableElevation
										style={utils.theme.LoginButton}
									>
										Click to select
									</Button>
									<div>
										<Dialog
											fullScreen={fullScreen}
											open={openInterventionDialog}
											onClose={handleCloseInterventionDialog}
											aria-labelledby="responsive-dialog-title"
											PaperProps={{ sx: { minWidth: '600px' } }}
										>
											<DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: '#c7d494', color: '#7A9A01', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px', fontWeight: 'bold', fontSize: '1.5em' }}>{"Select Product"}</DialogTitle>
											<DialogContent sx={{ backgroundColor: '#c7d494' }}>
												{
													<div className="container">
														<ThemeTextField
															type="search"
															value={productName}
															size="small"
															onChange={filter}
															placeholder="Search Product"
															sx={{ width: '100%' }}
															InputProps={{
																startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
															}}
														/>

														<div className="user-list">
															{foundProducts && foundProducts.length > 0 ? (
																foundProducts.map((item) => {
																	var comm = commodities.find(tcom => tcom.uuid === formData.comm_ref);
																	item.context_data = typeof item.context_data === 'string' ? JSON.parse(item.context_data) : item.context_data;
																	//if (!utils.isset(comm)) { return null; } 
																	//if (!utils.isset(item.context_data.commodity) || !utils.isset(item.context_data.commodity[comm.ref])) { return null; }
																	var checkedindex = formData.products.findIndex(fp => fp.uuid === item.uuid) === -1 ? false : true;
																	return (
																		<FormGroup key={item.id} sx={{ marginLeft: '40px', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap' }}>
																			<div className="nexus-checkbox">
																				<label>
																					<input
																						type="checkbox"
																						className="custom-control-input"
																						checked={checkedindex}
																						productuuid={item.uuid}
																						onChange={(event) => {
																							let tproduct = products.find(prod => prod.uuid === event.target.attributes.productuuid.value);
																							console.warn("tproduct",tproduct,"item",item);
																							if (!utils.isset(tproduct)) { return null; }
																							let ctdata = tproduct.context_data;
																							let ctdatacomm = ctdata.commodity;
																							delete tproduct.contextref;
																							delete tproduct.status;
																							delete tproduct.type;
																							delete tproduct.context_data;
																							tproduct.unitcost = tproduct.stdcost;
																							delete tproduct.stdcost;
																							tproduct.qty = ctdata.qtyperunit;
																							tproduct.rate = utils.isset(item.rate)?item.rate:"";
																							tproduct.trv = utils.isset(item.trv)?item.trv:"100";
																							tproduct.totalcost = tproduct.unitcost * tproduct.qty;
																							// tproduct.costha = totalcost/sprayarea

																							//Update to make use of utils each to make sure code stays consistent
																							if(utils.isset(tproduct.context_data)&&!utils.isempty(tproduct.context_data)) {
																								Object.entries(ctdatacomm).map((value, index) => {
																									if (utils.isset(comm) && value[0] === comm.ref) {
																										Object.entries(value[1]).map((val, idx) => {
																											if (val[0] === "defwhp") {
																												tproduct.whp = val[1]
																											}
																											if (val[0] === "rate") {
																												tproduct.rate = val[1]
																											}
																											if (val[0] === "rateunit") {
																												tproduct.rateunit = val[1]
																											}
																											return true;
																										});
																									}
																									return true;
																								});
																							} else {

																							}

																							let tproducts = formData.products;
																							tproducts = typeof tproducts === 'string' ? JSON.parse(tproducts) : tproducts;
																							let tindex = tproducts.findIndex(prod => prod.uuid === tproduct.uuid);

																							if (event.target.checked) {
																								if (tindex === -1) {
																									tproducts.push(tproduct);
																								}
																							}
																							if (!event.target.checked && tindex !== -1) {
																								tproducts.splice(tindex, 1);
																							}
																							setFormData({ ...formData, products: tproducts });
																						}}
																					/>
																					<span className="checkbox-material">
																						<span className="check"></span>
																					</span>
																				</label>
																			</div>
																			<Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
																				<div>{item.name}</div>
																				<div style={{ fontSize: '13px', color: '#919191' }}>{item.active_ing}</div>
																			</Box>
																		</FormGroup>
																	);
																})
															) : (
																<h3>No results for this product found!</h3>
															)}
														</div>
													</div>
												}
											</DialogContent>
											<DialogActions sx={{ backgroundColor: '#c7d494' }}>
												<Button
													variant="outlined"
													onClick={handleCloseInterventionDialog}
													autoFocus
													sx={{ color: '#fff' }}
													style={utils.theme.LoginButton}
												>
													Done
												</Button>
											</DialogActions>
										</Dialog>
									</div>

									<div className="recommendation-form-intervention-table">
										<table>
											<thead>
												<tr>
													<th>Product</th>
													<th>WHP</th>
													<th>WHI</th>
													<th>Rate</th>
													<th>Unit</th>
													<th>QTY</th>
													<th>UOM</th>
													<th>TRV</th>
													{/* <th>Cost</th> */}
													{/* <th>Total</th><th>R/ha</th> */}
												</tr>
											</thead>
											<tbody>
												{
													formData.products.length > 0 ?
														formData.products.map((value, prodind) => {
															return (
																<>
																	<tr>
																		<td>{value.name}</td>
																		<td>
																			<ThemeTextField
																				value={value.whp}
																				sx={{ maxWidth: '180px' }}
																				size="small"
																				onChange={(event) => {
																					updateAllCalcs({prodind:prodind, attr:"whp", value:event.target.value});
																				}}
																			/>
																		</td>
																		<td>
																			<ThemeTextField
																				value={value.whp_inf}
																				sx={{ maxWidth: '180px' }}
																				size="small"
																				onChange={(event) => {
																					updateAllCalcs({prodind:prodind, attr:"whp_inf", value:event.target.value});
																				}}
																			/>
																		</td>
																		<td>
																			<ThemeTextField
																				type="number"
																				value={value.rate}
																				sx={{ maxWidth: '180px' }}
																				size="small"
																				inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
																				onChange={(event) => {
																					updateAllCalcs({prodind:prodind, attr:"rate", value:event.target.value});
																				}}
																				onFocus = {(event) => { event.target.select(); }}
																			/>
																		</td>
																		<td>
																			<Box sx={{ minWidth: 120 }}>
																				<FormControl fullWidth>
																					<InputLabel id="rateunit" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }}>Rate Unit</InputLabel>
																					<ThemeSelect
																						label="Rate Unit"
																						value={value.rateunit}
																						size="small"
																						onChange={(event) => {
																							updateAllCalcs({prodind:prodind, attr:"rateunit", value:event.target.value});
																						}}
																						MenuProps={{
																							sx: {
																								"&& .Mui-selected": {
																									backgroundColor: "#5f7933",
																									color: "#fff"
																								},
																							},
																							PaperProps: {
																								sx: {
																									backgroundColor: "#c7d494",
																								}
																							}
																						}}
																					>
																						<MenuItem value={"--"} prodind={prodind}> - </MenuItem>
																						<MenuItem value={"ml/100L"} prodind={prodind}>ml/100L</MenuItem>
																						<MenuItem value={"L/100L"} prodind={prodind}>L/100L</MenuItem>
																						<MenuItem value={"mg/100L"} prodind={prodind}>mg/100L</MenuItem>
																						<MenuItem value={"g/100L"} prodind={prodind}>g/100L</MenuItem>
																						<MenuItem value={"kg/100L"} prodind={prodind}>kg/100L</MenuItem>
																						<MenuItem value={"kg/1000L"} prodind={prodind}>kg/1000L</MenuItem>
																						<MenuItem value={"units/100L"} prodind={prodind}>units/100L</MenuItem>
																						<MenuItem value={"units/1000L"} prodind={prodind}>units/1000L</MenuItem>
																						<MenuItem value={"g/ha"} prodind={prodind}>g/ha</MenuItem>
																						<MenuItem value={"kg/ha"} prodind={prodind}>kg/ha</MenuItem>
																						<MenuItem value={"ml/ha"} prodind={prodind}>ml/ha</MenuItem>
																						<MenuItem value={"L/ha"} prodind={prodind}>L/ha</MenuItem>
																						<MenuItem value={"units/ha"} prodind={prodind}>units/ha</MenuItem>
																					</ThemeSelect>
																				</FormControl>
																			</Box>
																		</td>
																		<td>{value.qty === null ? value.qty = 0 : typeof value.qty === "string" ? (isNaN(parseFloat(value.qty)) ? 0 : parseFloat(value.qty).toFixed(2)) : value.qty.toFixed(2)}</td>
																		<td>{value.unitofmeasure}</td>
																		<td>
																			<Box sx={{ minWidth: 120 }}>
																				<FormControl fullWidth>
																					<InputLabel id="trv" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }}>TRV</InputLabel>
																					<ThemeSelect
																						label="TRV"
																						value={value.trv}
																						size="small"
																						onChange={(event) => {
																							updateAllCalcs({prodind:prodind, attr:"trv", value:event.target.value});
																						}}
																						MenuProps={{
																							sx: {
																								"&& .Mui-selected": {
																									backgroundColor: "#5f7933",
																									color: "#fff"
																								},
																							},
																							PaperProps: {
																								sx: {
																									backgroundColor: "#c7d494",
																								}
																							}
																						}}
																					>
																						<MenuItem value={"0"} prodind={prodind}>-N/A-</MenuItem>
																						<MenuItem value={"10"} prodind={prodind}>10%</MenuItem>
																						<MenuItem value={"20"} prodind={prodind}>20%</MenuItem>
																						<MenuItem value={"30"} prodind={prodind}>30%</MenuItem>
																						<MenuItem value={"40"} prodind={prodind}>40%</MenuItem>
																						<MenuItem value={"50"} prodind={prodind}>50%</MenuItem>
																						<MenuItem value={"60"} prodind={prodind}>60%</MenuItem>
																						<MenuItem value={"70"} prodind={prodind}>70%</MenuItem>
																						<MenuItem value={"80"} prodind={prodind}>80%</MenuItem>
																						<MenuItem value={"90"} prodind={prodind}>90%</MenuItem>
																						<MenuItem value={"100"} prodind={prodind}>100%</MenuItem>
																						<MenuItem value={"Light Cover"} prodind={prodind}>Light Cover</MenuItem>
																						<MenuItem value={"Medium Cover"} prodind={prodind}>Medium Cover</MenuItem>
																						<MenuItem value={"Heavy Cover"} prodind={prodind}>Heavy Cover</MenuItem>
																					</ThemeSelect>
																				</FormControl>
																			</Box>
																		</td>
																		{/* <td>
																			<ThemeTextField
																				type="number"
																				value={value.unitcost}
																				sx={{ maxWidth: '180px' }}
																				size="small"
																				inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
																				onChange={(event) => {
																					updateProduct(prodind, "unitcost", event.target.value);
																				}}
																			/>
																		</td> */}
																		{/* <td>{value.totalcost}</td> */}
																	</tr>
																</>
															)
														})
														:
														<tr>
															<td className="placeholder">No Products Added</td>
														</tr>
												}
											</tbody>
										</table>
									</div>
								</Box>

								<div className="general-info-container">
									<span> GENERAL INFORMATION</span>
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<FormControl fullWidth error={!utils.isempty(formErrors.appmethod) ? true : false}>
											<InputLabel id="appmethod" sx={{ textAlign: 'left', color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size="small"> Application Method</InputLabel>
											<ThemeSelect
												label="Application Method"
												value={formData.appmethod}
												size="small"
												onChange={(event) =>
													setFormData({ ...formData, appmethod: event.target.value })
												}
												MenuProps={{
													sx: {
														"&& .Mui-selected": {
															backgroundColor: "#5f7933",
															color: "#fff"
														},
													},
													PaperProps: {
														style: {
															maxHeight: 48 * 4.5 + 8,
															width: 250,
														},
														sx: {
															backgroundColor: "#c7d494",
														}
													}
												}}
											>
												{applicationmethods.map((value, ind) => {
													return (
														<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
													);
												})}
											</ThemeSelect>
											<FormHelperText>{formErrors.appmethod}</FormHelperText>
										</FormControl>

										<FormControl error={!utils.isempty(formErrors.waterrate) ? true : false}>
											<ThemeFormTextField
												type="number"
												id="waterrate"
												label="Water Rate (L/ha)"
												placeholder="Water Rate in L/ha"
												size="small"
												inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
												defaultValue={"0"}
												value={!formData.waterrate ? "" : formData.waterrate}
												onChange={(event) =>
													updateAllCalcs({attr:"waterrate", value:event.target.value})
												}
												onFocus = {(event) => { event.target.select(); }}
												error={!utils.isempty(formErrors.waterrate) ? true : false}
											/>
											<FormHelperText>{formErrors.waterrate}</FormHelperText>
										</FormControl>


										<ThemeFormTextField
											type="number"
											id="sprayperc"
											label="% Spray Area"
											placeholder="% Spray Area"
											size="small"
											inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
											defaultValue={"0"}
											value={!formData.sprayperc ? "" : formData.sprayperc}
											onChange={(event) => {
													updateAllCalcs({attr:"sprayperc", value:event.target.value});
												}
											}
											onFocus = {(event) => { event.target.select(); }}
										/>
										{console.warn("formData.sprayarea",formData.sprayarea)}
										<FormControl error={!utils.isempty(formErrors.totalarea) ? true : false}>
											<ThemeFormTextField
												id="totalarea"
												label="Total Area (Ha)"
												placeholder="0.00 ha"
												size="small"
												value={!formData.totalarea ? "" : (formData.totalarea)}
												InputProps={{
													readOnly: true,
												}}
												error={!utils.isempty(formErrors.totalarea) ? true : false}
											/>
											<FormHelperText>{formErrors.totalarea}</FormHelperText>
										</FormControl>

										<FormControl error={!utils.isempty(formErrors.sprayarea) ? true : false}>
											<ThemeFormTextField
												id="sprayarea"
												label="Spray Area (Ha)"
												placeholder="0.00 ha"
												size="small"
												value={!formData.sprayarea ? "" : formData.sprayarea}
												InputProps={{
													readOnly: true,
												}}
												error={!utils.isempty(formErrors.sprayarea) ? true : false}
											/>
											<FormHelperText>{formErrors.sprayarea}</FormHelperText>
										</FormControl>

										<FormControl error={!utils.isempty(formErrors.totalvol) ? true : false}>
											<ThemeFormTextField
												id="totalvol"
												label="Total Amount of Water Needed Per Area (L)"
												placeholder="0 L"
												size="small"
												value={!formData.totalvol ? "" : formData.totalvol}
												defaultValue={"0"}
												InputProps={{
													readOnly: true,
												}}
												error={!utils.isempty(formErrors.totalvol) ? true : false}
											/>
											<FormHelperText>{formErrors.totalvol}</FormHelperText>
										</FormControl>

									</Box>
								</div>

								<Box>
									<div style={{ textAlign: "left" }}> <b>Comments</b></div>
									<div style={{ paddingTop: "5px", textAlign: "left" }} className="comments-container">
										{/* TextArea Styling Applied in css class (dependand on parent div) */}
										<TextareaAutosize
											aria-label="comments"
											minRows={2}
											value={formData.comments}
											placeholder="Comments"
											onChange={(event) =>
												setFormData({ ...formData, comments: event.target.value })
											}
										/>
									</div>
									{
										FormTitle[formAction] === "Edit Recommendation" ?
											<Box sx={{ minWidth: 120 }}>
												<FormControl fullWidth>
													<InputLabel id="email" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size="small">Issue To</InputLabel>
													<ThemeSelect
														label="Issue To"
														multiple
														value={issueToName}
														size="small"
														onChange={handleIssueToSelectChange}
														input={<OutlinedInput sx={{ display: 'grid', paddingRight: '35px', margin: '0px' }} label="Tag" />}
														renderValue={(selected) => selected.join(', ')}
														MenuProps={{
															sx: {
																"&& .Mui-selected": {
																	backgroundColor: "#5f7933",
																	color: "#fff"
																},
															},
															PaperProps: {
																style: {
																	maxHeight: 48 * 4.5 + 8,
																	width: 250,
																},
																sx: {
																	backgroundColor: "#c7d494",
																}
															}
														}}
													>
														{persons.map((value) => (
															<MenuItem key={utils.uuid()} value={value.name}>
																{/* // <MenuItem key={utils.uuid()} value={value.uuid}> */}
																{/* <Checkbox checked={issueToName.indexOf(value.name) > -1 || issueToNameList.indexOf(value.name) > -1} */}
																<Checkbox checked={issueToName.indexOf(value.name) > -1}
																	sx={{
																		color: "#7A9A01",
																		'&.Mui-checked': {
																			color: "#fff",
																		},
																	}}

																/>
																<ListItemText primary={value.name} secondary={value.email}></ListItemText>
															</MenuItem>
														))}
													</ThemeSelect>
												</FormControl>
											</Box>
											:
											null
									}
								</Box>
							</div>
						</div>
					</div>


					<div>
						<Dialog
							fullScreen={true}
							open={openRevocationDialog}
							onClose={handleCloseRevocationDialog}
							aria-labelledby="responsive-dialog-title"
							PaperProps={{ sx: { maxWidth: '600px', maxHeight: '600px' } }}
						>
							<DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: '#c7d494', color: '#7A9A01', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px', fontWeight: 'bold', fontSize: '1.5em' }}>{"Revoke Recommendation"}</DialogTitle>
							<DialogContent sx={{ backgroundColor: '#c7d494' }}>
								{
									<div className="container">
									<Box>
									<div style={{ textAlign: "left",color: '#7A9A01' }}> <b>Comments</b></div>
									<div style={{ paddingTop: "5px", textAlign: "left" }} className="comments-container">
										{/* TextArea Styling Applied in css class (dependand on parent div) */}
										<TextareaAutosize
											aria-label="comments"
											minRows={7}
											value={formData.revoked_comments}
											placeholder="Comments"
											onChange={(event) =>
												setFormData({ ...formData, revoked_comments: event.target.value })
											}
										/>
									</div>
									{
										FormTitle[formAction] === "Edit Recommendation" ?
											<Box sx={{ minWidth: 120 }}>
												<FormControl fullWidth>
													<InputLabel id="email" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size="small">Revoke To</InputLabel>
													<ThemeSelect
														label="Revoke To"
														multiple
														value={revokedToName}
														size="small"
														onChange={handleRevokeToSelectChange}
														input={<OutlinedInput sx={{ display: 'grid', paddingRight: '35px', margin: '0px' }} label="Tag" />}
														renderValue={(selected) => selected.join(', ')}
														MenuProps={{
															sx: {
																"&& .Mui-selected": {
																	backgroundColor: "#5f7933",
																	color: "#fff"
																},
															},
															PaperProps: {
																style: {
																	maxHeight: 48 * 4.5 + 8,
																	width: 250,
																},
																sx: {
																	backgroundColor: "#c7d494",
																}
															}
														}}
													>
														{persons.map((value) => (
															<MenuItem key={utils.uuid()} value={value.name}>
																{/* // <MenuItem key={utils.uuid()} value={value.uuid}> */}
																{/* <Checkbox checked={issueToName.indexOf(value.name) > -1 || issueToNameList.indexOf(value.name) > -1} */}
																<Checkbox checked={revokedToName.indexOf(value.name) > -1 || formData.issued_to.findIndex((element) => element===value.uuid) > -1}
																	sx={{
																		color: "#7A9A01",
																		'&.Mui-checked': {
																			color: "#fff",
																		},
																	}}

																/>
																<ListItemText primary={value.name} secondary={value.email}></ListItemText>
															</MenuItem>
														))}
													</ThemeSelect>
												</FormControl>
											</Box>
											:
											null
									}
								</Box>
										<div className="user-list">
										</div>
									</div>
								}
							</DialogContent>
							<DialogActions sx={{ backgroundColor: '#c7d494' }}>
								<Button
									variant="outlined"
									onClick={
										(e) => {
											setOverlayState("overlay_visible");
											editSprayRecommendation(
											{
												rid: formData.rid,
												citype: ["#12:296"],
												name: !utils.isset(formData.name) ? "New Spray Recommendation" : formData.name,
												organisation: formData.organisation,
												created_by: formData.created_by,
												client: formData.client,
												farm_ref: formData.farm_ref,
												comm_ref: formData.comm_ref,
												// cult_ref: formData.cult_ref,
												appmethod: formData.appmethod,
												zones: JSON.stringify(formData.zones),
												pests: JSON.stringify(formData.pests),
												products: JSON.stringify(formData.products),
												date_due: formData.date_due,
												date_expires: formData.date_expires,
												date_assigned: formData.date_assigned,
												orgadd_1: formData.orgadd_1,
												orgadd_2: formData.orgadd_2,
												orgadd_3: formData.orgadd_3,
												orgphone: formData.orgphone,
												cliadd_1: formData.cliadd_1,
												cliadd_2: formData.cliadd_2,
												cliadd_3: formData.cliadd_3,
												cliphone: formData.cliphone,
												waterrate: formData.waterrate,
												sprayperc: formData.sprayperc,
												totalarea: formData.totalarea,
												sprayarea: formData.sprayarea,
												totalvol: formData.totalvol,
												revoked_comments: formData.revoked_comments,
												note_ref: formData.note_ref,
												uuid: formData.uuid,
												status: 'revoked',
												email: formData.email
											},
											hideForm,
											setOverlayState,
											false,
											formFilters,
											formMeta,
											scrollToTop(),
											setRevokedToName([]));
											setOpenRevocationDialog(false);
										}
										}
									autoFocus
									sx={{ color: '#fff' }}
									style={utils.theme.LoginButton}
								>
									Submit
								</Button>
								<Button
									variant="outlined"
									onClick={
										(e) => {
											setFormData({...formData,revoked_comments:""});
											setFormMeta({...formMeta,issueto:[]});
											setRevokedToName([]);
											setOpenRevocationDialog(false);
										}
									}
									autoFocus
									sx={{ color: '#fff' }}
									style={utils.theme.LoginButton}
								>
									Cancel
								</Button>
							</DialogActions>
						</Dialog>
					</div>



					<div className="footer">
						<button
							onClick={(e) => {
								setOverlayState("overlay_visible");
								switch (e.target.innerText) {
									case "Submit":
										addSprayRecommendation(
											{
												rid: formData.rid,
												citype: ["#12:296"],
												name: !utils.isset(formData.name) ? "New Spray Recommendation" : formData.name,
												organisation: formData.organisation,
												created_by: formData.created_by,
												client: formData.client,
												farm_ref: formData.farm_ref,
												comm_ref: formData.comm_ref,
												// cult_ref: formData.cult_ref,
												appmethod: formData.appmethod,
												zones: JSON.stringify(formData.zones),
												pests: JSON.stringify(formData.pests),
												products: JSON.stringify(formData.products),
												date_due: formData.date_due,
												date_expires: formData.date_expires,
												date_assigned: formData.date_assigned,
												orgadd_1: formData.orgadd_1,
												orgadd_2: formData.orgadd_2,
												orgadd_3: formData.orgadd_3,
												orgphone: formData.orgphone,
												cliadd_1: formData.cliadd_1,
												cliadd_2: formData.cliadd_2,
												cliadd_3: formData.cliadd_3,
												cliphone: formData.cliphone,
												waterrate: formData.waterrate,
												sprayperc: formData.sprayperc,
												totalarea: formData.totalarea,
												sprayarea: formData.sprayarea,
												totalvol: formData.totalvol,
												comments: formData.comments,
												note_ref: formData.note_ref,
												uuid: formData.uuid,
												email: formData.email
											},
											hideForm,
											setOverlayState,
											scrollToTop,
											resetSelectedProgram
										);
										break;
									case "Save":
										editSprayRecommendation(
											{
												rid: formData.rid,
												citype: ["#12:296"],
												name: !utils.isset(formData.name) ? "New Spray Recommendation" : formData.name,
												organisation: formData.organisation,
												created_by: formData.created_by,
												client: formData.client,
												farm_ref: formData.farm_ref,
												comm_ref: formData.comm_ref,
												// cult_ref: formData.cult_ref,
												appmethod: formData.appmethod,
												zones: JSON.stringify(formData.zones),
												pests: JSON.stringify(formData.pests),
												products: JSON.stringify(formData.products),
												date_due: formData.date_due,
												date_expires: formData.date_expires,
												date_assigned: formData.date_assigned,
												orgadd_1: formData.orgadd_1,
												orgadd_2: formData.orgadd_2,
												orgadd_3: formData.orgadd_3,
												orgphone: formData.orgphone,
												cliadd_1: formData.cliadd_1,
												cliadd_2: formData.cliadd_2,
												cliadd_3: formData.cliadd_3,
												cliphone: formData.cliphone,
												waterrate: formData.waterrate,
												sprayperc: formData.sprayperc,
												totalarea: formData.totalarea,
												sprayarea: formData.sprayarea,
												totalvol: formData.totalvol,
												comments: formData.comments,
												note_ref: formData.note_ref,
												uuid: formData.uuid,
												email: formData.email
											},
											hideForm,
											setOverlayState,
											scrollToTop(),
										);
										break;
									default:
										scrollToTop();
										console.warn("Default triggered...");
										hideForm();
										break;
								}
							}}
						>
							{formAction === "add" ? "Submit" : "Save"}
						</button>
						{
							FormTitle[formAction] === "Edit Recommendation" ?
								<button
									onClick={(e) => {
										setOverlayState("overlay_visible");
										editSprayRecommendation(
											{
												rid: formData.rid,
												citype: ["#12:296"],
												name: !utils.isset(formData.name) ? "New Spray Recommendation" : formData.name,
												organisation: formData.organisation,
												created_by: formData.created_by,
												client: formData.client,
												farm_ref: formData.farm_ref,
												comm_ref: formData.comm_ref,
												// cult_ref: formData.cult_ref,
												appmethod: formData.appmethod,
												zones: JSON.stringify(formData.zones),
												pests: JSON.stringify(formData.pests),
												products: JSON.stringify(formData.products),
												date_due: formData.date_due,
												date_expires: formData.date_expires,
												date_assigned: formData.date_assigned,
												orgadd_1: formData.orgadd_1,
												orgadd_2: formData.orgadd_2,
												orgadd_3: formData.orgadd_3,
												orgphone: formData.orgphone,
												cliadd_1: formData.cliadd_1,
												cliadd_2: formData.cliadd_2,
												cliadd_3: formData.cliadd_3,
												cliphone: formData.cliphone,
												waterrate: formData.waterrate,
												sprayperc: formData.sprayperc,
												totalarea: formData.totalarea,
												sprayarea: formData.sprayarea,
												totalvol: formData.totalvol,
												comments: formData.comments,
												note_ref: formData.note_ref,
												uuid: formData.uuid,
												status: 'issued',
												email: formData.email
											},
											hideForm,
											setOverlayState,
											true,
											formFilters,
											formMeta,
											scrollToTop(),
											setIssueToName([])
										);
									}}
								>
									Issue
								</button>
								:
								null
						}
						{
							FormTitle[formAction] === "Edit Recommendation" ?
								<button
									onClick={(e) => {
										// setOverlayState("overlay_visible");
										// editSprayRecommendation(
										// 	{
										// 		rid: formData.rid,
										// 		citype: ["#12:296"],
										// 		name: !utils.isset(formData.name) ? "New Spray Recommendation" : formData.name,
										// 		organisation: formData.organisation,
										// 		created_by: formData.created_by,
										// 		client: formData.client,
										// 		farm_ref: formData.farm_ref,
										// 		comm_ref: formData.comm_ref,
										// 		// cult_ref: formData.cult_ref,
										// 		appmethod: formData.appmethod,
										// 		zones: JSON.stringify(formData.zones),
										// 		pests: JSON.stringify(formData.pests),
										// 		products: JSON.stringify(formData.products),
										// 		date_due: formData.date_due,
										// 		date_expires: formData.date_expires,
										// 		date_assigned: formData.date_assigned,
										// 		orgadd_1: formData.orgadd_1,
										// 		orgadd_2: formData.orgadd_2,
										// 		orgadd_3: formData.orgadd_3,
										// 		orgphone: formData.orgphone,
										// 		cliadd_1: formData.cliadd_1,
										// 		cliadd_2: formData.cliadd_2,
										// 		cliadd_3: formData.cliadd_3,
										// 		cliphone: formData.cliphone,
										// 		waterrate: formData.waterrate,
										// 		sprayperc: formData.sprayperc,
										// 		totalarea: formData.totalarea,
										// 		sprayarea: formData.sprayarea,
										// 		totalvol: formData.totalvol,
										// 		comments: formData.comments,
										// 		note_ref: formData.note_ref,
										// 		uuid: formData.uuid,
										// 		status: 'issued',
										// 		email: formData.email
										// 	},
										// 	hideForm,
										// 	setOverlayState,
										// 	true,
										// 	formFilters,
										// 	formMeta,
										// 	scrollToTop(),
										// 	setIssueToName([])
										// );
										handleRevokeToInit(formData.issued_to)
										handleClickOpenRevocationDialog();
									}}
								>
									Revoke
								</button>
								:
								null
						}
						<button
							onClick={(e) => {
								resetSelectedProgram();
								setIssueToName([]);
								scrollToTop();
								hideForm();
							}}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		)
	} else {
		return (
			<></>
		)
	}
}

export default function SprayRecommendationForm({ hideForm, sprayrecommendationid, formFilters, pageIndex, setPageIndex }) {

	console.warn("formFilters", formFilters);
	//const numPages = 1;
	var organisationsLookupState = utils.getLocalStorageItem("organisationsLookup");
	var farmsLookupState = utils.getLocalStorageItem("farmsLookup");
	var farmCommoditiesLookupState = utils.getLocalStorageItem("farmCommoditiesLookup");
	var cultivarsLookupState = utils.getLocalStorageItem("cultivarsLookup");
	var personsLookupState = utils.getLocalStorageItem("personsLookup");
	var harvestZonesLookupState = utils.getLocalStorageItem("harvestZonesLookup");
	var applicationMethodsLookupState = utils.getLocalStorageItem("applicationMethodsLookup");
	var pestsLookupState = utils.getLocalStorageItem("sprayTargetsLookup");
	var orchardsLookupState = utils.getLocalStorageItem("orchardsLookup");
	var plantSprayProductsLookupState = utils.getLocalStorageItem("plantSprayProductsLookup");
	var sprayProgramsLookupState = utils.getLocalStorageItem("sprayProgramsLookup");
	const { addSprayRecommendation, getSprayRecommendation, editSprayRecommendation, getSprayRecommendationCIType, formErrors, fetchSprayRecommendationPDF, generateSprayRecommendationPDF } = useContext(SprayRecommendationsContext);
	const { getCookieData } = useContext(SessionsContext);
	const { setOverlayState } = useContext(StateManagerContext);
	const FormTitle = {
		add: "Add Recommendation",
		edit: "Edit Recommendation",
	};

	const [formAction, setformAction] = useState("add");
	const [formData, setFormData] = useState({
		rid: "#13:0",
		citype: ["#12:296"],
		name: "New Spay Recommendation",
		organisation: "",
		created_by: getCookieData().useruuid,
		email: "",
		client: "",
		farm_ref: "",
		comm_ref: "",
		// cult_ref: "",
		appmethod: "",
		zones: {},
		pests: {},
		products: [],
		date_due: moment().format("YYYY-MM-DD HH:mm:ss"),
		date_expires: moment().add(7, 'days').format("YYYY-MM-DD HH:mm:ss"),
		date_assigned: moment().format("YYYY-MM-DD HH:mm:ss"),
		orgadd_1: "",
		orgadd_2: "",
		orgadd_3: "",
		orgphone: "",
		cliadd_1: "",
		cliadd_2: "",
		cliadd_3: "",
		cliphone: "",
		waterrate: "",
		sprayperc: 100,
		totalarea: "",
		sprayarea: "",
		totalvol: "",
		comments: "",
		note_ref: "",
		pdfname: "",
		uuid: metaphone(getSprayRecommendationCIType()) + "-" + uuidv4(),
	});
	const [formMeta, setFormMeta] = useState({ issueto: [] });
	const DisplayStep = () => {
		switch (pageIndex) {
			case 0:
				return (
					<Step001
						formData={formData}
						setFormData={setFormData}
						formMeta={formMeta}
						setFormMeta={setFormMeta}
						pageIndex={pageIndex}
						setPageIndex={setPageIndex}
						sprayprograms={sprayProgramsLookupState}
						organisations={organisationsLookupState}
						farms={farmsLookupState}
						cultivars={cultivarsLookupState}
						harvestzones={harvestZonesLookupState}
						commodities={farmCommoditiesLookupState}
						applicationmethods={applicationMethodsLookupState}
						pests={pestsLookupState}
						orchards={orchardsLookupState}
						products={plantSprayProductsLookupState}
						persons={personsLookupState}
						formErrors={formErrors}
						generateSprayRecommendationPDF={generateSprayRecommendationPDF}
						fetchSprayRecommendationPDF={fetchSprayRecommendationPDF}
						editSprayRecommendation={editSprayRecommendation}
						addSprayRecommendation={addSprayRecommendation}
						FormTitle={FormTitle}
						formAction={formAction}
						hideForm={hideForm}
						formFilters={formFilters}
						setOverlayState={setOverlayState}
					/>
				);
			default:
				return false;
		}
	};

	useEffect(() => {

		console.warn("useEffect",sprayrecommendationid,formData);

		if (utils.isempty(sprayrecommendationid)) {
			let tuuid = metaphone(getSprayRecommendationCIType()) + "-" + uuidv4();
			setformAction("add");
			setFormMeta({ issueto: [] });
			setFormData({
				rid: "#13:0",
				citype: ["#12:296"],
				name: "New Spay Recommendation",
				organisation: "",
				created_by: getCookieData().useruuid,
				email: "",
				client: "",
				farm_ref: "",
				comm_ref: "",
				// cult_ref: "",
				appmethod: "",
				zones: {},
				pests: {},
				products: [],
				date_due: moment().format("YYYY-MM-DD HH:mm:ss"),
				date_expires: moment().add(7, 'days').format("YYYY-MM-DD HH:mm:ss"),
				date_assigned: moment().format("YYYY-MM-DD HH:mm:ss"),
				orgadd_1: "",
				orgadd_2: "",
				orgadd_3: "",
				orgphone: "",
				cliadd_1: "",
				cliadd_2: "",
				cliadd_3: "",
				cliphone: "",
				waterrate: "",
				sprayperc: 100,
				totalarea: "",
				sprayarea: "",
				totalvol: "",
				comments: "",
				note_ref: "",
				pdfname: "",
				uuid: tuuid
			});
		} else {
			setformAction("edit");
			setFormMeta({ issueto: [] });
			setFormData(getSprayRecommendation(sprayrecommendationid));
		}
	}, [sprayrecommendationid,getSprayRecommendation,getSprayRecommendationCIType]);//sprayrecommendationid
	return (
		<>
			{DisplayStep()}
		</>
	);
}
