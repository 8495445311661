import React, { useContext } from "react";
import { StateManagerContext } from "../../contexts/statemanager";
import utils from "../../utils/utils";

export function AfterRender (props) {
	const {setOverlayState} = useContext(StateManagerContext);
    var tmp = utils.getLocalStorageItem("renderCounters");
		console.warn("Hiding overlay in AfterRender component.....",tmp);
    // if(tmp.pestTrapInspectionReviewList===0) {
    //     setOverlayState("overlay_hidden");
    // } else {
    //     utils.setLocalStorageItem("renderCounters",tmp.pestTrapInspectionReviewList--, "pestTrapInspectionReviewList");
    // }
    return (
        <>
        </>
    );
}

export default AfterRender;