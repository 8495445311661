import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//import saveAs from 'save-as';
//This to become serverside lookup
var ajaxstack = false;
var def_value = [];

const SprayProgramsReducer = (state, action) => {
	let tsprayprograms = [];
	console.warn("SprayProgramsReducer",action);
	switch (action.type) {
		case "REFRESH_SPRAYPROGRAMS":
			localStorage.setItem("sprayProgramsLookup", JSON.stringify(action.sprayprograms));
			localStorage.setItem("sprayprograms", JSON.stringify(action.sprayprograms));
			return {
				sprayprograms: action.sprayprograms
			};
		case "ADD_SPRAYPROGRAM":
			tsprayprograms = utils.updateArray(state.sprayprograms,action.sprayprogram);
			tsprayprograms = utils.sortArray(tsprayprograms);
			localStorage.setItem("sprayProgramsLookup", JSON.stringify(tsprayprograms));
			localStorage.setItem("sprayprograms", JSON.stringify(tsprayprograms));
			return {
				sprayprograms: tsprayprograms
			};
		case "EDIT_SPRAYPROGRAM":
			utils.each(state.sprayprograms, function (sprayprogram, sprayprogramind) {
				if (sprayprogram.uuid !== action.sprayprogram.uuid) {
					tsprayprograms.push(sprayprogram);
				} else {
					tsprayprograms.push(action.sprayprogram);
				}
			});
			tsprayprograms = utils.sortArray(tsprayprograms);
			localStorage.setItem("sprayProgramsLookup", JSON.stringify(tsprayprograms));
			localStorage.setItem("sprayprograms", JSON.stringify(tsprayprograms));
			return {
				sprayprograms: tsprayprograms
			};
		default:
			return state;
	}
};

export const SprayProgramsContext = createContext();

const SprayProgramsProvider = ({ children }) => {
	const sprayprograms = def_value;
	const [state, dispatch] = useReducer(SprayProgramsReducer, {
		sprayprograms: sprayprograms,
		filters: {}
	});

	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const [formErrors, setFormErrors] = useState({name: "",comm_ref: "", cult_ref: "", appmethod: "" });
	//console.warn("SprayProgramsProvider",formErrors);
	const value = {
		sprayprograms: state.sprayprograms,
		addSprayProgram: (sprayprogram, hideform, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: sprayprogram
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/sprayprogram", params, headers).then(
				function (response) {
					if (response.data.success) {
						sprayprogram = response.data.data;
						dispatch({ type: "ADD_SPRAYPROGRAM", sprayprogram });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		getSprayProgram: (id) => {
			let tsprayprogram = {};
			console.warn("getSprayProgram ",state.sprayprograms);
			utils.each(state.sprayprograms, function (sprayprogram, sprayprogramind) {
				if (sprayprogram.uuid === id) {
					tsprayprogram = utils.extend({},sprayprogram);
					return false;
				}
			});
			return tsprayprogram;
		},
		getSprayPrograms: (filters, setOverlayState, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let sprayProgramFetchRules = utils.getLocalStorageItem("sprayProgramFetchRules");

			// if (!utils.isempty(sprayProgramFetchRules) && !force) {
			// 	if (sprayProgramFetchRules.filtered === false && sprayProgramFetchRules.trycache) {
			// 		let sprayprograms = utils.getLocalStorageItem("sprayprograms");
			// 		if (!utils.isempty(sprayprograms)) {
			// 			dispatch({ type: "REFRESH_SPRAYPROGRAMS", sprayprograms });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	sprayProgramFetchRules = { trycache: true, filtered: true };
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/sprayprograms", params).then(
				function (response) {
					if (response.data.success) {
						var sprayprograms = [];
						utils.each(response.data.data, function (sprayprogram, sprayprogramid) {
							//console.warn('org',org);
							sprayprogram.zones = typeof sprayprogram.zones === 'string' ? JSON.parse(sprayprogram.zones) : sprayprogram.zones;
							sprayprogram.pests = typeof sprayprogram.pests === 'string' ? JSON.parse(sprayprogram.pests) : sprayprogram.pests;
							sprayprogram.products = typeof sprayprogram.products === 'string' ? JSON.parse(sprayprogram.products) : sprayprogram.products;
							sprayprograms.push(sprayprogram);
						});
						if (lookup !== false) {
							//setSprayProgramsLookup(sprayprograms);
							dispatch({ type: "REFRESH_SPRAYPROGRAMS", sprayprograms });
						} else {
							dispatch({ type: "REFRESH_SPRAYPROGRAMS", sprayprograms });
						}
						//responsereceived= true;
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setSprayProgramsLookup([]);
						}
					}
					// sprayProgramFetchRules.filtered = false;
					// localStorage.setItem("sprayProgramFetchRules", JSON.stringify(sprayProgramFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
					if(setOverlayState!==null) { setOverlayState("overlay_hidden"); }
				});
		},
		editSprayProgram: (sprayprogram, hideform, setOverlayState, filters = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: sprayprogram
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/sprayprogram", params, headers).then(
				function (response) {
					console.warn("editSprayProgram",response.data);
					if (response.data.success) {
						setFormErrors({ client: "", farm_ref: "", comm_ref: "", cult_ref: "", appmethod: "", waterrate: "", totalarea: "", sprayarea: "", totalvol: "", organisation: "",context:"" });
						//dispatch({ type: "EDIT_SPRAYPROGRAM", sprayprogram });
						value.getSprayPrograms(filters, setOverlayState, false, false, true);
						// if (!utils.isempty(sprayprogram.uuid) && issue) { value.generateSprayProgramPDF(sprayprogram.uuid, filters, formMeta); }
						hideform();
					} else {
						setOverlayState("overlay_hidden");
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
				});
		},
		// generateSprayProgramPDF: (uuid, filters, formMeta) => {
		// 	const a = utils.new("ajax");
		// 	if (utils.isempty(cookie)) { return false; }
		// 	var params = {
		// 		filters: { uuid: uuid }
		// 	};
		// 	if (formMeta !== false) {
		// 		utils.each(formMeta, function (value, key) {
		// 			params[key] = value;
		// 		});
		// 	}
		// 	var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
		// 	a.put("/sprayprogramgenpdf", params, headers).then(
		// 		function (response) {
		// 			if (response.data.success) {
		// 				value.getSprayPrograms(filters);
		// 			} else {
		// 				if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
		// 					setinvalid();
		// 				} else {
		// 					return response;
		// 				}
		// 			}
		// 		});
		// },
		// fetchSprayProgramPDF: (uuid) => {
		// 	const a = utils.new("ajax");
		// 	if (utils.isempty(cookie)) { return false; }
		// 	var params = {
		// 		headers: { "Authorization": "Bearer " + cookie.token },
		// 		params: { filters: { uuid: uuid } }
		// 	};
		// 	a.get("/sprayprogramfetchpdf", params).then(
		// 		function (response) {
		// 			if (response.data.success) {
		// 				response.data.filename = response.data.filename || "pdf.pdf";

		// 				// Convert from b64:
		// 				var binaryString = window.atob(response.data.data);
		// 				var binaryLen = binaryString.length;
		// 				var bytes = new Uint8Array(binaryLen);
		// 				for (var i = 0; i < binaryLen; i++) {
		// 					var ascii = binaryString.charCodeAt(i);
		// 					bytes[i] = ascii;
		// 				}
		// 				//var newBlob = new Blob([bytes], { type: "application/pdf" });
		// 				saveAs(new Blob([bytes], { type: response.data.mimetype }), response.data.filename);
		// 				bytes = null;

		// 			} else {
		// 				if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
		// 					setinvalid();
		// 				} else {
		// 					return response;
		// 				}
		// 			}
		// 		});
		// },
		// sprayRecommendationsLookupState:sprayRecommendationsLookupState,
		// setSprayProgramsLookup:setSprayProgramsLookup,
		formErrors: formErrors,
		setFormErrors: setFormErrors,
		getSprayProgramCIType: () => {
			return "ci_sprayprogram";
		},
		clearSprayPrograms: () => {
			// setSprayProgramsLookup([]);
			let empty = [];
			dispatch({ type: "REFRESH_SPRAYPROGRAMS", empty });
			localStorage.setItem("sprayProgramsLookup", JSON.stringify(empty));
			localStorage.setItem("sprayprograms", JSON.stringify(empty));
		},
		resetFormErrors: () => {
			setFormErrors({name: "",comm_ref: "", cult_ref: "", appmethod: "" });
		}
	}
	return (
		<SprayProgramsContext.Provider value={value}>
			{children}
		</SprayProgramsContext.Provider>
	);
};
export default SprayProgramsProvider;

