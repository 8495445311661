import React, { useContext } from 'react'
import utils from "../../utils/utils";
import '../../index.css';
import { UpdateCIStatusStateContext } from '../../contexts/updatecistatusstate';

export function UpdateCIStatusConfirm() { //{ hideForm, pesttrapinspectionid }
	const {updateCIState, setUpdateCIState} = useContext(UpdateCIStatusStateContext);
	let tsettings = utils.extend({},updateCIState);
	//const {updateCIState} = useContext(StateManagerContext);
	//console.warn("updateCIState",updateCIState);
	const show = "form-container dialog-loader-2 dialog-loader-2-show";
	const hide = "form-container dialog-loader-2 dialog-loader-2-hidden";
	var showstate = hide;
	if(updateCIState.visibility==="updateci_visible") { showstate = show; }
    return (
			<div className={showstate} >
					<div className="form form-bg">
							<div className="form-container">
									<div className="header">
											<h1>{updateCIState.data.message}</h1>
									</div>
									<div className="body"></div>
									<div className="footer">
											<button
													onClick={(e) => {
														if(utils.isset(updateCIState.data.callBack)) {updateCIState.data.callBack(updateCIState.data.rids,tsettings) }
															// switch (e.target.innerText) {
															// }
													}}
											>
											Submit
											</button>
											<button onClick={(e) => { setUpdateCIState({message:"",data:{rids:false},visibility:"updateci_hidden",}) }}
											>
													Cancel
											</button>
									</div>
							</div>
					</div >
			</div>
    );

}