import _ from 'lodash';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const utils = {
	isempty: function (mixed_var) {
		if (mixed_var === "" || mixed_var === 0 || mixed_var === "0" || mixed_var === null || mixed_var === false || mixed_var === undefined) {
			return true;
		}
		if (typeof mixed_var == 'object') {
			for (var key in mixed_var) {
				return false;
			}
			return true;
		}
		return false;
	},
	uuid: function() {
		return uuidv4();
	},
	parseURL: function(url) {
		var ret = url.replace(/^http(s)?:\/\/(.*?)\//g,"");
		return ret;
	},
	isset: function () {
		var a = arguments, l = a.length, i = 0, undef;
		if (l === 0) {
			throw new Error('Empty isset');
		}
		;
		while (i !== l) {
			if (a[i] === undef || a[i] === null) {
				return false;
			}
			i++;
		}
		;
		return true;
	},
	each: function (a, cb) {
		_.each(a, function (val, idx) {
			return cb(val, idx, a);
		});
	},
	new: function (type) {
		switch (type) {
			case "ajax":
				return axios.create({ baseURL: process.env.REACT_APP_API_URL });
			default:
				break;
		}
	},
	extend: function(destobj,srcobj) {
		return _.extend(destobj,srcobj);
	},
	setCookie: function (cname, cvalue, exdays) {
		var expires = "";
		if (exdays) {
			var d = new Date();
			d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
			expires = "expires=" + d.toUTCString();
		}
		document.cookie = cname + "=" + cvalue + "; " + expires;
	},
	getCookie: function (name) {
		var re = new RegExp(name + "=([^;]+)");
		var value = re.exec(document.cookie);
		return value != null ? unescape(value[1]) : null;
	},
	clearCookie: function (name) {
		document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
	},
	getDate: function(dt,options) {
		//.format("YYYY-MM-DD HH:mm:ss")
		let nowmonth = parseInt(moment().format("MM"));
		let nowyear = parseInt(moment().format("YYYY"));
		if(utils.isset(options.closestMonth)) {
			if(nowmonth<options.closestMonth) {
				let thenyear = nowyear-1;
				let thendate = thenyear+"-"+(options.closestMonth+"").padStart(2,"0")+"-01 00:00:00";
				return thendate;
			} else {
				let thendate = nowyear+"-"+(options.closestMonth+"").padStart(2,"0")+"-01 00:00:00";
				return thendate;
			}
		}
	},
	getName: function (state, lookupval) {
		let ci = state.find((record) => { return record.uuid === lookupval });
		if (utils.isset(ci) && utils.isset(ci.name)) { return ci.name; }
		else { return ''; }
	},
	getRID : function (state, lookupval) {
		let ci = state.find((record) => { return record.uuid === lookupval });
		if (utils.isset(ci) && utils.isset(ci.rid)) { return ci.rid; }
		else { return '#13:0'; }
	},
	toArrayRID: function(obj,attr) {
		if(!utils.isset(obj[attr])) { return obj; }
		let tarray = [];
		utils.each(obj[attr],function(item,itemind){
			if(utils.isset(item.rid)) {
				tarray.push(item.rid);
			}
		});
		obj[attr] = tarray;
		return obj;
	},
	getUUID : function (state, lookupval) {
		let ci = state.find((record) => { return record.rid === lookupval });
		if (utils.isset(ci) && utils.isset(ci.uuid)) { return ci.uuid; }
		else { return utils.uuid(); }
	},
	getRecord : function (state, lookupval) {
		let ci = state.find((record) => { return record.uuid === lookupval });
		if (utils.isset(ci)) { return ci; }
		else { return {}; }
	},
	getRecordByAttr : function (state, lookupval,attr) {
		let ci = state.find((record) => { return record[attr] === lookupval });
		if (utils.isset(ci)) { return ci; }
		else { return {}; }
	},
	getLocalStorageItem(key) {
		let tmp = localStorage.getItem(key);
		let tL = false;
		if(tmp===null) { return null; }
		try {
			tL = JSON.parse(tmp);
		} catch(err) {
			if(tmp==="false") { tmp = false; }
			if(tmp==="true") { tmp = true; }
			tL = tmp;
		}
		return tL;
	},
	route(route="/census") {
		console.warn("SETTING ROUT",route);
		window.location.href = route;
	},
	setFlag(attr,val) {
		this.setLocalStorageItem(attr,val);
	},
	getFlag(attr) {
		let tL = localStorage.getItem(attr);
		if(!this.isset(tL)) {
			return -1;
		} else {
			return tL;
		}
	},
	setAjaxStack(val) {
		this.setLocalStorageItem("AjaxStack",val);
	},
	getAjaxStack() {
		let tL = localStorage.getItem("AjaxStack");
		if(!this.isset(tL)) {
			this.setAjaxStack(0);
			return 0;
		} else {
			tL=parseInt(tL);
			if(isNaN(tL)) {
				this.setAjaxStack(0);
				return 0;
			} else {
				return tL;
			}
		}
	},
	incAjaxStack() {
		let t = this.getAjaxStack();
		t = t+1;
		this.setAjaxStack(t);
		//console.warn("incAjaxStack?? ",this.getAjaxStack());
	},
	decAjaxStack() {
		let t = this.getAjaxStack();
		t = t-1;
		if(t<0) { t=0;}
		this.setAjaxStack(t);
		//console.warn("decAjaxStack?? ",this.getAjaxStack());
	},
	checkAjaxStack() {
		//console.warn("this.getFlag(SwitchContext)",this.getFlag("SwitchContext"),"this.getAjaxStack()",this.getAjaxStack());
		if(this.getFlag("SwitchContext")=="true"&&this.getAjaxStack()==0) {
			this.setFlag("SwitchContext",false);
			this.route();
		}
	},
	setLocalStorageItem(key,value,attr = false) {
		let tL = localStorage.getItem(key);
		//console.warn("key",key,"tL",tL);
		if(this.isset(tL)) {
			try {
				//console.warn("trying to parse tL as json...");
				tL = JSON.parse(tL);
				if(attr!==false && utils.isset(tL[attr])) {
					tL[attr] = value;
					localStorage.setItem(key,JSON.stringify(tL));
					return true;
				} else {
					if(typeof tL!=='object') {
						tL = {};
					}
					//tL[attr] = value;
					//tL[attr] = value;
					localStorage.setItem(key,JSON.stringify(value));
					return true;
				}
			} catch(err) {
				//console.warn("failed to parse tL as json...",key,value);
				localStorage.setItem(key,value);
				return true;
			}
		} else {
			if(attr!==false) {
				//console.warn("setting object value...");
				localStorage.setItem(key,JSON.stringify({attr:value}));
			} else {
				//console.warn("setting string value...",key,value);
				localStorage.setItem(key,value);
			}
		}
		return false;
	},
	ajaxstack: false,
	getCDNFiles: (data,cookie,callback) =>{
		const a = utils.new("ajax");
		if (utils.isempty(cookie)) { return false; }
		let tokenbearer = cookie.token;
		
		if (utils.ajaxstack) { return; }
		else { utils.ajaxstack = true; }

		var params = {
			headers: { "Authorization": "Bearer " + tokenbearer },
			params: { data: data }
		};
		a.get("/cdnfiles", params).then(
			function (response) {
				if (response.data.success) {
					callback(response.data.data);
				} else {
				}
				utils.ajaxstack = false;
			});
	},
	updateArray(thearray,item) {
		let tarray = [];
		this.each(thearray,function(arrayitem,arrayitemind){
			tarray.push(arrayitem);
		});
		tarray.push(item);
		return tarray;
	},
	sortArray(thearray,sort=["name"]) {
		thearray.sort(function(a,b){
			console.warn("a",a,"b",b);
			var prevres = 0;
		  for(let i=0;i<sort.length;i++) {
			let t = a[sort[i]].localeCompare(b[sort[i]]);
				prevres = prevres || t;
			}
		  return prevres;
		});
		return thearray;
	},
	theme: {
		TextField: {
			'& label.Mui-focused': {
				color: '#7A9A01',
			},
			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: '#7A9A01',
				},
				'&:hover fieldset': {
					borderColor: '#5f7933',
				},
				'&.Mui-focused fieldset': {
					borderColor: '#7A9A01',
				},
			},
		},
		FormTextField: {
			// '& label.Mui-focused': {
			// 	color: '#fff',
			// },
			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: '#7A9A01',
				},
				'&:hover fieldset': {
					borderColor: '#5f7933',
				},
				'&.Mui-focused fieldset': {
					borderColor: '#7A9A01',
				},
			},
			'& label.MuiInputLabel-root': {
				color: 'rgba(0, 0, 0, 0.45)',
			},
		},
		LoginButton: {
			backgroundColor: "#7A9A01",
			borderColor: "#7A9A01",
			'&:hover': {
				background: "#f00",
			},
			content: "Sign In",
		},
		LoginButtonWait: {
			backgroundColor: "#7A9A01",
			borderColor: "#7A9A01",
			'&:hover': {
				background: "#f00",
			},
		},
		Button: {
			color: "#7A9A01",
			borderColor: "#7A9A01",
			size: "small",
			'&:hover': {
				color: "#52312d",
				borderColor: "#52312d"
			}
		},
		CarouselButton: {
			color: "#7A9A01",
			borderColor: "#7A9A01",
			size: "small",
			borderRadius: 50,
			'&:hover': {
				color: "#52312d",
				borderColor: "#52312d"
			},
			palette: {
				action: {
				  disabledBackground: 'rgba(0, 0, 0, 0.26)',
				  disabled: 'rgba(0, 0, 0, 0.26)'
				}
			  }
		},
		FormSelect: {
			'& label.Mui-focused': {
				color: '#fff',
			}
		},
		Select: {
			//Input Select Text
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: '#7A9A01',
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: '#5f7933',
			},
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: "#7A9A01",
			},
			"& .MuiSvgIcon-root": {
				color: "#7A9A01",
			},
			'& .MuiInputBase-input': {
				textAlign: "left",
			}
		},
		FormSwitch: {
			'& .MuiSwitch-switchBase.Mui-checked': {
				color: "#7A9A01",
			},
			'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
				backgroundColor: "#7A9A01",
			},
		},
		overlay_visible: {
			'background': 'rgba(0, 0, 0,0.5)',
			'width': '100%',
			'height': '100%',
			'zIndex': '1299',
			'top': '0',
			'left': '0',
			'position': 'fixed',
			'visibility': "visible",
			'display': 'flex',
		},
		overlay_hidden: {
			'background': 'rgba(0, 0, 0,0.5)',
			'width': '100%',
			'height': '100%',
			'zIndex': '1299',
			'top': '0',
			'left': '0',
			'position': 'fixed',
			'visibility': "hidden",
			'display': 'flex',
		},
		updateci_visible: {
			'background': 'rgba(199, 212, 148,0.7)',
			'width': '100%',
			'height': '100%',
			'zIndex': '1298',
			'top': '0',
			'left': '0',
			'position': 'fixed',
			'visibility': "visible",
			'display': 'flex',
			'textAlign' : 'center',
		},
		updateci_hidden: {
			'background': 'rgba(199, 212, 148,0.5)',
			'width': '100%',
			'height': '100%',
			'zIndex': '1298',
			'top': '0',
			'left': '0',
			'position': 'fixed',
			'visibility': "hidden",
			'display': 'flex',
		},
		Slider: {
			"& .MuiSlider-track": {
				color: "#c7d494",
			},
			"& .MuiSlider-thumb": {
				color: "#7a9a01",
			},
			"& .MuiSlider-rail": {
				color: "#c7d494",
			},
			"& .MuiSlider-mark": {
				color: "#7a9a01",
			},
		}
	},
};

export default utils;