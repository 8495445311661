import React, { useContext } from 'react'

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { useStyles } from '../../styles';
import PMZoneList from '../PMZones/PMZoneList';
import PestTrapList from '../PestTraps/PestTrapList';
import { StateManagerContext } from '../../contexts/statemanager';
import utils from '../../utils/utils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '10px 0px 0px 0px' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const PestAndDiseaseComponent = () => {
  // calling useStyles in our component - gives access to the classes
  // we can then apply the class to div
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    utils.setLocalStorageItem("chartType",event.target.innerText);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          TabIndicatorProps={{ style: { background: "#7A9A01" } }}
        >
          <Tab label="PM Zones" {...a11yProps(0)} />
          <Tab label="Traps" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <PMZoneList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PestTrapList />
      </TabPanel>
    </Box>
  )
}

export default PestAndDiseaseComponent