import React, { useContext } from 'react'

//internal
import { SessionsContext } from '../contexts/sessions';

//@material-ui
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#7A9A01',
    color: '#7A9A01',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const StyledAvatar = () => {
  
  const { getCookieData } = useContext(SessionsContext);
  var CD = getCookieData();
  var avatarInitial = CD.name.substring(0, 1);

  return (
    <Box>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
        <Avatar alt="Remy Sharp" sx={{ width: '40px', height: '40px' }}>
        {avatarInitial}
        </Avatar>
      </StyledBadge>
    </Box>
  )
}

export default StyledAvatar;