import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;
const pestTrapsReducer = (state, action) => {
	let tpesttraps = [];
	switch (action.type) {
		case "REFRESH_PESTTRAPS":
			localStorage.setItem("pestTrapsLookup", JSON.stringify(action.pesttraps));
			localStorage.setItem("pesttraps", JSON.stringify(action.pesttraps));
			return {
				pesttraps: action.pesttraps
			};
		case "ADD_PESTTRAP":
			tpesttraps = utils.updateArray(state.pesttraps,action.pesttrap,);
			tpesttraps = utils.sortArray(tpesttraps);
			localStorage.setItem("pestTrapsLookup", JSON.stringify(tpesttraps));
			localStorage.setItem("pesttraps", JSON.stringify(tpesttraps));
			return {
				pesttraps: tpesttraps
			};
		case "EDIT_PESTTRAP":
			utils.each(state.pesttraps, function (pesttrap, pesttrapind) {
				if (pesttrap.uuid !== action.pesttrap.uuid) {
					tpesttraps.push(pesttrap);
				} else {
					tpesttraps.push(action.pesttrap);
				}
			});
			tpesttraps = utils.sortArray(tpesttraps);
			localStorage.setItem("pestTrapsLookup", JSON.stringify(tpesttraps));
			localStorage.setItem("pesttraps", JSON.stringify(tpesttraps));
			return {
				pesttraps: tpesttraps
			};
		default:
			return state;
	}
};

export const PestTrapsContext = createContext();

const PestTrapsProvider = ({ children }) => {
	const pesttraps = def_value;
	const [state, dispatch] = useReducer(pestTrapsReducer, {
		pesttraps: pesttraps,
		filters: {}
	});
	// let ts = localStorage.getItem("pestTraps");
	// const [pestTrapsLookupState, setPestTrapsLookup] = useState((utils.isset(ts) && !utils.isempty(ts) ? JSON.parse(ts) : []));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const [formErrors, setFormErrors] = useState({ name: "", farm: "", block: "", zone: "", pest: "", traptype: "", pheromone: "", latitude: 0, longitude: 0, hasnull: 0, hashousingcheck: 0, hasstickingcheck: 0, rebait: 0 });

	const value = {
		pesttraps: state.pesttraps,
		addPestTrap: (pesttrap, hideform, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pesttrap
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/pesttrap", params, headers).then(
				function (response) {
					if (response.data.success) {
						pesttrap = response.data.data;
						dispatch({ type: "ADD_PESTTRAP", pesttrap });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		getPestTrap: (id) => {
			let tpesttrap = {};
			utils.each(state.pesttraps, function (pesttrap, pesttrapind) {
				if (pesttrap.uuid === id) {
					tpesttrap = pesttrap;
					return false;
				}
			});
			return tpesttrap;
		},
		getPestTraps: (filters, lookup = false, ttoken = false, force = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let pestTrapsFetchRules = utils.getLocalStorageItem("pestTrapsFetchRules");
			
			// if (!utils.isempty(pestTrapsFetchRules) && !force) {
			// 	if (pestTrapsFetchRules.filtered === false&&pestTrapsFetchRules.trycache) {
			// 		let pesttraps = utils.getLocalStorageItem("pesttraps");
			// 		if(!utils.isempty(pesttraps)) {
			// 			dispatch({ type: "REFRESH_PESTTRAPS", pesttraps });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	pestTrapsFetchRules = {trycache:true,filtered:true};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/pesttraps", params).then(
				function (response) {
					if (response.data.success) {
						var pesttraps = [];
						utils.each(response.data.data, function (pesttrap, pesttrapid) {
							pesttraps.push(pesttrap);
						});
						if (lookup !== false) {
							// setPestTrapsLookup(pesttraps);
							dispatch({ type: "REFRESH_PESTTRAPS", pesttraps });
						} else {
							dispatch({ type: "REFRESH_PESTTRAPS", pesttraps });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setPestTrapsLookup([]);
						}
					}
					// pestTrapsFetchRules.filtered = false;
					// localStorage.setItem("pestTrapsFetchRules", JSON.stringify(pestTrapsFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editPestTrap: (pesttrap, hideform, filters, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pesttrap
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/pesttrap", params, headers).then(
				function (response) {
					if (response.data.success) {
						setFormErrors({ name: "", farm: "", block: "", zone: "", pest: "", traptype: "", pheromone: "", latitude: 0, longitude: 0, hasnull: 0, hashousingcheck: 0, hasstickingcheck: 0, rebait: 0 });
						value.getPestTraps(filters, false, false, true);
						//dispatch({ type: "EDIT_PESTTRAP", pesttrap });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		// pestTrapsLookupState: pestTrapsLookupState,
		// setPestTrapsLookup: setPestTrapsLookup,
		formErrors: formErrors,
		setFormErrors: setFormErrors,
		getPestTrapCIType: () => {
			return "ci_pesttrap";
		},
		clearPestTraps: () => {
			//setPestTrapsLookup([]);
			let empty = [];
			dispatch({ type: "REFRESH_PESTTRAPS", empty });
			localStorage.setItem("pestTrapsLookup", JSON.stringify(empty));
			localStorage.setItem("pesttraps", JSON.stringify(empty));
		},
		resetFormErrors: () => {
			setFormErrors({ name: "", farm: "", block: "", zone: "", pest: "", traptype: "", pheromone: "", latitude: 0, longitude: 0, hasnull: 0, hashousingcheck: 0, hasstickingcheck: 0, rebait: 0 });
		}
	}
	return (
		<PestTrapsContext.Provider value={value}>
			{children}
		</PestTrapsContext.Provider>
	);
};
export default PestTrapsProvider;

