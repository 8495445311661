import React, { useEffect, useState, useContext } from "react";
import utils from "../../utils/utils";
import { metaphone } from "metaphone";
import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from '@mui/material';
import Select from "@mui/material/Select";
import OutlinedInput from '@mui/material/OutlinedInput';
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { SprayProgramsContext } from "../../contexts/sprayprograms";
import { SessionsContext } from "../../contexts/sessions";
import "../../index.css";
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import { StateManagerContext } from '../../contexts/statemanager';

// Theme
const ThemeFormTextField = styled(TextField)(utils.theme.FormTextField)
const ThemeSelect = styled(Select)(utils.theme.Select);
const ThemeTextField = styled(TextField)(utils.theme.TextField);
const uomdata = {
	"--": {
		"perlitre": 1, // 
		"qtyfactor": 1, // Conversion factor to get to "L"
		"qtyunit": "L", // 
		"rateunit": "L", // 
		"ignorewater": 1,
		"multiplier": 0,
	},
	"ml/100L": {// ( ( {RATE} / {qtyfactor} ) / {perlitre} ) = DOSAGE/LITRE; {WATERRATE} * {DOSAGE} 
		"perlitre": 100, // 
		"qtyfactor": 1000, // Conversion factor to get to "L"
		"qtyunit": "L", // Conversion factor to get to "L"
		"rateunit": "ml", // Conversion factor to get to "L"
		"ignorewater": 0,
		"multiplier": 1,
	}, // Total volume of wter: WATERRATE * SIZE, * DOSAGE
	"L/100L": {// ( ( {RATE} / {qtyfactor} ) / {perlitre} ) = DOSAGE/LITRE; {WATERRATE} * {DOSAGE} 
		"perlitre": 100, // 
		"qtyfactor": 1, // Conversion factor to get to "L"
		"qtyunit": "L", // Conversion factor to get to "L"
		"rateunit": "L",
		"ignorewater": 0,
		"multiplier": 1,
	},
	"mg/100L": {
		"perlitre": 100,
		"qtyfactor": 1000,
		"qtyunit": "g",
		"rateunit": "mg",
		"ignorewater": 0,
		"multiplier": 1,
	},
	"g/100L": {
		"perlitre": 100,
		"qtyfactor": 1000,
		"qtyunit": "kg",
		"rateunit": "g",
		"ignorewater": 0,
		"multiplier": 1,
	},
	"kg/1000L": {
		"perlitre": 1000,
		"qtyfactor": 1,
		"qtyunit": "kg",
		"rateunit": "kg",
		"ignorewater": 0,
		"multiplier": 1,
	},
	"kg/100L": {
		"perlitre": 100,
		"qtyfactor": 1,
		"qtyunit": "kg",
		"rateunit": "g",
		"ignorewater": 0,
		"multiplier": 1,
	},
	"g/ha": {
		"perlitre": 1,
		"qtyfactor": 1000,
		"qtyunit": "kg",
		"rateunit": "g",
		"ignorewater": 1,
		"multiplier": 1,
	},
	"kg/ha": {
		"perlitre": 1,
		"qtyfactor": 1,
		"qtyunit": "kg",
		"rateunit": "kg",
		"ignorewater": 1,
		"multiplier": 1,
	},
	"ml/ha": {
		"perlitre": 1,
		"qtyfactor": 1000,
		"qtyunit": "L",
		"rateunit": "ml",
		"ignorewater": 1,
		"multiplier": 1,
	},
	"L/ha": {
		"perlitre": 1,
		"qtyfactor": 1,
		"qtyunit": "L",
		"rateunit": "L",
		"ignorewater": 1,
		"multiplier": 1,
	},
	"units/ha": {
		"perlitre": 1,
		"qtyfactor": 1,
		"qtyunit": "units",
		"rateunit": "units",
		"ignorewater": 1,
		"ignoretrv": 1,
		"isperha": 1,
		"multiplier": 1,
	},
	"units/100L": {
		"perlitre": 1,
		"qtyfactor": 1,
		"qtyunit": "units",
		"rateunit": "units",
		"ignorewater": 1,
		"ignoretrv": 1,
		"isperha": 1,
		"multiplier": 1,
	},
	"units/1000L": {
		"perlitre": 1,
		"qtyfactor": 1,
		"qtyunit": "units",
		"rateunit": "units",
		"ignorewater": 1,
		"ignoretrv": 1,
		"isperha": 1,
		"multiplier": 1,
	}
};

export function Step001({ formData, setFormData, formMeta, setFormMeta, pageIndex, setPageIndex, organisations, farms, cultivars, harvestzones, commodities, applicationmethods, pests, orchards, products, persons, formErrors, generateSprayRecommendationPDF, fetchSprayRecommendationPDF, editSprayProgram, addSprayProgram, FormTitle, formAction, hideForm, formFilters, setOverlayState,contextGroups }) {
	var zonedialogdata = [];
	const { getCookieData } = useContext(SessionsContext);
	if (utils.isset(formData.products)) {
		formData.products = typeof formData.products === 'string' ? JSON.parse(formData.products) : formData.products;
	}
	if (utils.isset(formData.pests)) {
		formData.pests = typeof formData.pests === 'string' ? JSON.parse(formData.pests) : formData.pests;
	}
	if (utils.isset(formData.zones)) {
		utils.each(harvestzones, function (harvestzone, harvestzoneind) {
			if (!utils.isset(harvestzone.farm) || !utils.isset(harvestzone.cultivar_name)) { return true; }
			let farmname = utils.getName(farms, harvestzone.farm);
			let farmind = zonedialogdata.findIndex(farm => farm.uuid === harvestzone.farm);
			if (farmind === -1) {
				zonedialogdata.push({ uuid: harvestzone.farm, name: farmname, selected: 'none', cultivars: [] });
				farmind = zonedialogdata.length - 1;
			}

			let cultivarname = utils.getName(cultivars, harvestzone.cultivar_name);
			let cultivarind = zonedialogdata[farmind].cultivars.findIndex(cultivar => cultivar.uuid === harvestzone.cultivar_name);
			if (cultivarind === -1) {
				zonedialogdata[farmind].cultivars.push({ uuid: harvestzone.cultivar_name, name: cultivarname, selected: 'none', zones: [] });
				cultivarind = zonedialogdata[farmind].cultivars.length - 1;
			}

			if (zonedialogdata[farmind].cultivars[cultivarind].zones.findIndex(zone => zone.uuid === harvestzone.uuid) === -1) {
				zonedialogdata[farmind].cultivars[cultivarind].zones.push({ uuid: harvestzone.uuid, selected: 'none', name: harvestzone.name });

				zonedialogdata[farmind].cultivars.sort(function (a, b) { return a.name.localeCompare(b.name); });
				zonedialogdata[farmind].cultivars[cultivarind].zones.sort(function (a, b) { return a.name.localeCompare(b.name); });
			}
		});
		var selectedtallies = {};
		formData.zones = typeof formData.zones === 'string' ? JSON.parse(formData.zones) : formData.zones;
		utils.each(formData.zones, function (fdzone, fdzoneind) {
			let farmind = zonedialogdata.findIndex(farm => farm.uuid === utils.getUUID(farms, fdzone.farm_id));
			let cultivarind = zonedialogdata[farmind].cultivars.findIndex(cultivar => cultivar.uuid === utils.getUUID(cultivars, fdzone.cultivar_id));
			if (!utils.isset(selectedtallies["" + farmind])) {
				selectedtallies["" + farmind] = { farmcount: 0, cultivarcounts: {} };
			}
			selectedtallies["" + farmind].farmcount++;
			if (!utils.isset(selectedtallies["" + farmind].cultivarcounts["" + cultivarind])) {
				selectedtallies["" + farmind].cultivarcounts["" + cultivarind] = 0;
			}
			selectedtallies["" + farmind].cultivarcounts["" + cultivarind]++;
			let zoneind = zonedialogdata[farmind].cultivars[cultivarind].zones.findIndex(tzone => tzone.uuid === utils.getUUID(harvestzones, fdzone.rid));
			zonedialogdata[farmind].cultivars[cultivarind].zones[zoneind].selected = 'full';
		});

		utils.each(selectedtallies, function (farmval, farmind) {
			farmind = parseInt(farmind);
			let farmzonecount = 0;
			utils.each(farmval.cultivarcounts, function (cultivarval, cultivarind) {
				cultivarind = parseInt(cultivarind);
				farmzonecount += zonedialogdata[farmind].cultivars[cultivarind].zones.length;
				if (zonedialogdata[farmind].cultivars[cultivarind].zones.length === cultivarval) {
					zonedialogdata[farmind].cultivars[cultivarind].selected = 'full';
				} else if (cultivarval > 0) {
					zonedialogdata[farmind].cultivars[cultivarind].selected = 'partial';
				} else {
					zonedialogdata[farmind].cultivars[cultivarind].selected = 'none';
				}
			});
			if (farmval.farmcount === farmzonecount) {
				zonedialogdata[farmind].selected = 'full';
			} else if (farmval.farmcount > 0) {
				zonedialogdata[farmind].selected = 'partial';
			} else {
				zonedialogdata[farmind].selected = 'none';
			}
		});
		zonedialogdata.sort(function (a, b) {
			return a.name.localeCompare(b.name);
		})
	}

	var tcultivarArr = [];

	// PART 2 OF FORM /////////////////////////////////////////////////////////////////////////////////////
	const [openInterventionDialog, setOpenInterventionDialog] = useState(false);
	const [openContextDialog, setOpenContextDialog] = useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const handleClickOpenInterventionDialog = () => {
		setOpenInterventionDialog(true);
	};
	const handleCloseInterventionDialog = () => {
		setOpenInterventionDialog(false);
	};

	const handleClickOpenContextDialog = () => {
		setOpenContextDialog(true);
	};
	const handleCloseContextDialog = () => {
		setOpenContextDialog(false);
	};

	const [pestName, setPestName] = useState([]);
	const handleProblemSelectChange = (event) => {
		var tpests = typeof event.target.value === 'string' ? event.target.value.split(",") : event.target.value;
		var tmp = {};
		var tpestName = [];
		utils.each(tpests, function (tpest, tpestind) {
			if(typeof tpest == 'undefined') { return true; }
			let pestind = pests.findIndex(p => p.name === tpest.trim());
			if (pestind !== -1) {
				if (!utils.isset(tmp[pests[pestind].rid])) { tmp[pests[pestind].rid] = { rid: "", name: "" }; }
				tmp[pests[pestind].rid].rid = pests[pestind].rid;
				tmp[pests[pestind].rid].name = pests[pestind].name;
				tpestName.push(pests[pestind].name);
			}
		});
		//console.warn("tpestName",tpestName);
		setFormData({ ...formData, pests: tmp });
		setPestName(tpestName);
	};

	function updateProductCalcs(item) {
		var uom = uomdata[item.rateunit];
		item.rate = isNaN(parseFloat(item.rate)) ? 0 : parseFloat(item.rate);
		var crate = item.rate;
		var dosage = 1;
		crate = (item.rate / uom.qtyfactor);// / uom.perlitre;
		dosage = crate / uom.perlitre;// GET DOSAGE PER LITRE
		if (!utils.isempty(uom.ignorewater || parseInt(uom.ignorewater) === 1)) {// Ignore the water rate:
			item.qty = dosage * formData.sprayarea * uom.multiplier;
		} else {
			item.qty = dosage * formData.totalvol * uom.multiplier;
		}
		item.qty = parseFloat(item.qty.toFixed(3));
		item.unitcost = parseFloat(item.unitcost);
		item.totalcost = item.unitcost * item.qty;
		item.totalcost = parseFloat(item.totalcost.toFixed(2));
		item.costha = 0;
		if (formData.sprayarea > 0) {
			item.costha = item.totalcost / formData.sprayarea;
		}
		item.costha = parseFloat(item.costha.toFixed(2));

		item.unitofmeasure = uom.qtyunit;
		return item;
	};

	function updateProduct(prodind, attr, value) {
		var tproducts = formData.products;
		tproducts = typeof tproducts === 'string' ? JSON.parse(tproducts) : tproducts;
		switch (attr) {
			case "whp":
				value = parseFloat(value);
				value = isNaN(value) ? 0 : value;
				break;
			case "rateunit":
				value = ""+value;
				break;
			default:
				break;
		}
		tproducts[prodind][attr] = value;

		tproducts[prodind] = updateProductCalcs(tproducts[prodind]);
		setFormData({ ...formData, products: tproducts });
	}

	function updateGeneral(attr, value) {
		var tformData = formData;
		switch (attr) {
			case "sprayperc":
			case "waterrate":
				value = parseFloat(value);
				value = isNaN(value) ? 0 : value;
				break;
			default:
				break;
		}
		tformData[attr] = value;
		tformData.sprayarea = tformData.sprayperc / 100 * tformData.totalarea;
		tformData.totalvol = tformData.sprayarea * tformData.waterrate;
		utils.each(tformData.products, function (product, productind) {
			tformData.products[productind] = updateProductCalcs(product);
		});
		//console.warn("updateGeneral tformData",tformData);
		setFormData({ ...formData, tformData });
	}

	var pestNameList = [];
	if (!utils.isempty(formData.pests)) {
		utils.each(formData.pests, function (fpest) {
			pestNameList.push(fpest.name);
		});
	}

	// the value of the search field 
	const [productName, setProductName] = useState("");
	// the search result
	const [foundProducts, setFoundProducts] = useState(products);
	const filter = (e) => {
		const keyword = e.target.value;
		if (keyword !== '') {
			const results = products.filter((product) => {
				return product.name.toLowerCase().startsWith(keyword.toLowerCase());
			});
			setFoundProducts(results);
		} else {
			setFoundProducts(products);
		}
		setProductName(keyword);
	};

	const [contextName, setContextName] = useState("");
	// the search result
	const [foundContexts, setFoundContexts] = useState(contextGroups);
	const filterContext = (e) => {
		const keyword = e.target.value;
		if (keyword !== '') {
			const results = contextGroups.filter((contextgroup) => {
				return contextgroup.name.toLowerCase().startsWith(keyword.toLowerCase());
			});
			setFoundContexts(results);
		} else {
			setFoundContexts(contextGroups);
		}
		setContextName(keyword);
	};
	//console.warn("foundProducts",foundProducts);
	//console.warn("foundContexts",foundContexts);
	//console.warn("formData",formData);
	if (!utils.isempty(formData)) {
		return (
			<div className="form form-bg recommendations-form-bg">
				<div className="form-container">
					<div className="header">
						<h1 style={{ color: "#7A9A01" }}>{FormTitle[formAction]}</h1>
					</div>
					<div className="body">
						<div className="step001-container">
							<div className="recomendation-form-container">
								<Box sx={{ flexGrow: 1 }}>
									<Box sx={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
										<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', marginLeft: '5px' }}>
											<Box sx={{ display: 'flex', flex: 1, margin: '0px !important', paddingBottom: '10px' }}>
												<FormControl fullWidth error={!utils.isempty(formErrors.cult_ref) ? true : false}>
													<InputLabel id="cult_ref" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size='small'>Cultivar Ref</InputLabel>
													<ThemeSelect
														label="Cultivar Ref"
														size='small'
														value={formData.cult_ref}
														onChange={(event) => {
															setFormData({ ...formData, cult_ref: event.target.value });
														}}
														MenuProps={{
															sx: {
																"&& .Mui-selected": {
																	backgroundColor: "#5f7933",
																	color: "#fff"
																},
															},
															PaperProps: {
																style: {
																	maxHeight: 48 * 4.5 + 8,
																	width: 250,
																},
																sx: {
																	backgroundColor: "#c7d494",
																}
															}
														}}
													>

														{
															harvestzones.map((value, ind) => {
																if (!utils.isempty(formData.farm_ref) && formData.farm_ref === harvestzones.farm) {
																	tcultivarArr.push(value)
																}
																if (tcultivarArr.findIndex(temp => temp.uuid === value.cultivar_name) === -1) {
																	tcultivarArr.push({ uuid: value.cultivar_name, name: utils.getName(cultivars, value.cultivar_name) });
																}
																return true;
															})

														}

														{tcultivarArr.map((value, ind) => {
															return (
																<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
															);
														})}
													</ThemeSelect>
													<FormHelperText>{formErrors.cult_ref}</FormHelperText>
												</FormControl>
											</Box>

											<Box sx={{ display: 'flex', flex: 1, margin: '0px !important', paddingBottom: '10px' }}>
												<FormControl fullWidth error={!utils.isempty(formErrors.comm_ref) ? true : false}>
													<InputLabel id="comm_ref" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size='small'>Commodity Ref</InputLabel>
													<ThemeSelect
														label="Commodity Ref"
														size='small'
														value={formData.comm_ref}
														onChange={(event) => {
															setFormData({ ...formData, comm_ref: event.target.value });
														}}
														MenuProps={{
															sx: {
																"&& .Mui-selected": {
																	backgroundColor: "#5f7933",
																	color: "#fff"
																},
															},
															PaperProps: {
																style: {
																	maxHeight: 48 * 4.5 + 8,
																	width: 250,
																},
																sx: {
																	backgroundColor: "#c7d494",
																}
															}
														}}
													>
														{commodities.map((value, ind) => {
															return (
																<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
															);
														})}
													</ThemeSelect>
													<FormHelperText>{formErrors.comm_ref}</FormHelperText>
												</FormControl>
											</Box>

											<Box sx={{ display: 'flex', flex: 1, margin: '0px !important', paddingBottom: '10px' }}>
													<ThemeFormTextField
														type="text"
														id="name"
														label="Name"
														placeholder="Name"
														size='small'
														fullWidth
														sx={{ margin: '0px !important' }}
														value={formData.name}
														error={!utils.isempty(formErrors.name) ? true : false}
														helperText={formErrors.name}
														onChange={(event) =>
															setFormData({ ...formData, name: event.target.value })
														}
													/>
											</Box>
											<Box sx={{ display: 'flex', flex: 1, margin: '0px !important' }}>
													<ThemeFormTextField
														type="text"
														id="number"
														label="Number"
														placeholder="Number"
														size='small'
														fullWidth
														sx={{ margin: '0px !important' }}
														value={formData.number}
														error={!utils.isempty(formErrors.number) ? true : false}
														helperText={formErrors.number}
														onChange={(event) =>
															setFormData({ ...formData, number: event.target.value })
														}
													/>
											</Box>
										</Box>
									</Box>
								</Box>
								<Box>
									<div>SELECT PROBLEMS</div>
									<FormControl sx={{ m: 0, width: "100%", marginTop: '5px' }}>
										<InputLabel id="pests" sx={{ boxSizing: 'border-box', color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size='small'>Reasons</InputLabel>
										<ThemeSelect
											label="Reasons"
											id="pests"
											size="small"
											multiple
											value={pestNameList}
											onChange={handleProblemSelectChange}
											input={<OutlinedInput sx={{ display: 'grid', paddingRight: '35px', margin: '0px' }} label="Tag" />}
											renderValue={(selected) => selected.join(', ')}
											MenuProps={{
												sx: {
													"&& .Mui-selected": {
														backgroundColor: "#5f7933",
														color: "#fff"
													},
												},
												PaperProps: {
													style: {
														maxHeight: 48 * 4.5 + 8,
														width: 250,
													},
													sx: {
														backgroundColor: "#c7d494",
													},
												},
											}}
										>
											{pests.map((value) => (
												<MenuItem key={value.uuid} value={value.name}>
													<Checkbox
														checked={pestName.indexOf(value.name) > -1 || pestNameList.indexOf(value.name) > -1}
														sx={{
															color: "#7A9A01",
															'&.Mui-checked': {
																color: "#fff",
															},
														}}
													/>
													<ListItemText primary={value.name}></ListItemText>
												</MenuItem>
											))}
										</ThemeSelect>
									</FormControl>
								</Box>

								<Box>
									<div>SELECT INTERVENTION</div>
									<Button
										sx={{ width: 1, marginBottom: '10px', marginTop: '5px' }}
										variant="contained"
										size="small"
										onClick={handleClickOpenInterventionDialog}
										disableElevation
										style={utils.theme.LoginButton}
									>
										Click to select
									</Button>
									<div>
										<Dialog
											fullScreen={fullScreen}
											open={openInterventionDialog}
											onClose={handleCloseInterventionDialog}
											aria-labelledby="responsive-dialog-title"
											PaperProps={{ sx: { minWidth: '600px' } }}
										>
											<DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: '#c7d494', color: '#7A9A01', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px', fontWeight: 'bold', fontSize: '1.5em' }}>{"Select Product"}</DialogTitle>
											<DialogContent sx={{ backgroundColor: '#c7d494' }}>
												{
													<div className="container">
														<ThemeTextField
															type="search"
															value={productName}
															size="small"
															onChange={filter}
															placeholder="Search Product"
															sx={{ width: '100%' }}
															InputProps={{
																startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
															}}
														/>

														<div className="user-list">
															{foundProducts && foundProducts.length > 0 ? (
																foundProducts.map((item) => {
																	var comm = commodities.find(tcom => tcom.uuid === formData.comm_ref);
																	item.context_data = typeof item.context_data === 'string' ? JSON.parse(item.context_data) : item.context_data;
																	//if (!utils.isset(comm)) { return null; } 
																	//if (!utils.isset(item.context_data.commodity) || !utils.isset(item.context_data.commodity[comm.ref])) { return null; }
																	var checkedindex = false;
																	checkedindex = formData.products.length===0||formData.products.findIndex(fp => fp.uuid === item.uuid) === -1 ? false : true;
																	//console.warn("checkedindex",checkedindex,"item",item);
																	return (
																		<FormGroup key={item.id} sx={{ marginLeft: '40px', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap' }}>
																			<div className="nexus-checkbox">
																				<label>
																					<input
																						type="checkbox"
																						className="custom-control-input"
																						checked={checkedindex}
																						productuuid={item.uuid}
																						onChange={(event) => {
																							let tproduct = products.find(prod => prod.uuid === event.target.attributes.productuuid.value);
																							tproduct = utils.extend({},tproduct);//create a copy of the product so that the original one does not get modified
																							if (!utils.isset(tproduct)) { return null; }
																							//console.warn("products",products);
																							//console.warn("tproduct",tproduct);
																							let ctdata = tproduct.context_data;
																							let ctdatacomm = ctdata.commodity;
																							//let uom = uomdata[item.rateunit];
																							delete tproduct.contextref;
																							delete tproduct.status;
																							delete tproduct.type;
																							delete tproduct.context_data;
																							tproduct.unitcost = tproduct.stdcost;
																							delete tproduct.stdcost;
																							tproduct.qty = ctdata.qtyperunit;

																							// tproduct.trv = "100";
																							// if(!utils.isset(tproduct.rateunit)||utils.isempty(tproduct.rateunit)) {
																							// 	tproduct.rateunit = "ml/100L";
																							// }
																							if (!utils.isset(tproduct.whp)) {
																								tproduct.whp = "";
																							}
																							tproduct.totalcost = tproduct.unitcost * tproduct.qty;
																							// tproduct.costha = totalcost/sprayarea

																							//Update to make use of utils each to make sure code stays consistent
																							if(utils.isset(tproduct.context_data)&&!utils.isempty(tproduct.context_data)) {
																								Object.entries(ctdatacomm).map((value, index) => {
																									if (utils.isset(comm) && value[0] === comm.ref) {
																										Object.entries(value[1]).map((val, idx) => {
																											if (val[0] === "defwhp") {
																												tproduct.whp = val[1]
																											}
																											if (val[0] === "rate") {
																												tproduct.rate = val[1]
																											}
																											if (val[0] === "rateunit") {
																												tproduct.rateunit = val[1]
																											}
																											return true;
																										});
																									}
																									return true;
																								});
																							} else {

																							}

																							let tproducts = utils.extend([],formData.products);
																							tproducts = typeof tproducts === 'string' ? JSON.parse(tproducts) : tproducts;
																							let tindex = tproducts.findIndex(prod => prod.uuid === tproduct.uuid);

																							if (event.target.checked) {
																								if (tindex === -1) {
																									tproducts.push(tproduct);
																								}
																							}
																							if (!event.target.checked && tindex !== -1) {
																								tproducts.splice(tindex, 1);
																							}
																							setFormData({ ...formData, products: tproducts });
																						}}
																					/>
																					<span className="checkbox-material">
																						<span className="check"></span>
																					</span>
																				</label>
																			</div>
																			<Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
																				<div>{item.name}</div>
																				<div style={{ fontSize: '13px', color: '#919191' }}>{item.active_ing}</div>
																			</Box>
																		</FormGroup>
																	);
																})
															) : (
																<h3>No results for this product found!</h3>
															)}
														</div>
													</div>
												}
											</DialogContent>
											<DialogActions sx={{ backgroundColor: '#c7d494' }}>
												<Button
													variant="outlined"
													onClick={handleCloseInterventionDialog}
													autoFocus
													sx={{ color: '#fff' }}
													style={utils.theme.LoginButton}
												>
													Done
												</Button>
											</DialogActions>
										</Dialog>
									</div>

									<div className="recommendation-form-intervention-table">
										<table>
											<thead>
												<tr>
													<th>Product</th>
													<th>WHP</th>
													<th>Rate</th>
													<th>Unit</th>
													<th>UOM</th>
													<th>TRV</th>
													{/* <th>Cost</th> */}
													{/* <th>Total</th><th>R/ha</th> */}
												</tr>
											</thead>
											<tbody>
												{
													formData.products.length > 0 ?
														formData.products.map((value, prodind) => {
															return (
																<>
																	<tr>
																		<td>{value.name}</td>
																		<td>
																			<ThemeTextField
																				value={value.whp}
																				sx={{ maxWidth: '180px' }}
																				size="small"
																				onChange={(event) => {
																					updateProduct(prodind, "whp", event.target.value);
																				}}
																			/>
																		</td>
																		<td>
																			<ThemeTextField
																				type="number"
																				value={value.rate}
																				sx={{ maxWidth: '180px' }}
																				size="small"
																				inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
																				onChange={(event) => {
																					updateProduct(prodind, "rate", event.target.value);
																				}}
																				onFocus = {(event) => { event.target.select(); }}
																			/>
																		</td>
																		<td>
																			<Box sx={{ minWidth: 120 }}>
																				<FormControl fullWidth>
																					<InputLabel id="rateunit" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }}>Rate Unit</InputLabel>
																					<ThemeSelect
																						label="Rate Unit"
																						value={value.rateunit}
																						size="small"
																						onChange={(event) => {
																							updateProduct(prodind, "rateunit", event.target.value);
																						}}
																						MenuProps={{
																							sx: {
																								"&& .Mui-selected": {
																									backgroundColor: "#5f7933",
																									color: "#fff"
																								},
																							},
																							PaperProps: {
																								sx: {
																									backgroundColor: "#c7d494",
																								}
																							}
																						}}
																					>
																						<MenuItem value={"--"} prodind={prodind}> - </MenuItem>
																						<MenuItem value={"ml/100L"} prodind={prodind}>ml/100L</MenuItem>
																						<MenuItem value={"L/100L"} prodind={prodind}>L/100L</MenuItem>
																						<MenuItem value={"mg/100L"} prodind={prodind}>mg/100L</MenuItem>
																						<MenuItem value={"g/100L"} prodind={prodind}>g/100L</MenuItem>
																						<MenuItem value={"kg/100L"} prodind={prodind}>kg/100L</MenuItem>
																						<MenuItem value={"kg/1000L"} prodind={prodind}>kg/1000L</MenuItem>
																						<MenuItem value={"units/100L"} prodind={prodind}>units/100L</MenuItem>
																						<MenuItem value={"units/1000L"} prodind={prodind}>units/1000L</MenuItem>
																						<MenuItem value={"g/ha"} prodind={prodind}>g/ha</MenuItem>
																						<MenuItem value={"kg/ha"} prodind={prodind}>kg/ha</MenuItem>
																						<MenuItem value={"ml/ha"} prodind={prodind}>ml/ha</MenuItem>
																						<MenuItem value={"L/ha"} prodind={prodind}>L/ha</MenuItem>
																						<MenuItem value={"units/ha"} prodind={prodind}>units/ha</MenuItem>
																					</ThemeSelect>
																				</FormControl>
																			</Box>
																		</td>
																		<td>{value.unitofmeasure}</td>
																		<td>
																			<Box sx={{ minWidth: 120 }}>
																				<FormControl fullWidth>
																					<InputLabel id="trv" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }}>TRV</InputLabel>
																					<ThemeSelect
																						label="TRV"
																						value={value.trv}
																						size="small"
																						onChange={(event) => {
																							updateProduct(prodind, "trv", event.target.value);
																						}}
																						MenuProps={{
																							sx: {
																								"&& .Mui-selected": {
																									backgroundColor: "#5f7933",
																									color: "#fff"
																								},
																							},
																							PaperProps: {
																								sx: {
																									backgroundColor: "#c7d494",
																								}
																							}
																						}}
																					>
																						<MenuItem value={"0"} prodind={prodind}> -N/A- </MenuItem>
																						<MenuItem value={"10"} prodind={prodind}>10%</MenuItem>
																						<MenuItem value={"20"} prodind={prodind}>20%</MenuItem>
																						<MenuItem value={"30"} prodind={prodind}>30%</MenuItem>
																						<MenuItem value={"40"} prodind={prodind}>40%</MenuItem>
																						<MenuItem value={"50"} prodind={prodind}>50%</MenuItem>
																						<MenuItem value={"60"} prodind={prodind}>60%</MenuItem>
																						<MenuItem value={"70"} prodind={prodind}>70%</MenuItem>
																						<MenuItem value={"80"} prodind={prodind}>80%</MenuItem>
																						<MenuItem value={"90"} prodind={prodind}>90%</MenuItem>
																						<MenuItem value={"100"} prodind={prodind}>100%</MenuItem>
																						<MenuItem value={"Light Cover"} prodind={prodind}>Light Cover</MenuItem>
																						<MenuItem value={"Medium Cover"} prodind={prodind}>Medium Cover</MenuItem>
																						<MenuItem value={"Heavy Cover"} prodind={prodind}>Heavy Cover</MenuItem>
																					</ThemeSelect>
																				</FormControl>
																			</Box>
																		</td>
																		{/* <td>
																			<ThemeTextField
																				type="number"
																				value={value.unitcost}
																				sx={{ maxWidth: '180px' }}
																				size="small"
																				inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
																				onChange={(event) => {
																					updateProduct(prodind, "unitcost", event.target.value);
																				}}
																			/>
																		</td> */}
																		{/* <td>{value.totalcost}</td> */}
																	</tr>
																</>
															)
														})
														:
														<tr>
															<td className="placeholder">No Products Added</td>
														</tr>
												}
											</tbody>
										</table>
									</div>
								</Box>

								<div className="general-info-container">
									<span> GENERAL INFORMATION</span>
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<FormControl fullWidth error={!utils.isempty(formErrors.appmethod) ? true : false}>
											<InputLabel id="appmethod" sx={{ textAlign: 'left', color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} size="small"> Application Method</InputLabel>
											<ThemeSelect
												label="Application Method"
												value={formData.appmethod}
												size="small"
												onChange={(event) =>
													setFormData({ ...formData, appmethod: event.target.value })
												}
												MenuProps={{
													sx: {
														"&& .Mui-selected": {
															backgroundColor: "#5f7933",
															color: "#fff"
														},
													},
													PaperProps: {
														style: {
															maxHeight: 48 * 4.5 + 8,
															width: 250,
														},
														sx: {
															backgroundColor: "#c7d494",
														}
													}
												}}
											>
												{applicationmethods.map((value, ind) => {
													return (
														<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
													);
												})}
											</ThemeSelect>
											<FormHelperText>{formErrors.appmethod}</FormHelperText>
										</FormControl>

										<ThemeFormTextField
											type="number"
											id="waterrate"
											label="Water Rate (L/ha)"
											placeholder="Water Rate in L/ha"
											size="small"
											inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
											defaultValue={"0"}
											value={formData.waterrate}
											onChange={(event) =>
												updateGeneral("waterrate", event.target.value)
											}
											onFocus = {(event) => { event.target.select(); }}
										/>
									</Box>
								</div>

								<Box>
									<div style={{ textAlign: "left" }}> <b>Comments</b></div>
									<div style={{ paddingTop: "5px", textAlign: "left" }} className="comments-container">
										{/* TextArea Styling Applied in css class (dependand on parent div) */}
										<TextareaAutosize
											aria-label="comments"
											minRows={2}
											value={formData.comments}
											placeholder="Comments"
											onChange={(event) =>
												setFormData({ ...formData, comments: event.target.value })
											}
										/>
									</div>
								</Box>
								{/**CONTEXT PICKER START */}
								{
								foundContexts.length>0 ? (
								<Button
										sx={{ width: 1, marginBottom: '10px', marginTop: '5px' }}
										variant="contained"
										size="small"
										onClick={handleClickOpenContextDialog}
										disableElevation
										style={utils.theme.LoginButton}
									>
										Assign Contexts
								</Button>
								)
								:
								null
								}
								{
								foundContexts.length>0 ? (
								<div>
										<Dialog
											fullScreen={fullScreen}
											open={openContextDialog}
											onClose={handleCloseContextDialog}
											aria-labelledby="responsive-dialog-title"
											PaperProps={{ sx: { minWidth: '600px' } }}
										>
											<DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: '#c7d494', color: '#7A9A01', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px', fontWeight: 'bold', fontSize: '1.5em' }}>{"Assigned Context"}</DialogTitle>
											<DialogContent sx={{ backgroundColor: '#c7d494' }}>
												{
													<div className="container">
														<ThemeTextField
															type="search"
															value={contextName}
															size="small"
															onChange={filterContext}
															placeholder="Search Context"
															sx={{ width: '100%' }}
															InputProps={{
																startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
															}}
														/>

														<div className="user-list">
															{foundContexts && foundContexts.length > 0 ? (
																foundContexts.map((item) => {
																	var checkedindex = false;
																	checkedindex = formData.context.length===0||formData.context.findIndex(fp => fp === item.rid) === -1 ? false : true;
																	//console.warn("checkedindex",checkedindex,"item",item);
																	return (
																		<FormGroup key={item.rid} sx={{ marginLeft: '40px', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap' }}>
																			<div className="nexus-checkbox">
																				<label>
																					<input
																						type="checkbox"
																						className="custom-control-input"
																						checked={checkedindex}
																						contextrid={item.rid}
																						disabled = {formData.context.length===1&&checkedindex?true:false}
																						onChange={(event) => {
																							let tcontext = contextGroups.find(contextgroup => contextgroup.rid === event.target.attributes.contextrid.value);
																							tcontext = utils.extend({},tcontext);//create a copy of the product so that the original one does not get modified
																							//console.warn("tcontext",tcontext);
																							let tcontexts = utils.extend([],formData.context);
																							tcontexts = typeof tcontexts === 'string' ? JSON.parse(tcontexts) : tcontexts;
																							let tindex = tcontexts.findIndex(rid => rid === tcontext.rid);
																							//console.warn("tindex",tindex);
																							if (event.target.checked) {
																								if (tindex === -1) {
																									tcontexts.push(tcontext.rid);
																								}
																							}
																							if (!event.target.checked && tindex !== -1) {
																								tcontexts.splice(tindex, 1);
																							}
																							//console.warn("tcontexts",tcontexts);
																							//return false;
																							setFormData({ ...formData, context: tcontexts });
																						}}
																					/>
																					<span className="checkbox-material">
																						<span className="check"></span>
																					</span>
																				</label>
																			</div>
																			<Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
																				<div>{item.name}</div>
																			</Box>
																		</FormGroup>
																	);
																})
															) : (
																<h3>No contexts found!</h3>
															)}
														</div>
													</div>
												}
											</DialogContent>
											<DialogActions sx={{ backgroundColor: '#c7d494' }}>
												<Button
													variant="outlined"
													onClick={handleCloseContextDialog}
													autoFocus
													sx={{ color: '#fff' }}
													style={utils.theme.LoginButton}
												>
													Done
												</Button>
											</DialogActions>
										</Dialog>
								</div>
								)
								:
								null
								}
							</div>
						</div>
					</div>
					<div className="footer">
						<button
							onClick={(e) => {
								setOverlayState("overlay_visible");
								switch (e.target.innerText) {
									case "Submit":
										addSprayProgram(
											{
												rid: formData.rid,
												citype: ["#54:24"],
												context: formData.context,
												name: !utils.isset(formData.name) ? "New Spay Program" : formData.name,
												created_by: utils.isempty(formData.created_by)?getCookieData().useruuid:formData.created_by,
												comm_ref: formData.comm_ref,
												cult_ref: formData.cult_ref,
												appmethod: formData.appmethod,
												zones: JSON.stringify(formData.zones),
												pests: JSON.stringify(formData.pests),
												products: JSON.stringify(formData.products),
												// date_due: formData.date_due,
												// date_expires: formData.date_expires,
												// date_assigned: formData.date_assigned,
												waterrate: formData.waterrate,
												sprayperc: formData.sprayperc,
												comments: formData.comments,
												uuid: formData.uuid,
											},
											hideForm, setOverlayState
										);
										break;
									case "Save":
										editSprayProgram(
											{
												rid: formData.rid,
												citype: ["#54:24"],
												context: formData.context,
												name: !utils.isset(formData.name) ? "New Spay Program" : formData.name,
												created_by: utils.isempty(formData.created_by)?getCookieData().useruuid:formData.created_by,
												comm_ref: formData.comm_ref,
												cult_ref: formData.cult_ref,
												appmethod: formData.appmethod,
												zones: JSON.stringify(formData.zones),
												pests: JSON.stringify(formData.pests),
												products: JSON.stringify(formData.products),
												// date_due: formData.date_due,
												// date_expires: formData.date_expires,
												// date_assigned: formData.date_assigned,
												waterrate: formData.waterrate,
												comments: formData.comments,
												uuid: formData.uuid,
											},
											hideForm,setOverlayState
										);
										break;
									default:
										hideForm();
										break;
								}
							}}
						>
							{formAction === "add" ? "Submit" : "Save"}
						</button>
						<button
							onClick={(e) => {
								hideForm();
							}}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		)
	} else {
		return (
			<></>
		)
	}
}

export default function SprayProgramForm({ hideForm, sprayprogramid, formFilters, pageIndex, setPageIndex }) {
	//const numPages = 1;
	var organisationsLookupState = utils.getLocalStorageItem("organisationsLookup");
	var farmsLookupState = utils.getLocalStorageItem("farmsLookup");
	var farmCommoditiesLookupState = utils.getLocalStorageItem("farmCommoditiesLookup");
	var cultivarsLookupState = utils.getLocalStorageItem("cultivarsLookup");
	var personsLookupState = utils.getLocalStorageItem("personsLookup");
	var harvestZonesLookupState = utils.getLocalStorageItem("harvestZonesLookup");
	var applicationMethodsLookupState = utils.getLocalStorageItem("applicationMethodsLookup");
	var pestsLookupState = utils.getLocalStorageItem("sprayTargetsLookup");
	var orchardsLookupState = utils.getLocalStorageItem("orchardsLookup");
	var plantSprayProductsLookupState = utils.getLocalStorageItem("plantSprayProductsLookup");
	var contextGroupsLookupState = utils.getLocalStorageItem("contextGroupsLookup");

	const { addSprayProgram, getSprayProgram, editSprayProgram, getSprayProgramCIType, formErrors } = useContext(SprayProgramsContext);
	const { getCookieData } = useContext(SessionsContext);
	const { setOverlayState } = useContext(StateManagerContext);
	const FormTitle = {
		add: "Add Spray Program",
		edit: "Edit Spray Program",
	};
	const [formAction, setformAction] = useState("add");
	const [formData, setFormData] = useState({
		rid: "#13:0",
		citype: ["#54:24"],
		context: [getCookieData().activecontext],
		name: "New Spay Program",
		//organisation: "",
		created_by: getCookieData().useruuid,
		//email: "",
		//client: "",
		//farm_ref: "",
		comm_ref: "",
		cult_ref: "",
		appmethod: "",
		zones: {},
		pests: {},
		products: [],
		// date_due: moment().format("YYYY-MM-DD HH:mm:ss"),
		// date_expires: moment().format("YYYY-MM-DD HH:mm:ss"),
		// date_assigned: moment().format("YYYY-MM-DD HH:mm:ss"),
		//orgadd_1: "",
		//orgadd_2: "",
		//orgadd_3: "",
		//orgphone: "",
		//cliadd_1: "",
		//cliadd_2: "",
		//cliadd_3: "",
		//cliphone: "",
		waterrate: "0",
		//sprayperc: 100,
		//totalarea: "",
		//sprayarea: "",
		//totalvol: "",
		comments: "",
		//pdfname: "",
		uuid: metaphone(getSprayProgramCIType()) + "-" + uuidv4(),
	});
	const [formMeta, setFormMeta] = useState({ issueto: "" });
	const DisplayStep = () => {

		switch (pageIndex) {
			case 0:
				return (
					<Step001
						formData={formData}
						setFormData={setFormData}
						formMeta={formMeta}
						setFormMeta={setFormMeta}
						pageIndex={pageIndex}
						setPageIndex={setPageIndex}
						organisations={organisationsLookupState}
						farms={farmsLookupState}
						cultivars={cultivarsLookupState}
						harvestzones={harvestZonesLookupState}
						commodities={farmCommoditiesLookupState}
						applicationmethods={applicationMethodsLookupState}
						pests={pestsLookupState}
						orchards={orchardsLookupState}
						products={plantSprayProductsLookupState}
						persons={personsLookupState}
						formErrors={formErrors}
						//generateSprayProgramPDF={generateSprayProgramPDF}
						//fetchSprayProgramPDF={fetchSprayProgramPDF}
						editSprayProgram={editSprayProgram}
						addSprayProgram={addSprayProgram}
						FormTitle={FormTitle}
						formAction={formAction}
						hideForm={hideForm}
						formFilters={formFilters}
						setOverlayState={setOverlayState}
						contextGroups={contextGroupsLookupState}
					/>
				);
			default:
				return false;
		}

		/************* TO UNCOMMENT INCASE WE NEED TO RE-STEPIFY FORM  **************/
		// switch (pageIndex) {
		// 	case 0:
		// 		return (
		// 			<Step001 formData={formData} setFormData={setFormData} pageIndex={pageIndex} setPageIndex={setPageIndex} organisations={organisationsLookupState} farms={farmsLookupState} cultivars={cultivarsLookupState} harvestzones={harvestZonesLookupState} commodities={farmCommoditiesLookupState} formErrors={formErrors} generateSprayProgramPDF={generateSprayProgramPDF} fetchSprayProgramPDF={fetchSprayProgramPDF} FormTitle={FormTitle} formAction={formAction} hideForm={hideForm} />
		// 		);
		// 	case 1:
		// 		return (
		// 			<Step002 formData={formData} setFormData={setFormData} pageIndex={pageIndex} setPageIndex={setPageIndex} farms={farmsLookupState} cultivars={cultivarsLookupState} harvestzones={harvestZonesLookupState} applicationmethods={applicationMethodsLookupState} pests={pestsLookupState} orchards={orchardsLookupState} commodities={farmCommoditiesLookupState} products={plantSprayProductsLookupState} formErrors={formErrors} FormTitle={FormTitle} formAction={formAction} hideForm={hideForm} formFilters={formFilters} />
		// 		);
		// 	case 2:
		// 		return (
		// 			<Step003 formData={formData} setFormData={setFormData} formMeta={formMeta} setFormMeta={setFormMeta} pageIndex={pageIndex} setPageIndex={setPageIndex} persons={personsLookupState} formErrors={formErrors} FormTitle={FormTitle} formAction={formAction} hideForm={hideForm} formFilters={formFilters} editSprayProgram={editSprayProgram} addSprayProgram={addSprayProgram} />
		// 		);
		// 	default:
		// 		return false;
		// }
	};

	useEffect(() => {
		//console.warn("SP useEffect called...",sprayprogramid);
		if (utils.isempty(sprayprogramid.id)) {

			if(!utils.isempty(sprayprogramid.fromID)) {
				let tformData = utils.extend({},getSprayProgram(sprayprogramid.fromID));
				tformData.rid="#13:0";
				tformData.name = tformData.name+" - Copy";
				tformData.number = "";
				tformData.created_by = getCookieData().useruuid;
				tformData.uuid = metaphone(getSprayProgramCIType()) + "-" + uuidv4();
				console.warn("Copied SP??",tformData);
				setformAction("add");
				setFormMeta({ issueto: "" });
				setFormData(tformData);
			} else {
				let tuuid = metaphone(getSprayProgramCIType()) + "-" + uuidv4();
				setformAction("add");
				setFormMeta({ issueto: "" });
				setFormData({
					rid: "#13:0",
					citype: ["#54:24"],
					context: [getCookieData().activecontext],
					name: "New Spay Program",
					number:"",
					//organisation: "",
					created_by: getCookieData().useruuid,
					//email: "",
					//client: "",
					//farm_ref: "",
					comm_ref: "",
					cult_ref: "",
					appmethod: "",
					zones: {},
					pests: {},
					products: [],
					// date_due: moment().format("YYYY-MM-DD HH:mm:ss"),
					// date_expires: moment().format("YYYY-MM-DD HH:mm:ss"),
					// date_assigned: moment().format("YYYY-MM-DD HH:mm:ss"),
					//orgadd_1: "",
					//orgadd_2: "",
					//orgadd_3: "",
					//orgphone: "",
					//cliadd_1: "",
					//cliadd_2: "",
					//cliadd_3: "",
					//cliphone: "",
					waterrate: "0",
					//sprayperc: 100,
					//totalarea: "",
					//sprayarea: "",
					//totalvol: "",
					comments: "",
					//pdfname: "",
					uuid: tuuid
				});
			}
		} else {
			setformAction("edit");
			setFormMeta({ issueto: "" });
			var tformData = utils.extend({},getSprayProgram(sprayprogramid.id));
			//console.warn("tformData from useeffect",tformData);
			setFormData(tformData);
		}
	}, [sprayprogramid]);
	return (
		<>
			{DisplayStep()}
		</>
	);
}
