import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;
const personsReducer = (state, action) => {
	let tpersons = [];
	switch (action.type) {
		case "REFRESH_PERSONS":
			localStorage.setItem("personsLookup", JSON.stringify(action.persons));
			localStorage.setItem("persons", JSON.stringify(action.persons));
			return {
				persons: action.persons
			};
		case "ADD_PERSON":
			tpersons = utils.updateArray(state.persons,action.person,);
			tpersons = utils.sortArray(tpersons);
			localStorage.setItem("personsLookup", JSON.stringify(action.persons));
			localStorage.setItem("persons", JSON.stringify(action.persons));
			return {
				persons: tpersons
			};
		case "EDIT_PERSON":
			utils.each(state.persons, function (person, personind) {
				if (person.uuid !== action.person.uuid) {
					tpersons.push(person);
				} else {
					tpersons.push(action.person);
				}
			});
			tpersons = utils.sortArray(tpersons);
			localStorage.setItem("personsLookup", JSON.stringify(action.persons));
			localStorage.setItem("persons", JSON.stringify(action.persons));
			return {
				persons: tpersons
			};
		default:
			return state;
	}
};

export const PersonsContext = createContext();

const PersonsProvider = ({ children }) => {
	const persons = def_value;
	const [state, dispatch] = useReducer(personsReducer, {
		persons: persons,
		filters: {}
	});
	// let ts = localStorage.getItem("persons");
	// const [personsLookupState, setPersonsLookup] = useState((utils.isset(ts) && !utils.isempty(ts) ? JSON.parse(ts) : []));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const [formErrors, setFormErrors] = useState({ name: '' });

	const value = {
		persons: state.persons,
		addPerson: (person, hideform, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: person
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/person", params, headers).then(
				function (response) {
					if (response.data.success) {
						person = response.data.data;
						dispatch({ type: "ADD_PERSON", person });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							if(utils.isset(response.data.error_code)&&response.data.error_code===100) {
								setinvalid();
							} else {
								setFormErrors(response.data.data);
							}
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		getPerson: (id) => {
			let tperson = {};
			utils.each(state.persons, function (prsn, prsnid) {
				if (prsn.uuid === id) {
					tperson = prsn;
					return false;
				}
			});
			return tperson;
		},
		getPersons: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let personsFetchRules = utils.getLocalStorageItem("personsFetchRules");
			
			// if (!utils.isempty(personsFetchRules)) {
			// 	if (personsFetchRules.filtered === false&&personsFetchRules.trycache) {
			// 		let persons = utils.getLocalStorageItem("persons");
			// 		if(!utils.isempty(persons)) {
			// 			dispatch({ type: "REFRESH_PERSONS", persons });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	personsFetchRules = {trycache:true,filtered:true};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/persons", params).then(
				function (response) {
					if (response.data.success) {
						var persons = [];
						utils.each(response.data.data, function (prsn, prsnid) {
							//console.warn('org',org);
							persons.push(prsn);
						});
						if (lookup !== false) {
							//setPersonsLookup(persons);
							// dispatch({ type: "REFRESH_PERSONS", persons });
							dispatch({ type: "REFRESH_PERSONS", persons });
							// localStorage.setItem("persons", JSON.stringify(persons));
						} else {
							dispatch({ type: "REFRESH_PERSONS", persons });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setPersonsLookup([]);
						}
					}
					// personsFetchRules.filtered = false;
					// localStorage.setItem("personsFetchRules", JSON.stringify(personsFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editPerson: (person, hideform, filters, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: person
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/person", params, headers).then(
				function (response) {
					if (response.data.success) {
						setFormErrors({ name: '' });
						value.getPersons(filters, false, false, true);
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		// personsLookupState: personsLookupState,
		// setPersonsLookup: setPersonsLookup,
		formErrors: formErrors,
		setFormErrors: setFormErrors,
		getPersonCIType: () => {
			return "ci_person";
		},
		clearPersons: () => {
			// setPersonsLookup([]);
			let empty = [];
			dispatch({ type: "REFRESH_PERSONS", empty });
			localStorage.setItem("personsLookup", JSON.stringify(empty));
			localStorage.setItem("persons", JSON.stringify(empty));
		},
		resetFormErrors: () => {
			setFormErrors({ name: '' });
		}
	}
	return (
		<PersonsContext.Provider value={value}>
			{children}
		</PersonsContext.Provider>
	);
};
export default PersonsProvider;

