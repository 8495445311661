import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;
const farmcommoditiesReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_COMMODITIES":
			return {
				farmcommodities: action.farmcommodities
			};
		case "ADD_COMMODITY":
			return {
				farmcommodities: [...state.farmcommodities, action.farmcommodity]
			};
		case "EDIT_COMMODITY":
			let tfarmcommodities = [];
			utils.each(state.farmcommodities, function (comm, commind) {
				if (comm.uuid !== action.farmcommodity.uuid) {
					tfarmcommodities.push(comm);
				} else {
					tfarmcommodities.push(action.farmcommodity);
				}
			});
			//console.warn("edit tfarmcommodities",tfarmcommodities);
			return {
				farmcommodities: tfarmcommodities
			};
		default:
			return state;
	}
};

export const FarmCommoditiesContext = createContext();

const FarmCommoditiesProvider = ({ children }) => {
	const farmcommodities = def_value;
	const [state, dispatch] = useReducer(farmcommoditiesReducer, {
		farmcommodities: farmcommodities,
		filters: {}
	});
	// let ts = localStorage.getItem("farmCommodities");
	// const [farmCommoditiesLookupState, setFarmCommoditiesLookup] = useState((utils.isset(ts) && !utils.isempty(ts) ? JSON.parse(ts) : []));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const value = {
		farmcommodities: state.farmcommodities,
		addFarmCommodity: (farmcommodity, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }

			var params = {
				data: farmcommodity
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/farmcommodity", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "ADD_COMMODITY", farmcommodity });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		getFarmCommodity: (id) => {
			let tfarmcommodity = {};
			utils.each(state.farmcommodities, function (comm, commind) {
				if (comm.uuid === id) {
					tfarmcommodity = comm;
					return false;
				}
			});
			return tfarmcommodity;
		},
		getFarmCommodities: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let farmCommoditiesFetchRules = utils.getLocalStorageItem("farmCommoditiesFetchRules");
			
			// if (!utils.isempty(farmCommoditiesFetchRules)) {
			// 	if (farmCommoditiesFetchRules.filtered === false&&farmCommoditiesFetchRules.trycache) {
			// 		let farmcommodities = utils.getLocalStorageItem("farmcommodities");
			// 		if(!utils.isempty(farmcommodities)) {
			// 			dispatch({ type: "REFRESH_COMMODITIES", farmcommodities });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	farmCommoditiesFetchRules = {trycache:true,filtered:true};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/farmcommodities", params).then(
				function (response) {
					if (response.data.success) {
						var farmcommodities = [];
						utils.each(response.data.data, function (farmcommodity, farmcommodityid) {
							//console.warn('org',org);
							farmcommodities.push(farmcommodity);
						});
						if (lookup !== false) {
							// setFarmCommoditiesLookup(farmcommodities);
							// dispatch({ type: "REFRESH_COMMODITIES", farmcommodities });
							localStorage.setItem("farmCommoditiesLookup", JSON.stringify(farmcommodities));
						} else {
							dispatch({ type: "REFRESH_COMMODITIES", farmcommodities });
						}
						//responsereceived= true;
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							// setFarmCommoditiesLookup([]);
						}
					}
					// farmCommoditiesFetchRules.filtered = false;
					// localStorage.setItem("farmCommoditiesFetchRules", JSON.stringify(farmCommoditiesFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editFarmCommodity: (farmcommodity, hideform) => {
			const a = utils.new("ajax");

			if (utils.isempty(cookie)) { return false; }

			var params = {
				data: farmcommodity
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/farmcommodity", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "EDIT_COMMODITY", farmcommodity });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		// farmCommoditiesLookupState: farmCommoditiesLookupState,
		// setFarmCommoditiesLookup: setFarmCommoditiesLookup,
		getFarmCommodityCIType: () => {
			return "ci_farm_commodity";
		},
		clearFarmCommodities: () => {
			let empty = [];
			// dispatch({ type: "REFRESH_COMMODITIES", empty });
			localStorage.setItem("farmCommoditiesLookup", JSON.stringify(empty));
		}
	}
	return (
		<FarmCommoditiesContext.Provider value={value}>
			{children}
		</FarmCommoditiesContext.Provider>
	);
};
export default FarmCommoditiesProvider;