import React, { useState, useContext, useEffect } from 'react'
import parse from 'html-react-parser';
import utils from '../../utils/utils';
import moment from 'moment';
import Box from '@mui/material/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PestTrapInspectionsContext } from '../../contexts/pesttrapinspections';
import styled from "@emotion/styled";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Localbase from 'localbase';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';

//Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

// Theme
const ThemeTextField = styled(TextField)(utils.theme.TextField);
const ThemeSelect = styled(Select)(utils.theme.Select);
const color = "#7A9A01";
const theme = createTheme({
	components: {
		MuiIconButton: {
			styleOverrides: {
				sizeMedium: {
					color
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color
				}
			}
		}
	}
});

export function PestTrapInspectionReport(props) {


	const { ptiReportData, getReportPestTrapInspections, clearPestTrapInspections } = useContext(PestTrapInspectionsContext);
	const pesttraps = utils.getLocalStorageItem("pestTrapsLookup");
	const pests = utils.getLocalStorageItem("pestsLookup");
	// const [startDate, setStartDate] = useState(moment().add(-2, "w"));
	// const [endDate, setEndDate] = useState(moment());
	//console.warn("!utils.isset(ptiReportData)??????",!utils.isset(ptiReportData));
	//if(!utils.isset(ptiReportData)) { clearPestTrapInspections(); }
	// if(!utils.isset(ptiReportData)) {
	// 	return(
	// 		<>
	// 		</>
	// 	)
	//  }
	const filerStartDate = moment();
	const month = moment().month();
	if(month < moment().month(8)){
		filerStartDate.add(-1, "year").month(7).startOf("month");
	}

	const [formFilters, setFormFilters] = useState(utils.isset(ptiReportData) ? ptiReportData.formFilters : { status: "closed", startDate: filerStartDate.format("YYYY-MM-DD HH:mm:ss"), endDate: moment(), pests: [] });
	//let db = new Localbase('xappdb');
	// const handleMSChange = (event) => {
	//     console.warn("handleMSChange",event,this);
	//     const {
	//       target: { value },
	//     } = event;
	//     setFormFilters({ ...formFilters, pests: value});
	// };

	var fpests = [];
	if (!utils.isempty(pesttraps)) {
		utils.each(pesttraps, function (trap, trapid) {
			if (fpests.findIndex(pest => pest.uuid === trap.pest) === -1) {
				fpests.push({ uuid: trap.pest, name: utils.getName(pests, trap.pest) });
			}
		});
		//const index = fruits.findIndex(fruit => fruit === "blueberries");
	}
	// var datafarmzones = [];
	// var dataweeks = [];
	// var datapests = [];
	// //var chartdata = {};
	// var tdata = {};
	// var chartdata = {};
	// if (!utils.isempty(reportpesttrapinspections)) {
	// 	utils.each(reportpesttrapinspections, function (inspection, inspectionid) {
	// 		var tdate = (moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW') == '01') ? moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).endOf('isoWeek').format('YYYY') + ' ' + moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW') : moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).startOf('isoWeek').format('YYYY') + ' ' + moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW');
	// 		if (dataweeks.findIndex(week => week === tdate) === -1) {
	// 			dataweeks.push(tdate);
	// 		}
	// 		if (datapests.findIndex(pest => pest.uuid === inspection.pest) === -1) {
	// 			datapests.push({ uuid: inspection.pest, name: inspection.pestname });
	// 		}
	// 		if (datafarmzones.findIndex(farmzone => farmzone.farm === inspection.farm && farmzone.zone === inspection.zone) === -1) {
	// 			datafarmzones.push({ farm: inspection.farm, farmname: inspection.farmname, zone: inspection.zone, zonename: inspection.zonename });
	// 		}
	// 		let key = inspection.farm + " " + inspection.zone;
	// 		if (!utils.isset(tdata[key])) { tdata[key] = {}; }
	// 		if (!utils.isset(tdata[key][tdate])) { tdata[key][tdate] = {}; }
	// 		if (!utils.isset(tdata[key][tdate][inspection.pest])) { tdata[key][tdate][inspection.pest] = 0; }
	// 		tdata[key][tdate][inspection.pest] += parseInt(inspection.pestcount);

	// 		if (!utils.isset(chartdata[tdate])) { chartdata[tdate] = {}; }
	// 		if (!utils.isset(chartdata[tdate][inspection.pest])) { chartdata[tdate][inspection.pest] = { pestname: inspection.pestname, pest: inspectionid, latitude: inspection.latitude, longitude: inspection.longitude, pestcount: 0, inspcount: 0 }; }
	// 		chartdata[tdate][inspection.pest].pestcount += inspection.pestcount;
	// 		chartdata[tdate][inspection.pest].inspcount++;
	// 	});
	// 	//setReportPestTrapInspections(tdata);
	// 	dataweeks.sort();
	// 	datapests.sort((a, b) => {
	// 		return a.name.localeCompare(b.name);
	// 	});
	// }
	// db.collection('reportdata').doc('ptireportweeks').set({ data: JSON.stringify(dataweeks) });
	// db.collection('reportdata').doc('ptireportpests').set({ data: JSON.stringify(datapests) });
	// db.collection('reportdata').doc('ptireportchartdata').set({ data: JSON.stringify(chartdata) });

	console.log(":::::::::: PTI REPORT DATA ::::::::::  ", ptiReportData)

	var htr1 = "<tr key={'" + utils.uuid() + "'}><th className='sticky-table-header-col' colspan='2'></th>" + (!utils.isempty(ptiReportData.tdata) ? "<th style='text-align: center; vertical-align: middle;' colspan='" + (ptiReportData.dataweeks.length * ptiReportData.datapests.length) + "'>Weeks</th>" : "") + "</tr>";
	var htr2 = "<tr key={'" + utils.uuid() + "'}><th className='sticky-table-header-col' colspan='2'></th>";
	var htr3 = "<tr key={'" + utils.uuid() + "'}><th className='sticky-col'>Farm</th><th className='sticky-col'>Field</th>";
	utils.each(ptiReportData.dataweeks, function (week, weekind) {
		htr2 += "<th style='text-align: center; vertical-align: middle;' colspan='" + ptiReportData.datapests.length + "'>" + (week.replace("_", " ")) + "</th>";
		utils.each(ptiReportData.datapests, function (dp, dpind) {
			htr3 += "<th style='text-align: center; vertical-align: middle;'>" + (dp.name) + "</th>";
		});
	});

	htr2 += "</tr>";
	htr3 += "</tr>";

	//The below code is for the body of the table
	var tr = "";
	utils.each(ptiReportData.datafarmzones, function (farmzone, fzind) {
		tr += "<tr key={'" + utils.uuid() + "'}><td className='sticky-col'>" + farmzone.farmname + "</td><td className='sticky-col'>" + farmzone.zonename + "</td>";
		utils.each(ptiReportData.dataweeks, function (w, wind) {
			utils.each(ptiReportData.datapests, function (dp, dpind) {
				let key = farmzone.farm + " " + farmzone.zone;
				if (utils.isset(ptiReportData.tdata[key][w]) && utils.isset(ptiReportData.tdata[key][w][dp.uuid])) {
					tr += "<td className='week-readings-col' style='"+(ptiReportData.tdata[key][w][dp.uuid]==0?"color:#CCC;":"")+"'>" + ptiReportData.tdata[key][w][dp.uuid] + "</td>";
				} else {
					//console.warn("No reading for week",week,"we need to add empty cell");
					tr += "<td className='week-readings-col' style='color:#CCC;'>-</td>";
				}
			});
		});
		tr += "</tr>";
	});
	if (utils.isempty(ptiReportData.tdata)) {
		tr = "<tr key={'" + utils.uuid() + "'}><td colSpan='99'>No results found...</td></tr>";
	}
	//console.warn("dataweeks",dataweeks,"datapests",datapests);
	// useEffect(() => { getReportPestTrapInspections(formFilters) }, [])

	//Dialog
	const [openFilterDialog, setOpenFilterDialog] = useState(false);
	const handleFilterDialogClickOpen = () => {
		setOpenFilterDialog(true);
	};
	const handleFilterDialogClose = () => {
		setOpenFilterDialog(false);
	};

	return (
		<>
			<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Button
						onClick={handleFilterDialogClickOpen}
						variant="outlined"
						startIcon={<FilterListIcon />}
						style={utils.theme.Button}
					>
						Filter
					</Button>
				</Box>

				{
					!utils.isempty(ptiReportData.tdata) ?
						<Box sx={{ alignSelf: 'center', display: 'flex' }}>
							<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
								<span style={{ color: '#7a9a01', marginRight: '5px' }}>Start Date:</span>
								<span style={{ color: 'GrayText' }}>{typeof formFilters.startDate == 'object' || typeof formFilters.startDate == 'string' ? moment(formFilters.startDate).format("DD-MM-YYYY") : formFilters.startDate}</span>
							</div>
							<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
								<span style={{ color: '#7a9a01', marginRight: '5px' }}>End Date:</span>
								<span style={{ color: 'GrayText' }}>{typeof formFilters.endDate == 'object' || typeof formFilters.endDate == 'string' ? moment(formFilters.endDate).format("DD-MM-YYYY") : formFilters.endDate}</span>
							</div>
							<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
								<span style={{ color: '#7a9a01', marginRight: '5px' }}>Status:</span>
								<span style={{ color: 'GrayText' }}>{formFilters.status === "in_review" ? "In Review" : formFilters.status.charAt(0).toUpperCase() + formFilters.status.slice(1)}</span>
							</div>
						</Box>
						:
						null
				}

				<Dialog
					open={openFilterDialog}
					onClose={handleFilterDialogClose}
					fullWidth={true}
					maxWidth={'sm'}
				>
					<DialogTitle
						id="alert-dialog-title"
						sx={{ m: 0, p: 2, color: '#7A9A01' }}
					>
						List Filters
						<IconButton
							aria-label="close"
							onClick={handleFilterDialogClose}
							sx={{ position: 'absolute', right: 8, top: 8, color: '#7A9A01' }}
						>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent dividers >
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<Box sx={{ margin: '5px' }}>
								<ThemeProvider theme={theme}>
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											label="Start Date"
											value={formFilters.startDate}
											inputFormat="DD/MM/yyyy"
											renderInput={(params) => <ThemeTextField {...params} sx={{ width: '400px' }} />}
											onChange={(tdate) => {
												setFormFilters({ ...formFilters, startDate: tdate.format("YYYY-MM-DD HH:mm:ss") });
											}}
										/>
										<FormHelperText sx={{ paddingLeft: '14px' }}>Filter by start date</FormHelperText>
									</LocalizationProvider>
								</ThemeProvider>
							</Box>
							<Box sx={{ margin: '5px' }}>
								<ThemeProvider theme={theme}>
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											label="End Date"
											value={formFilters.endDate}
											inputFormat="DD/MM/yyyy"
											renderInput={(params) => <ThemeTextField {...params} sx={{ width: '400px' }} />}
											onChange={(tdate) => {
												setFormFilters({ ...formFilters, endDate: tdate.format("YYYY-MM-DD HH:mm:ss") });
											}}
										/>
										<FormHelperText sx={{ paddingLeft: '14px' }}>Filter by end date</FormHelperText>
									</LocalizationProvider>
								</ThemeProvider>
							</Box>
							<Box sx={{ margin: '5px' }}>
								<FormControl sx={{ m: 1, minWidth: 120 }} >
									<InputLabel sx={{ color: "#7A9A01", '&.Mui-focused': { color: '#7A9A01' } }} id="pest-inspection-status">Status</InputLabel>
									<ThemeSelect
										labelId="pest-inspection-status-label"
										id="pest-inspection-simple-select-disabled"
										value={formFilters.status}
										label="Status"
										sx={{ width: '400px' }}
										onChange={(event) =>
											setFormFilters({ ...formFilters, status: event.target.value })
										}
										MenuProps={{
											sx: {
												"&& .Mui-selected": {
													backgroundColor: "rgb(199, 212, 148)",
													color: "rgb(122, 154, 1)"
												},
											}
										}}
									>
										<MenuItem value={"closed"}>Closed</MenuItem>
										<MenuItem value={"in_review"}>In Review</MenuItem>
									</ThemeSelect>
									<FormHelperText>Filter list by status</FormHelperText>
								</FormControl>
							</Box>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => { console.warn("Running report..."); getReportPestTrapInspections(formFilters); setOpenFilterDialog(false) }}
							autoFocus
							variant="outlined"
							style={utils.theme.Button}
						>
							Apply Filters
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
			<Box sx={{ width: '100%', marginTop: '5px' }}>
				<div className="scrollable-trap-readings-table-container">
					<table role="table" className="trap-inspection-list-table">
						<thead className="sticky-table-header">
							{parse(htr1)}
							{parse(htr2)}
							{parse(htr3)}
						</thead>
						<tbody>
							{parse(tr)}
						</tbody>
					</table>
				</div>
			</Box>

		</>

	)
}

export default PestTrapInspectionReport;