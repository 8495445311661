import React, { useState, useContext, useEffect } from "react";
import PMZoneForm from './PMZoneForm';
import { PMZonesContext } from '../../contexts/pmzones';
import TextField from '@mui/material/TextField';
import '../../index.css';
import utils from "../../utils/utils";
import styled from "@emotion/styled";

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import { AddCircle, Edit } from "@material-ui/icons";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

//Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

// Theme
const ThemeTextField = styled(TextField)(utils.theme.TextField);
const ThemeSelect = styled(Select)(utils.theme.Select);

export function PMZoneList(props) {

  const { pmzones, getPMZones, resetFormErrors } = useContext(PMZonesContext);

  const farmsLookupState = utils.getLocalStorageItem("farmsLookup");
  const orchardsLookupState = utils.getLocalStorageItem("orchardsLookup");
  // const farmCommoditiesLookupState = utils.getLocalStorageItem("farmCommoditiesLookup");
  const cultivarsLookupState = utils.getLocalStorageItem("cultivarsLookup");

  const [formContainerVis, setformContainerVis] = useState("form-container dialog-loader-2 dialog-loader-2-hidden");
  const [pmzoneid, setpmzoneid] = useState("");
  const [formAction, setFormAction] = useState("");
  const [formFilters, setFormFilters] = useState({ name: "", farm: [], status: "active" });

  //const isEven = (idx) => idx % 2 === 0;
  const showForm = () => {
    setformContainerVis("form-container dialog-loader-2 dialog-loader-2-show");
  }
  const hideForm = () => {
    resetFormErrors();
    setformContainerVis("form-container dialog-loader-2 dialog-loader-2-hidden");
  }
  const prePMZoneID = (id) => {
    if (id !== "") {
      setpmzoneid(id);
      setFormAction("edit");
      showForm();
    } else {
      setpmzoneid(id);
      setFormAction("add");
      showForm();
    }
  }

  useEffect(() => { getPMZones({ status: "active" }) }, []);

  //Dialog
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const handleFilterDialogClickOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };
  //Below line was cut from PMZoneForm definition after pmzoneid={pmzoneid}
  // formAction={formAction}

  const [farmName, setFarmName] = useState([]);
  const handleFarmSelectChange = (event) => {
    var tfarms = typeof event.target.value === 'string' ? event.target.value.split(",") : event.target.value;
    var tmp = [];
    var tfarmName = [];
    utils.each(tfarms, function (tfarm, tfarmind) {
      let farmind = farmsLookupState.findIndex(p => p.name === tfarm.trim());
      if (farmind !== -1) {
        if (!utils.isset(tmp[farmsLookupState[farmind].uuid])) { tmp.push(farmsLookupState[farmind].uuid) }
        tfarmName.push(farmsLookupState[farmind].name);
      }
    });
    setFormFilters({ ...formFilters, farm: tmp });
    setFarmName(tfarmName);
  };

  var farmNameList = [];
  if (!utils.isempty(formFilters.farm)) {
    utils.each(formFilters.farm, function (ffarm) {
      farmNameList.push(utils.getName(farmsLookupState, ffarm));
    });
  };

  return (
    <>
      <div className={formContainerVis} >
        <PMZoneForm hideForm={hideForm} pmzoneid={pmzoneid} formFilters={formFilters} />
      </div>

      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Box>
          <div className="filter-dialog">
            <Button
              variant="outlined"
              onClick={handleFilterDialogClickOpen}
              startIcon={<FilterListIcon />}
              style={utils.theme.Button}
            >
              Filter
            </Button>
            <Dialog
              open={openFilterDialog}
              onClose={handleFilterDialogClose}
              fullWidth={true}
              maxWidth={'sm'}
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{ m: 0, p: 2, color: '#7A9A01' }}
              >
                List Filters
                <IconButton
                  aria-label="close"
                  onClick={handleFilterDialogClose}
                  sx={{ position: 'absolute', right: 8, top: 8, color: '#7A9A01' }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Box sx={{ margin: '5px' }}>
                    <ThemeTextField
                      type="text"
                      id="name"
                      label="Name"
                      placeholder="Name"
                      helperText="Filter list by name"
                      sx={{ width: '400px' }}
                      fullWidth={true}
                      value={formFilters.name}
                      onChange={(event) =>
                        setFormFilters({ ...formFilters, name: event.target.value })
                      }
                    />
                  </Box>
                  <Box sx={{ margin: '5px' }}>
                    <FormControl sx={{ m: 0, width: "100%", marginTop: '5px' }}>
                      <InputLabel id="farms" sx={{ boxSizing: 'border-box', '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }}>Farms</InputLabel>
                      <ThemeSelect
                        label="Farms"
                        id="farms"
                        multiple
                        value={farmNameList}
                        onChange={handleFarmSelectChange}
                        input={<OutlinedInput sx={{ display: 'grid', paddingRight: '35px', margin: '0px' }} label="Tag" />}
                        renderValue={(selected) => selected.join(', ')}
                        sx={{ width: '400px' }}
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "rgb(199, 212, 148)",
                              color: "rgb(122, 154, 1)"
                            },
                          },
                          PaperProps: {
                            style: {
                              maxHeight: 48 * 4.5 + 8,
                              width: 250,

                            },
                          },
                        }}
                      >
                        {
                          farmsLookupState.map((value) => (
                            <MenuItem key={value.uuid} value={value.name}>
                              <Checkbox
                                checked={farmName.indexOf(value.name) > -1 || farmNameList.indexOf(value.name) > -1}
                                sx={{
                                  color: "#7A9A01",
                                  '&.Mui-checked': {
                                    color: "rgb(122, 154, 1)",
                                  },
                                }}
                              />
                              <ListItemText primary={value.name}></ListItemText>
                            </MenuItem>
                          ))
                        }
                      </ThemeSelect>
                      <FormHelperText>Filter list by farms</FormHelperText>
                    </FormControl>
                  </Box>
                  <Box sx={{ margin: '5px' }}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} >
                      <InputLabel id="simple-select-disabled-label" sx={{ '&.Mui-focused': { color: '#7A9A01' } }}>Status</InputLabel>
                      <ThemeSelect
                        labelId="simple-select-disabled-label"
                        id="simple-select-disabled"
                        value={formFilters.status}
                        label="Status"
                        sx={{ width: '400px' }}
                        onChange={(event) =>
                          setFormFilters({ ...formFilters, status: event.target.value })
                        }
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "rgb(199, 212, 148)",
                              color: "rgb(122, 154, 1)"
                            },
                          }
                        }}
                      >
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"deleted"}>Deleted</MenuItem>
                      </ThemeSelect>
                      <FormHelperText>Filter list by status</FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => { utils.setLocalStorageItem('pmZonesFetchRules', true, 'filtered'); getPMZones(formFilters); setOpenFilterDialog(false) }}
                  autoFocus
                  variant="outlined"
                  style={utils.theme.Button}
                >
                  Apply Filters
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Box>

        <Box sx={{ alignSelf: 'center', display: 'flex' }}>
          {
            !utils.isempty(formFilters.name) ?
              <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#7a9a01', marginRight: '5px' }}>Name:</span>
                <span style={{ color: 'GrayText' }}>{formFilters.name}</span>
              </div>
              : null
          }
          {
            !utils.isempty(formFilters.farm) ?
              <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#7a9a01', marginRight: '5px' }}>Farm:</span>
                {
                  farmNameList.map((name, ind) => {
                    return (<span style={{ color: 'GrayText' }}>{name}{(farmNameList.length - 1 !== ind) && ', '}</span>)
                  })
                }
              </div>
              : null
          }
          <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: '#7a9a01', marginRight: '5px' }}>Status:</span>
            <span style={{ color: 'GrayText' }}>{formFilters.status.charAt(0).toUpperCase() + formFilters.status.slice(1)}</span>
          </div>
        </Box>

        <Box>
          <Button
            onClick={() => { prePMZoneID(pmzoneid === null ? "" : null) }}
            variant="outlined"
            startIcon={<AddCircle />}
            sx={{ "&:hover": { color: "#52312d", borderColor: "#52312d" } }}
            style={{ color: "#7A9A01", borderColor: "#7A9A01" }}
          >
            Add
          </Button>
        </Box>
      </Box>

      <Box sx={{ width: '100%', marginTop: '5px', borderTop: 1, borderColor: 'divider' }}>
        <div className="nexus-scroll-table">
          <table>
            <thead>
              <tr>
                <th>Farm</th>
                <th>Orchard</th>
                <th>Cultivar</th>
                <th>Name</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {
                utils.isset(pmzones) ? pmzones.map(pmzone => (
                  <tr key={utils.uuid()}>
                    <td>{(!utils.isempty(pmzone.farm) ? utils.getName(farmsLookupState, pmzone.farm) : "")}</td>
                    <td>{(!utils.isempty(pmzone.block) ? utils.getName(orchardsLookupState, pmzone.block) : "")}</td>
                    <td>{(!utils.isempty(pmzone.cultivar_name) ? utils.getName(cultivarsLookupState, pmzone.cultivar_name) : "")}</td>
                    <td>{pmzone.name}</td>
                    <td>
                      <IconButton onClick={() => { prePMZoneID(pmzone.uuid) }}
                        color="primary"
                        sx={{ "&:hover": { color: "#789a3d" } }}
                        style={{ color: "#7A9A01" }}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>
                ))
                  : null
              }
              {
                (utils.isempty(pmzones)) ?
                  <tr key={utils.uuid()}><td colSpan={99}>No results found...</td></tr> : null
              }
            </tbody>
          </table>
        </div>
      </Box>
    </>
  );
}

export default PMZoneList;
