import React, { useState, useContext, useEffect } from 'react'
import utils from "../../utils/utils";
import { v4 as uuidv4 } from 'uuid';
import { metaphone } from 'metaphone';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import moment from 'moment';
import { PestTrapInspectionsContext } from '../../contexts/pesttrapinspections';
import { StateManagerContext } from '../../contexts/statemanager';
import { SessionsContext } from '../../contexts/sessions';
import '../../index.css';
import styled from '@emotion/styled';
import { FormHelperText } from '@mui/material';

//Theme
const ThemeFormTextField = styled(TextField)(utils.theme.FormTextField);
const ThemeSelect = styled(Select)(utils.theme.Select);
const ThemeSwitch = styled(Switch)(utils.theme.FormSwitch);

export function Step001({ formData, setFormData, formErrors, farms, pmzones, pests, pesttraps, persons/*, types*/ }) {
    const { getCookieData } = useContext(SessionsContext);

    var CD = getCookieData();
    var tpestslookup = [];
    utils.each(pesttraps, function (trap, trapind) {
        let tpest = utils.getRecord(pests, trap.pest);
        tpestslookup.push({ uuid: tpest.uuid, name: tpest.name, trap: trap.uuid });
    });

    return (
        <div className="step001-container">
            <ThemeFormTextField
                type="text"
                id="scout"
                label="Scout"
                disabled={true}
                sx={{
                    "&.MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black",
                    },
                }}
                value={(!utils.isempty(formData.inspected_by) ? utils.getName(persons, formData.inspected_by) : CD.name)}
                error={!utils.isempty(formErrors.inspected_by) ? true : false}
								helperText={formErrors.inspected_by}
            />

            <ThemeFormTextField
                type="text"
                id="status"
                label="Status"
                placeholder="Status..."
                disabled={true}
                value={formData.status}
            />

            <Box sx={{ minWidth: "50%", marginTop: "5px" }}>
                <FormControl fullWidth error={!utils.isempty(formErrors.farm) ? true : false}>
                    <InputLabel id="farm" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Farm</InputLabel>
                    <ThemeSelect
                        label="Farm"
                        value={formData.farm}
                        onChange={(event) => {
                            utils.each(farms, function (farm, farmid) {
                                if (farm.uuid === event.target.value) {
                                    setFormData({ ...formData, farm: event.target.value, farmname: farm.name });
                                }
                            })
                        }}
                        MenuProps={{
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: "#5f7933",
                                    color: "#fff"
                                },
                            },
                            PaperProps: {
                                style: {
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 250,
                                },
                                sx: {
                                    backgroundColor: "#c7d494",
                                }
                            }
                        }}
                    >
                        {
                            farms.map((value, ind) => {
                                return (
                                    <MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
                                )
                            })
                        }
                    </ThemeSelect>
                    <FormHelperText>{formErrors.farm}</FormHelperText>
                </FormControl>
            </Box>

            <Box sx={{ minWidth: "50%", marginTop: "5px" }}>
                <FormControl fullWidth error={!utils.isempty(formErrors.zone) ? true : false}>
                    <InputLabel id="zone" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Zone</InputLabel>
                    <ThemeSelect
                        label="Zone"
                        value={formData.zone}
                        onChange={(event) => {
                            utils.each(pmzones, function (pmzone, pmzoneid) {
                                if (pmzone.uuid === event.target.value) {
                                    setFormData({ ...formData, zone: event.target.value });
                                }
                            })
                        }}
                        MenuProps={{
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: "#5f7933",
                                    color: "#fff"
                                },
                            },
                            PaperProps: {
                                style: {
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 250,
                                },
                                sx: {
                                    backgroundColor: "#c7d494",
                                }
                            }
                        }}
                    >
                        {
                            pmzones.map((value, ind) => {
                                if (!utils.isempty(formData.farm) && formData.farm !== value.farm) { return null; }
                                return (
                                    <MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
                                )
                            })
                        }
                    </ThemeSelect>
                    <FormHelperText>{formErrors.zone}</FormHelperText>
                </FormControl>
            </Box>

            <Box sx={{ minWidth: "50%", marginTop: "5px" }}>
                <FormControl fullWidth error={!utils.isempty(formErrors.trap) ? true : false}>
                    <InputLabel id="pesttraps" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Trap</InputLabel>
                    <ThemeSelect
                        label="Trap"
                        value={formData.trap}
                        onChange={(event) => {
                            utils.each(pesttraps, function (pesttraps, pesttraptypeid) {
                                if (pesttraps.uuid === event.target.value) {
                                    setFormData({ ...formData, trap: event.target.value });
                                }
                            })
                        }}
                        MenuProps={{
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: "#5f7933",
                                    color: "#fff"
                                },
                            },
                            PaperProps: {
                                style: {
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 250,
                                },
                                sx: {
                                    backgroundColor: "#c7d494",
                                }
                            }
                        }}
                    >
                        {
                            pesttraps.map((value, ind) => {
                                if (!utils.isempty(formData.zone) && formData.zone !== value.zone) { return null; }
                                return (
                                    <MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
                                )
                            })
                        }
                    </ThemeSelect>
                    <FormHelperText>{formErrors.trap}</FormHelperText>
                </FormControl>
            </Box>

            <Box sx={{ minWidth: "50%", marginTop: "5px" }}>
                <FormControl fullWidth error={!utils.isempty(formErrors.pest) ? true : false}>
                    <InputLabel id="pest" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Pest</InputLabel>
                    <ThemeSelect
                        label="Pest"
                        value={formData.pest}
                        onChange={(event) => {
                            setFormData({ ...formData, pest: event.target.value });
                        }}
                        MenuProps={{
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: "#5f7933",
                                    color: "#fff"
                                },
                            },
                            PaperProps: {
                                style: {
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 250,
                                },
                                sx: {
                                    backgroundColor: "#c7d494",
                                }
                            }
                        }}
                    >
                        {
                            tpestslookup.map((value, ind) => {
                                if (formData.trap !== value.trap) { return null; }
                                return (
                                    <MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
                                )
                            })
                        }
                    </ThemeSelect>
                    <FormHelperText>{formErrors.pest}</FormHelperText>
                </FormControl>
            </Box>

            <ThemeFormTextField
                type="text"
                id="pestcount"
                label="Pest Count"
                placeholder="Pest Count..."
                value={formData.pestcount}
                onChange={(event) =>
                    setFormData({ ...formData, pestcount: event.target.value })
                }
                error={!utils.isempty(formErrors.pestcount) ? true : false}
				helperText={formErrors.pestcount}
            />

            <FormControlLabel
                label={"Rebaited"}
                sx={{ width: "200px" }}
                labelPlacement="start"
                control={
                    <ThemeSwitch
                        checked={(formData.rebait == 1 || formData.rebait == true || formData.rebait == '1' ? true : false)}
                        onChange={(event) => { setFormData({ ...formData, rebait: event.target.checked }); }}
                    />
                }
            />
        </div>
    );
}

export default function TrapInspectionForm({ hideForm, pesttrapinspectionid }) {
    const [pageIndex] = useState(0);
    //const {organisations} = useContext(OrganisationsContext);
    const currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");


    const { getCookieData } = useContext(SessionsContext);
    var CD = getCookieData();

    var farmsLookupState = utils.getLocalStorageItem("farmsLookup");
    var pmZonesLookupState = utils.getLocalStorageItem("pmZonesLookup");
    var pestsLookupState = utils.getLocalStorageItem("pestsLookup");
    var pestTrapsLookup = utils.getLocalStorageItem("pestTrapsLookup");
    var personsLookup = utils.getLocalStorageItem("personsLookup");
    /*
        const types = [
        { type: "fruit", name: "Fruit" },
        { type: "veg", name: "Vegetable" },
    ];
        */
    const { addPestTrapInspection, getPestTrapInspection, editPestTrapInspection, getPestTrapInspectionCIType, formErrors } = useContext(PestTrapInspectionsContext);
	const { setOverlayState } = useContext(StateManagerContext);

    const FormTitle = {
        add: "Add Trap Inspection",
        edit: "Edit Trap Inspection"
    };

    const [formAction, setformAction] = useState("add");
    const [formData, setFormData] = useState({
        name: "",
        rid: "#13:0",
        citype: "#12:171",
        farm: "",
        contextref: [],
        blockrid: "",
        zone: "",
        pest: "",
        checkhousing: "",
        trap: "",
        latitude: 0,
        longitude: 0,
        checksticking: 0,
        rebait: 0,
        data: "",
        barcode: 0,
        inspected_by: CD.useruuid,
        comments: "",
        pestcount: 0,
        date_performed: currentDateTime,
        status: "in_review",
        uuid: metaphone(getPestTrapInspectionCIType()) + "-" + uuidv4()
    });
    //setFormAction(pesttrapinspectionid===""?"add":"edit");
    const DisplayStep = () => {
        switch (pageIndex) {
            case 0:
                return <Step001 formData={formData} setFormData={setFormData} formErrors={formErrors} farms={farmsLookupState} pmzones={pmZonesLookupState} pests={pestsLookupState} pesttraps={pestTrapsLookup} persons={personsLookup} /*types={types}*/ />;
            default:
                return false;
        }
    };
    useEffect(() => {
        if (utils.isempty(pesttrapinspectionid)) {
            let tuuid = metaphone(getPestTrapInspectionCIType()) + "-" + uuidv4();
            setformAction("add");
            setFormData({
                name: "",
                rid: "#13:0",
                citype: "#12:171",
                farm: "",
                contextref: [],
                blockrid: "",
                zone: "",
                pest: "",
                checkhousing: "",
                trap: "",
                latitude: 0,
                longitude: 0,
                checksticking: 0,
                rebait: 0,
                data: "",
                barcode: 0,
                inspected_by: CD.useruuid,
                comments: "",
                pestcount: 0,
                date_performed: currentDateTime,
                status: "in_review",
                uuid: tuuid
            });
        } else {
            setformAction("edit");
            setFormData(getPestTrapInspection(pesttrapinspectionid));
        }
    }, [pesttrapinspectionid])
    return (
        <div className="form form-bg">
            <div className="progressbar">
                <div
                    style={{ width: pageIndex === 0 ? "100%" : "100%" }}
                ></div>
            </div>
            <div className="form-container">
                <div className="header">
                    <h1>{FormTitle[formAction]}</h1>
                </div>
                <div className="body">{DisplayStep()}</div>
                <div className="footer">
                    <button
                        onClick={(e) => {
							setOverlayState("overlay_visible");
                            switch (e.target.innerText) {
                                case 'Submit':
                                    addPestTrapInspection({
                                        name: "",
                                        rid: formData.rid,
                                        citype: "#12:171",
                                        farm: formData.farm,
                                        contextref: formData.contextref,
                                        blockrid: formData.blockrid,
                                        zone: formData.zone,
                                        pest: formData.pest,
                                        checkhousing: formData.checkhousing,
                                        trap: formData.trap,
                                        latitude: formData.latitude,
                                        longitude: formData.longitude,
                                        checksticking: formData.checksticking,
                                        rebait: formData.rebait,
                                        data: formData.data,
                                        barcode: formData.barcode,
                                        inspected_by: formData.inspected_by,
                                        comments: formData.comments,
                                        pestcount: formData.pestcount,
                                        date_performed: currentDateTime,
                                        status: "in_review",
                                        uuid: formData.uuid
                                    }, hideForm, setOverlayState);
                                    break;
                                case 'Save':
                                    editPestTrapInspection({
                                        name: "",
                                        rid: formData.rid,
                                        citype: "#12:171",
                                        farm: formData.farm,
                                        contextref: formData.contextref,
                                        blockrid: formData.blockrid,
                                        zone: formData.zone,
                                        pest: formData.pest,
                                        checkhousing: formData.checkhousing,
                                        trap: formData.trap,
                                        latitude: formData.latitude,
                                        longitude: formData.longitude,
                                        checksticking: formData.checksticking,
                                        rebait: formData.rebait,
                                        data: formData.data,
                                        barcode: formData.barcode,
                                        inspected_by: formData.inspected_by,
                                        comments: formData.comments,
                                        pestcount: formData.pestcount,
                                        date_performed: formData.date_performed,
                                        status: formData.status,
                                        uuid: formData.uuid
                                    }, hideForm, setOverlayState);
                                    break;
                                default:
                                    hideForm();
                                    break;
                            }
                        }
                        }
                    >
                        {formAction === "add" ? "Submit" : "Save"}
                    </button>
                    <button
                        onClick={(e) => {
                            hideForm();
                        }
                        }
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div >
    );

}