import React, { useEffect, useState, useContext } from "react";
import { FarmsContext } from '../../contexts/farms';
import FarmForm from './FarmForm';
import '../../index.css';
import styled from "@emotion/styled";
import utils from "../../utils/utils";

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { AddCircle, Edit } from "@material-ui/icons";
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

//Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

// Theme
const ThemeTextField = styled(TextField)(utils.theme.TextField)
const ThemeSelect = styled(Select)(utils.theme.Select);

export function FarmList(props) {

  const { farms, getFarms, resetFormErrors } = useContext(FarmsContext);
  const organisationsLookupState = utils.getLocalStorageItem("organisationsLookup");
  const personsLookupState = utils.getLocalStorageItem("personsLookup");

  const [formContainerVis, setformContainerVis] = useState("form-container dialog-loader-2 dialog-loader-2-hidden");
  const [farmid, setfarmid] = useState("");
  const [formAction, setFormAction] = useState("");
  const [formFilters, setFormFilters] = useState({ name: "", status: "active" });

  //const isEven = (idx) => idx % 2 === 0;
  const showForm = () => {
    setformContainerVis("form-container dialog-loader-2 dialog-loader-2-show");
  }
  const hideForm = () => {
    resetFormErrors();
    setformContainerVis("form-container dialog-loader-2 dialog-loader-2-hidden");
  }
  const preFarmID = (id) => {
    if (id !== "") {
      setfarmid(id);
      setFormAction("edit");
      showForm();
    } else {
      setfarmid(id);
      setFormAction("add");
      showForm();
    }
  }
  useEffect(() => { getFarms({ status: "active" }) }, []);
  //Dialog
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const handleFilterDialogClickOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  //Below line was cut from FarmForm definition after farmid={farmid}
  // formAction={formAction}
  return (
    <>
      <div className={formContainerVis} >
        <FarmForm hideForm={hideForm} farmid={farmid} formFilters={formFilters} />
      </div>

      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Box>
          <div className="filter-dialog">
            <Button
              variant="outlined"
              onClick={handleFilterDialogClickOpen}
              startIcon={<FilterListIcon />}
              style={utils.theme.Button}
            >
              Filter
            </Button>
            <Dialog
              open={openFilterDialog}
              onClose={handleFilterDialogClose}
              fullWidth={true}
              maxWidth={'sm'}
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{ m: 0, p: 2, color: '#7A9A01' }}
              >
                List Filters
                <IconButton
                  aria-label="close"
                  onClick={handleFilterDialogClose}
                  sx={{ position: 'absolute', right: 8, top: 8, color: '#7A9A01' }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Box   >
                    <ThemeTextField
                      type="text"
                      id="name"
                      label="Name"
                      placeholder="Name"
                      helperText="Filter list by name"
                      sx={{ width: '400px' }}
                      fullWidth={true}
                      value={formFilters.name}
                      onChange={(event) =>
                        setFormFilters({ ...formFilters, name: event.target.value })
                      }
                    />
                  </Box>
                  <Box>
                    <FormControl sx={{ m: 1, minWidth: 120 }} >
                      <InputLabel id="simple-select-disabled-label" sx={{ '&.Mui-focused': { color: '#7A9A01' } }}>Status</InputLabel>
                      <ThemeSelect
                        labelId="simple-select-disabled-label"
                        id="simple-select-disabled"
                        value={formFilters.status}
                        label="Status"
                        sx={{ width: '400px' }}
                        onChange={(event) =>
                          setFormFilters({ ...formFilters, status: event.target.value })
                        }
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "rgb(199, 212, 148)",
                              color: "rgb(122, 154, 1)"
                            },
                          }
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"deleted"}>Deleted</MenuItem>
                      </ThemeSelect>
                      <FormHelperText>Filter list by status</FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => { utils.setLocalStorageItem('farmFetchRules', true, 'filtered'); getFarms(formFilters); setOpenFilterDialog(false) }}
                  autoFocus
                  variant="outlined"
                  style={utils.theme.Button}
                >
                  Apply Filters
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Box>

        <Box sx={{ alignSelf: 'center', display: 'flex' }}>
          {
            !utils.isempty(formFilters.name) ?
              <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#7a9a01', marginRight: '5px' }}>Name:</span>
                <span style={{ color: 'GrayText' }}>{formFilters.name}</span>
              </div>
              : null
          }
          <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: '#7a9a01', marginRight: '5px' }}>Status:</span>
            <span style={{ color: 'GrayText' }}>{formFilters.status.charAt(0).toUpperCase() + formFilters.status.slice(1)}</span>
          </div>
        </Box>

        <Box>
          <Button
            onClick={() => { preFarmID("") }}
            variant="outlined"
            startIcon={<AddCircle />}
            style={utils.theme.Button}
          >
            Add
          </Button>
        </Box>
      </Box>

      <Box sx={{ width: '100%', marginTop: '5px' }}>
        <div className="nexus-scroll-table">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Short Code</th>
                <th>Organisation</th>
                <th>Contact Person</th>
                <th>PUC</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {
                (!utils.isempty(farms)) ?
                  farms.map(farm => (
                    <tr key={utils.uuid()}>
                      <td>{farm.name}</td>
                      <td>{farm.shortcode}</td>
                      <td>{(!utils.isempty(farm.organisation_id && utils.isset(organisationsLookupState)) ? utils.getName(organisationsLookupState, farm.organisation_id) : "")}</td>
                      <td>{(!utils.isempty(farm.contactperson) && utils.isset(personsLookupState) ? utils.getName(personsLookupState, farm.contactperson) : "")}</td>
                      <td>{farm.puc_code}</td>
                      <td>
                        <IconButton onClick={() => { preFarmID(farm.uuid) }}
                          color="primary"
                          sx={{ "&:hover": { color: "#789a3d" } }}
                          style={{ color: "#7A9A01" }}
                        >
                          <Edit />
                        </IconButton>
                      </td>
                    </tr>
                  ))
                  : <tr key={utils.uuid()}><td colSpan={99}>No results found...</td></tr>
              }
            </tbody>
          </table>
        </div>
      </Box>
    </>
  );
}

export default FarmList;
