import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
import saveAs from 'save-as';
//This to become serverside lookup
var ajaxstack = false;
var def_value = [];

const SprayRecommendationsReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_SPRAYRECOMMENDATIONS":
			return {
				sprayrecommendations: action.sprayrecommendations
			};
		case "ADD_SPRAYRECOMMENDATION":
			return {
				sprayrecommendations: [...state.sprayrecommendations, action.sprayrecommendation]
			};
		case "EDIT_SPRAYRECOMMENDATION":
			let tsprayrecommendations = [];
			utils.each(state.sprayrecommendations, function (sprayrecommendation, sprayrecommendationind) {
				if (sprayrecommendation.uuid !== action.sprayrecommendation.uuid) {
					tsprayrecommendations.push(sprayrecommendation);
				} else {
					tsprayrecommendations.push(action.sprayrecommendation);
				}
			});
			return {
				sprayrecommendations: tsprayrecommendations
			};
		default:
			return state;
	}
};

export const SprayRecommendationsContext = createContext();

const SprayRecommendationsProvider = ({ children }) => {
	const sprayrecommendations = def_value;
	const [state, dispatch] = useReducer(SprayRecommendationsReducer, {
		sprayrecommendations: sprayrecommendations,
		filters: {}
	});
	// const [sprayRecommendationsLookupState,setSprayRecommendationsLookup] = useState([]);
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const [formErrors, setFormErrors] = useState({ client: "", farm_ref: "", comm_ref: "", cult_ref: "", appmethod: "", waterrate: "", totalarea: "", sprayarea: "", totalvol: "", organisation: "" });

	const value = {
		sprayrecommendations: state.sprayrecommendations,
		addSprayRecommendation: (sprayrecommendation, hideform, setOverlayState,scrollToTop=false,resetSelectedProgram=false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: sprayrecommendation
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/sprayrecommendation", params, headers).then(
				function (response) {
					if (response.data.success) {
						setFormErrors({ client: "", farm_ref: "", comm_ref: "", cult_ref: "", appmethod: "", waterrate: "", totalarea: "", sprayarea: "", totalvol: "", organisation: "" });
						sprayrecommendation = response.data.data;
						dispatch({ type: "ADD_SPRAYRECOMMENDATION", sprayrecommendation });
						console.warn("Hiding form....");
						if(scrollToTop) { scrollToTop(); }
						if(resetSelectedProgram) { resetSelectedProgram(); }
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		getSprayRecommendation: (id) => {
			let tsprayrecommendation = {};
			utils.each(state.sprayrecommendations, function (sprayrecommendation, sprayrecommendationind) {
				if (sprayrecommendation.uuid === id) {
					tsprayrecommendation = sprayrecommendation;
					return false;
				}
			});
			return tsprayrecommendation;
		},
		getSprayRecommendations: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			console.warn("cookie???", cookie, "ttoken", ttoken, "ajaxstack", ajaxstack);
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let sprayRecommendationsFetchRules = utils.getLocalStorageItem("sprayRecommendationsFetchRules");

			// if (!utils.isempty(sprayRecommendationsFetchRules)) {
			// 	if (sprayRecommendationsFetchRules.filtered === false&&sprayRecommendationsFetchRules.trycache) {
			// 		let pmzones = utils.getLocalStorageItem("pmzones");
			// 		if(!utils.isempty(pmzones)) {
			// 			dispatch({ type: "REFRESH_PMZONES", pmzones });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	sprayRecommendationsFetchRules = {trycache:true,filtered:true};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }

			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/sprayrecommendations", params).then(
				function (response) {
					if (response.data.success) {
						var sprayrecommendations = [];
						utils.each(response.data.data, function (sprayrecommendation, sprayrecommendationid) {
							//console.warn('org',org);
							sprayrecommendation.zones = typeof sprayrecommendation.zones === 'string' ? JSON.parse(sprayrecommendation.zones) : sprayrecommendation.zones;
							sprayrecommendation.pests = typeof sprayrecommendation.pests === 'string' ? JSON.parse(sprayrecommendation.pests) : sprayrecommendation.pests;
							sprayrecommendation.products = typeof sprayrecommendation.products === 'string' ? JSON.parse(sprayrecommendation.products) : sprayrecommendation.products;
							sprayrecommendations.push(sprayrecommendation);
						});
						if (lookup !== false) {
							//setSprayRecommendationsLookup(sprayrecommendations);
							dispatch({ type: "REFRESH_SPRAYRECOMMENDATIONS", sprayrecommendations });
						} else {
							dispatch({ type: "REFRESH_SPRAYRECOMMENDATIONS", sprayrecommendations });
						}
						//responsereceived= true;
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setSprayRecommendationsLookup([]);
						}
					}
					// sprayRecommendationsFetchRules.filtered = false;
					// localStorage.setItem("sprayRecommendationsFetchRules", JSON.stringify(sprayRecommendationsFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					if(utils.getFlag("SwitchContext")==true&&utils.getAjaxStack()==0) {
						utils.setFlag("SwitchContext",false);
						utils.route();
					}
				});
		},
		editSprayRecommendation: (sprayrecommendation, hideform, setOverlayState, issue = false, filters = false, formMeta = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: sprayrecommendation
			};
			if (formMeta !== false && sprayrecommendation.status==="revoked") {
				utils.each(formMeta, function (value, key) {
					params[key] = value;
				});
			}
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/sprayrecommendation", params, headers).then(
				function (response) {
					if (response.data.success) {
						setFormErrors({ client: "", farm_ref: "", comm_ref: "", cult_ref: "", appmethod: "", waterrate: "", totalarea: "", sprayarea: "", totalvol: "", organisation: "" });
						sprayrecommendation = response.data.data;
						dispatch({ type: "EDIT_SPRAYRECOMMENDATION", sprayrecommendation });
						if (!utils.isempty(sprayrecommendation.uuid) && issue) { value.generateSprayRecommendationPDF(sprayrecommendation.uuid, filters, formMeta,setOverlayState); }
						else { setOverlayState("overlay_hidden"); }
						hideform();
					} else {
						setOverlayState("overlay_hidden");
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
				});
		},
		generateSprayRecommendationPDF: (uuid, filters, formMeta, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				filters: { uuid: uuid }
			};
			if (formMeta !== false) {
				utils.each(formMeta, function (value, key) {
					params[key] = value;
				});
			}
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/sprayrecommendationgenpdf", params, headers).then(
				function (response) {
					if (response.data.success) {
						value.getSprayRecommendations(filters);
						setOverlayState("overlay_hidden");
					} else {
						setOverlayState("overlay_hidden");
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		fetchSprayRecommendationPDF: (uuid, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				headers: { "Authorization": "Bearer " + cookie.token },
				params: { filters: { uuid: uuid } }
			};
			a.get("/sprayrecommendationfetchpdf", params).then(
				function (response) {
					if (response.data.success) {
						response.data.filename = response.data.filename || "pdf.pdf";

						// Convert from b64:
						var binaryString = window.atob(response.data.data);
						var binaryLen = binaryString.length;
						var bytes = new Uint8Array(binaryLen);
						for (var i = 0; i < binaryLen; i++) {
							var ascii = binaryString.charCodeAt(i);
							bytes[i] = ascii;
						}
						//var newBlob = new Blob([bytes], { type: "application/pdf" });
						saveAs(new Blob([bytes], { type: response.data.mimetype }), response.data.filename);
						bytes = null;

						setOverlayState("overlay_hidden");
					} else {
						setOverlayState("overlay_hidden");
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		// sprayRecommendationsLookupState:sprayRecommendationsLookupState,
		// setSprayRecommendationsLookup:setSprayRecommendationsLookup,
		formErrors: formErrors,
		setFormErrors: setFormErrors,
		getSprayRecommendationCIType: () => {
			return "ci_sprayrecommendation";
		},
		clearSprayRecommendations: () => {
			// setSprayRecommendationsLookup([]);
			let empty = [];
			dispatch({ type: "REFRESH_SPRAYRECOMMENDATIONS", empty });
		},
		resetFormErrors: () => {
			setFormErrors({ client: "", farm_ref: "", comm_ref: "", cult_ref: "", appmethod: "", waterrate: "", totalarea: "", sprayarea: "", totalvol: "", organisation: "" });
		}
	}
	return (
		<SprayRecommendationsContext.Provider value={value}>
			{children}
		</SprayRecommendationsContext.Provider>
	);
};
export default SprayRecommendationsProvider;

