import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;
const pheromonesReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_PHEROMONES":
			return {
				pheromones: action.pheromones
			};
		case "ADD_PHEROMONE":
			return {
				pheromones: [...state.pheromones, action.pheromone]
			};
		case "EDIT_PHEROMONE":
			let tpheromones = [];
			utils.each(state.pheromones, function (pheromone, pheromoneind) {
				if (pheromone.uuid !== action.pheromone.uuid) {
					tpheromones.push(pheromone);
				} else {
					tpheromones.push(action.pheromone);
				}
			});
			return {
				pheromones: tpheromones
			};
		default:
			return state;
	}
};

export const PheromonesContext = createContext();

const PheromonesProvider = ({ children }) => {
	const pheromones = def_value;
	const [state, dispatch] = useReducer(pheromonesReducer, {
		pheromones: pheromones,
		filters: {}
	});
	// const [pheromonesLookupState, setPheromonesLookup] = useState([]);
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);

	const value = {
		pheromones: state.pheromones,
		addPheromone: (pheromone, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pheromone
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/pheromone", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "ADD_PHEROMONE", pheromone });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		getPheromone: (id) => {
			let tpheromone = {};
			utils.each(state.pheromones, function (pheromone, pheromoneind) {
				if (pheromone.uuid === id) {
					tpheromone = pheromone;
					return false;
				}
			});
			return tpheromone;
		},
		getPheromones: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let pheromonesFetchRules = utils.getLocalStorageItem("pheromonesFetchRules");
			
			// if (!utils.isempty(pheromonesFetchRules)) {
			// 	if (pheromonesFetchRules.filtered === false&&pheromonesFetchRules.trycache) {
			// 		let pheromones = utils.getLocalStorageItem("pheromones");
			// 		if(!utils.isempty(pheromones)) {
			// 			dispatch({ type: "REFRESH_PHEROMONES", pheromones });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	pheromonesFetchRules = {trycache:true,filtered:true};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/pheromones", params).then(
				function (response) {
					if (response.data.success) {
						var pheromones = [];
						utils.each(response.data.data, function (pheromone, pheromoneid) {
							pheromones.push(pheromone);
						});
						if (lookup !== false) {
							//setPheromonesLookup(pheromones);
							//dispatch({ type: "REFRESH_PHEROMONES", pheromones });
							localStorage.setItem("pheromonesLookup", JSON.stringify(pheromones));
							//localStorage.setItem("pheromonesLookup",JSON.stringify(pheromones));
						} else {
							//dispatch({ type: "REFRESH_PHEROMONES", pheromones });
							localStorage.setItem("pheromonesLookup", JSON.stringify(pheromones));
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setPheromonesLookup([]);
						}
					}
					// pheromonesFetchRules.filtered = false;
					// localStorage.setItem("pheromonesFetchRules", JSON.stringify(pheromonesFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editPheromone: (pheromone, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pheromone
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/pheromone", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "EDIT_PHEROMONE", pheromone });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		// pheromonesLookupState: pheromonesLookupState,
		// setPheromonesLookup: setPheromonesLookup,
		getPheromoneCIType: () => {
			return "ci_pheromone";
		},
		clearPheromones: () => {
			// setPheromonesLookup([]);
			let empty = [];
			// dispatch({ type: "REFRESH_PHEROMONES", empty });
			localStorage.setItem("pheromonesLookup", JSON.stringify(empty));
		}
	}
	return (
		<PheromonesContext.Provider value={value}>
			{children}
		</PheromonesContext.Provider>
	);
};
export default PheromonesProvider;

