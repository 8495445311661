import { createContext, useReducer, useContext } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;
const pestsReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_PESTS":
			return {
				pests: action.pests
			};
		case "ADD_PEST":
			return {
				pests: [...state.pests, action.pest]
			};
		case "EDIT_PEST":
			let tpests = [];
			utils.each(state.pests, function (pest, pestind) {
				if (pest.uuid !== action.pest.uuid) {
					tpests.push(pest);
				} else {
					tpests.push(action.pest);
				}
			});
			return {
				pests: tpests
			};
		default:
			return state;
	}
};

export const PestsContext = createContext();

const PestsProvider = ({ children }) => {
	const pests = def_value;
	const [state, dispatch] = useReducer(pestsReducer, {
		pests: pests,
		filters: {}
	});
	// let ts = localStorage.getItem("pests");
	// const [pestsLookupState, setPestsLookup] = useState((utils.isset(ts) && !utils.isempty(ts) ? JSON.parse(ts) : []));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);

	const value = {
		pests: state.pests,
		addPest: (pest, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pest
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/pest", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "ADD_PEST", pest });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		getPest: (id) => {
			let tpest = {};
			utils.each(state.pests, function (org, orgind) {
				if (org.uuid === id) {
					tpest = org;
					return false;
				}
			});
			return tpest;
		},
		getPests: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let pestsFetchRules = utils.getLocalStorageItem("pestsFetchRules");
			
			// if (!utils.isempty(pestsFetchRules)) {
			// 	if (pestsFetchRules.filtered === false&&pestsFetchRules.trycache) {
			// 		let pests = utils.getLocalStorageItem("pests");
			// 		if(!utils.isempty(pests)) {
			// 			dispatch({ type: "REFRESH_PESTS", pests });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	pestsFetchRules = {trycache:true,filtered:true};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/pests", params).then(
				function (response) {
					if (response.data.success) {
						var pests = [];
						utils.each(response.data.data, function (pest, pestid) {
							//console.warn('org',org);
							pests.push(pest);
						});
						if (lookup !== false) {
							// setPestsLookup(pests);
							//dispatch({ type: "REFRESH_PESTS", pests });
							localStorage.setItem("pestsLookup", JSON.stringify(pests));
						} else {
							//dispatch({ type: "REFRESH_PESTS", pests });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setPestsLookup([]);
						}
					}
					// pestsFetchRules.filtered = false;
					// localStorage.setItem("pestsFetchRules", JSON.stringify(pestsFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editPest: (pest, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pest
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/pest", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "EDIT_PEST", pest });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		// pestsLookupState: pestsLookupState,
		// setPestsLookup: setPestsLookup,
		getPestCIType: () => {
			return "ci_pest";
		},
		clearPests: () => {
			//setPestsLookup([]);
			let empty = [];
			//dispatch({ type: "REFRESH_PESTS", empty });
			localStorage.setItem("pestsLookup", JSON.stringify(empty));
		}
	}
	return (
		<PestsContext.Provider value={value}>
			{children}
		</PestsContext.Provider>
	);
};
export default PestsProvider;

