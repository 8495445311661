import React, { useEffect, useState, useContext } from "react";
import { SprayProgramsContext } from "../../contexts/sprayprograms";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import "../../index.css";
import utils from "../../utils/utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { AddCircle, Edit } from "@material-ui/icons";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import SprayProgramForm from "./SprayProgramForm";
import { StateManagerContext } from '../../contexts/statemanager';

// Theme
const ThemeTextField = styled(TextField)(utils.theme.TextField);
const ThemeSelect = styled(Select)(utils.theme.Select);

export function SprayProgramList(props) {
  const { sprayprograms, getSprayPrograms, resetFormErrors } = useContext(SprayProgramsContext);
  var personsLookupState = utils.getLocalStorageItem("personsLookup");
  var farmCommoditiesLookupState = utils.getLocalStorageItem("farmCommoditiesLookup");
  // const { organisationsLookupState, getOrganisations } = useContext(OrganisationsContext);
  // const { personsLookupState, getPersons } = useContext(PersonsContext);
	const { setOverlayState } = useContext(StateManagerContext);
  //console.warn("sprayprograms",sprayprograms);
  useEffect(() => {
    if (utils.isempty(sprayprograms)) {
      setOverlayState("overlay_visible");
      getSprayPrograms({ status: "active" },setOverlayState);
    }
  }, []);

  const [formContainerVis, setformContainerVis] = useState(
    "form-container dialog-loader-2 dialog-loader-2-hidden"
  );
  const [sprayprogramid, setsprayprogramid] = useState({id:"",fromID:""});
  const [formAction, setFormAction] = useState("");
  const [formFilters, setFormFilters] = useState({ name: "", status: "active", comm_ref: "" });
  const [pageIndex, setPageIndex] = useState(0);

  //const isEven = (idx) => idx % 2 === 0;
  const showForm = () => {
    setformContainerVis(
      "form-container dialog-loader-2 dialog-loader-2-show"
    );
  };
  const hideForm = () => {
    resetFormErrors();
    setformContainerVis(
      "form-container dialog-loader-2 dialog-loader-2-hidden"
    );
    setPageIndex(0);
  };
  const preSprayProgramID = (id,fromID="") => {
    if (id !== "") {
      setsprayprogramid({id:id,fromID:fromID});
      setFormAction("edit");
      showForm();
    } else {
      setsprayprogramid({id:id,fromID:fromID});
      setFormAction("add");
      showForm();
    }
  };

  // if (utils.isempty(organisationsLookupState)) {
  // //   getOrganisations({ status: "active" }, true);
  // // }
  // // if (utils.isempty(personsLookupState)) {
  // //   getPersons({ status: "active" }, true);
  // // }
  // // var SprayProgramsData = [];

  //Dialog
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const handleFilterDialogClickOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };
  //console.warn("sprayprograms", sprayprograms);
  return (
    <>
      <div className={formContainerVis}>
        <SprayProgramForm
          hideForm={hideForm}
          sprayprogramid={sprayprogramid}
          formFilters={formFilters}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      </div>

      <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
        <Box>
          <Button
            variant="outlined"
            onClick={handleFilterDialogClickOpen}
            startIcon={<FilterListIcon />}
            style={utils.theme.Button}
          >
            Filter
          </Button>
          <div className="filter-dialog">
            <Dialog
              open={openFilterDialog}
              onClose={handleFilterDialogClose}
              fullWidth={true}
              maxWidth={"sm"}
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{ m: 0, p: 2, color: "#7A9A01" }}
              >
                List Filters
                <IconButton
                  aria-label="close"
                  onClick={handleFilterDialogClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: "#7A9A01",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", }} >
                  <Box   >
                    <ThemeTextField
                      type="text"
                      id="name"
                      label="Name"
                      placeholder="Name"
                      helperText="Filter list by name"
                      sx={{ width: '400px' }}
                      fullWidth={true}
                      value={formFilters.name}
                      onChange={(event) =>
                        setFormFilters({ ...formFilters, name: event.target.value })
                      }
                    />
                  </Box>
                  <Box>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel
                        id="simple-select-disabled-label"
                        sx={{ "&.Mui-focused": { color: "#7A9A01" } }}
                      >
                        Status
                      </InputLabel>
                      <ThemeSelect
                        labelId="simple-select-disabled-label"
                        id="simple-select-disabled"
                        value={formFilters.status}
                        label="Status"
                        sx={{ width: "400px" }}
                        onChange={(event) =>
                          setFormFilters({ ...formFilters, status: event.target.value })
                        }
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "rgb(199, 212, 148)",
                              color: "rgb(122, 154, 1)",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"issued"}>Issued</MenuItem>
                        <MenuItem value={"closed"}>Closed</MenuItem>
                        <MenuItem value={"deleted"}>Deleted</MenuItem>
                      </ThemeSelect>
                      <FormHelperText>Filter list by status</FormHelperText>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel
                        id="simple-select-disabled-label"
                        sx={{ "&.Mui-focused": { color: "#7A9A01" } }}
                      >
                        Commodity
                      </InputLabel>
                      <ThemeSelect
                        labelId="simple-select-disabled-label"
                        id="simple-select-disabled"
                        value={formFilters.comm_ref}
                        label="Commodity"
                        sx={{ width: "400px" }}
                        onChange={(event) =>
                          setFormFilters({ ...formFilters, comm_ref: event.target.value })
                        }
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "rgb(199, 212, 148)",
                              color: "rgb(122, 154, 1)",
                            },
                          },
                          PaperProps: {
                            style: {
                              maxHeight: 48 * 4.5 + 8,
                              width: 250,
                            },
                          },
                        }}
                      >
                        {farmCommoditiesLookupState.map((value, ind) => {
                          return (
                            <MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
                          );
                        })}
                      </ThemeSelect>
                      <FormHelperText>Filter list by commodity</FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOverlayState("overlay_visible");
                    utils.setLocalStorageItem(
                      "sprayProgramsFetchRules",
                      true,
                      "filtered"
                    );
                    getSprayPrograms(formFilters,setOverlayState);
                    setOpenFilterDialog(false);
                  }}
                  autoFocus
                  variant="outlined"
                  style={utils.theme.Button}
                >
                  Apply Filters
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Box>

        <Box sx={{ alignSelf: 'center', display: 'flex' }}>
          <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: '#7a9a01', marginRight: '5px' }}>Status:</span>
            <span style={{ color: 'GrayText' }}>{formFilters.status.charAt(0).toUpperCase() + formFilters.status.slice(1)}</span>
          </div>
        </Box>

        <Box>
          <Button
            onClick={() => {
              preSprayProgramID("");
            }}
            variant="outlined"
            startIcon={<AddCircle />}
            style={utils.theme.Button}
          >
            Add
          </Button>
        </Box>
      </Box>

      <Box sx={{ width: "100%", marginTop: "5px", borderTop: 1, borderColor: "divider" }} >
        <div className="nexus-scroll-table">
          <table>
            <thead>
              <tr>
                {/* <th>Farm</th> */}
                {/* <th>Organisation</th> */}
                <th>Program Name</th>
                <th>Program Number</th>
                <th>Comments</th>
                <th>Created By</th>
                <th>Edit</th>
                <th>Copy</th>
              </tr>
            </thead>
            <tbody>
              {!utils.isempty(sprayprograms)
                ? sprayprograms.map((sprayprogram) => (
                  <tr key={utils.uuid()}>
                    {/* <td>
                      {!utils.isempty(sprayprogram.farm_ref)
                        ? utils.getName(
                          farmsLookupState,
                          sprayprogram.farm_ref
                        )
                        : ""}
                    </td>
                    <td>
                      {!utils.isempty(sprayprogram.organisation)
                        ? utils.getName(
                          organisationsLookupState,
                          sprayprogram.organisation
                        )
                        : ""}
                    </td> */}
                    <td>{sprayprogram.name}</td>
                    <td>{!utils.isset(sprayprogram.number)?"":sprayprogram.number}</td>
                    <td>{sprayprogram.comments}</td>
                    <td>{!utils.isempty(sprayprogram.created_by) ? utils.getName(personsLookupState, sprayprogram.created_by) : ""}</td>
                    <td width={30}>
                      <IconButton
                        onClick={() => {
                          preSprayProgramID(sprayprogram.uuid);
                        }}
                        color="primary"
                        sx={{ "&:hover": { color: "#789a3d" } }}
                        style={{ color: "#7A9A01" }}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                    <td width={30}>
                      <IconButton
                        onClick={() => {
                          preSprayProgramID("",sprayprogram.uuid);
                        }}
                        color="primary"
                        sx={{ "&:hover": { color: "#789a3d" } }}
                        style={{ color: "#7A9A01" }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))
                : ""}
            </tbody>
          </table>
        </div>
      </Box>
    </>
  );
}

export default SprayProgramList;
