import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts/font.css';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import reportWebVitals from './reportWebVitals';
import OrganisationsProvider from './contexts/organisations';
import SessionsProvider from './contexts/sessions';
import FarmsProvider from './contexts/farms';
import PersonsProvider from './contexts/persons';
import OrchardsProvider from './contexts/orchards';
import PMZonesProvider from './contexts/pmzones';
import FarmCommoditiesProvider from './contexts/farmcommodities';
import CultivarsProvider from './contexts/cultivars';
import PestTrapsProvider from './contexts/pesttraps';
import PestsProvider from './contexts/pests';
import ContextGroupsProvider from './contexts/contextgroups';
import PestTrapTypesProvider from './contexts/pesttraptypes';
import PheromonesProvider from './contexts/pheromones';
import HarvestZonesProvider from './contexts/harvestzones';
import PestTrapInspectionsProvider from './contexts/pesttrapinspections';
import SprayTargetsProvider from './contexts/spraytargets';
import ApplicationMethodsProvider from './contexts/applicationmethods';
import PestInspectionsProvider from './contexts/pestinspections';
import InspectionTemplatesProvider from './contexts/inspectiontemplates';
import SprayRecommendationsProvider from './contexts/sprayrecommendations';
import SprayProgramsProvider from './contexts/sprayprograms';
import PlantSprayProductsProvider from './contexts/plantsprayproducts';
import StateManagerProvider from './contexts/statemanager';
import UserContextsProvider from './contexts/usercontexts';
import ZoneTypesProvider from './contexts/zonetypes';
import UpdateCIStatusStateProvider from './contexts/updatecistatusstate';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <SessionsProvider>
        <UpdateCIStatusStateProvider>
          <UserContextsProvider>
            <OrganisationsProvider>
              <FarmsProvider>
                <PersonsProvider>
                  <OrchardsProvider>
                    <FarmCommoditiesProvider>
                      <CultivarsProvider>
                        <PMZonesProvider>
                          <PestTrapsProvider>
                            <PestsProvider>
                              <ContextGroupsProvider>
                                <HarvestZonesProvider>
                                  <PheromonesProvider>
                                    <PestTrapTypesProvider>
                                      <PestTrapInspectionsProvider>
                                        <PestInspectionsProvider>
                                          <InspectionTemplatesProvider>
                                            <SprayTargetsProvider>
                                              <PlantSprayProductsProvider>
                                                <ApplicationMethodsProvider>
                                                  <SprayRecommendationsProvider>
                                                    <SprayProgramsProvider>
                                                      <ZoneTypesProvider>
                                                        <StateManagerProvider>
                                                          <BrowserRouter>
                                                            <App />
                                                          </BrowserRouter>
                                                        </StateManagerProvider>
                                                      </ZoneTypesProvider>
                                                    </SprayProgramsProvider>
                                                  </SprayRecommendationsProvider>
                                                </ApplicationMethodsProvider>
                                              </PlantSprayProductsProvider>
                                            </SprayTargetsProvider>
                                          </InspectionTemplatesProvider>
                                        </PestInspectionsProvider>
                                      </PestTrapInspectionsProvider>
                                    </PestTrapTypesProvider>
                                  </PheromonesProvider>
                                </HarvestZonesProvider>
                              </ContextGroupsProvider>
                            </PestsProvider>
                          </PestTrapsProvider>
                        </PMZonesProvider>
                      </CultivarsProvider>
                    </FarmCommoditiesProvider>
                  </OrchardsProvider>
                </PersonsProvider>
              </FarmsProvider>
            </OrganisationsProvider>
          </UserContextsProvider>
        </UpdateCIStatusStateProvider>
      </SessionsProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
