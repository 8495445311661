import './App.css';
import './fonts/font.css';
import './index.css';
import Navigation from './components/Navigation';
import Map from './components/Map/Map';
import Chart from './components/Chart/Chart';
import { Routes, Route } from "react-router-dom";
import { useStyles } from './styles';
import React, { useState, useContext, useEffect } from 'react';
import CensusRegisterComponent from "./components/Census/Census";
import DashboardComponent from "./components/Dashboard/Dashboard";
import PestAndDiseaseComponent from "./components/PestAndDisease/PestAndDisease";
import PestAndDiseaseRoot from "./components/PestAndDisease/PestAndDiseaseRoot";
import CropChemRoot from "./components/CropChem/CropChemRoot";
import CropChemComponent from "./components/CropChem/CropChem";
import SprayRecommendationComponent from "./components/CropChem/SprayRecommendation";
import SprayProgramComponent from './components/CropChem/SprayProgram';
import PestMonitoringCompnent from './components/Pest Monitoring/PestMonitoring';
import PestTrapInspectionsComponent from './components/PestTrapInspections/PestTrapInspections';
import { SessionsContext } from './contexts/sessions';
import { StateManagerContext } from './contexts/statemanager';
import Button from '@mui/material/Button';
import ScoutInspectionsComponent from './components/PestInspections/PestInspections';
import LoginImage from './assets/Login/nexus_login.png';
import LoadingOverlay from './components/Overlays/Loading';
import { UpdateCIStatusConfirm } from './components/Overlays/UpdateCIStatusConfirm';
//external
import clsx from 'clsx';

// @material-ui
import { IconButton, Tooltip } from '@material-ui/core';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { MapOutlined } from '@material-ui/icons';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import utils from './utils/utils';

function Copyright(props) {

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://nexus.keyphase.co.za/">
        Nexus AG
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

const ThemeTextField = styled(TextField)(utils.theme.TextField);

function App() {

  const classes = useStyles();
  const { authorize, validate, sessions } = useContext(SessionsContext);
  const [showChart, setshowChart] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginbuttontext] = useState("Sign In");
  const { clearStates, getStates, setOverlayState } = useContext(StateManagerContext);
  const href = window.location.pathname;
  //const location = useLocation();

  useEffect(() => {
    validate(clearStates);
  }, []);


  console.log("Init app.js",href);

  return (
    <div>
      <UpdateCIStatusConfirm />
      <LoadingOverlay></LoadingOverlay>
      {
        sessions.authorized ?
          <div className={classes.appRoot}>
            <Navigation />
            <Paper className={classes.appRootMainGridContainer} elevation={8}
              sx={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                overflow: 'auto',
                height: 'fit-content',
              }}>
              <div className={classes.appGridContainer}>
                <Routes>
                  {/* <Route exact path="/" element={<Navigate to='/census' />} /> */}
                  <Route path='dashboard' element={<DashboardComponent />} />
                  <Route path='census' element={<CensusRegisterComponent />} />
                  <Route path='pest-and-disease' element={<PestAndDiseaseRoot />} />
                  <Route path='pest-and-disease-settings' element={<PestAndDiseaseComponent />} />
                  <Route path='crop-chemicals' element={<CropChemRoot />} />
                  <Route path='crop-chemicals-settings' element={<CropChemComponent />} />
                  <Route path='crop-chemicals-recommendations' element={<SprayRecommendationComponent />} />
                  <Route path='crop-chemicals-recommendation-programs' element={<SprayProgramComponent />} />
                  <Route path='pest-monitoring' element={<PestMonitoringCompnent />} />
                  <Route path='pest-monitoring-trap-readings' element={<PestTrapInspectionsComponent />} />
                  <Route path='scout-insepctions' element={<ScoutInspectionsComponent />} />
                </Routes>
              </div>
            </Paper>
            {
              (href === '/pest-monitoring-trap-readings' || href === '/scout-insepctions') ?
                showChart ?
                  <Paper className={classes.chartContainer} elevation={8}>
                    <div className={classes.chartChildContainer}>
                      <Chart />
                    </div>
                  </Paper>
                  : null
                : null
            }
            {
              showMap ?
                <Paper className={classes.mapFunctionContainer} elevation={8}>
                  <div className={classes.mapContainer}>
                    <Map />
                  </div>
                </Paper>
                : null
            }
            <div className={classes.toolbarOptionsContainer}>
              {
                showMap ? (
                  <Tooltip title="Hide Map">
                    <IconButton style={{ backgroundColor: "#c7d494" }} size='medium' className={clsx(classes.toolbarOptionsBtn, showMap && classes.toolbarOptionsBtnActive)} onClick={() => setShowMap(!showMap)}>
                      <MapOutlined fontSize='small' style={{ color: "#7A9A01" }} />
                    </IconButton>
                  </Tooltip>
                ) : <Tooltip title="Show Map">
                  <IconButton size='medium' className={clsx(classes.toolbarOptionsBtn, showMap && classes.toolbarOptionsBtnActive)} onClick={() => setShowMap(!showMap)}>
                    <MapOutlined fontSize='small' />
                  </IconButton>
                </Tooltip>
              }

              {
                (href === '/pest-monitoring-trap-readings' || href === '/scout-insepctions') ?
                  showChart ? (
                    <Tooltip title="Hide Chart">
                      <IconButton style={{ backgroundColor: "#c7d494" }} size='medium' className={clsx(classes.toolbarOptionsBtn, showChart && classes.toolbarOptionsBtnActive)} onClick={() => setshowChart(!showChart)}>
                        <ShowChartIcon fontSize='small' style={{ color: "#7A9A01" }} />
                      </IconButton>
                    </Tooltip>
                  ) : <Tooltip title="Show Chart">
                    <IconButton size='medium' className={clsx(classes.toolbarOptionsBtn, showChart && classes.toolbarOptionsBtnActive)} onClick={() => setshowChart(!showChart)}>
                      <ShowChartIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  : null
              }
            </div>
          </div>
          :
          // Login Screen
          <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
              <CssBaseline />
              <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                  backgroundImage: 'url(' + LoginImage + ')',
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
              <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                  sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: "#7A9A01" }}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Sign in
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <ThemeTextField
                      type="text"
                      id="username"
                      label="User"
                      placeholder="Username..."
                      margin="normal"
                      required
                      fullWidth
                      autoFocus
                      value={username}
                      onChange={(event) =>
                        setUsername(event.target.value)
                      }
                    />
                    <ThemeTextField
                      name="password"
                      type="password"
                      id="password"
                      label="Password"
                      placeholder="Password..."
                      margin="normal"
                      required
                      fullWidth
                      value={password}
                      onChange={(event) =>
                        setPassword(event.target.value)
                      }
                    />
                    <Button
                      onClick={() => { setOverlayState("overlay_visible"); setTimeout(() => { authorize(username, password, getStates, setOverlayState); }, 100); }}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      style={utils.theme.LoginButton}
                    >
                      {loginbuttontext}
                    </Button>
                    {/* <Grid container>
                      <Grid item xs>
                        <Link href="#" variant="body2" color="#7A9A01">
                          Forgot password?
                        </Link>
                      </Grid>
                    </Grid> */}
                    <Copyright sx={{ mt: 5 }} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </ThemeProvider>
      }
    </div>
  );
};

export default App;