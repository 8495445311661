import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;

const inspectiontemplateReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_INSPECTIONTEMPLATES":
			return {
				inspectiontemplates: action.inspectiontemplates
			};
		case "ADD_INSPECTIONTEMPLATE":
			return {
				inspectiontemplates: [...state.inspectiontemplates, action.inspectiontemplate]
			};
		case "EDIT_INSPECTIONTEMPLATE":
			let tinspectiontemplates = [];
			utils.each(state.inspectiontemplates, function (comm, commind) {
				if (comm.uuid !== action.inspectiontemplate.uuid) {
					tinspectiontemplates.push(comm);
				} else {
					tinspectiontemplates.push(action.inspectiontemplate);
				}
			});
			return {
				inspectiontemplates: tinspectiontemplates
			};
		default:
			return state;
	}
};

export const InspectionTemplatesContext = createContext();

const InspectionTemplateProvider = ({ children }) => {
	const inspectiontemplates = def_value;
	const [state, dispatch] = useReducer(inspectiontemplateReducer, {
		inspectiontemplates: inspectiontemplates,
		filters: {}
	});
	// let ts = localStorage.getItem("inspectionTemplatesLookup");
	// const [inspectionTemplatesLookupState,setInspectionTemplatesLookup] = useState((utils.isset(ts)&&!utils.isempty(ts)?JSON.parse(ts):[]));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const value = {
		inspectiontemplates: state.inspectiontemplates,
		addInspectionTemplate: (inspectiontemplate, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: inspectiontemplate
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/inspectiontemplate", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "ADD_INSPECTIONTEMPLATE", inspectiontemplate });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		getInspectionTemplate: (id) => {
			let tinspectiontemplate = {};
			utils.each(state.inspectiontemplates, function (comm, commind) {
				if (comm.uuid === id) {
					tinspectiontemplate = comm;
					return false;
				}
			});
			return tinspectiontemplate;
		},
		getInspectionTemplates: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let inspectionTemplatesFetchRules = utils.getLocalStorageItem("inspectionTemplatesFetchRules");
			
			// if (!utils.isempty(inspectionTemplatesFetchRules)) {
			// 	if (inspectionTemplatesFetchRules.filtered === false&&inspectionTemplatesFetchRules.trycache) {
			// 		let farms = utils.getLocalStorageItem("farms");
			// 		if(!utils.isempty(farms)) {
			// 			dispatch({ type: "REFRESH_HARVESTZONES", farms });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	inspectionTemplatesFetchRules = {trycache:true,filtered:true};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/inspectiontemplates", params).then(
				function (response) {
					if (response.data.success) {
						var inspectiontemplates = [];
						utils.each(response.data.data, function (inspectiontemplate, inspectiontemplateid) {
							inspectiontemplates.push(inspectiontemplate);
						});
						if (lookup !== false) {
							//setInspectionTemplatesLookup(inspectiontemplates);
							// dispatch({ type: "REFRESH_INSPECTIONTEMPLATES", inspectiontemplates });
							localStorage.setItem("inspectionTemplatesLookup", JSON.stringify(inspectiontemplates));
							// localStorage.setItem("inspectiontemplates", JSON.stringify(inspectiontemplates));
						} else {
							// dispatch({ type: "REFRESH_INSPECTIONTEMPLATES", inspectiontemplates });
							// localStorage.setItem("inspectiontemplates", JSON.stringify(inspectiontemplates));
						}
						//responsereceived= true;
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							// setInspectionTemplatesLookup([]);
						}
					}
					// inspectionTemplatesFetchRules.filtered = false;
					// localStorage.setItem("inspectionTemplatesFetchRules", JSON.stringify(inspectionTemplatesFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editInspectionTemplate: (inspectiontemplate, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: inspectiontemplate
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/inspectiontemplate", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "EDIT_INSPECTIONTEMPLATE", inspectiontemplate });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		// inspectionTemplatesLookupState:inspectionTemplatesLookupState,
		// setInspectionTemplatesLookup:setInspectionTemplatesLookup,
		getInspectionTemplateCIType: () => {
			return "ci_inspectiontemplate";
		},
		clearInspectionTemplates: () => {
			// setInspectionTemplatesLookup([]);
			let empty = [];
			// dispatch({ type: "REFRESH_INSPECTIONTEMPLATES", empty });
			localStorage.setItem("inspectionTemplatesLookup", JSON.stringify(empty));
			// localStorage.setItem("inspectiontemplates", JSON.stringify(empty));
		}
	}
	return (
		<InspectionTemplatesContext.Provider value={value}>
			{children}
		</InspectionTemplatesContext.Provider>
	);
};
export default InspectionTemplateProvider;