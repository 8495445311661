import { createContext, useReducer, useContext, useEffect, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;
const ApplicationMethodsReducer = (state, action) => {
	let tapplicationmethods = [];
	switch (action.type) {
		case "REFRESH_APPLICATIONMETHODS":
			localStorage.setItem("applicationMethodsLookup", JSON.stringify(action.applicationmethods));
			localStorage.setItem("applicationmethods", JSON.stringify(action.applicationmethods));
			return {
				applicationmethods: action.applicationmethods
			};
		case "ADD_APPLICATIONMETHODS":
			tapplicationmethods = utils.updateArray(state.applicationmethods,action.applicationmethod,);
			tapplicationmethods = utils.sortArray(tapplicationmethods);
			localStorage.setItem("applicationMethodsLookup", JSON.stringify(tapplicationmethods));
			localStorage.setItem("applicationmethods", JSON.stringify(tapplicationmethods));
			return {
				applicationmethods: tapplicationmethods
			};
		case "EDIT_APPLICATIONMETHODS":
			utils.each(state.applicationmethods, function (applicationmethod, applicationmethodind) {
				if (applicationmethod.uuid !== action.applicationmethod.uuid) {
					tapplicationmethods.push(applicationmethod);
				} else {
					tapplicationmethods.push(action.applicationmethod);
				}
			});
			tapplicationmethods = utils.sortArray(tapplicationmethods);
			localStorage.setItem("applicationMethodsLookup", JSON.stringify(tapplicationmethods));
			localStorage.setItem("applicationmethods", JSON.stringify(tapplicationmethods));
			return {
				applicationmethods: tapplicationmethods
			};
		default:
			return state;
	}
};

export const ApplicationMethodsContext = createContext();

const ApplicationMethodsProvider = ({ children }) => {
	const applicationmethods = def_value;
	const [state, dispatch] = useReducer(ApplicationMethodsReducer, {
		applicationmethods: applicationmethods,
		filters: {}
	});
	// let ts = localStorage.getItem("applicationMethodsLookup");
	// const [applicationMethodsLookupState,setApplicationMethodsLookup] = useState((utils.isset(ts)&&!utils.isempty(ts)?JSON.parse(ts):[]));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);

	const value = {
		applicationmethods: state.applicationmethods,
		addApplicationMethod: (applicationmethod, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: applicationmethod
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/applicationmethod", params, headers).then(
				function (response) {
					if (response.data.success) {
						applicationmethod = response.data.data;
						dispatch({ type: "ADD_APPLICATIONMETHODS", applicationmethod });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		getApplicationMethod: (id) => {
			let tapplicationmethod = {};
			utils.each(state.applicationmethods, function (applicationmethod, applicationmethodind) {
				if (applicationmethod.uuid === id) {
					tapplicationmethod = applicationmethod;
					return false;
				}
			});
			return tapplicationmethod;
		},
		getApplicationMethods: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let applicationMethodsFetchRules = utils.getLocalStorageItem("applicationMethodsFetchRules");
			
			// if (!utils.isempty(applicationMethodsFetchRules)) {
			// 	if (applicationMethodsFetchRules.filtered === false&&applicationMethodsFetchRules.trycache) {
			// 		let applicationmethods = utils.getLocalStorageItem("applicationmethods");
			// 		if(!utils.isempty(applicationmethods)) {
			// 			dispatch({ type: "REFRESH_APPLICATIONMETHODS", applicationmethods });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	applicationMethodsFetchRules = {trycache:true,filtered:true};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/applicationmethods", params).then(
				function (response) {
					if (response.data.success) {
						var applicationmethods = [];
						utils.each(response.data.data, function (applicationmethod, applicationmethodid) {
							applicationmethods.push(applicationmethod);
						});
						if (lookup !== false) {
							//setApplicationMethodsLookup(applicationmethods);
							dispatch({ type: "REFRESH_APPLICATIONMETHODS", applicationmethods });
							//localStorage.setItem("applicationMethods",JSON.stringify(applicationmethods));
						} else {
							dispatch({ type: "REFRESH_APPLICATIONMETHODS", applicationmethods });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setApplicationMethodsLookup([]);
						}
					}
					// applicationMethodsFetchRules.filtered = false;
					// localStorage.setItem("farmFetchRules", JSON.stringify(applicationMethodsFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editApplicationMethod: (applicationmethod, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: applicationmethod
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/applicationmethod", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "EDIT_APPLICATIONMETHODS", applicationmethod });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		// applicationMethodsLookupState:applicationMethodsLookupState,
		// setApplicationMethodsLookup:setApplicationMethodsLookup,
		getApplicationMethodCIType: () => {
			return "ci_applicationmethod";
		},
		clearApplicationMethods: () => {
			localStorage.setItem("applicationMethodsLookup", []);
			// setApplicationMethodsLookup([]);
			let empty = [];
			// dispatch({type:"REFRESH_APPLICATIONMETHODS",empty});
			localStorage.setItem("applicationMethodsLookup", JSON.stringify(empty));
			localStorage.setItem("applicationmethods", JSON.stringify(empty));
		}
	}

	return (
		<ApplicationMethodsContext.Provider value={value}>
			{children}
		</ApplicationMethodsContext.Provider>
	);
};
export default ApplicationMethodsProvider;

