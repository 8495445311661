import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var ajaxstack = false;
var def_value = [];

const farmsReducer = (state, action) => {
	let tfarms = [];
	switch (action.type) {
		case "REFRESH_FARMS":
			let t = utils.getLocalStorageItem("farmsLookup");
			if(utils.isempty(t)) {
				localStorage.setItem("farmsLookup", JSON.stringify(action.farms));
			}
			localStorage.setItem("farms", JSON.stringify(action.farms));
			return {
				farms: action.farms
			};
		case "ADD_FARM":
			tfarms = utils.updateArray(state.farms, action.farm,);
			tfarms = utils.sortArray(tfarms);
			localStorage.setItem("farmsLookup", JSON.stringify(tfarms));
			localStorage.setItem("farms", JSON.stringify(tfarms));
			return {
				farms: tfarms
			};
		case "EDIT_FARM":
			utils.each(state.farms, function (farm, farmind) {
				if (farm.uuid !== action.farm.uuid) {
					tfarms.push(farm);
				} else {
					tfarms.push(action.farm);
				}
			});
			tfarms = utils.sortArray(tfarms);
			localStorage.setItem("farmsLookup", JSON.stringify(tfarms));
			localStorage.setItem("farms", JSON.stringify(tfarms));
			return {
				farms: tfarms
			};
		default:
			return state;
	}
};

export const FarmsContext = createContext();

const FarmsProvider = ({ children }) => {
	const farms = def_value;
	const [state, dispatch] = useReducer(farmsReducer, {
		farms: farms,
		filters: {}
	});
	// let ts = localStorage.getItem("farmsLookup");
	// const [farmsLookupState,setFarmsLookup] = useState((utils.isset(ts)&&!utils.isempty(ts)?JSON.parse(ts):[]));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const [formErrors, setFormErrors] = useState({ name: '', shortcode: '', puc_code: '', organisation_id: '', contactperson: '' });
	const value = {
		farms: state.farms,
		addFarm: (farm, hideform, setOverlayState) => {
			const a = utils.new("ajax");

			if (utils.isempty(cookie)) { return false; }

			var params = {
				data: farm
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/farm", params, headers).then(
				function (response) {
					console.log("FARM POST RESPONSE", response)
					if (response.data.success) {
						farm = response.data.data
						dispatch({ type: "ADD_FARM", farm });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		getFarm: (id) => {
			let tfarm = {};
			utils.each(state.farms, function (farm, farmind) {
				if (farm.uuid === id) {
					tfarm = farm;
					return false;
				}
			});
			return tfarm;
		},
		getFarms: (filters, lookup = false, ttoken = false, force = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let farmFetchRules = utils.getLocalStorageItem("farmFetchRules");

			// if (!utils.isempty(farmFetchRules) && !force) {
			// 	if (farmFetchRules.filtered === false && farmFetchRules.trycache) {
			// 		let farms = utils.getLocalStorageItem("farms");
			// 		if (!utils.isempty(farms)) {
			// 			dispatch({ type: "REFRESH_FARMS", farms });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	farmFetchRules = { trycache: true, filtered: true };
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/farms", params).then(
				function (response) {
					if (response.data.success) {
						var farms = [];
						utils.each(response.data.data, function (farm, farmid) {
							//console.warn('org',org);
							farms.push(farm);
						});
						if (lookup !== false) {
							//setFarmsLookup(farms);
							dispatch({ type: "REFRESH_FARMS", farms });
						} else {
							dispatch({ type: "REFRESH_FARMS", farms });
						}
						//responsereceived= true;
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setFarmsLookup([]);
						}
					}
					// farmFetchRules.filtered = false;
					// localStorage.setItem("farmFetchRules", JSON.stringify(farmFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editFarm: (farm, hideform, filters, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: farm
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/farm", params, headers).then(
				function (response) {
					if (response.data.success) {
						setFormErrors({ name: '', shortcode: '', puc_code: '', organisation_id: '', contactperson: '' });
						//dispatch({ type: "EDIT_FARM", farm });
						value.getFarms(filters, false, false, true)
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		// farmsLookupState:farmsLookupState,
		// setFarmsLookup:setFarmsLookup,
		formErrors: formErrors,
		setFormErrors: setFormErrors,
		getFarmCIType: () => {
			return "ci_farm";
		},
		clearFarms: () => {
			//setFarmsLookup([]);
			let empty = [];
			dispatch({ type: "REFRESH_FARMS", empty });
			localStorage.setItem("farmsLookup", JSON.stringify(empty));
			localStorage.setItem("farms", JSON.stringify(empty));
		},
		resetFormErrors: () => {
			setFormErrors({ name: '', shortcode: '', puc_code: '', organisation_id: '', contactperson: '' });
		}
	}
	return (
		<FarmsContext.Provider value={value}>
			{children}
		</FarmsContext.Provider>
	);
};
export default FarmsProvider;

