import React, { useState, useContext, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import { PersonsContext } from '../../contexts/persons';
import { StateManagerContext } from '../../contexts/statemanager';

import utils from "../../utils/utils";
import { v4 as uuidv4 } from 'uuid';
import { metaphone } from 'metaphone';
import '../../index.css';
import styled from "@emotion/styled";

// Theme
const ThemeFormTextField = styled(TextField)(utils.theme.FormTextField);

export function Step001({ formData, setFormData, formErrors }) {
    return (
        <div className="step001-container">
            <ThemeFormTextField
                type="text"
                id="first_name"
                label="Name"
                placeholder="Name..."
                value={formData.first_name}
                onChange={(event) =>
                    setFormData({ ...formData, first_name: event.target.value })
                }
                error={!utils.isempty(formErrors.first_name) ? true : false}
                helperText={formErrors.first_name}
            />
                <ThemeFormTextField
                    type="text"
                    id="last_name"
                    label="Surname"
                    placeholder="Last Name..."
                    value={formData.last_name}
                    onChange={(event) =>
                        setFormData({ ...formData, last_name: event.target.value })
                    }
                    sx={{ marginTop: "5px" }}
                    error={!utils.isempty(formErrors.last_name) ? true : false}
                    helperText={formErrors.last_name}
                />
                <ThemeFormTextField
                    type="text"
                    id="username"
                    label="User Name"
                    placeholder="User Name..."
                    value={formData.username}
                    onChange={(event) =>
                        setFormData({ ...formData, username: event.target.value })
                    }
                    sx={{ marginTop: "5px" }}
                    error={!utils.isempty(formErrors.username) ? true : false}
                    helperText={formErrors.username}
                />
                <ThemeFormTextField
                    type="text"
                    id="mobile_phone_number"
                    label="Mobile Phone Number"
                    placeholder="Mobile Phone Number..."
                    value={formData.mobile_phone_number}
                    onChange={(event) =>
                        setFormData({ ...formData, mobile_phone_number: event.target.value })
                    }
                    sx={{ marginTop: "5px" }}
                    error={!utils.isempty(formErrors.mobile_phone_number) ? true : false}
                    helperText={formErrors.mobile_phone_number}
                />
                <ThemeFormTextField
                    type="text"
                    id="email"
                    label="Email"
                    placeholder="Email..."
                    value={formData.email}
                    onChange={(event) =>
                        setFormData({ ...formData, email: event.target.value })
                    }
                    sx={{ marginTop: "5px" }}
                    error={!utils.isempty(formErrors.email) ? true : false}
                    helperText={formErrors.email}
                />
        </div>
    );
}

export default function PersonsForm({ hideForm, personid, formFilters }) {
    const [pageIndex, setPageIndex] = useState(0);
    //const numPages = 1;
    const { addPerson, getPerson, editPerson, getPersonCIType, formErrors } = useContext(PersonsContext);
	const { setOverlayState } = useContext(StateManagerContext);

    const FormTitle = {
        add: "Add People",
        edit: "Edit People"
    };
    const [formAction, setformAction] = useState("add");
    const [formData, setFormData] = useState({
        rid: "#13:0",
        citype: ["#12:1"],
        first_name: "",
        last_name: "",
        username: "",
        name: "",
        mobile_phone_number: "",
        email: "",
        status: "active",
        uuid: metaphone(getPersonCIType()) + "-" + uuidv4()
    });

    const DisplayStep = () => {
        switch (pageIndex) {
            case 0:
                return <Step001 formData={formData} setFormData={setFormData} formErrors={formErrors} />;
            default:
                return false;
        }
    };

    useEffect(() => {
        if (utils.isempty(personid)) {
            let tuuid = metaphone(getPersonCIType()) + "-" + uuidv4();
            setformAction("add");
            setFormData({
                rid: "#13:0",
                citype: ["#12:1"],
                first_name: "",
                last_name: "",
                username: "",
                name: "",
                mobile_phone_number: "",
                email: "",
                status: "active",
                uuid: tuuid
            });
        } else {
            setformAction("edit");
            setFormData(getPerson(personid));
        }
    }, [personid, getPerson, getPersonCIType])

    return (
        <div className="form form-bg">
            <div className="progressbar">
                <div
                    style={{ width: pageIndex === 0 ? "100%" : "100%" }}
                ></div>
            </div>
            <div className="form-container">
                <div className="header">
                    <h1 style={{ color: "#7A9A01" }}>{FormTitle[formAction]}</h1>
                </div>
                <div className="body">{DisplayStep()}</div>
                <div className="footer">
                    <button
                        onClick={(e) => {
							setOverlayState("overlay_visible");
                            switch (e.target.innerText) {
                                case 'Submit':
                                    //if (validateForm()) {
                                    addPerson({
                                        rid: formData.rid,
                                        citype: ["#12:1"],
                                        first_name: formData.first_name,
                                        last_name: formData.last_name,
                                        username: formData.username,
                                        name: formData.first_name + " " + formData.last_name,
                                        mobile_phone_number: formData.mobile_phone_number,
                                        email: formData.email,
                                        status: formData.status,
                                        uuid: formData.uuid
                                    }, hideForm, setOverlayState);
                                    break;
                                case 'Save':
                                    editPerson({
                                        rid: formData.rid,
                                        citype: ["#12:1"],
                                        first_name: formData.first_name,
                                        last_name: formData.last_name,
                                        username: formData.username,
                                        name: formData.first_name + " " + formData.last_name,
                                        mobile_phone_number: formData.mobile_phone_number,
                                        email: formData.email,
                                        status: formData.status,
                                        uuid: formData.uuid
                                    }, hideForm, formFilters, setOverlayState);
                                    break;
                                default:
                                    hideForm();
                                    break;
                            }
                        }}
                    >
                        {formAction === "add" ? "Submit" : "Save"}
                    </button>
                    <button
                        onClick={(e) => {
                            hideForm();
                        }}
                    >
                        Cancel
                    </button>
                    {
                        formAction === "add" ?
                            null :
                            <button
                                onClick={(e) => {
                                    setOverlayState("overlay_visible");
                                    switch (e.target.innerText) {
                                        case 'Delete':
                                            editPerson({
                                                rid: formData.rid,
                                                citype: ["#12:1"],
                                                first_name: formData.first_name,
                                                last_name: formData.last_name,
                                                username: formData.username,
                                                name: formData.first_name + " " + formData.last_name,
                                                mobile_phone_number: formData.mobile_phone_number,
                                                email: formData.email,
                                                status: "deleted",
                                                uuid: formData.uuid
                                            }, hideForm, formFilters, setOverlayState);
                                            break;
                                        case 'Reinstate':
                                            editPerson({
                                                rid: formData.rid,
                                                citype: ["#12:1"],
                                                first_name: formData.first_name,
                                                last_name: formData.last_name,
                                                username: formData.username,
                                                name: formData.first_name + " " + formData.last_name,
                                                mobile_phone_number: formData.mobile_phone_number,
                                                email: formData.email,
                                                status: "active",
                                                uuid: formData.uuid
                                            }, hideForm, formFilters, setOverlayState);
                                            break;
                                        default:
                                            hideForm();
                                            break;
                                    }
                                }}
                            >
                                {formData.status === "active" ? "Delete" : "Reinstate"}
                            </button>
                    }
                </div>
            </div>
        </div>
    );
}
