import { createContext, useReducer, useContext } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;
const pestTrapTypesReducer = (state, action) => {
	let tpesttraptypes = [];
	switch (action.type) {
		case "REFRESH_PESTTRAPTYPES":
			localStorage.setItem("pestTrapTypesLookup", JSON.stringify(action.pesttraptypes));
			localStorage.setItem("pesttraptypes", JSON.stringify(action.pesttraptypes));
			return {
				pesttraptypes: action.pesttraptypes
			};
		case "ADD_PESTTRAPTYPE":
			tpesttraptypes = utils.updateArray(state.pesttraptypes,action.pesttraptype,);
			tpesttraptypes = utils.sortArray(tpesttraptypes);
			localStorage.setItem("pestTrapTypesLookup", JSON.stringify(tpesttraptypes));
			localStorage.setItem("pesttraptypes", JSON.stringify(tpesttraptypes));
			return {
				pesttraptypes: tpesttraptypes
			};
		case "EDIT_PESTTRAPTYPE":
			utils.each(state.pesttraptypes, function (pesttraptype, pesttraptypeind) {
				if (pesttraptype.uuid !== action.pesttraptype.uuid) {
					tpesttraptypes.push(pesttraptype);
				} else {
					tpesttraptypes.push(action.pesttraptype);
				}
			});
			tpesttraptypes = utils.sortArray(tpesttraptypes);
			localStorage.setItem("pestTrapTypesLookup", JSON.stringify(tpesttraptypes));
			localStorage.setItem("pesttraptypes", JSON.stringify(tpesttraptypes));
			return {
				pesttraptypes: tpesttraptypes
			};
		default:
			return state;
	}
};

export const PestTrapTypesContext = createContext();

const PestTrapTypesProvider = ({ children }) => {
	const pesttraptypes = def_value;
	const [state, dispatch] = useReducer(pestTrapTypesReducer, {
		pesttraptypes: pesttraptypes,
		filters: {}
	});
	// let ts = localStorage.getItem("pestTrapTypes");
	// const [pestTrapTypesLookupState, setPestTrapTypesLookup] = useState((utils.isset(ts) && !utils.isempty(ts) ? JSON.parse(ts) : []));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);

	const value = {
		pesttraptypes: state.pesttraptypes,
		addPestTrapType: (pesttraptype, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pesttraptype
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/pesttraptype", params, headers).then(
				function (response) {
					if (response.data.success) {
						pesttraptype =  response.data.data;
						dispatch({ type: "ADD_PESTTRAPTYPE", pesttraptype });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		getPestTrapType: (id) => {
			let tpesttraptype = {};
			utils.each(state.pesttraptypes, function (pesttraptype, pesttraptypeind) {
				if (pesttraptype.uuid === id) {
					tpesttraptype = pesttraptype;
					return false;
				}
			});
			return tpesttraptype;
		},
		getPestTrapTypes: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let pestTrapTypesFetchRules = utils.getLocalStorageItem("pestTrapTypesFetchRules");

			// if (!utils.isempty(pestTrapTypesFetchRules)) {
			// 	if (pestTrapTypesFetchRules.filtered === false && pestTrapTypesFetchRules.trycache) {
			// 		let pesttraptypes = utils.getLocalStorageItem("pesttraptypes");
			// 		if (!utils.isempty(pesttraptypes)) {
			// 			dispatch({ type: "REFRESH_PESTTRAPTYPES", pesttraptypes });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	pestTrapTypesFetchRules = { trycache: true, filtered: true };
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/pesttraptypes", params).then(
				function (response) {
					if (response.data.success) {
						var pesttraptypes = [];
						utils.each(response.data.data, function (pesttraptype, pesttraptypeid) {
							pesttraptypes.push(pesttraptype);
						});
						if (lookup !== false) {
							//setPestTrapTypesLookup(pesttraptypes);
							dispatch({ type: "REFRESH_PESTTRAPTYPES", pesttraptypes });
						} else {
							dispatch({ type: "REFRESH_PESTTRAPTYPES", pesttraptypes });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							// setPestTrapTypesLookup([]);
						}
					}
					// pestTrapTypesFetchRules.filtered = false;
					// localStorage.setItem("pestTrapTypesFetchRules", JSON.stringify(pestTrapTypesFetchRules));
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
				});
		},
		editPestTrapType: (pesttraptype, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pesttraptype
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/pesttraptype", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "EDIT_PESTTRAPTYPE", pesttraptype });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		// pestTrapTypesLookupState: pestTrapTypesLookupState,
		// setPestTraptypesLookup: setPestTrapTypesLookup,
		getPestTrapTypeCIType: () => {
			return "ci_pesttraptype";
		},
		clearPestTrapTypes: () => {
			// setPestTrapTypesLookup([]);
			let empty = [];
			dispatch({ type: "REFRESH_PESTTRAPTYPES", empty });
			localStorage.setItem("pestTrapTypesLookup", JSON.stringify(empty));
			localStorage.setItem("pesttraptypes", JSON.stringify(empty));
		}
	}
	return (
		<PestTrapTypesContext.Provider value={value}>
			{children}
		</PestTrapTypesContext.Provider>
	);
};
export default PestTrapTypesProvider;

