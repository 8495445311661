import React, { useEffect, useState, useContext } from "react";
import { OrganisationsContext } from '../../contexts/organisations';

import OrganisationForm from './OrganisationForm';
import TextField from '@mui/material/TextField';
import '../../index.css';
import utils from "../../utils/utils";
import styled from "@emotion/styled";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import { AddCircle, Edit } from "@material-ui/icons";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

//Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { PaddingOutlined } from "@mui/icons-material";

// Theme
const ThemeTextField = styled(TextField)(utils.theme.TextField);
const ThemeSelect = styled(Select)(utils.theme.Select);

export function OrganisationList(props) {

  const { organisations, getOrganisations, setFormErrors, resetFormErrors } = useContext(OrganisationsContext);
  const [formContainerVis, setformContainerVis] = useState("form-container dialog-loader-2 dialog-loader-2-hidden");
  const [organisationid, setorganisationid] = useState("");
  const [formAction, setFormAction] = useState("");
  const [formFilters, setFormFilters] = useState({ name: "", status: "active" });

  const showForm = () => {
    setformContainerVis("form-container dialog-loader-2 dialog-loader-2-show");
  }
  const hideForm = () => {
    resetFormErrors();
    setformContainerVis("form-container dialog-loader-2 dialog-loader-2-hidden");
  }
  const preOrganisationID = (id) => {
    setFormErrors({ name: '', shortcode: '', address_1: '', address_2: '', address_3: '', phone: '' });
    if (id !== "") {
      setorganisationid(id);
      setFormAction("edit");
      showForm();
    } else {
      setorganisationid(id);
      setFormAction("add");
      showForm();
    }
  }
  useEffect(() => { getOrganisations({ status: "active" }) }, []);
  console.warn("organisations???", organisations);
  //Dialog
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const handleFilterDialogClickOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };
  //Below line was cut from OrganisationForm definition after organisationid={organisationid}
  // formAction={formAction}
  return (
    <>
      <div className={formContainerVis} >
        <OrganisationForm hideForm={hideForm} organisationid={organisationid} formFilters={formFilters} />
      </div>

      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Box>
          <div className="filter-dialog">
            <Button
              variant="outlined"
              onClick={handleFilterDialogClickOpen}
              startIcon={<FilterListIcon />}
              style={utils.theme.Button}
            >
              Filter
            </Button>
            <Dialog
              open={openFilterDialog}
              onClose={handleFilterDialogClose}
              fullWidth={true}
              maxWidth={'sm'}
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{ m: 0, p: 2, color: '#7A9A01' }}
              >
                List Filters
                <IconButton
                  aria-label="close"
                  onClick={handleFilterDialogClose}
                  sx={{ position: 'absolute', right: 8, top: 8, color: '#7A9A01' }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Box>
                    <ThemeTextField
                      type="text"
                      label="Name"
                      placeholder="Name"
                      helperText="Filter list by name"
                      sx={{ width: '400px' }}
                      fullWidth={true}
                      value={formFilters.name}
                      onChange={(event) =>
                        setFormFilters({ ...formFilters, name: event.target.value })
                      }
                    />
                  </Box>
                  <Box>
                    <FormControl sx={{ m: 1, minWidth: 120 }} >
                      <InputLabel id="simple-select-disabled-label" sx={{ '&.Mui-focused': { color: '#7A9A01' } }}>Status</InputLabel>
                      <ThemeSelect
                        labelId="simple-select-disabled-label"
                        id={utils.uuid()}
                        value={formFilters.status}
                        label="Status"
                        sx={{ width: '400px' }}
                        onChange={(event) =>
                          setFormFilters({ ...formFilters, status: event.target.value })
                        }
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "rgb(199, 212, 148)",
                              color: "rgb(122, 154, 1)"
                            },
                          }
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"deleted"}>Deleted</MenuItem>
                      </ThemeSelect>
                      <FormHelperText>Filter list by status</FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => { utils.setLocalStorageItem('organisationFetchRules', true, 'filtered'); getOrganisations(formFilters); setOpenFilterDialog(false) }}
                  autoFocus
                  variant="outlined"
                  style={utils.theme.Button}
                >
                  Apply Filters
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Box>

        {console.log(formFilters)}

        <Box sx={{ alignSelf: 'center', display: 'flex' }}>
          {
            !utils.isempty(formFilters.name) ?
              <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#7a9a01', marginRight: '5px' }}>Name:</span>
                <span style={{ color: 'GrayText' }}>{formFilters.name}</span>
              </div>
              : null
          }
          <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: '#7a9a01', marginRight: '5px' }}>Status:</span>
            <span style={{ color: 'GrayText' }}>{formFilters.status.charAt(0).toUpperCase() + formFilters.status.slice(1)}</span>
          </div>
        </Box>

        <Box>
          <Button
            onClick={() => { preOrganisationID("") }}
            variant="outlined"
            startIcon={<AddCircle />}
            style={utils.theme.Button}
          >
            Add
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: '100%', marginTop: '5px' }}>
        <div className="nexus-scroll-table">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Short Code</th>
                <th>Address 1</th>
                <th>Address 2</th>
                <th>Address 3</th>
                <th>Phone</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {
                !utils.isempty(organisations) ?
                  organisations.map((data, dataid) => (
                    <tr key={utils.uuid()}>
                      <td>{data.name}</td>
                      <td>{data.shortcode}</td>
                      <td>{data.address_1}</td>
                      <td>{data.address_2}</td>
                      <td>{data.address_3}</td>
                      <td>{data.phone}</td>
                      <td>
                        <IconButton onClick={() => { preOrganisationID(data.uuid) }}
                          color="primary"
                          sx={{ "&:hover": { color: "#789a3d" } }}
                          style={{ color: "#7A9A01" }}
                        >
                          <Edit />
                        </IconButton>
                      </td>
                    </tr>
                  ))
                  : <tr key={utils.uuid()}><td colSpan={99}>No results found...</td></tr>
              }
            </tbody>
          </table>
        </div>
      </Box>
    </>
  );
}

export default OrganisationList;
