import React, { useState, useContext, useEffect } from 'react'
import utils from "../../utils/utils";
import { v4 as uuidv4 } from 'uuid';
import { metaphone } from 'metaphone';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { FarmsContext } from '../../contexts/farms';
import { StateManagerContext } from '../../contexts/statemanager';
import '../../index.css';
import styled from '@emotion/styled';

// Theme
const ThemeFormTextField = styled(TextField)(utils.theme.FormTextField)
const ThemeSelect = styled(Select)(utils.theme.Select);

export function Step001({ formData, setFormData, formErrors, organisations, persons }) {
	return (
		<div className="step001-container">
			<ThemeFormTextField
				type="text"
				id="name"
				label="Name"
				placeholder="Name..."
				value={formData.name}
				onChange={(event) =>
					setFormData({ ...formData, name: event.target.value })
				}
				error={!utils.isempty(formErrors.name) ? true : false}
				helperText={formErrors.name}
			/>
			<ThemeFormTextField
				type="text"
				id="shortcode"
				label="Short Code"
				placeholder="Short Code..."
				value={formData.shortcode}
				onChange={(event) =>
					setFormData({ ...formData, shortcode: event.target.value })
				}
			/>
			<ThemeFormTextField
				type="text"
				id="puc_code"
				label="PUC"
				placeholder="PUC..."
				value={formData.puc_code}
				onChange={(event) =>
					setFormData({ ...formData, puc_code: event.target.value })
				}
			/>
			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth>
					<InputLabel sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} id="">Organisation</InputLabel>
					<ThemeSelect
						label="Organisation"
						value={formData.organisation_id}
						onChange={(event) => {
							utils.each(organisations, function (org, orgid) {
								if (org.uuid === event.target.value) {
									setFormData({ ...formData, organisation_id: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							organisations.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
				</FormControl>
			</Box>
			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth>
					<InputLabel sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} id="">Contact Person</InputLabel>
					<ThemeSelect
						label="Contact Person"
						value={formData.contactperson}
						onChange={(event) => {
							utils.each(persons, function (person, personid) {
								if (person.uuid === event.target.value) {
									setFormData({ ...formData, contactperson: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							persons.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
				</FormControl>
			</Box>
		</div>
	);
}

export default function FarmForm({ hideForm, farmid, formFilters }) {
	const [pageIndex] = useState(0);
	//const numPages = 1;
	const organisationsLookupState = utils.getLocalStorageItem("organisationsLookup");
	const personsLookupState = utils.getLocalStorageItem("personsLookup");
	const { addFarm, getFarm, editFarm, getFarmCIType, formErrors } = useContext(FarmsContext);
	const { setOverlayState } = useContext(StateManagerContext);

	const FormTitle = {
		add: "Add Farm",
		edit: "Edit Farm"
	};
	const [formAction, setformAction] = useState("add");
	const [formData, setFormData] = useState({
		rid: "#13:0",
		citype: ["#12:162"],
		organisation_id: "",
		name: "",
		shortcode: "",
		puc_code: "",
		contactperson: "",
		uuid: metaphone(getFarmCIType()) + "-" + uuidv4()
	});

	const DisplayStep = () => {
		switch (pageIndex) {
			case 0:
				return <Step001 formData={formData} setFormData={setFormData} formErrors={formErrors} organisations={organisationsLookupState} persons={personsLookupState} />;
			default:
				return false;
		}
	};

	useEffect(() => {
		if (utils.isempty(farmid)) {
			let tuuid = metaphone(getFarmCIType()) + "-" + uuidv4();
			setformAction("add");
			setFormData({
				rid: "#13:0",
				citype: ["#12:162"],
				organisation_id: "",
				name: "",
				shortcode: "",
				puc_code: "",
				contactperson: "",
				status: "active",
				uuid: tuuid
			});
		} else {
			setformAction("edit");
			setFormData(getFarm(farmid));
		}
	}, [farmid, getFarm, getFarmCIType])
	return (
		<div className="form form-bg">
			<div className="progressbar">
				<div
					style={{ width: pageIndex === 0 ? "100%" : "100%" }}
				></div>
			</div>
			<div className="form-container">
				<div className="header">
					<h1 style={{ color: "#7A9A01" }}>{FormTitle[formAction]}</h1>
				</div>
				<div className="body">{DisplayStep()}</div>
				<div className="footer">
					<button
						onClick={(e) => {
							setOverlayState("overlay_visible");
							switch (e.target.innerText) {
								case 'Submit':
									addFarm({
										rid: formData.rid,
										citype: ["#12:162"],
										organisation_id: formData.organisation_id,
										name: formData.name,
										shortcode: formData.shortcode,
										puc_code: formData.puc_code,
										contactperson: formData.contactperson,
										status: formData.status,
										uuid: formData.uuid
									}, hideForm, setOverlayState);
									break;
								case 'Save':
									editFarm({
										rid: formData.rid,
										citype: ["#12:162"],
										organisation_id: formData.organisation_id,
										name: formData.name,
										shortcode: formData.shortcode,
										puc_code: formData.puc_code,
										contactperson: formData.contactperson,
										status: formData.status,
										uuid: formData.uuid
									}, hideForm, formFilters, setOverlayState);
									break;
								default:
									hideForm();
									break;
							}
						}}
					>
						{formAction === "add" ? "Submit" : "Save"}
					</button>
					<button
						onClick={(e) => {
							hideForm();
						}}
					>
						Cancel
					</button>
					{
						formAction === "add" ?
							null :
							<button
								onClick={(e) => {
									setOverlayState("overlay_visible");
									switch (e.target.innerText) {
										case 'Delete':
											editFarm({
												rid: formData.rid,
												citype: ["#12:162"],
												organisation_id: formData.organisation_id,
												name: formData.name,
												shortcode: formData.shortcode,
												puc_code: formData.puc_code,
												contactperson: formData.contactperson,
												status: "deleted",
												uuid: formData.uuid
											}, hideForm, formFilters, setOverlayState);
											break;
										case 'Reinstate':
											editFarm({
												rid: formData.rid,
												citype: ["#12:162"],
												organisation_id: formData.organisation_id,
												name: formData.name,
												shortcode: formData.shortcode,
												puc_code: formData.puc_code,
												contactperson: formData.contactperson,
												status: "active",
												uuid: formData.uuid
											}, hideForm, formFilters, setOverlayState);
											break;
										default:
											hideForm();
											break;
									}
								}}
							>
								{formData.status === "active" ? "Delete" : "Reinstate"}
							</button>
					}
				</div>
			</div>
		</div>
	);

}
