import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;
const sprayTargetsReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_SPRAYTARGETS":
			return {
				spraytargets: action.spraytargets
			};
		case "ADD_SPRAYTARGETS":
			return {
				spraytargets: [...state.spraytargets, action.spraytarget]
			};
		case "EDIT_SPRAYTARGETS":
			let tspraytargets = [];
			utils.each(state.spraytargets, function (spraytarget, spraytargetind) {
				if (spraytarget.uuid !== action.spraytarget.uuid) {
					tspraytargets.push(spraytarget);
				} else {
					tspraytargets.push(action.spraytarget);
				}
			});
			return {
				spraytargets: tspraytargets
			};
		default:
			return state;
	}
};

export const SprayTargetsContext = createContext();

const SprayTargetsProvider = ({ children }) => {
	const spraytargets = def_value;
	const [state, dispatch] = useReducer(sprayTargetsReducer, {
		spraytargets: spraytargets,
		filters: {}
	});
	// let ts = localStorage.getItem("sprayTargets");
	// const [sprayTargetsLookupState, setSprayTargetsLookup] = useState((utils.isset(ts) && !utils.isempty(ts) ? JSON.parse(ts) : []));
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);

	const value = {
		spraytargets: state.spraytargets,
		addSprayTarget: (spraytarget, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: spraytarget
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/spraytarget", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "ADD_SPRAYTARGETS", spraytarget });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		getSprayTarget: (id) => {
			let tspraytarget = {};
			utils.each(state.spraytargets, function (spraytarget, spraytargetind) {
				if (spraytarget.uuid === id) {
					tspraytarget = spraytarget;
					return false;
				}
			});
			return tspraytarget;
		},
		getSprayTargets: (filters, lookup = false, ttoken = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie) && ttoken === false) { return false; }
			let tokenbearer = cookie.token;
			if (ttoken !== false) { tokenbearer = ttoken; }
			// let sprayTargetsFetchRules = utils.getLocalStorageItem("sprayTargetsFetchRules");
			
			// if (!utils.isempty(sprayTargetsFetchRules)) {
			// 	if (sprayTargetsFetchRules.filtered === false&&sprayTargetsFetchRules.trycache) {
			// 		let spraytargets = utils.getLocalStorageItem("spraytargets");
			// 		if(!utils.isempty(spraytargets)) {
			// 			dispatch({ type: "REFRESH_SPRAYTARGETS", spraytargets });
			// 			return true;
			// 		}
			// 	}
			// } else {
			// 	sprayTargetsFetchRules = {trycache:true,filtered:true};
			// }

			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + tokenbearer },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/spraytargets", params).then(
				function (response) {
					if (response.data.success) {
						var spraytargets = [];
						utils.each(response.data.data, function (spraytarget, spraytargetid) {
							spraytargets.push(spraytarget);
						});
						if (lookup !== false) {
							//setSprayTargetsLookup(spraytargets);
							localStorage.setItem("sprayTargetsLookup", JSON.stringify(spraytargets))
							//localStorage.setItem("sprayTargets", JSON.stringify(spraytargets))
						} else {
							//dispatch({ type: "REFRESH_SPRAYTARGETS", spraytargets });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setSprayTargetsLookup([]);
						}
					}
					ajaxstack = false;
					utils.decAjaxStack();
					utils.checkAjaxStack();
});
		},
		editSprayTarget: (spraytarget, hideform) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: spraytarget
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/spraytarget", params, headers).then(
				function (response) {
					if (response.data.success) {
						dispatch({ type: "EDIT_SPRAYTARGETS", spraytarget });
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							return response;
						}
					}
				});
		},
		// sprayTargetsLookupState: sprayTargetsLookupState,
		// setSprayTargetsLookup: setSprayTargetsLookup,
		getSprayTargetCIType: () => {
			return "ci_spraytarget";
		},
		clearSprayTargets: () => {
			//setSprayTargetsLookup([]);
			let empty = [];
			// dispatch({ type: "REFRESH_SPRAYTARGETS", empty });
			localStorage.setItem("sprayTargetsLookup", JSON.stringify(empty))
		}
	}
	return (
		<SprayTargetsContext.Provider value={value}>
			{children}
		</SprayTargetsContext.Provider>
	);
};
export default SprayTargetsProvider;

