import React, { useState, useContext, useEffect } from 'react'
import utils from "../../utils/utils";
import { v4 as uuidv4 } from 'uuid';
import { metaphone } from 'metaphone';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { SessionsContext } from '../../contexts/sessions';

import { PMZonesContext } from '../../contexts/pmzones';
import { StateManagerContext } from '../../contexts/statemanager';

import '../../index.css';
import styled from '@emotion/styled';
import { FormHelperText } from '@mui/material';

// Theme
const ThemeFormTextField = styled(TextField)(utils.theme.FormTextField)  // Theme
const ThemeSelect = styled(Select)(utils.theme.Select);

export function Step001({ formData, setFormData, formErrors, farms, orchards, farmcommodities, cultivars, zonetypes }) {
	return (
		<div className="step001-container">
			{/* <label className='form-label-200' htmlFor="name">Name</label> */}
			<ThemeFormTextField
				type="text"
				id="name"
				label="Name"
				placeholder="Name..."
				value={formData.name}
				onChange={(event) =>
					setFormData({ ...formData, name: event.target.value })
				}
				error={!utils.isempty(formErrors.name) ? true : false}
				helperText={formErrors.name}
			/>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.farm) ? true : false}>
					<InputLabel id="farm" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Farm</InputLabel>
					<ThemeSelect
						label="Farm"
						value={formData.farm}
						onChange={(event) => {
							utils.each(farms, function (farm, farmid) {
								if (farm.uuid === event.target.value) {
									setFormData({ ...formData, farm: event.target.value, block: '' });
								}
							})

						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							farms.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.farm}</FormHelperText>
				</FormControl>
			</Box>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.block) ? true : false}>
					<InputLabel id="orchard" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Field</InputLabel>
					<ThemeSelect
						label="Field"
						value={formData.block}
						onChange={(event) => {
							utils.each(orchards, function (orchard, orchardid) {
								if (orchard.uuid === event.target.value) {
									setFormData({ ...formData, block: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							orchards.map((value, ind) => {
								if (!utils.isempty(formData.farm) && formData.farm !== value.farm) { return null; }
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.block}</FormHelperText>
				</FormControl>
			</Box>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.commodity) ? true : false}>
					<InputLabel id="commodity" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Commodity</InputLabel>
					<ThemeSelect
						label="Commodity"
						value={formData.commodity}
						onChange={(event) => {
							utils.each(farmcommodities, function (commodity, commodityid) {
								if (commodity.uuid === event.target.value) {
									setFormData({ ...formData, commodity: event.target.value, cultivar_name: '' });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							farmcommodities.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.commodity}</FormHelperText>
				</FormControl>
			</Box>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.cultivar_name) ? true : false}>
					<InputLabel id="cultivar" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Cultivar</InputLabel>
					<ThemeSelect
						label="Cultivar"
						value={formData.cultivar_name}
						onChange={(event) => {
							utils.each(cultivars, function (cultivar, cultivarid) {
								if (cultivar.uuid === event.target.value) {
									setFormData({ ...formData, cultivar_name: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							cultivars.map((value, ind) => {
								if (!utils.isempty(formData.commodity) && formData.commodity !== value.agri_produce_commodity) { return null; }
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.cultivar_name}</FormHelperText>
				</FormControl>
			</Box>

			<Box sx={{ minWidth: "50%", marginTop: "5px" }}>
				<FormControl fullWidth error={!utils.isempty(formErrors.zone_type) ? true : false}>
					<InputLabel id="zone_type" sx={{ color: "rgba(0, 0, 0, 0.45)", '&.Mui-focused': { color: 'rgba(0, 0, 0, 0.45)' } }} >Zone Type</InputLabel>
					<ThemeSelect
						label="Zone Type"
						value={formData.zone_type}
						onChange={(event) => {
							utils.each(zonetypes, function (zone_type, zonetypeid) {
								if (zone_type.uuid === event.target.value) {
									setFormData({ ...formData, zone_type: event.target.value });
								}
							})
						}}
						MenuProps={{
							sx: {
								"&& .Mui-selected": {
									backgroundColor: "#5f7933",
									color: "#fff"
								},
							},
							PaperProps: {
								style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								},
								sx: {
									backgroundColor: "#c7d494",
								}
							}
						}}
					>
						{
							zonetypes.map((value, ind) => {
								return (
									<MenuItem key={value.uuid} value={value.uuid}>{value.name}</MenuItem>
								)
							})
						}
					</ThemeSelect>
					<FormHelperText>{formErrors.zone_type}</FormHelperText>
				</FormControl>
			</Box>

			<ThemeFormTextField
				type="number"
				id="farm_zone_size"
				label="Size"
				placeholder="Size..."
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
				value={formData.farm_zone_size}
				onChange={(event) =>
					setFormData({ ...formData, farm_zone_size: event.target.value })
				}
			/>
		</div>
	);
}

export default function PMZoneForm({ hideForm, pmzoneid, formFilters }) {
	//const [pageIndex, setPageIndex] = useState(0);
	const [pageIndex] = useState(0);
	//const numPages = 1;
	const farmsLookupState = utils.getLocalStorageItem("farmsLookup");
	const orchardsLookupState = utils.getLocalStorageItem("orchardsLookup");
	const farmCommoditiesLookupState = utils.getLocalStorageItem("farmCommoditiesLookup");
	const cultivarsLookupState = utils.getLocalStorageItem("cultivarsLookup");
	const { setOverlayState } = useContext(StateManagerContext);
	const types = [
		{ type: "fruit", name: "Fruit" },
		{ type: "veg", name: "Vegetable" },
	];
	const zoneTypesLookupState = utils.getLocalStorageItem("zoneTypesLookup");
	const { addPMZone, getPMZone, editPMZone, getPMZoneCIType, formErrors } = useContext(PMZonesContext);
	const { getCookieData } = useContext(SessionsContext);
	var CD = getCookieData();
	const FormTitle = {
		add: "Add Zone",
		edit: "Edit Zone"
	};
	const [formAction, setformAction] = useState("add");
	const [formData, setFormData] = useState({
		rid: "#13:0",
		citype: "#12:165",
		farm: "",
		block: "",
		commodity: "",
		cultivar_name: "",
		//zone_year_established: "1000-01-01 00:00:00",
		//zone_tree_count: 0,
		//zone_work_row_width: 0,
		//zone_planting_distance: 0,
		//zone_trees_per_ha: 0,
		farm_zone_size: 0,
		//zone_tree_row_volume: 0,
		name: "",
		created_by: CD.useruuid,
		zone_type: "",
		//type: "",
		uuid: metaphone(getPMZoneCIType()) + "-" + uuidv4()
	});
	//setFormAction(pmzoneid===""?"add":"edit");

	const DisplayStep = () => {
		switch (pageIndex) {
			case 0:
				return <Step001 formData={formData} setFormData={setFormData} formErrors={formErrors} farms={farmsLookupState} orchards={orchardsLookupState} farmcommodities={farmCommoditiesLookupState} cultivars={cultivarsLookupState} zonetypes={zoneTypesLookupState} />;
			default:
				return false;
		}
	};
	useEffect(() => {
		if (utils.isempty(pmzoneid)) {
			let tuuid = metaphone(getPMZoneCIType()) + "-" + uuidv4();
			setformAction("add");
			setFormData({
				rid: "#13:0",
				citype: ["#12:165"],
				farm: "",
				block: "",
				commodity: "",
				cultivar_name: "",
				//zone_year_established: "1000-01-01 00:00:00",
				//zone_tree_count: 0,
				//zone_work_row_width: 0,
				//zone_planting_distance: 0,
				//zone_trees_per_ha: 0,
				farm_zone_size: 0,
				//zone_tree_row_volume: 0,
				name: "",
				status: "active",
				created_by: CD.useruuid,
				zone_type: "",
				//type: "",
				uuid: tuuid
			});
		} else {
			setformAction("edit");
			setFormData(getPMZone(pmzoneid));
		}
	}, [pmzoneid])

	return (
		<div className="form form-bg">
			<div className="progressbar">
				<div
					style={{ width: pageIndex === 0 ? "100%" : "100%" }}
				></div>
			</div>
			<div className="form-container">
				<div className="header">
					<h1 style={{ color: "#7A9A01" }}>{FormTitle[formAction]}</h1>
				</div>
				<div className="body">{DisplayStep()}</div>
				<div className="footer">
					<button
						onClick={(e) => {
							setOverlayState("overlay_visible");
							switch (e.target.innerText) {
								case 'Submit':
									addPMZone({
										rid: formData.rid,
										citype: ["#12:165"],
										farm: formData.farm,
										block: formData.block,
										commodity: formData.commodity,
										cultivar_name: formData.cultivar_name,
										//zone_year_established: formData.zone_year_established,
										//zone_tree_count: formData.zone_tree_count,
										//zone_work_row_width: formData.zone_work_row_width,
										//zone_planting_distance: formData.zone_planting_distance,
										//zone_trees_per_ha: formData.zone_trees_per_ha,
										farm_zone_size: formData.farm_zone_size,
										//zone_tree_row_volume: formData.zone_tree_row_volume,
										name: formData.name,
										status: formData.status,
										//type: formData.type,
										created_by: formData.created_by,
										zone_type: formData.zone_type,
										uuid: formData.uuid
									}, hideForm, setOverlayState);
									break;
								case 'Save':
									editPMZone({
										rid: formData.rid,
										citype: ["#12:165"],
										farm: formData.farm,
										block: formData.block,
										commodity: formData.commodity,
										cultivar_name: formData.cultivar_name,
										//zone_year_established: formData.zone_year_established,
										//zone_tree_count: formData.zone_tree_count,
										//zone_work_row_width: formData.zone_work_row_width,
										//zone_planting_distance: formData.zone_planting_distance,
										//zone_trees_per_ha: formData.zone_trees_per_ha,
										farm_zone_size: formData.farm_zone_size,
										//zone_tree_row_volume: formData.zone_tree_row_volume,
										name: formData.name,
										status: formData.status,
										//type: formData.type,
										created_by: formData.created_by,
										zone_type: formData.zone_type,
										uuid: formData.uuid
									}, hideForm, formFilters, setOverlayState);
									break;
								default:
									hideForm();
									break;
							}
						}
						}
					>
						{formAction === "add" ? "Submit" : "Save"}
					</button>
					<button
						onClick={(e) => {
							hideForm();
						}}
					>
						Cancel
					</button>
					{
						formAction === "add" ?
							null :
							<button
								onClick={(e) => {
									setOverlayState("overlay_visible");
									switch (e.target.innerText) {
										case 'Delete':
											editPMZone({
												rid: formData.rid,
												citype: ["#12:165"],
												farm: formData.farm,
												block: formData.block,
												commodity: formData.commodity,
												cultivar_name: formData.cultivar_name,
												//zone_year_established: formData.zone_year_established,
												//zone_tree_count: formData.zone_tree_count,
												//zone_work_row_width: formData.zone_work_row_width,
												//zone_planting_distance: formData.zone_planting_distance,
												//zone_trees_per_ha: formData.zone_trees_per_ha,
												farm_zone_size: formData.farm_zone_size,
												//zone_tree_row_volume: formData.zone_tree_row_volume,
												name: formData.name,
												status: "deleted",
												//type: formData.type,
												created_by: formData.created_by,
												zone_type: formData.zone_type,
												uuid: formData.uuid
											}, hideForm, formFilters, setOverlayState);
											break;
										case 'Reinstate':
											editPMZone({
												rid: formData.rid,
												citype: ["#12:165"],
												farm: formData.farm,
												block: formData.block,
												commodity: formData.commodity,
												cultivar_name: formData.cultivar_name,
												//zone_year_established: formData.zone_year_established,
												//zone_tree_count: formData.zone_tree_count,
												//zone_work_row_width: formData.zone_work_row_width,
												//zone_planting_distance: formData.zone_planting_distance,
												//zone_trees_per_ha: formData.zone_trees_per_ha,
												farm_zone_size: formData.farm_zone_size,
												//zone_tree_row_volume: formData.zone_tree_row_volume,
												name: formData.name,
												status: "active",
												//type: formData.type,
												created_by: formData.created_by,
												zone_type: formData.zone_type,
												uuid: formData.uuid
											}, hideForm, formFilters, setOverlayState);
											break;
										default:
											hideForm();
											break;
									}
								}}
							>
								{formData.status === "active" ? "Delete" : "Reinstate"}
							</button>
					}
				</div>
			</div>
		</div>
	);

}
