import { createContext, useReducer, useContext, useState } from "react";
import utils from "../utils/utils";
import { SessionsContext } from './sessions';
import { useCookies } from 'react-cookie';
import moment from "moment";
import { UpdateCIStatusStateContext } from '../contexts/updatecistatusstate';
//This to become serverside lookup
var def_value = [];
var ajaxstack = false;
const PestInspectionsReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_PESTINSPECTIONS":
			return {
				pestinspections: action.pestinspections
			};
		case "REFRESH_REPORT_DATA":
			return {
				piReportData: action.piReportData
			};
		case "ADD_PESTINSPECTION":
			return {
				pestinspections: [...state.pestinspections, action.pestinspection]
			};
		case "EDIT_PESTINSPECTION":
			let tpestinspections = [];
			utils.each(state.pestinspections, function (pestinspection, pestinspectionind) {
				if (pestinspection.rid !== action.pestinspection.rid) {
					tpestinspections.push(pestinspection);
				} else {
					tpestinspections.push(action.pestinspection);
				}
			});
			return {
				pestinspections: tpestinspections
			};
		default:
			return state;
	}
};
const ListPestInspectionsReducer = (state, action) => {
	switch (action.type) {
		case "REFRESH_LIST_DATA":
			return {
				piListData: action.piListData
			};
		case "REFRESH_REPORT_DATA":
			return {
				piReportData: action.piReportData
			};
		case "ADD_PESTINSPECTION":
			return {
				pestinspections: [...state.pestinspections, action.pestinspection]
			};
		case "EDIT_PESTINSPECTION":
			let tpestinspections = [];
			utils.each(state.pestinspections, function (pestinspection, pestinspectionind) {
				if (pestinspection.rid !== action.pestinspection.rid) {
					tpestinspections.push(pestinspection);
				} else {
					tpestinspections.push(action.pestinspection);
				}
			});
			return {
				pestinspections: tpestinspections
			};
		default:
			return state;
	}
};

export const PestInspectionsContext = createContext();

const PestInspectionsProvider = ({ children }) => {
	const pestinspections = def_value;

	const filerStartDate = moment();
	const month = moment().month();
	if(month < moment().month(8)){
		filerStartDate.add(-1, "year").month(7).startOf("month");
	}

	const { setUpdateCIState } = useContext(UpdateCIStatusStateContext);
	const [state, dispatch] = useReducer(PestInspectionsReducer, {
		pestinspections: pestinspections,
		piReportData: {
			datafarmzones: [],
			dataweeks: [],
			datapests: [],
			tdata: [],
			chartdata: {},
			mapdata: {},
			formFilters: { status: "closed", startDate: filerStartDate.format("YYYY-MM-DD HH:mm:ss"), endDate: moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"), template: "" },
		},
		filters: {}
	});
	const [state2, dispatch2] = useReducer(ListPestInspectionsReducer, {
		piListData: {
			datafarmzones: [],
			dataweeks: [],
			datapests: [],
			tdata: {},
			tdataindex:[],
			chartdata: {},
			mapdata: {},
			formFilters: { status: "in_review", startDate: filerStartDate.format("YYYY-MM-DD HH:mm:ss"), endDate: moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"), template: "" },
		},
	});
	const { setinvalid } = useContext(SessionsContext);
	const [cookie] = useCookies(["token"]);
	const [formErrors, setFormErrors] = useState({ farm: "", orchard: "", date: "", scout: "" });

	const value = {
		pestinspections: state.pestinspections,
		piListData: state2.piListData,
		piReportData: state.piReportData,
		setCloseInspectionSettings: (settings) => {
			let closeInspectionSettings = {
				message:"",
				data:{},
				visibility:"updateci_hidden",
			};
			closeInspectionSettings = settings;
			if(utils.isset(settings.data.rids) && settings.data.rids===false) {
				if(!utils.isset(settings.data.error)||utils.isempty(settings.data.error)) { closeInspectionSettings.visibility = "updateci_hidden"; }
				console.warn("rids is false closeInspectionSettings",closeInspectionSettings);
				setUpdateCIState(closeInspectionSettings);
			} else {
				closeInspectionSettings.visibility = "updateci_visible";
				
				if(settings.data.rids.length>1) { closeInspectionSettings.data.message = "Close selected inspections?"; }
				else { closeInspectionSettings.data.message = "Close selected inspection?"; }
				//closeInspectionSettings.data.rids = closeInspectionSettings.data.rids;
				closeInspectionSettings.data.callBack = value.closeInspections;
				closeInspectionSettings.data.error = "";
				console.warn("closeInspectionSettings",closeInspectionSettings);
				setUpdateCIState(closeInspectionSettings);
			}
		},
		addPestInspection: (pestinspection, hideform, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pestinspection
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.post("/pestinspection", params, headers).then(
				function (response) {
					if (response.data.success) {
						//dispatch2({ type: "ADD_PESTINSPECTION", pestinspection });
						// console.warn("addPestInspection",state2.piListData);
						value.getPestInspections(state2.piListData.formFilters);
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				}
			);
		},
		getPestInspection: (id) => {
			let tpestinspection = {};
			utils.each(state2.piListData.tdata, function (pestinspection, pestinspectionind) {
				if (pestinspection.rid === id) {
					tpestinspection = pestinspection;
					return false;
				}
			});
			return tpestinspection;
		},
		getPestInspections: (filters, lookup = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + cookie.token },
				params: { filters: filters }
			};
			utils.incAjaxStack();
			a.get("/pestinspections", params).then(
				function (response) {
					if (response.data.success) {
						var piListData = {
							datafarmzones: [],
							dataweeks: [],
							datapests: [],
							tdata: [],
							chartdata: {},
							mapdata: {},
							formFilters: filters,
						};

						var tchartdata = [];
						var templates = utils.getLocalStorageItem("inspectionTemplatesLookup");
						piListData.template = templates.find(template => template.ref === filters.template);
						piListData.template.data = typeof piListData.template.data === 'string' ? JSON.parse(piListData.template.data) : piListData.template.data;
						utils.each(piListData.template.data.columns, function (pest, pestind) {
							let groupname = piListData.template.data.groups[pest.group].label;
							piListData.datapests.push({ uuid: pestind, name: pest.label + " - " + groupname,group:groupname,pest:pest.label });
						});

						utils.each(response.data.data, function (inspection, inspectionid) {
							inspection.totals = typeof inspection.totals === 'string' ? JSON.parse(inspection.totals) : inspection.totals;
							inspection.rows = typeof inspection.rows === 'string' ? JSON.parse(inspection.rows) : inspection.rows;
							var tdate = (moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW') == '01') ? moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).endOf('isoWeek').format('YYYY') + ' ' + moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW') : moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).startOf('isoWeek').format('YYYY') + ' ' + moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW');
							if (piListData.dataweeks.findIndex(week => week === tdate) === -1) {
								piListData.dataweeks.push(tdate);
							}
							if (!utils.isset(piListData.chartdata[tdate])) { piListData.chartdata[tdate] = []; }
							utils.each(inspection.totals, function (pest, pestind) {
								if (!utils.isset(piListData.chartdata[tdate][pestind])) { piListData.chartdata[tdate][pestind] = { count: 0, value: 0, name: "" }; }
								piListData.chartdata[tdate][pestind].count++;
								piListData.chartdata[tdate][pestind].value += pest.value;
								let group = piListData.template.data.groups[piListData.template.data.columns[pestind].group].label;
								piListData.chartdata[tdate][pestind].name = piListData.template.data.columns[pestind].label + " (" + group + ")";
							});


							if (!utils.isset(piListData.mapdata[tdate])) { piListData.mapdata[tdate] = []; }
							utils.each(inspection.rows, function (row, rowind) {
								if(!row.inspection[0].sample){ return; }
								piListData.mapdata[tdate].push({ inspection: row.inspection, latitude: row.latitude, longitude: row.longitude });
							});

							piListData.tdata.push(inspection);
						});

						if (lookup !== false) {
							//setPestInspectionsLookup(pestinspections);
							dispatch2({ type: "REFRESH_LIST_DATA", piListData });
						} else {
							dispatch2({ type: "REFRESH_LIST_DATA", piListData });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setPestInspectionsLookup([]);
						}
					}
					ajaxstack = false;
					utils.decAjaxStack();
					if(utils.getFlag("SwitchContext")==true&&utils.getAjaxStack()==0) {
						utils.setFlag("SwitchContext",false);
						utils.route();
					}
				});
		},
		getReportPestInspections: (filters, lookup = false) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			if (ajaxstack) { return; }
			else { ajaxstack = true; }
			var params = {
				headers: { "Authorization": "Bearer " + cookie.token },
				params: { filters: filters }
			};
			a.get("/reports/pestinspections", params).then(
				function (response) {
					if (response.data.success) {
						var pestinspections = [];
						utils.each(response.data.data, function (pestinspection, pestinspectionid) {
							pestinspections.push(pestinspection);
						});
						var piReportData = {
							datafarmzones: [],
							dataweeks: [],
							datapests: [],
							tdata: {},
							tdataindex:[],
							chartdata: {},
							mapdata: {},
							formFilters: filters,
						};
						var tdataindex = [];
						var tchartdata = [];
						var templates = utils.getLocalStorageItem("inspectionTemplatesLookup");
						piReportData.template = templates.find(template => template.ref === filters.template);
						piReportData.template.data = typeof piReportData.template.data === 'string' ? JSON.parse(piReportData.template.data) : piReportData.template.data;
						utils.each(piReportData.template.data.columns, function (pest, pestind) {
							let groupname = piReportData.template.data.groups[pest.group].label;
							piReportData.datapests.push({ uuid: pestind, name: pest.label + " - " + groupname,group:groupname,pest:pest.label });
						});
						utils.each(response.data.data, function (inspection, inspectionid) {
							inspection.totals = typeof inspection.totals === 'string' ? JSON.parse(inspection.totals) : inspection.totals;
							inspection.rows = typeof inspection.rows === 'string' ? JSON.parse(inspection.rows) : inspection.rows;
							var tdate = (moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW') == '01') ? moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).endOf('isoWeek').format('YYYY') + ' ' + moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW') : moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).startOf('isoWeek').format('YYYY') + ' ' + moment.unix(moment(inspection.date_performed, 'YYYY-MM-DD').format('X')).format('WW');
							if (piReportData.dataweeks.findIndex(week => week === tdate) === -1) {
								piReportData.dataweeks.push(tdate);
							}
							if (!utils.isset(piReportData.chartdata[tdate])) { piReportData.chartdata[tdate] = []; }
							utils.each(inspection.totals, function (pest, pestind) {
								if (!utils.isset(piReportData.chartdata[tdate][pestind])) { piReportData.chartdata[tdate][pestind] = { count: 0, value: 0, name: "", type: "avg" }; }
								piReportData.chartdata[tdate][pestind].count++;
								piReportData.chartdata[tdate][pestind].value += pest.value;
								piReportData.chartdata[tdate][pestind].type = piReportData.template.data.columns[pestind].type;
								let group = piReportData.template.data.groups[piReportData.template.data.columns[pestind].group].label;
								piReportData.chartdata[tdate][pestind].name = piReportData.template.data.columns[pestind].label + " (" + group + ")";
							});

							if (!utils.isset(piReportData.mapdata[tdate])) { piReportData.mapdata[tdate] = []; }
							//console.warn("inspection",inspection);
							let key = inspection.farmname+"_"+inspection.zonename+"_"+inspection.cultivarname;
							if(piReportData.tdataindex.indexOf(key) === -1) { piReportData.tdataindex.push(key); }
							utils.each(inspection.rows, function (row, rowind) {
								if(!row.inspection[0].sample){ return; }
								if(!utils.isset(piReportData.tdata[key])) { piReportData.tdata[key] = {farmname:inspection.farmname,zonename:inspection.zonename,cultivarname:inspection.cultivarname,data:{}} };
								if(!utils.isset(piReportData.tdata[key].data[tdate])) { piReportData.tdata[key].data[tdate] = []; };
								utils.each(piReportData.template.data.columns,function(pest,pestind) {
									if(!utils.isset(piReportData.tdata[key].data[tdate][pestind])) { piReportData.tdata[key].data[tdate][pestind] = {total:0,count:0,min:99999999,max:0,type:"avg",samplesize:pest.samplesize}; };
									if(utils.isset(row.inspection[pestind])) {
										if(utils.isset(pest.ignorezero)&&pest.ignorezero==1) { return true; }
										piReportData.tdata[key].data[tdate][pestind].total += isNaN(parseFloat(row.inspection[pestind].count))?0:row.inspection[pestind].count;
										piReportData.tdata[key].data[tdate][pestind].count++;
										piReportData.tdata[key].data[tdate][pestind].min = Math.min(piReportData.tdata[key].data[tdate][pestind].min,isNaN(parseFloat(row.inspection[pestind].count))?99999999:row.inspection[pestind].count);
										piReportData.tdata[key].data[tdate][pestind].max = Math.min(piReportData.tdata[key].data[tdate][pestind].max,isNaN(parseFloat(row.inspection[pestind].count))?0:row.inspection[pestind].count);
									}
							
								});
								
								piReportData.mapdata[tdate].push({ inspection: row.inspection, latitude: row.latitude, longitude: row.longitude });
							});
							//piReportData.tdata.push(inspection);
						});
						piReportData.tdataindex.sort((a,b) => {
							return a.localeCompare(b);
						});
						utils.setLocalStorageItem("pestInspectionReportRefresh", "true");
						if (lookup !== false) {
							dispatch({ type: "REFRESH_REPORT_DATA", piReportData });
						} else {
							dispatch({ type: "REFRESH_REPORT_DATA", piReportData });
						}
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						}
						if (lookup !== false) {
							//setPestInspectionsLookup([]);
						}
					}
					ajaxstack = false;
				});
		},
		editPestInspection: (pestinspection, hideform, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: pestinspection
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/pestinspection", params, headers).then(
				function (response) {
					if (response.data.success) {
						setFormErrors({ farm: "", orchard: "", date: "", scout: "" });
						// dispatch({ type: "EDIT_PESTINSPECTION", pestinspection });
						value.getPestInspections(state2.piListData.formFilters);
						hideform();
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							setFormErrors(response.data.data);
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		closeInspections: (rids, setOverlayState) => {
			const a = utils.new("ajax");
			if (utils.isempty(cookie)) { return false; }
			var params = {
				data: rids
			};
			var headers = { headers: { "Authorization": "Bearer " + cookie.token } };
			a.put("/closepesttrapinspection", params, headers).then(
				function (response) {
					if (response.data.success) {
						value.getPestInspections(state2.piListData.formFilters);
						console.warn("Calling setCloseInspectionSettings");
						value.setCloseInspectionSettings({visibility:"updateci_hidden",data:{rids:false}});
					} else {
						if (utils.isset(response.data.error_code) && parseInt(response.data.error_code) === 100) {
							setinvalid();
						} else {
							value.setCloseInspectionSettings({data:{rids:response.data.data}});
						}
					}
					setOverlayState("overlay_hidden");
				});
		},
		// pestInspectionsLookupState: pestInspectionsLookupState,
		// setPestInspectionsLookup: setPestInspectionsLookup,
		formErrors: formErrors,
		setFormErrors: setFormErrors,
		getPestInspectionCIType: () => {
			return "ci_pestinspection";
		},
		clearPestInspections: () => {
			// setPestInspectionsLookup([]);
			let empty = [];
			dispatch({ type: "REFRESH_PESTINSPECTIONS", empty });
		}
	}
	return (
		<PestInspectionsContext.Provider value={value}>
			{children}
		</PestInspectionsContext.Provider>
	);
};
export default PestInspectionsProvider;

