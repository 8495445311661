import React, { useState,useContext } from 'react'

//internal
import PestInspectionList from './PestInspectionList';
import PestInspectionReportList from './PestInspectionsReportList';
import { StateManagerContext } from '../../contexts/statemanager';


//@material-ui
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import utils from '../../utils/utils';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: '10px 0px 0px 0px'}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const ScoutInspectionsComponent = () => {

	const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        utils.setLocalStorageItem("chartType",event.target.innerText);
    };

    return (
        <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    // variant="fullWidth"
                    TabIndicatorProps={{ style: { background: "#7A9A01" } }}
                >
                    <Tab label="Review" {...a11yProps(0)} />
                    <Tab label="Report" {...a11yProps(1)} />
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <PestInspectionList />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PestInspectionReportList />
            </TabPanel>
        </Box>
    )
}

export default ScoutInspectionsComponent;