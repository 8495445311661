import React, { useState, useContext, useEffect } from 'react'
import utils from '../../utils/utils';
import PestInspectionForm from './PestInspectionForm';
import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PestInspectionsContext } from '../../contexts/pestinspections';
import styled from "@emotion/styled";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CollectionsIcon from '@mui/icons-material/Collections';
import { useCookies } from 'react-cookie';

//Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

//Carousel
import clsx from 'clsx';
import ParallaxSlide from '../Carousel/ParallaxSlide';
import DotIndicator from '../Carousel/DotIndicator';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import { useArrowDarkButtonStyles } from '@mui-treasury/styles/button/arrowDark';
import LockIcon from '@mui/icons-material/Lock';
import { Tooltip } from '@mui/material';

// Theme
const ThemeTextField = styled(TextField)(utils.theme.TextField);
const ThemeSelect = styled(Select)(utils.theme.Select);
const color = "#7A9A01";
const theme = createTheme({
	components: {
		MuiIconButton: {
			styleOverrides: {
				sizeMedium: {
					color
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color
				}
			}
		}
	}
});

export function PestInspectionList(props) {
	const { piListData, getPestInspections, setCloseInspectionSettings } = useContext(PestInspectionsContext);
	var farmsLookupState = utils.getLocalStorageItem("farmsLookup");
	var personsLookupState = utils.getLocalStorageItem("personsLookup");
	var pmZonesLookupState = utils.getLocalStorageItem("pmZonesLookup");
	var inspectionTemplatesLookupState = utils.getLocalStorageItem("inspectionTemplatesLookup");
	const [formContainerVis, setformContainerVis] = useState("form-container dialog-loader-2 dialog-loader-2-hidden");
	const [pestinspectionid, setpestinspectionid] = useState("");
	const [formAction, setFormAction] = useState("");

	const showForm = () => {
		setformContainerVis("form-container dialog-loader-2 dialog-loader-2-show");
	}
	const hideForm = () => {
		setformContainerVis("form-container dialog-loader-2 dialog-loader-2-hidden");
	}
	const prePestInspectionID = (id) => {
		if (id !== "") {
			setpestinspectionid(id);
			setFormAction("edit");
			showForm();
		} else {
			setpestinspectionid(id);
			setFormAction("add");
			showForm();
		}
	}

	if (utils.isempty(piListData.formFilters.template)) {
		piListData.formFilters.template = !utils.isempty(inspectionTemplatesLookupState) ? inspectionTemplatesLookupState[0].ref : "";
		piListData.formFilters.templatedisplayname = !utils.isempty(inspectionTemplatesLookupState) ? inspectionTemplatesLookupState[0].name : "";
	}
	const [formFilters, setFormFilters] = useState(piListData.formFilters);

	const [cookie] = useCookies(["token"]);

	useEffect(() => { getPestInspections(formFilters) }, [])

	//Dialog
	const [openFilterDialog, setOpenFilterDialog] = useState(false);
	const handleFilterDialogClickOpen = () => {
		setOpenFilterDialog(true);
	};
	const handleFilterDialogClose = () => {
		setOpenFilterDialog(false);
	};

	const [openCarouselDialog, setOpenCarouselDialog] = useState(false);
	const handleCarouselDialogOpen = () => {
		setOpenCarouselDialog(true);
	};
	const handleCarouselDialogClose = () => {
		setOpenCarouselDialog(false);
	};
	const [photos, setPhotos] = useState([]);
	const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
		root: {
			// a must if you want to set arrows, indicator as absolute
			position: 'relative',
			width: '100%',
		},
		slide: {
			perspective: 1000, // create perspective
			overflow: 'hidden',
			// relative is a must if you want to create overlapping layers in children
			position: 'relative',
			paddingTop: spacing(2),
			[breakpoints.up('sm')]: {
				paddingTop: spacing(2),
			},
			[breakpoints.up('md')]: {
				paddingTop: spacing(2),
			},
		},
		imageContainer: {
			display: 'block',
			position: 'relative',
			zIndex: 2,
			paddingBottom: '95%',
		},
		image: {
			display: 'block',
			position: 'absolute',
			zIndex: 10,
			width: '100%',
			height: '100%',
			objectFit: 'cover',

		},
		arrow: {
			display: 'none',
			position: 'absolute',
			// top: '50%',
			transform: 'translateY(-400%)',
			[breakpoints.up('sm')]: {
				display: 'inline-flex',
			},
		},
		arrowLeft: {
			left: 0,
			[breakpoints.up('lg')]: {
				left: 8,
			},
		},
		arrowRight: {
			right: 0,
			[breakpoints.up('lg')]: {
				right: -418,
			},
		},
		text: {
			// shared style for text-top and text-bottom
			fontFamily: 'Poppins, san-serif',
			fontWeight: 900,
			position: 'absolute',
			color: palette.common.white,
			padding: '0 8px',
			transform: 'rotateY(45deg)',
			lineHeight: 1.2,
			[breakpoints.up('sm')]: {
				padding: '0 16px',
			},
			[breakpoints.up('md')]: {
				padding: '0 24px',
			},
		},
		title: {
			top: 20,
			left: '20%',
			height: '40%',
			fontSize: 40,
			zIndex: 1,
			background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, #9c9c9c 100%)',
			[breakpoints.up('sm')]: {
				top: 40,
				fontSize: 72,
			},
			[breakpoints.up('md')]: {
				top: 52,
				fontSize: 72,
			},
		},
		subtitle: {
			top: 60,
			left: '0%',
			height: '52%',
			fontSize: 56,
			zIndex: 2,
			background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, #888888 100%)',
			[breakpoints.up('sm')]: {
				top: 112,
				left: '6%',
				fontSize: 96,
			},
			[breakpoints.up('md')]: {
				top: 128,
				fontSize: 104,
			},
		},
		indicatorContainer: {
			textAlign: 'center',
		},
	}));
	const classes = useStyles();
	const arrowStyles = useArrowDarkButtonStyles();

	const renderElements = ({ index, onChangeIndex }) => (
		<>
			<Button
				variant="outlined"
				size="medium"
				className={clsx(classes.arrow, classes.arrowLeft)}
				classes={arrowStyles}
				disabled={index === 0}
				onClick={() => onChangeIndex(index - 1)}
				style={utils.theme.CarouselButton}
			>
				<KeyboardArrowLeftIcon />
			</Button>
			<Button
				variant="outlined"
				size="medium"
				className={clsx(classes.arrow, classes.arrowRight)}
				classes={arrowStyles}
				disabled={index === photos.length - 1}
				onClick={() => onChangeIndex(index + 1)}
				style={utils.theme.CarouselButton}
			>
				<KeyboardArrowRightIcon />
			</Button>
			<div className={classes.indicatorContainer}>
				{photos.map(({ id }, i) => (
					<DotIndicator
						key={id}
						active={i === index}
						onClick={() => onChangeIndex(i)}
					/>
				))}
			</div>
		</>
	);

	const renderChildren = ({ injectStyle, fineIndex }) =>
		photos.map(({ id, image }, i) => (
			<div key={id} className={classes.slide}>
				<div className={classes.imageContainer}>
					<img className={classes.image} src={image} alt={'slide'} />
				</div>
			</div>
		)
		);
	console.warn("piListData", piListData);
	return (
		<>
			<div className={formContainerVis} >
				<PestInspectionForm hideForm={hideForm} pestinspectionid={pestinspectionid} formTemplate={(!utils.isset(formFilters.template) ? piListData.formFilters.template : formFilters.template)} />
			</div>

			<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Button
						onClick={handleFilterDialogClickOpen}
						variant="outlined"
						startIcon={<FilterListIcon />}
						style={utils.theme.Button}
					>
						Filter
					</Button>
				</Box>

				<Box sx={{ alignSelf: 'center', display: 'flex' }}>
					<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
						<span style={{ color: '#7a9a01', marginRight: '5px' }}>Start Date:</span>
						<span style={{ color: 'GrayText' }}>{typeof formFilters.startDate == 'object' || typeof formFilters.startDate == 'string' ? moment(formFilters.startDate).format("DD-MM-YYYY") : formFilters.startDate}</span>
					</div>
					<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
						<span style={{ color: '#7a9a01', marginRight: '5px' }}>End Date:</span>
						<span style={{ color: 'GrayText' }}>{typeof formFilters.endDate == 'object' || typeof formFilters.endDate == 'string' ? moment(formFilters.endDate).format("DD-MM-YYYY") : formFilters.endDate}</span>
					</div>
					<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
						<span style={{ color: '#7a9a01', marginRight: '5px' }}>Status:</span>
						<span style={{ color: 'GrayText' }}>{formFilters.status === "in_review" ? "In Review" : formFilters.status.charAt(0).toUpperCase() + formFilters.status.slice(1)}</span>
					</div>
					<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column' }}>
						<span style={{ color: '#7a9a01', marginRight: '5px' }}>Template:</span>
						<span style={{ color: 'GrayText' }}>{formFilters.templatedisplayname}</span>
					</div>
				</Box>
				<Box>
					<Button
						onClick={() => { prePestInspectionID("") }}
						variant="outlined"
						startIcon={<AddCircleIcon />}
						sx={{ "&:hover": { color: "#52312d", borderColor: "#52312d" } }}
						style={{ color: "#7A9A01", borderColor: "#7A9A01" }}
					>
						Add
					</Button>
				</Box>

				<Dialog
					open={openFilterDialog}
					onClose={handleFilterDialogClose}
					fullWidth={true}
					maxWidth={'sm'}
				>
					<DialogTitle
						id="alert-dialog-title"
						sx={{ m: 0, p: 2, color: '#7A9A01' }}
					>
						List Filters
						<IconButton
							aria-label="close"
							onClick={handleFilterDialogClose}
							sx={{ position: 'absolute', right: 8, top: 8, color: '#7A9A01' }}
						>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent dividers >
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<Box sx={{ margin: '5px' }}>
								<ThemeProvider theme={theme}>
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											label="Start Date"
											value={formFilters.startDate}
											inputFormat="DD/MM/yyyy"
											renderInput={(params) => <ThemeTextField {...params} sx={{ width: '400px' }} />}
											onChange={(tdate) => {
												setFormFilters({ ...formFilters, startDate: tdate.format("YYYY-MM-DD HH:mm:ss") });
											}}
										/>
									</LocalizationProvider>
									<FormHelperText sx={{ paddingLeft: '14px' }}>Filter by start date</FormHelperText>
								</ThemeProvider>
							</Box>
							<Box sx={{ margin: '5px' }}>
								<ThemeProvider theme={theme}>
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											label="End Date"
											value={formFilters.endDate}
											inputFormat="DD/MM/yyyy"
											renderInput={(params) => <ThemeTextField style={{}} {...params} sx={{ width: '400px' }} />}
											onChange={(tdate) => {
												setFormFilters({ ...formFilters, endDate: tdate.format("YYYY-MM-DD HH:mm:ss") });
											}}
										/>
									</LocalizationProvider>
									<FormHelperText sx={{ paddingLeft: '14px' }}>Filter by end date</FormHelperText>
								</ThemeProvider>
							</Box>
							<Box sx={{ margin: '5px' }}>
								<FormControl sx={{ m: 1, minWidth: 120 }} >
									<InputLabel sx={{ color: "#7A9A01", '&.Mui-focused': { color: '#7A9A01' } }} id="pest-inspection-status">Status</InputLabel>
									<ThemeSelect
										labelId="pest-inspection-status-label"
										id="pest-inspection-simple-select-disabled"
										value={formFilters.status}
										label="Status"
										sx={{ width: '400px' }}
										onChange={(event) =>
											setFormFilters({ ...formFilters, status: event.target.value })
										}
										MenuProps={{
											sx: {
												"&& .Mui-selected": {
													backgroundColor: "rgb(199, 212, 148)",
													color: "rgb(122, 154, 1)"
												},
											}
										}}
									>
										<MenuItem key={0} value={"active"}>Acive</MenuItem>
										<MenuItem key={1} value={"closed"}>Closed</MenuItem>
										<MenuItem key={2} value={"in_review"}>In Review</MenuItem>
										<MenuItem key={3} value={"deleted"}>Deleted</MenuItem>
									</ThemeSelect>
									<FormHelperText>Filter list by status</FormHelperText>
								</FormControl>
							</Box>
							<Box sx={{ margin: '5px' }}>
								<FormControl sx={{ m: 1, minWidth: 120 }} >
									<InputLabel sx={{ color: "#7A9A01", '&.Mui-focused': { color: '#7A9A01' } }} id="pest-inspection-template">Template</InputLabel>
									<ThemeSelect
										labelId="pest-inspection-template-label"
										id="pest-inspection-template-simple-select-disabled"
										value={formFilters.template}
										label="Template"
										sx={{ width: '400px' }}
										onChange={(event) => {
											let templatedisplayname = "";
											utils.each(inspectionTemplatesLookupState, function (it, itind) {
												if (event.target.value === it.ref) {
													templatedisplayname = it.name;
												}
											});

											setFormFilters({ ...formFilters, template: event.target.value, templatedisplayname:templatedisplayname });
											}
										}
										MenuProps={{
											sx: {
												"&& .Mui-selected": {
													backgroundColor: "rgb(199, 212, 148)",
													color: "rgb(122, 154, 1)"
												},
											}
										}}
									>
										{
											inspectionTemplatesLookupState.map((template) => {
												return (<MenuItem key={utils.uuid()} value={template.ref}>{template.name}</MenuItem>)
											})
										}
									</ThemeSelect>
									<FormHelperText>Filter list by template</FormHelperText>
								</FormControl>
							</Box>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => { getPestInspections(formFilters); setOpenFilterDialog(false) }}
							autoFocus
							variant="outlined"
							style={utils.theme.Button}
						>
							Apply Filters
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={openCarouselDialog}
					onClose={handleCarouselDialogClose}
					fullWidth={true}
					maxWidth={'sm'}
				>
					<DialogContent>
						<div className={classes.root}>
							<ParallaxSlide renderElements={renderElements}>
								{renderChildren}
							</ParallaxSlide>
						</div>
					</DialogContent>
				</Dialog>

				{/* <Box>
					<Button
						onClick={() => { prePestInspectionID("") }}
						variant="outlined"
						startIcon={<AddCircleIcon />}
						sx={{ "&:hover": { color: "#52312d", borderColor: "#52312d" } }}
						style={{ color: "#7A9A01", borderColor: "#7A9A01" }}
					>
						Add
					</Button>
				</Box> */}
			</Box>

			<Box sx={{ width: '100%', marginTop: '5px' }}>
				<div className="nexus-scroll-table">
					<table>
						<thead>
							<tr>
								<th>Date</th>
								<th>Scout</th>
								<th>Farm</th>
								<th>Orchard</th>
								<th>Images</th>
								<th>Edit</th>
								<th>
									<IconButton onClick={() => { return false }}
										color="primary"
										style={{ color: "#7A9A01", display: "inline-flex", padding: "2px", paddingLeft: "0px" }}
									>
									</IconButton>
									{(formFilters.status === "in_review" && piListData.tdata.length > 0) ?
										<IconButton onClick={() => {
											var rids = [];
											utils.each(piListData.tdata, function (i, ind) {
												rids.push(i.rid);
											});
											setCloseInspectionSettings({ data: { rids: rids } });
										}
										}
											color="primary"
											sx={{ "&:hover": { color: "#789a3d" } }}
											style={{ color: "#7A9A01", display: "inline-flex", padding: "2px", paddingLeft: "0px" }}
										>
											<Tooltip id="button-close-inspection" title="Close all inspections.">
												<LockIcon />
											</Tooltip>
										</IconButton>
										:
										null
									}
								</th>
							</tr>
						</thead>
						<tbody>
							{
								(!utils.isempty(piListData.tdata)) ?
									piListData.tdata.map(inspection => (
										<tr key={utils.uuid()}>
											<td>{moment(inspection.date_performed, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</td>
											<td>{(!utils.isempty(inspection.performed_by) ? utils.getName(personsLookupState, inspection.performed_by) : "")}</td>
											<td>{(!utils.isempty(inspection.farm) ? utils.getName(farmsLookupState, inspection.farm) : "")}</td>
											<td>{(!utils.isempty(inspection.zone) ? utils.getName(pmZonesLookupState, inspection.zone) : "")}</td>
											<td>
												{(!utils.isset(inspection.photos) || utils.isempty(inspection.photos)) ?
													<IconButton
														color="primary"
														sx={{ "&:hover": { color: "#789a3d" } }}
														style={{ color: "grey" }}
													>
														<Tooltip id="button-close-inspection" title="No photos to view.">
															<CollectionsIcon />
														</Tooltip>
													</IconButton>
													:
													<IconButton onClick={() => {
														utils.getCDNFiles(inspection.photos, cookie, function (photos) {
															let tphotos = [];
															utils.each(photos, function (img, ind) {
																if (!img) { return true; }
																tphotos.push({ id: ind, image: img });
															});
															setPhotos(tphotos);
															handleCarouselDialogOpen();
														});
													}}
														color="primary"
														sx={{ "&:hover": { color: "#789a3d" } }}
														style={{ color: "#7A9A01" }}
													>
														<Tooltip id="button-close-inspection" title="View photos.">
															<CollectionsIcon />
														</Tooltip>
													</IconButton>
												}
											</td>
											<td>
												<IconButton onClick={() => { prePestInspectionID(inspection.rid) }}
													color="primary"
													sx={{ "&:hover": { color: "#789a3d" } }}
													style={{ color: "#7A9A01" }}
												>
													<Tooltip id="button-edit-inspection" title="Edit this inspection.">
														<EditIcon />
													</Tooltip>
												</IconButton>

											</td>
											<td>
												{(inspection.status === "in_review") ?
													<IconButton onClick={() => { setCloseInspectionSettings({ data: { rids: [inspection.rid] } }) }}
														color="primary"
														sx={{ "&:hover": { color: "#789a3d" } }}
														style={{ color: "#7A9A01", display: "inline-flex", padding: "2px" }}
													>
														<Tooltip id="button-close-inspection" title="Close this inspection.">
															<LockIcon />
														</Tooltip>
													</IconButton>
													:
													null
												}
											</td>
										</tr>
									))
									: ""
							}
						</tbody>
					</table>
				</div>
			</Box>
		</>
	)
}

export default PestInspectionList;